<!--
 * @Author: zhanln
 * @Date: 2021-12-01 16:58:49
 * @LastEditTime: 2021-12-30 16:23:08
 * @LastEditors: zhanln
 * @Description: 员工选择器 - 按钮样式
-->

<template>
  <div class="emp">
    <!-- 选择列表 -->
    <template v-if="showSelect">
      <div class="emp-item" v-for="(item, index) in selected" :key="index">
        <img
          class="emp-item__avatar"
          :src="item.avatar_url || defaultAvatar"
          alt=""
        />
        <span class="emp-item__name">{{
          item.name.length > 4 ? item.name.slice(0, 3) + "..." : item.name
        }}</span>
        <span class="emp-item__close" @click="fn_removeItem(index)">
          <i class="iconfont icon-close-fill"></i>
        </span>
      </div>
    </template>

    <!-- 选择按钮 -->
    <el-button
      size="small"
      class="emp-btn"
      plain
      round
      icon="iconfont icon-plus"
      type="primary"
      @click="fn_showSelect"
      >{{ btnText }}</el-button
    >

    <!-- 选择器 -->
    <ly-emp
      ref="lyEmpSelectRef"
      :title="title || btnText"
      :checkListProp="selected"
      :max="max"
      :isReal="isReal"
      :modal="modal"
      :datafilter="datafilter"
      :diabledList="diabledList"
      @getEmpId="fn_getEmpId"
    ></ly-emp>
  </div>
</template>

<script>
import scaleAvatar from '@/common/scaleAvatar'
export default {
  name: 'ly-empSelect',

  props: {
    btnText: {
      type: String,
      default: '选择成员'
    },
    title: {
      type: String,
      default: ''
    },
    checkList: {
      type: Array,
      default: () => {
        return []
      }
    },
    max: {
      type: [Number, null],
      default: null
    },
    isReal: {
      type: Boolean,
      default: false
    },
    datafilter: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: true
    },
    diabledList: {
      type: Array,
      default: () => {
        return []
      }
    },
    showSelect: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      selected: [],
      defaultAvatar: require('@assets/images/default_avatar.png'),
      firstLoad: true
    }
  },

  watch: {
    async checkList (val) {
      if (val && this.firstLoad) {
        this.firstLoad = false
        this.selected = await this.fn_getEmpForId(val)
      }
    }
  },

  async mounted () {
    if (this.checkList && this.checkList.length > 0 && this.firstLoad) {
      this.firstLoad = false
      this.selected = await this.fn_getEmpForId(this.checkList)
    }
  },

  methods: {
    // 打开弹框
    fn_showSelect () {
      this.$refs.lyEmpSelectRef.visible = true
      if (this.diabledList.length > 0) {
        this.selected = []
      }
    },

    // id 获取员工数据
    async fn_getEmpForId (emps) {
      const data = await this.axios.get('empUsers', {
        params: {
          user_ids: emps.join(',')
        }
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return []
      }

      if (!data.data) {
        return []
      }

      return data.data.map(item => {
        item.avatar_url = scaleAvatar(item.avatar_url)
        return item
      })
    },

    // 移除
    fn_removeItem (index) {
      this.selected.splice(index, 1)
      this.fn_handleCallback(this.selected)
    },

    // 弹框回调
    async fn_getEmpId (data) {
      this.firstLoad = false
      if (data.length > 0) {
        const ids = data.map(item => item.base_id || item.id)
        const emps = await this.fn_getEmpForId(ids)
        this.fn_handleCallback(emps)
        this.selected = emps
      } else {
        this.selected = []
        this.fn_handleCallback([])
      }
    },

    // 回调
    fn_handleCallback (data) {
      if (this.$listeners.getEmpId) {
        if (data.length > 0) {
          const ids = data.map(item => item.base_id || item.id)
          this.$emit('getEmpId', ids)
        } else {
          this.$emit('getEmpId', null)
        }
      }
      if (this.$listeners.getEmpIdArr) {
        data.forEach(item => {
          item.id = item.base_id || item.id
          delete item.base_id
        })
        this.$emit('getEmpIdArr', data)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.emp {
  display: flex;
  flex-wrap: wrap;

  &-item {
    display: inline-flex;
    height: 32px;
    align-items: center;
    padding: 0 8px;
    border-radius: 32px;
    background-color: #f7f8fc;
    margin: 3px 0;
    margin-right: 8px;

    &__avatar {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      margin-right: 4px;
    }

    &__name {
      margin-right: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    i {
      color: #b4bbcc;
      cursor: pointer;

      &:hover {
        color: #a5a5a5;
      }
    }
  }

  &-btn {
    height: 32px;
    font-size: 14px;
    padding: 0 15px !important;
    line-height: 32px !important;
    margin: 3px 0;
  }
}
</style>
