<!--
 * @Descripttion:
 * @version:
 * @Author: lw
 * @Date: 2021-05-13 18:18:19
 * @LastEditTime: 2021-12-15 15:38:37
-->
<template>
  <div class="wx-qr">
    <div class="left-area">
      <div
        class="wxpay-box"
        v-loading="$attrs.loading"
        element-loading-spinner="el-icon-loading"
      >
        <div v-if="$attrs.error" class="net-error">
          <div class="error-desc">网络错误</div>
          <span class="recharge-btn" @click="$emit('refresh')">点击刷新</span>
        </div>
        <img class="wxpay-qr" v-if="$attrs.qr" :src="$attrs.qr" />
      </div>
    </div>
    <div class="right-area">
      <div class="wx-pay-desc">
        <div class="flex">
          <img class="wx-pay-icon" src="@assets/images/wx_pay.png" alt="wx" />
          <span>微信扫码支付</span>
        </div>
        <span class="amount"><span style="font-size: 18px">￥</span>{{ $attrs.total }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.wx-qr {
  border-radius: 8px;
  border: 1px solid #EAEBF2;
  display: flex;
  align-items: center;
  height: 130px;
  .wx-pay-desc {
    float: left;
  }
  .left-area,
  .right-area{
    width: 50%;
  }
  .left-area{
    padding-right: 16px;
    box-sizing: border-box;
    .wxpay-box{
      float: right;
    }
  }
  .wx-pay-icon {
    margin-right: 5px;
  }
  .amount {
    font-size: 30px;
    color: #ECAB0E;
    margin: 0 3px;
  }
  .wxpay-qr {
    width: 110px;
    height: 110px;
    display: block;
  }
  .net-error {
    margin: auto;
    text-align: center;
    background: rgba(255, 255, 255, 0.9);
    width: 150px;
    height: 150px;
    position: absolute;
    z-index: 9;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .error-desc {
    color: #212b36;
    margin-top: 35px;
  }
  .recharge-btn {
    display: inline-block;
    width: 80px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #fff;
    background: #2B7CFF;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  ::v-deep {
    .el-loading-spinner i {
      font-size: 18px;
      color: #8e8e8e;
    }
  }
}
</style>
