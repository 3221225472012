var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-aside", { staticClass: "ly-side", attrs: { width: "260px" } }, [
    _c(
      "div",
      { staticClass: "ly-side-bar" },
      [
        _c(
          "el-menu",
          {
            attrs: {
              "default-openeds": _vm.defaultOpeneds,
              "default-active": _vm.defaultActive
            }
          },
          [
            _c(
              "el-scrollbar",
              { staticStyle: { height: "100%" } },
              _vm._l(_vm.menus, function(submenu) {
                return _c(
                  "el-submenu",
                  {
                    key: submenu.path,
                    class: {
                      "class-active": _vm.defaultPid === submenu.meta.id
                    },
                    attrs: { index: submenu.path }
                  },
                  [
                    _c("template", { slot: "title" }, [
                      _c("img", {
                        staticClass: "icon",
                        attrs: {
                          src: require("@assets/svg/sidebar/" +
                            submenu.icon[_vm.getIconIndex(submenu)])
                        }
                      }),
                      _c("span", [_vm._v(_vm._s(submenu.meta.title))])
                    ]),
                    _vm._l(submenu.children, function(item) {
                      return _c(
                        "el-menu-item",
                        { key: item.path, attrs: { index: item.path } },
                        [
                          _c(
                            "router-link",
                            {
                              class: item.badge
                                ? "brage brage-" + item.badge
                                : "",
                              attrs: { to: item.path }
                            },
                            [_vm._v(_vm._s(item.title) + " ")]
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              }),
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }