<!--
 * @Author: zhanln
 * @Date: 2021-12-01 17:34:31
 * @LastEditTime: 2022-09-15 10:25:29
 * @LastEditors: zhanln
 * @Description: 选择弹框
-->

<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="visible"
      width="780px"
      :close-on-click-modal="false"
      :modal="modal"
      append-to-body
    >
      <div class="emp-box">
        <!-- 成员 -->
        <div
          class="emp-box__left"
          ref="empBoxRef"
          v-loading="dataLoading"
          element-loading-text="数据加载中，请稍后~"
        >
          <div class="emp-box__left--header">
            <div class="emp-box__search">
              <el-input
                placeholder="搜索成员"
                v-model="searchName"
                prefix-icon="el-icon-search"
                v-lydebounce="['input', fn_search]"
                class="emp-box__search--input"
              ></el-input>
              <el-button
                icon="el-icon-circle-close"
                class="emp-box__search--close"
                type="text"
                circle
                @click="fn_clearSearch"
                v-if="searchName"
              ></el-button>
            </div>
            <div class="emp-tree__total">全部成员（{{ total }}）</div>
          </div>

          <!-- 部门、成员树 -->
          <el-scrollbar class="emp-tree__scroll" ref="empScollRef">
            <el-tree
              ref="empTreeRef"
              class="emp-tree"
              :load="fn_loadNode"
              lazy
              nodeKey="id"
              :props="treeProps"
              :default-expanded-keys="defaultExpanded"
              @node-click="fn_nodeClick"
              v-if="visible"
            >
              <div slot-scope="{ node, data }" class="tree-content">
                <template v-if="data.corp_department_id">
                  <i class="iconfont icon-folder-fill q-primary"></i>
                  <span class="emp-tree__label">
                    {{ data.name }}
                  </span>
                </template>

                <template v-if="data.base_id">
                  <div class="emp-tree__item">
                    <div class="emp-tree__avatar">
                      <img :src="data.avatar_url || defaultAvatar" alt="" />
                    </div>

                    <div class="emp-tree__info">
                      <span class="name">
                        {{ data.name }}
                      </span>
                      <span
                        class="role"
                        :class="[
                          data.role_id === 4 || data.role_id === 1
                            ? 'super'
                            : 'normal'
                        ]"
                      >
                        {{ data.role_name }}
                      </span>
                      <span class="dep">
                        部门：{{ data.wecom_departments.join('，') }}
                      </span>
                    </div>
                    <el-checkbox
                      v-model="node.checked"
                      :disabled="data.isDisable"
                      @click.prevent.native
                    ></el-checkbox>
                  </div>
                </template>
                <template v-if="data.load_more">
                  <div class="emp-tree__load" :class="{ active: data.loading }">
                    <i class="el-icon-refresh-right"></i>加载更多
                  </div>
                </template>
              </div>
            </el-tree>
          </el-scrollbar>

          <!-- 搜索结果 -->
          <search-box
            ref="searchBoxRef"
            :checkList="checkList"
            :diabledList="diabledList"
            :datafilter="datafilter"
            @setCheck="fn_setCheck"
          >
          </search-box>
        </div>

        <!-- 右侧已选 -->
        <div class="emp-box__right">
          <div class="emp-checked__count">
            已选择
            <span
              :class="[
                max && checkList.length > max ? 'q-danger' : 'q-primary'
              ]"
              >{{ checkList.length }} </span
            >{{ max ? `/ ${max}` : '' }}
            成员
          </div>
          <el-scrollbar class="emp-checked__scroll">
            <div
              class="emp-checked__item"
              v-for="(item, index) of checkList"
              :key="index"
            >
              <div class="emp-checked__info">
                <img
                  class="emp-checked__avatar"
                  :src="item.avatar_url || defaultAvatar"
                  alt=""
                />
                <span class="emp-checked__name">{{ item.name }}</span>
              </div>
              <div
                class="emp-checked__remove"
                @click="fn_removeItem(item, index)"
              >
                <i class="el-icon-close"></i>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>

      <!-- footer -->
      <span slot="footer">
        <div class="flex flex-between">
          <el-button
            round
            @click="fn_clearCheck"
            :disabled="dataLoading || checkList.length === 0"
            >清空所选</el-button
          >
          <div>
            <el-button round @click="visible = false" :disabled="dataLoading"
              >取消</el-button
            >

            <el-tooltip
              class="item"
              effect="dark"
              :content="`最多可选 ${max} 名成员`"
              placement="top"
              :disabled="
                max === null || (max !== null && checkList.length <= max)
              "
            >
              <span style="margin-left: 12px">
                <el-button
                  round
                  type="primary"
                  @click="fn_confirm"
                  :loading="btnLoading"
                  :disabled="dataLoading || (max && checkList.length > max)"
                  >确定</el-button
                >
              </span>
            </el-tooltip>
          </div>
        </div>
      </span>
    </el-dialog>

    <emp-tip ref="empTipRef" :searchName="searchName"></emp-tip>
  </div>
</template>

<script>
import scaleAvatar from '@/common/scaleAvatar'
import empTip from './empTip'
import searchBox from './searchBox.vue'
export default {
  name: 'empSelect',

  components: {
    empTip,
    searchBox
  },

  props: {
    modal: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    max: {
      type: [Number, null],
      default: null
    },
    isReal: {
      type: Boolean,
      default: false
    },
    checkListProp: {
      type: Array,
      default: () => {
        return []
      }
    },
    diabledList: {
      type: Array,
      default: () => {
        return []
      }
    },
    datafilter: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      visible: false,
      dataLoading: true,
      searchName: null,
      btnLoading: false,
      total: null,
      depList: null,
      depPage: 1,
      depPerPage: 9999,
      treeProps: {
        children: 'children',
        label: 'name',
        isLeaf: 'leaf'
      },
      defaultExpanded: [],
      empLoadLength: 20,
      defaultAvatar: require('@assets/images/default_avatar.png'),
      allEmps: [],
      checkList: [],
      checkListOnly: [],
      params: {
        status: -1,
        is_auth_app: -1,
        is_auth_third_app: -1
      }
    }
  },

  watch: {
    async visible (val) {
      if (val) {
      } else {
        this.depList = null
        this.allEmps = []
        this.checkList = []
        this.defaultExpanded = []
        this.btnLoading = false
        this.searchName = null
        this.$refs.searchBoxRef.visible = false
        this.dataLoading = true
      }
    }
  },

  methods: {

    // 懒加载处理
    async fn_loadNode (node, resolve) {
      if (this.dataLoading) {
        // 过滤参数配置
        this.fn_setParams()

        // 如果有回显
        if (this.checkListProp && this.checkListProp.length > 0) {
          this.checkList = await this.fn_initEmps(this.checkListProp, 'base', false)
        }

        // 部门数据
        this.depList = await this.fn_getDep()

        // 成员总数
        const all = await this.fn_getEmpForDep({ page: 1, per_page: 1 })
        this.total = all.total
      }

      // 加载子数据
      const topDep = await this.fn_loadChildren(node, resolve)

      setTimeout(() => {
        for (let i = 0; i < topDep.length; i++) {
          this.fn_setCheckLoad(topDep[i])
        }
      }, 150)
    },

    // 子数据
    async fn_loadChildren (node, resolve) {
      const firstId = this.depList.findIndex(item => item.corp_department_id === 1)
      const corpId = node.data ? node.data.corp_department_id : this.depList[firstId].corp_parent_id

      const topDep = this.fn_getDepForId(corpId)

      if (node.level === 0) {
        // 一级部门
        resolve(topDep)
        this.defaultExpanded.push(topDep[0].id)
        this.dataLoading = false
        return topDep
      } else {
        // 其他部门
        if (node.data.total === null) {
          const emps = await this.fn_loadEmpFirst(node.data)
          if (emps) {
            topDep.unshift(...emps)
          }
        }
        setTimeout(() => {
          resolve(topDep)
        }, 150)
        return topDep
      }
    },

    // 设置过滤参数
    fn_setParams () {
      if (this.datafilter) {
        this.params.status = 1
        this.params.is_auth_app = 1
        this.params.is_auth_third_app = -1
      }
    },

    // 根据父级部门id获取子级部门
    fn_getDepForId (parentId) {
      const dep = this.depList.filter(item => item.corp_parent_id === parentId)
      for (let i = 0; i < dep.length; i++) {
        const hasChildren = this.depList.findIndex(item => item.corp_parent_id === dep[i].corp_department_id)
        if (hasChildren > -1 && dep[i].leaf) {
          dep[i].leaf = false
        }
      }
      return dep
    },

    // 打开弹框时初始化
    async fn_init () {
      // 如果有回显
      if (this.checkListProp && this.checkListProp.length > 0) {
        this.checkList = await this.fn_initEmps(this.checkListProp, 'base', false)
      }

      // 成员总数
      const all = await this.fn_getEmpForDep({ page: 1, per_page: 1 })
      this.total = all.total

      // 部门树
      this.treeData = await this.fn_getDep()
      // 获取一级部门的成员
      if (this.treeData) {
        this.$nextTick(async () => {
          await this.fn_loadEmpFirst(this.treeData[0])
          this.dataLoading = false
        })
      }
    },

    // 获取部门数据
    async fn_getDep () {
      this.params.page = this.depPage
      this.params.per_page = this.depPerPage
      const data = await this.axios.get('wecomDepartmentsNoauth', {
        params: this.params
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      const deps = data.data

      if ((deps && !deps.length)) return false

      // 是否有公司级主部门
      const hasCorpDep = deps.findIndex(item => item.corp_parent_id === 0)

      // 找不到父级部门的子部门，统一放到一级部门组下
      for (let i = 0; i < deps.length; i++) {
        // 如果是一级部门，跳过
        if (deps[i].corp_department_id === 1) {
          continue
        }
        const corpId = deps[i].corp_parent_id
        const hasParent = deps.findIndex(item => item.corp_department_id === corpId)
        if (hasParent === -1) {
          deps[i].corp_parent_id = hasCorpDep > -1 ? 1 : 0
        }
      }

      return deps.map(item => {
        item.page = 0
        item.emp_total = 0
        item.total = null
        item.leaf = item.user_num === 0
        return item
      })
    },

    // 部门数据层级处理
    async fn_initDep (depData) {
      let root

      const idMapping = depData.reduce((acc, el, i) => {
        acc[el.corp_department_id] = i
        return acc
      }, {})

      depData.forEach(el => {
        el.page = 0
        el.emp_total = 0
        el.total = null
        el.children = []
        if (!el.name) return
        if (el.corp_parent_id === 0) {
          root = [el]
          return
        }

        const parentEl = depData[idMapping[el.corp_parent_id]]
        parentEl.children = [...(parentEl.children || []), el]
      })

      return root
    },

    // 首次加载部门成员
    async fn_loadEmpFirst (pnode) {
      // 设置分页
      pnode.page += 1

      // 获取成员
      const data = await this.fn_getEmpForDep(pnode)
      if (!data) {
        pnode.total = 0
        return false
      }

      // 成员总数
      pnode.total = pnode.total || data.total

      // 已加载成员数
      pnode.emp_total = data.data.length

      // 成员数据处理，防止多部门时成员id重复
      const emps = this.fn_initEmps(data.data, pnode.id)

      // 如果已加载的成员数量少于成员总数，显示“加载更多”
      if (pnode.emp_total < pnode.total) {
        const loadEl = this.fn_initLoadEl(pnode, emps[emps.length - 1].id)
        emps.push(loadEl)
      }

      return emps
    },

    // 部门加载数据
    async fn_loadEmpMore (pnode) {
      // laoding
      pnode.loading = true

      // 获取成员数据
      const params = {
        id: pnode.dep_id,
        page: pnode.page
      }
      const data = await this.fn_getEmpForDep(params)
      if (!data) {
        pnode.loading = false
        return false
      }

      const emps = this.fn_initEmps(data.data, pnode.id)

      // 插入到“加载更多”前面
      this.fn_insertBefore(emps, pnode.id)

      // 设置部门已加载成员数量
      const parent = this.$refs.empTreeRef.getNode(pnode.dep_id).data
      parent.emp_total += emps.length

      // 存在未加载，页码 + 1
      // 全部加载完毕，移除“加载更多”
      if (parent.emp_total < parent.total) {
        pnode.page += 1
        pnode.loading = false
      } else {
        this.$refs.empTreeRef.remove(pnode.id)
      }
    },

    // 插入数据到节点前面
    fn_insertBefore (data, nextId) {
      for (let i = 0; i < data.length; i++) {
        this.$refs.empTreeRef.insertBefore(data[i], nextId)
        this.fn_setCheckLoad(data[i])
      }
    },

    // 按部门获取成员
    async fn_getEmpForDep (pnode) {
      this.params.page = pnode.page
      this.params.per_page = pnode.per_page || this.empLoadLength
      this.params.depart_id = pnode.id || null
      const data = await this.axios.get('empAll', {
        params: this.params
      })

      if ((data && data.code) || (data && data.code === 0)) {
        return this.fn_lyMsg('info', data.msg)
      }

      if (!data.data) {
        return false
      }

      return data
    },

    // 成员数据处理，防止id重复
    fn_initEmps (data, pid, push = true) {
      const emps = data.map(item => {
        item.leaf = true
        if (!item.base_id) {
          item.base_id = item.id
          item.id = `${pid}_e_${item.base_id}`
          item.avatar_url = scaleAvatar(item.avatar_url)
        }
        if (push) {
          this.allEmps.push(item)
        }
        if (this.diabledList.length > 0) {
          const isDisable = this.diabledList.includes(item.role_id)
          if (isDisable) {
            item.isDisable = isDisable
            this.checkListOnly.push(item)
          }
        }
        return item
      })
      return emps
    },

    // 生产加载更多
    fn_initLoadEl (pnode, prevId) {
      const loadEl = {
        load_more: true,
        id: `${pnode.id}_load`,
        dep_id: pnode.id,
        page: pnode.page + 1,
        prev_id: prevId,
        loading: false,
        leaf: true
      }
      return loadEl
    },

    // 加载的数据设置选中
    fn_setCheckLoad (data) {
      for (let j = 0; j < this.checkList.length; j++) {
        if (data.base_id === this.checkList[j].base_id || data.base_id === this.checkList[j].id) {
          this.$refs.empTreeRef.setChecked(data.id, true)
        }
      }
      if (this.checkListOnly.length > 0) {
        for (let j = 0; j < this.checkListOnly.length; j++) {
          if (data.base_id === this.checkListOnly[j].base_id || data.base_id === this.checkListOnly[j].id) {
            this.$refs.empTreeRef.setChecked(data.id, true)
          }
        }
      }
    },

    // 点击节点
    async fn_nodeClick (data, node) {
      if (data.isDisable) return false

      // 如果是成员
      if (data.base_id) {
        this.$refs.empTreeRef.setChecked(data, !node.checked)
        this.$nextTick(() => {
          this.fn_setCheck(data, node.checked)
        })
      }
      // 如果是加载更多
      if (data.load_more) {
        this.fn_loadEmpMore(data)
      }
    },

    // 选中节点
    fn_setCheck (data, check, type = true) {
      if (type) {
        this.fn_setCheckList(data, check)
      }
      const all = this.allEmps
      const eid = data.base_id || data.id
      for (let i = 0; i < all.length; i++) {
        if (all[i].base_id === eid) {
          if (type) {
            if (all[i].id !== eid) {
              this.$refs.empTreeRef.setChecked(all[i].id, check)
            }
          } else {
            this.$refs.empTreeRef.setChecked(all[i].id, check)
          }
        }
      }
    },

    // 选中列表处理
    fn_setCheckList (data, check) {
      const hasEmp = this.checkList.findIndex(item => {
        const base_id = data.base_id || data.id
        const item_id = item.base_id || item.id
        return base_id === item_id
      })
      // 增加
      if (check) {
        if (hasEmp > -1) return false
        this.checkList.push(data)
      } else {
        if (hasEmp > -1) {
          this.checkList.splice(hasEmp, 1)
        }
      }
    },

    // 移除
    fn_removeItem (data, index) {
      this.fn_setCheck(data, false, false)
      this.checkList.splice(index, 1)
      if (this.searchName) {
        this.$refs.searchBoxRef.fn_removeItem(data)
      }
    },

    // 清空
    fn_clearCheck () {
      setTimeout(() => {
        for (let i = 0; i < this.checkList.length; i++) {
          if (this.searchName) {
            this.$refs.searchBoxRef.fn_removeItem(this.checkList[i])
          }
          this.fn_removeItem(this.checkList[i], false)
          i--
        }
      })
    },

    // 确定
    async fn_confirm () {
      if (this.checkList.length === 0) {
        this.fn_handleCallback([])
        return false
      }

      if (this.isReal) {
        this.btnLoading = true
        const ids = this.checkList.map(item => item.base_id || item.id).join(',')
        const valid = await this.fn_wecomQrcodeUsersValid(ids)
        if (!valid) {
          this.$refs.empTipRef.visible = true
          this.btnLoading = false
          return false
        }
      }

      this.fn_handleCallback(this.checkList)
    },

    // 实名制判断
    async fn_wecomQrcodeUsersValid (ids) {
      const data = await this.axios.get('wecomQrcodeUsersValid', {
        params: {
          wecom_user_ids: ids
        }
      })

      if ((data && data.code) || (data && data.code === 0)) {
        return this.fn_lyMsg('info', data.msg)
      }

      if (data.status === 0) {
        return false
      }
      return true
    },

    // 回调
    fn_handleCallback (data) {
      if (this.$listeners.getEmpId) {
        this.$emit('getEmpId', data)
      }
      this.visible = false
    },

    // 搜索
    fn_search () {
      if (this.searchName) {
        this.$refs.searchBoxRef.visible = true
        this.$refs.searchBoxRef.fn_getData(this.searchName)
      } else {
        this.$refs.searchBoxRef.visible = false
      }
    },

    // 取消搜索
    fn_clearSearch () {
      this.searchName = ''
      this.fn_search()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@assets/scss/var.scss';

.emp {
  &-box {
    display: flex;
    height: 420px;
    overflow: hidden;
    border: 1px solid #eaebf2;
    border-radius: 8px;

    &__left,
    &__right {
      box-sizing: border-box;
    }

    &__left {
      position: relative;
      width: 50%;
      border-right: 1px solid #eaebf2;

      &--header {
        padding: 16px 16px 0;
      }
    }

    &__right {
      flex: 1;
      padding-top: 16px;
      box-sizing: border-box;
    }

    &__search {
      display: flex;
      align-items: center;
      position: relative;

      &--input {
        transition: 0.25s;

        ::v-deep .el-input__inner {
          padding-right: 32px;
        }
      }

      &--close {
        position: absolute;
        top: 0px;
        right: 0px;
        color: #a2a9b8;

        &:hover {
          color: $--color-danger;
        }
      }
    }
  }

  &-search {
    &__select {
      width: 300px;
      margin-right: 8px;
    }
  }

  &-tree {
    user-select: none;

    &__total {
      color: #212b36;
      margin: 10px 0;
      line-height: 20px;
    }

    &__scroll {
      height: calc(100% - 92px);
    }

    .icon-folder-fill {
      font-size: 22px;
      margin-right: 4px;
    }

    &__item {
      display: flex;
      overflow: hidden;
      padding: 8px 0;
      padding-right: 48px;
      box-sizing: border-box;
      width: 100%;
    }

    &__info {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      overflow: hidden;

      span {
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }

      .role {
        font-size: 12px;
        background-color: #f0f1f8;
        border-radius: 2px;
        padding: 4px;
        color: #454d5b;
        margin: 4px 0;
        line-height: 1;

        &.super {
          background-color: rgba(#ffc107, 0.1);
          color: #ffc107;
        }
      }

      .dep {
        font-size: 12px;
        color: #b4bbcc;
      }
    }

    &__avatar {
      flex: 0 0 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #f2f2f2;
      margin-right: 12px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }

    &__load {
      color: $--color-primary;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 16px;

      i {
        margin-right: 8px;
        font-size: 16px;
      }

      &.active i {
        animation: rotating 0.5s linear infinite;
      }
    }

    ::v-deep {
      .el-tree-node__content {
        position: relative;
        height: inherit;
        min-height: 40px;
        padding-left: 10px;
      }

      .el-tree-node__children {
        height: 100%;
        overflow: auto;
      }

      .tree-content {
        display: flex;
        overflow: hidden;
        align-items: center;
      }

      .el-checkbox {
        position: absolute;
        right: 32px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &-checked {
    &__count {
      line-height: 36px;
      margin: 0 16px;
    }

    &__scroll {
      height: calc(100% - 36px);
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 24px 10px 16px;
      transition: 0.25s;
      overflow: hidden;

      &:hover {
        background-color: #f5f7fa;
      }
    }

    &__info {
      display: flex;
      align-items: center;
    }

    &__avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 12px;
    }

    &__name {
      color: #454d5b;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      width: 250px;
    }

    &__remove {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;
      border-radius: 50%;
      transition: 0.25s;

      &:hover {
        cursor: pointer;
        color: $--color-danger;
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }
}
</style>
