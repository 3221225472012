<!--
 * @Author: zhanln
 * @Date: 2021-06-11 10:38:46
 * @LastEditTime: 2022-08-02 15:16:10
 * @LastEditors: zhan
 * @Description: 顶部提示条
-->

<template>
  <div class="global-tips" v-if="isShow">
    <i class="iconfont icon-warning-fill"></i>{{ tips }}
    <el-button type="text" class="global-tips_btn" @click="fn_config" v-if="btnText">{{ btnText }}<i
        class="iconfont icon-right1"></i></el-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'globalTip',
  data () {
    return {
      isShow: true,
      tips: '您的企业暂未安装自建应用，暂时无法使用平台功能哦。',
      btnText: '点击这里，30s快速安装'
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'permission/GET_ISADMIN'
    }),
    config () {
      return this.$store.state.global.appConfig
    }
  },
  watch: {
    config (val) {
      this.fn_init(val)
    }
  },
  mounted () {
    this.fn_init(this.$store.state.global.appConfig)
  },
  methods: {

    // 状态判断
    fn_init (val) {
      // 0无错误 1客户联系信息未配置 2客户联系信息配置异常
      switch (val) {
        case 1:
          this.isShow = true
          this.tips = this.isAdmin ? '自建应用客户参数信息未完成配置，无法正常获取客户数据，请尽快完成配置。' : '您的企业自建应用客户参数信息未完成配置，请联系企业超级管理员处理哦~'
          this.btnText = this.isAdmin ? '快速配置' : ''
          break
        case 2:
          this.isShow = true
          this.tips = this.isAdmin ? '自建应用客户参数信息配置异常，无法正常获取客户数据，请尽快完成配置。' : '您的企业自建应用客户参数信息配置异常，请联系企业超级管理员处理哦'
          this.btnText = this.isAdmin ? '快速配置' : ''
          break
        default:
          this.isShow = false
          break
      }
    },

    // 快速安装 or 跳转配置页
    fn_config () {
      if (this.$route.path === '/enterprise/management/config') {
        this.fn_lyMsg('info', '您已在此页面！')
        return false
      } else {
        this.$router.push('/enterprise/management/config')
      }
    }
  },

  beforeDestroy () {
    this.$store.commit('_SET', {
      'global.appConfig': 0
    })
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/var.scss";

.global-tips {
  height: 50px;
  background: #ffeded;
  color: #cb0000;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  .icon-warning-fill {
    margin-right: 8px;
    font-size: 24px;
    color: #ff4842;
  }

  .el-icon-arrow-right {
    margin-left: 2px;
  }

  &_btn {
    margin-left: 16px;
  }
}
</style>
