<!--
 * @Author: zhanln
 * @Date: 2021-09-17 17:38:35
 * @LastEditTime: 2021-12-09 15:18:45
 * @LastEditors: Please set LastEditors
 * @Description: <ly-dialog></ly-dialog>
-->

<template>
  <el-dialog
    :visible.sync="selfVisible"
    class="ly-dialog"
    :title="title"
    v-bind="setting"
    :width="setting.width || '600px'"
    @open="$emit('open')"
    @opende="$emit('opende')"
    @close="$emit('close')"
    @closed="$emit('closed')"
    :before-close="fn_cancle"
  >
    <div class="ly-dialog__body">
      <slot></slot>
    </div>

    <slot name="footer" slot="footer">
      <slot name="footer-left" class="ly-dialog__footer-left"></slot>
      <div class="ly-dialog__footer-right">
        <el-button
          round
          @click="fn_cancle"
          v-if="cancle"
          :disabled="setting.confirmLoading"
          >{{ setting.cancleText || "取消" }}</el-button
        >
        <el-button
          round
          type="primary"
          @click="fn_confirm"
          :disabled="setting.confirmDisable"
          :loading="setting.confirmLoading"
          >{{ setting.confirmText || "确定" }}</el-button
        >
      </div>
    </slot>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    cancle: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    setting: {
      type: Object,
      default: () => {
        return {
        }
      }
    }
  },
  computed: {
    selfVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },

  methods: {
    // 取消
    fn_cancle () {
      if (this.$listeners.cancle) {
        this.$emit('cancle')
      } else {
        this.$emit('update:visible', false)
      }
    },

    // 确定
    fn_confirm () {
      if (this.$listeners.confirm) {
        this.$emit('confirm')
      } else {
        this.$emit('update:visible', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ly-dialog {
  ::v-deep {
    .el-dialog__header {
      display: flex;
      align-items: center;
    }
  }

  &__body {
    min-height: 240px;
    max-height: 46vh;
    overflow: auto;
  }

  &__footer {
    &-left {
      flex: 1;
      text-align: left;
    }
    &-right {
      flex: 1;
      text-align: right;
    }
  }
}
</style>
