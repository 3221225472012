/*
 * @Author: zhanln
 * @Date: 2021-07-26 14:58:55
 * @LastEditTime: 2021-09-22 18:04:48
 * @LastEditors: zhanln
 * @Description:
 */
import axios from '@/common/ajax.js'
// const { stringify } = require('qs')

const apiD = '/scrm-api/'

// 企业标签
export const tagApi = {
  reqQuery: () => axios.get(`${apiD}tag/group/list`), // 标签列表
  addGroup: params => axios.post(`${apiD}tag/group/create`, params), // 创建标签组
  editGroup: params => axios.put(`${apiD}tag/group/update`, params), // 修改标签组
  delGroup: params => axios.delete(`${apiD}tag/group/delete/${params.id}`), // 修改标签组
  addTag: params => axios.post(`${apiD}tag/create`, params) // 修改标签组
}
