<!--
  * 状态基础组件
  * 支持传入type[brand、success、danger、warning、info；默认为info]
  * 支持传入icon[不传显示小原点]
-->
<template>
  <div :class="`ly-status ly-status-${type}`">
    <i class="iconfont" :class="icon || 'dot'"></i>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'info'
    }
  }
}
</script>

<style lang="scss" scoped>
.ly-status {
  display: inline-flex;
  align-items: center;
  border-radius: 12px;
  padding: 0 7px;
  height: 24px;
  line-height: 24px;
  &.ly-status-info{
    background: #F0F1F8;
    color: $--color-text-regular;
    .dot{
      background: #6E788A;
    }
  }
  &.ly-status-brand{
    background: #EBF3FF;
    color: #2b7cff;
    .dot{
      background: #2b7cff;
    }
  }
  &.ly-status-success{
    background: #EEFDF8;
    color: #00A86E;
    .dot{
      background: #00A86E;
    }
  }
  &.ly-status-danger{
    background: #FFEDED;
    color: $--color-danger;
    .dot{
      background: $--color-danger;
    }
  }
  &.ly-status-warning{
    background: #FFF7C9;
    color: #C17F00;
    .dot{
      background: #C17F00;
    }
  }
  .iconfont{
    margin-right: 2px;
    line-height: unset;
    font-size: 14px;
  }
  .dot{
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    vertical-align: middle;
    margin-right: 4px;
  }
}
</style>
