/*
 * @Author: zhan
 * @Date: 2022-05-26 14:19:53
 * @LastEditTime: 2022-06-05 16:15:16
 * @LastEditors: zhan
 */
export default {
  namespaced: true,

  state: {
    editType: '',

    bg_url: '',
    notice: [],
    btn_title: '预约直播',

    // 海报背景
    poster_img: null,
    avatar_status: null,
    avatar_sharp: null,
    nickname_status: null,
    nickname_size: null,
    nickname_color: null,
    nickname_align: null,

    // 海报配置
    avatar_x: null,
    avatar_y: null,
    avatar_width: null,
    avatar_height: null,
    nickname_x: null,
    nickname_y: null,
    nickname_offsetX: null,
    qrcode_x: null,
    qrcode_y: null,
    qrcode_width: null,
    qrcode_height: null
  },

  getters: {
    GET_INFO: state => name => {
      return state[name]
    },
    GET_POSTER_INFO: state => {
      return {
        avatar_x: state.avatar_x,
        avatar_y: state.avatar_y,
        avatar_width: state.avatar_width,
        avatar_height: state.avatar_height,
        nickname_x: state.nickname_x,
        nickname_y: state.nickname_y,
        nickname_offsetX: state.nickname_offsetX,
        qrcode_x: state.qrcode_x,
        qrcode_y: state.qrcode_y,
        qrcode_width: state.qrcode_width,
        qrcode_height: state.qrcode_height
      }
    }
  },

  mutations: {
    // 存储数据
    SET_INFO: (state, n) => {
      state[n.name] = n.val
    },

    // 重置
    RESET: state => {
      state.editType = ''

      state.bg_url = ''
      state.notice = []

      // 海报背景
      state.poster_img = null
      state.avatar_status = null
      state.avatar_sharp = null
      state.nickname_status = null
      state.nickname_size = null
      state.nickname_color = null
      state.nickname_align = null

      // 海报配置
      state.avatar_x = null
      state.avatar_y = null
      state.avatar_width = null
      state.avatar_height = null
      state.nickname_x = null
      state.nickname_y = null
      state.nickname_offsetX = null
      state.qrcode_x = null
      state.qrcode_y = null
      state.qrcode_width = null
      state.qrcode_height = null
    }
  }
}
