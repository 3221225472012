/*
 * @Author: zhanln
 * @Date: 2021-09-12 18:24:37
 * @LastEditTime: 2021-09-17 11:27:44
 * @LastEditors: zhanln
 * @Description:
 */
const isOpenProxy = false

const proxyConfig = {
  '/scrm-api/form': 'http://192.168.10.130:20001/scrm-api/form',
  '/scrm-api/live': 'http://192.168.20.130:21001/scrm-api/live',

}
const proxy = {}

for (const i in proxyConfig) {
  proxy[i] = {
    target: proxyConfig[i],
    changeOrigin: true,
    pathRewrite: {
      [`^${i}`]: ''
    }
  }
}

module.exports = {
  proxy,
  isOpenProxy,
  proxyConfig
}
