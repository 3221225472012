<template>
  <div class="login">
    <login-header showWork></login-header>
    <login-card v-if="loginType === 'code'">
      <div class="login-wechat">
        <div class="login-title">微信扫码登录</div>
        <div class="login-info">请使用微信扫描二维码登录</div>
      </div>
      <div
        class="login-wechat__code qy-mtop__3"
        v-loading="wechatCodeLoading"
        element-loading-spinner="el-icon-loading"
        :element-loading-text="loadingText"
        element-loading-custom-class="login-loading"
      >
        <img :src="wechatCode" alt="" />
        <div
          class="login-wechat__reload"
          v-if="wechatCodeReload"
          @click="fn_getWechatCode"
        >
          <i class="iconfont icon-shuaxin1-right"></i>
          <span>二维码失效，点击刷新</span>
        </div>
      </div>
      <div style="margin-top: 32px; font-size: 12px">
        <span class="login-info login-tips">扫码即同意</span>
        <span class="login-regular is-link" @click="fn_jumpToAgreement"
          >《零一无限增长用户协议》</span
        >
      </div>

      <div class="login-badge" @click="fn_changeType('mobile')">
        <i class="iconfont icon-mobile"></i>
      </div>
    </login-card>

    <login-card v-if="loginType === 'mobile'">
      <div class="login-title">手机号登录</div>
      <!-- <div class="login-info qy-mtop__1">
        为了您的账号安全，首次注册请绑定手机
      </div> -->
      <el-form
        :model="formMobile"
        :rules="formMobileRules"
        ref="formMobileRef"
        class="login-form__mobile qy-mtop__8"
      >
        <el-form-item prop="mobile">
          <el-row>
            <el-col :span="24">
              <el-input
                v-model="formMobile.mobile"
                placeholder="请输入手机号"
                maxlength="11"
                :disabled="mobileDisable"
              >
                <i slot="prefix">手机号</i>
              </el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item prop="code">
          <el-row :gutter="12">
            <el-col :span="16">
              <el-input
                v-model="formMobile.code"
                placeholder="请输入验证码"
                maxlength="6"
                :disabled="codeDisable"
              >
                <i slot="prefix">验证码</i>
              </el-input>
            </el-col>
            <el-col :span="8">
              <el-button
                :disabled="checkCodeBtnDisable"
                @click="fn_getCheckCode"
                class="code-btn"
              >
                <template v-if="checkCodeBtnDisable && !mobileDisable"
                  >{{ checkCodeTimeOut / 1000 }}s</template
                >
                <template v-else>发送验证码</template>
              </el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item style="margin-top: 60px">
          <el-button
            type="primary"
            class="login-btn__block mobile"
            :loading="loginLoading"
            round
            @click="fn_loginByMobile"
            >{{ loginLoading ? "登录中..." : "登录" }}</el-button
          >
        </el-form-item>
        <div style="margin-top: 32px; font-size: 12px">
          <span class="login-info login-tips">登录即同意</span>
          <span class="login-regular is-link" @click="fn_jumpToAgreement"
            >《零一无限增长用户协议》</span
          >
        </div>
      </el-form>

      <div class="login-badge" @click="fn_changeType('code')">
        <i class="iconfont icon-Code"></i>
      </div>
    </login-card>

    <login-footer></login-footer>
  </div>
</template>

<script>
// 页面布局
import loginHeader from './components/LoginHeader'
import loginCard from './components/LoginCard'
import loginFooter from './components/LoginFooter'
// import initMenu from '@/common/initMenu'
export default {
  name: 'login',

  data () {
    return {
      loginType: 'code',
      wechatCodeReload: false,
      wechatCodeLoading: true,
      wechatCode: require('../../assets/images/wechat_code_default.png'),
      wechatScene: '',
      wechatInterval: null,
      wechatTimeGap: 1000,
      wechatTimeWait: 0,
      wechatReloadTime: 1000 * 60 * 5, // 5分钟未扫码，取消监听，提示重新获取二维码
      loadingText: '二维码加载中，请稍等',
      formMobile: {
        mobile: '',
        code: ''
      },
      formMobileRules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'change' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号' }
        ],
        code: [
          { required: true, message: '请输入短信验证码', trigger: 'change' },
          { min: 6, max: 6, message: '验证码长度为 6 个字符' }
        ]
      },
      mobileDisable: false,
      codeDisable: false,
      checkCodeBtnDisable: false,
      loginLoading: false,
      checkCodeInterval: null,
      checkCodeTimeOut: 60000
    }
  },

  async created () {
    await this.$store.dispatch('permission/Reset')
    await this.fn_getWechatCode()
  },

  destroyed () {
    if (this.wechatInterval) {
      clearInterval(this.wechatInterval)
    }
    if (this.checkCodeInterval) {
      clearInterval(this.checkCodeInterval)
    }
  },

  methods: {

    // 获取微信登录二维码
    async fn_getWechatCode () {
      this.wechatCodeReload = false
      this.wechatCodeLoading = true
      this.loadingText = '二维码加载中，请稍等'
      const data = await this.axios.get('qrCode')
      this.wechatCode = data.url
      this.wechatScene = data.scene
      this.wechatCodeLoading = false
      this.fn_startListenCode()
    },

    // 开始监听二维码
    fn_startListenCode () {
      this.wechatInterval = setInterval(() => {
        if (this.wechatTimeWait >= this.wechatReloadTime) {
          this.wechatCodeReload = true
          this.wechatTimeWait = 0
          clearInterval(this.wechatInterval)
          return false
        }

        this.wechatTimeWait += this.wechatTimeGap
        // 监听过程中，如有其他标签页登录，则停止监听
        const _token = localStorage.getItem('token')

        if (_token) {
          this.$router.replace('/company')

          this.wechatCodeLoading = true
          this.loadingText = '登录中...'
        } else {
          this.fn_loginWechat()
        }
      }, this.wechatTimeGap)
    },

    // 监听微信扫码回调
    async fn_loginWechat () {
      const data = await this.axios.post('login', {
        scene: this.wechatScene
      })

      // 如果返回中存在 token 字段，则登录成功，清除扫码监听
      if (data.token) {
        clearInterval(this.wechatInterval)

        // 用户感知，一秒加载状态
        this.wechatCodeLoading = true
        this.loadingText = '登录中...'

        setTimeout(() => {
          this.wechatCodeLoading = false
          localStorage.setItem('token', data.token)
          localStorage.setItem('accountToken', data.token)
          localStorage.setItem('type', 'account')

          const userInfo = {
            id: data.id,
            nickname: data.nickname,
            type: data.type,
            avatar_url: data.avatar_url,
            mobile: data.mobile
          }

          localStorage.setItem('userInfo', JSON.stringify(userInfo))

          // 如果未绑定手机，跳转绑定手机页面
          if (!data.mobile) {
            this.$router.replace('/bindmobile')
            return
          }

          this.$router.replace('/company')
        }, 1000)
      }
    },

    // 用户协议
    fn_jumpToAgreement () {
      window.open('https://shimo.im/docs/WkvTt8r3RDGQ8RvW/')
    },

    // 切换类型
    fn_changeType (type) {
      if (this.loginLoading) {
        this.fn_lyMsg('info', '您正在登录中...')
        return false
      }
      this.loginType = type
      if (type === 'mobile') {
        clearInterval(this.wechatInterval)
      } else {
        this.fn_getWechatCode()
      }
    },

    // 获取验证码
    fn_getCheckCode () {
      if (this.loginLoading) {
        this.fn_lyMsg('info', '您正在登录中...')
        return false
      }
      // 验证手机号
      this.$refs.formMobileRef.validateField('mobile', async error => {
        if (!error) {
          // 检测中状态
          this.mobileDisable = true
          this.checkCodeBtnDisable = true

          const data = await this.axios.post('checkCode', {
            mobile: this.formMobile.mobile,
            type: 1
          })

          if ((data && data.code) || (data && data.code === 0)) {
            this.fn_lyMsg('info', data.msg)
            this.mobileDisable = false
            this.checkCodeBtnDisable = false
            return false
          }
          // 已发送验证码提示
          this.fn_lyMsg('success', '验证码已发送')
          this.mobileDisable = false

          // 验证码倒计时 60s
          this.checkCodeInterval = setInterval(() => {
            this.checkCodeTimeOut -= 1000
            if (this.checkCodeTimeOut === 0) {
              clearInterval(this.checkCodeInterval)
              this.checkCodeTimeOut = 60000
              this.checkCodeBtnDisable = false
            }
          }, 1000)
        }
      })
    },

    // 绑定手机
    fn_loginByMobile () {
      // 表单验证
      this.$refs.formMobileRef.validate(async (valid) => {
        if (valid) {
          this.loginLoading = true
          const data = await this.axios.post('loginByMobile', this.formMobile)
          if ((data && data.code) || (data && data.code === 0)) {
            this.fn_lyMsg('info', data.msg)
            this.loginLoading = false
            return false
          }

          clearInterval(this.checkCodeInterval)

          // 用户感知，一秒加载状态
          this.loadingText = '登录中...'

          setTimeout(() => {
            // this.loginLoading = false
            localStorage.setItem('token', data.token)
            localStorage.setItem('accountToken', data.token)
            localStorage.setItem('type', 'account')

            const userInfo = {
              id: data.id,
              nickname: data.nickname,
              type: data.type,
              avatar_url: data.avatar_url,
              mobile: data.mobile
            }

            localStorage.setItem('userInfo', JSON.stringify(userInfo))
            this.$router.replace('/company')
          }, 1000)
        }
      })
    }

  },

  components: {
    loginHeader,
    loginCard,
    loginFooter
  }
}
</script>

<style lang="scss">
@import "./components/login.scss";
.login-loading {
  .el-loading-spinner i,
  .el-loading-text {
    font-size: 14px;
    color: $--color-text-regular;
  }

  .el-loading-spinner i {
    font-size: 32px;
  }
}

.login-card {
  position: relative;
  overflow: hidden;
}

.login-badge {
  width: 100px;
  height: 100px;
  background-color: #2b7cff57;
  position: absolute;
  right: -50px;
  top: -50px;
  transform: rotate(45deg);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  i {
    position: relative;
    font-size: 36px;
    color: $--color-primary;
    top: 42px;
    transform: rotate(-45deg);
  }
}
</style>
