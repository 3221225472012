var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "no-authorized flex flex-center flex-column" },
      [
        _c("img", {
          attrs: {
            src: require("@assets/svg/default/no_permission.svg"),
            alt: ""
          }
        }),
        _c("div", { staticClass: "no-authorized__text" }, [
          _vm._v("无此页面访问权限")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }