<!--
 * @Author: zhanln
 * @Date: 2021-12-28 11:58:01
 * @LastEditTime: 2021-12-31 16:14:25
 * @LastEditors: zhanln
 * @Description: 员工选择器 - 下拉框样式
-->

<template>
  <div>
    <el-select
      v-model="selectValue"
      popper-class="hide-select"
      class="emp-select"
      :popper-append-to-body="false"
      :placeholder="selected.length > 0 ? '' : placeholder"
      @focus="fn_showSelect"
    >
      <template slot="prefix" v-if="selected.length > 0">
        <span
          class="emp-select__item"
          v-for="(item, index) in selected.slice(0, showLength)"
          :key="item.id"
        >
          <img :src="item.avatar_url" alt="" class="avatar" />
          <span class="name">{{ item.name }}</span>
          <span class="emp-select__gap" v-if="index !== selected.length - 1"
            >,</span
          >
        </span>
        <span class="emp-select__more" v-if="selected.length - showLength > 0"
          >+{{ selected.length - showLength }}</span
        >
      </template>
    </el-select>

    <!-- 选择器 -->
    <ly-emp
      ref="lyEmpSelectRef"
      :title="placeholder"
      :checkListProp="selected"
      :max="max"
      :isReal="isReal"
      :modal="modal"
      :datafilter="datafilter"
      @getEmpId="fn_getEmpId"
    ></ly-emp>
  </div>
</template>

<script>
import scaleAvatar from '@/common/scaleAvatar'
export default {
  name: 'empSelect',

  props: {
    placeholder: {
      type: String,
      default: '请选择'
    },
    showLength: {
      type: Number,
      default: 2
    },
    checkList: {
      type: [Array, String],
      default: () => {
        return []
      }
    },
    max: {
      type: [Number, null],
      default: null
    },
    isReal: {
      type: Boolean,
      default: false
    },
    datafilter: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      selectValue: '',
      selected: [],
      defaultAvatar: require('@assets/images/default_avatar.png'),
      firstLoad: true
    }
  },

  watch: {
    async checkList (val) {
      if (val) {
        if (this.firstLoad) {
          const list = val.length ? val : val.split()
          this.firstLoad = false
          this.selected = await this.fn_getEmpForId(list)
        }
      } else {
        this.selected = []
      }
    }
  },

  async mounted () {
    if (this.checkList && this.checkList.length > 0 && this.firstLoad) {
      this.firstLoad = false
      this.selected = await this.fn_getEmpForId(this.checkList)
    }
  },

  methods: {

    // 打开弹框
    fn_showSelect () {
      this.$refs.lyEmpSelectRef.visible = true
    },

    // id 获取员工数据
    async fn_getEmpForId (emps) {
      const data = await this.axios.get('empUsers', {
        params: {
          user_ids: emps.join(',')
        }
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      if (!data.data) {
        return []
      }

      return data.data.map(item => {
        item.avatar_url = scaleAvatar(item.avatar_url)
        return item
      })
    },

    // 弹框回调
    async fn_getEmpId (data) {
      this.firstLoad = false
      if (data.length > 0) {
        const ids = data.map(item => item.base_id || item.id)
        const emps = await this.fn_getEmpForId(ids)
        this.fn_handleCallback(emps)
        this.selected = emps
      } else {
        this.selected = []
        this.fn_handleCallback([])
      }
    },

    // 回调
    fn_handleCallback (data) {
      if (this.$listeners.getEmpId) {
        if (data.length > 0) {
          const ids = data.map(item => item.base_id || item.id)
          this.$emit('getEmpId', ids)
        } else {
          this.$emit('getEmpId', null)
        }
      }
      if (this.$listeners.getEmpIdArr) {
        data.forEach(item => {
          item.id = item.base_id || item.id
          delete item.base_id
        })
        this.$emit('getEmpIdArr', data)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/utils.scss";
::v-deep .hide-select {
  display: none;
}
.emp-select {
  width: 100%;

  ::v-deep .el-input__prefix {
    display: flex;
  }

  &__item {
    display: inline-flex;
    align-items: center;
    color: #454d5b;

    &:not(:last-child) {
      margin-right: 8px;
    }
    .avatar {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      margin-right: 4px;
    }
    .name {
      max-width: 56px;
      @include ellipsis;
    }
  }

  &__more {
    color: #454d5b;
  }
}
</style>
