<!--
 * @Author: zhanln
 * @Date: 2021-06-15 17:51:19
 * @LastEditTime: 2022-02-25 17:16:57
 * @LastEditors: zhanln
 * @Description: 登录异常弹框
-->

<template>
  <el-dialog
    :visible.sync="visible"
    custom-class="card error"
    width="400px"
    top="22vh"
  >
    <div class="card-body error-body">
      <div class="card-title error-title">
        <i class="iconfont icon-close-fill"></i>
        {{ title }}
      </div>
      <div class="error-img">
        <img src="~@assets/svg/login_default.svg" alt="" />
      </div>
      <div class="error-center">
        <div class="error-content" v-html="content"></div>
        <el-button type="primary" round class="error-btn" @click="fn_back"
          >返回</el-button
        >
      </div>
      <el-tooltip class="item" effect="light" placement="right-start">
        <div slot="content">
          <div class="error-contact">
            <div class="error-contact_title">添加专属客服</div>
            <div class="error-contact_text">一对一为您答疑解惑</div>
            <div class="error-contact_code">
              <img src="~@assets/images/contact_me_qr.png" alt="" />
            </div>
          </div>
        </div>
        <div class="error-contact_item q-primary">联系客服处理</div>
      </el-tooltip>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'errorCard',
  props: {
    title: {
      type: String,
      default: '登录成功'
    },
    content: {
      type: String,
      default: '您正在登录的企业没有安装<b>零一SCRM应用</b>，请重新选择企业或者安装应用'
    }
  },
  data () {
    return {
      visible: false
    }
  },

  methods: {
    fn_back () {
      this.visible = false
      this.$emit('handleBack')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./card.scss";

.error {
  &-body {
    min-height: 480px;
  }

  &-title {
    margin-top: 40px;
  }

  &-center {
    text-align: center;
  }

  &-img {
    margin: 66px 0 32px 0;
  }

  &-content {
    margin-bottom: 16px;
  }

  &-btn {
    display: block;
    width: 310px;
    height: 40px;
    padding: 0;
    font-size: 16px;
  }

  &-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 14px;

    &_title {
      color: $--color-text-primary;
    }

    &_text {
      font-size: 12px;
      margin: 6px 0 10px 0;
    }

    &_code {
      img {
        height: 100px;
      }
    }

    &_item {
      margin: 40px 0;
    }
  }
}
</style>
