// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./iconfont.woff2?t=1623748312661");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./iconfont.woff?t=1623748312661");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./iconfont.ttf?t=1623748312661");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"icon\"; /* Project id 2247366 */\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff'),\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n}\n\n.icon {\n  font-family: \"icon\" !important;\n  font-size: 16px;\n  font-style: normal;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.qy-icon-reload:before {\n  content: \"\\e61e\";\n}\n\n.qy-icon-contact:before {\n  content: \"\\e61d\";\n}\n\n.qy-icon-download:before {\n  content: \"\\e61c\";\n}\n\n.qy-icon-check-line:before {\n  content: \"\\e61b\";\n}\n\n.qy-icon-switch:before {\n  content: \"\\e61a\";\n}\n\n.qy-icon-enterprise:before {\n  content: \"\\e619\";\n}\n\n.qy-icon-setting:before {\n  content: \"\\e618\";\n}\n\n.qy-icon-application:before {\n  content: \"\\e617\";\n}\n\n.qy-icon-idcard:before {\n  content: \"\\e616\";\n}\n\n.qy-icon-team:before {\n  content: \"\\e615\";\n}\n\n.qy-icon-user-business:before {\n  content: \"\\e614\";\n}\n\n.qy-icon-organization:before {\n  content: \"\\e613\";\n}\n\n.qy-icon-user:before {\n  content: \"\\e612\";\n}\n\n.qy-icon-group:before {\n  content: \"\\e60f\";\n}\n\n", ""]);
// Exports
module.exports = exports;
