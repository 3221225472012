/*
 * @Author: zhanln
 * @Date: 2021-06-18 15:12:48
 * @LastEditTime: 2021-06-18 16:33:05
 * @LastEditors: zhanln
 * @Description: 红包裂变
 */

const base = '/wecom/redpacket-activity'

export default {
  //  红包裂变-添加活动、修改活动、保存草稿箱
  redPacketSave: base + '/save',
  //  红包裂变-活动详情
  redPacketDetail: base + '/show',
  //  红包裂变-余额
  redPacketInfo: base + '/current-info'
}
