var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "config" }, [
    _c("header", [
      _c("div", { staticClass: "config-container" }, [
        _c(
          "div",
          { staticClass: "flex" },
          [
            _c("img", {
              staticStyle: { "margin-right": "24px" },
              attrs: { src: require("@assets/svg/01sk.svg"), alt: "" }
            }),
            _vm.company
              ? _c(
                  "el-dropdown",
                  { on: { command: _vm.handleCorpCommand } },
                  [
                    _c("span", { staticClass: "el-dropdown-link" }, [
                      _c("div", { staticClass: "login-container_user" }, [
                        _vm._v(" " + _vm._s(_vm.company)),
                        _c("i", {
                          staticClass: "el-icon-arrow-down el-icon--right"
                        })
                      ])
                    ]),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "company" } },
                          [_vm._v("选择企业")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { display: "flex", "align-items": "center" } },
          [
            _c(
              "el-button",
              {
                staticStyle: { "margin-right": "24px" },
                attrs: { type: "text" },
                on: { click: _vm.jumpToLogin }
              },
              [_vm._v("返回自动配置，扫码一次搞定")]
            ),
            _c(
              "el-dropdown",
              { on: { command: _vm.handleHeaderCommand } },
              [
                _c("span", { staticClass: "el-dropdown-link" }, [
                  _c("div", { staticClass: "config-container_user" }, [
                    _c("img", {
                      staticClass: "avatar",
                      attrs: { src: _vm.userInfo.avatar_url, alt: "" }
                    }),
                    _vm._v(" " + _vm._s(_vm.userInfo.nickname)),
                    _c("i", {
                      staticClass: "el-icon-arrow-down el-icon--right"
                    })
                  ])
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c("el-dropdown-item", { attrs: { command: "user" } }, [
                      _vm._v("个人中心")
                    ]),
                    _c("el-dropdown-item", { attrs: { command: "signOut" } }, [
                      _vm._v("退出登录")
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "config-main" }, [
      _c("div", { staticClass: "config-main-left" }, [
        _c(
          "div",
          { staticClass: "config-timeline" },
          _vm._l(_vm.activities, function(item, index) {
            return _c(
              "div",
              { key: index, staticClass: "config-timeline-item" },
              [
                _c(
                  "div",
                  {
                    class: [
                      "dot",
                      item.success ? "success" : "",
                      index + 1 === _vm.active ? "active" : ""
                    ]
                  },
                  [
                    item.success && index + 1 !== _vm.active
                      ? [_c("i", { staticClass: "el-icon-check" })]
                      : [_vm._v(" " + _vm._s(index + 1) + " ")]
                  ],
                  2
                ),
                _c(
                  "div",
                  { class: ["text", index + 1 === _vm.active ? "bold" : ""] },
                  [_vm._v(" " + _vm._s(item.title) + " ")]
                )
              ]
            )
          }),
          0
        )
      ]),
      _c(
        "div",
        { staticClass: "config-main-center" },
        [
          _vm.active === 1
            ? _c("el-scrollbar", { staticStyle: { height: "100%" } }, [
                _c("h1", [_vm._v("简单对接后可免费使用")]),
                _c("p", { staticClass: "title" }, [
                  _vm._v("一. 创建零一裂变自建应用")
                ]),
                _c("p", [
                  _vm._v(" (1) 打开 "),
                  _c(
                    "a",
                    {
                      staticClass: "q-primary",
                      attrs: {
                        href:
                          "https://work.weixin.qq.com/wework_admin/frame#apps",
                        target: "_blank"
                      }
                    },
                    [_vm._v("企业微信后台")]
                  ),
                  _vm._v(" 【应用管理】，在【自建】下选择【创建应用】。 ")
                ]),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/step1_1.png"),
                    alt: ""
                  }
                }),
                _c("p", [
                  _vm._v(
                    " (2) 应用名称填写【我的通知】，请在右侧下载应用logo进行上传，不用填写应用介绍。 "
                  )
                ]),
                _c("p", [
                  _vm._v(
                    " (3) 在【可见范围】内【选择部门/成员】，可先选择授权给顶级部门（部门列表第一个），避免部分员工无法使用功能。 "
                  )
                ]),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/step1_2.png"),
                    alt: ""
                  }
                }),
                _c("p", [
                  _vm._v(
                    " (4) 创建成功后，在【我的通知】内找到secret和Agentld，复制并填写到右侧输入框内。 "
                  )
                ]),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/step1_3.png"),
                    alt: ""
                  }
                }),
                _c("p", [_vm._v("(5) 完成后请点击右侧【下一步】")])
              ])
            : _vm._e(),
          _vm.active === 2
            ? _c("el-scrollbar", { staticStyle: { height: "100%" } }, [
                _c("p", { staticClass: "title" }, [_vm._v("二. 配置客户权限")]),
                _c("p", [
                  _vm._v(
                    " (1) 选择【客户联系】-【权限配置】，点击【添加/修改】添加使用范围，请选择授权给顶级部门（部门列表第一个），避免部分员工无法使用功能。 "
                  )
                ]),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/step2_1.png"),
                    alt: ""
                  }
                }),
                _c("p", [
                  _vm._v(
                    " (2) 切换到【客户联系】- 【客户】，点击【API】展开选择【可调用应用】，点击【设置】。 "
                  )
                ]),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/step2_2.png"),
                    alt: ""
                  }
                }),
                _c("p", [_vm._v("(3) 勾选之前创建的【我的通知】。")]),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/step2_3.png"),
                    alt: ""
                  }
                }),
                _c("p", [
                  _vm._v(
                    "(4) 复制secret，填写到右侧输入框完成后请点击【下一步】。"
                  )
                ]),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/step2_4.png"),
                    alt: ""
                  }
                })
              ])
            : _vm._e(),
          _vm.active === 3
            ? _c("el-scrollbar", { staticStyle: { height: "100%" } }, [
                _c("p", { staticClass: "title" }, [_vm._v("三. 对接自建应用")]),
                _c("p", [
                  _vm._v("(1) 点击【应用管理】-【自建】中创建的【我的通知】。")
                ]),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/step3_1.png"),
                    alt: ""
                  }
                }),
                _c("p", [
                  _vm._v(
                    " (2) 找到【功能】中的【接收消息】，点击【设置API接收】，进行设置。 "
                  )
                ]),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/step3_2.png"),
                    alt: ""
                  }
                }),
                _c("p", [
                  _vm._v(
                    "(3) 将右侧信息依次填写到【接收消息服务器配置】对应的输入框中。"
                  )
                ]),
                _c("p", { staticClass: "q-danger" }, [
                  _vm._v(
                    " 在【选择需要接受的事件类型】中，一定要勾选【用户发送的普通消息】和【外部联系人变更回调】，否则会导致部分功能无法使用 "
                  )
                ]),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/step3_3.png"),
                    alt: ""
                  }
                })
              ])
            : _vm._e(),
          _vm.active === 4
            ? _c("el-scrollbar", { staticStyle: { height: "100%" } }, [
                _c("p", { staticClass: "title" }, [_vm._v("四. 域名验证")]),
                _c("p", [
                  _vm._v(
                    " (1) 在创建应用成功后，进入【我的通知详情页】找到【开发者接口】中的【网页授权及JS-SDK】页面，点击【设置可信域名】，进行设置 "
                  )
                ]),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/step4_1.png"),
                    alt: ""
                  }
                }),
                _c("p", [
                  _vm._v(
                    "(2) 复制右侧链接填写到输入框，填写完成后点击【申请校验域名】。"
                  )
                ]),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/step4_2.png"),
                    alt: ""
                  }
                }),
                _c("p", [
                  _vm._v(
                    " (3) 下载txt文件，在右侧上传，上传成功后直接勾选【已上传域名归属校验文件】，确认即可验证。 "
                  )
                ]),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/step4_3.png"),
                    alt: ""
                  }
                })
              ])
            : _vm._e(),
          _vm.active === 5 || _vm.active === 6
            ? _c("el-scrollbar", { staticStyle: { height: "100%" } }, [
                _c("p", { staticClass: "title" }, [
                  _vm._v("五. 配置聊天工具栏")
                ]),
                _c("p", [
                  _vm._v(
                    "(1) 点击【应用管理】-【第三方】中创建的【零一无限增长】。"
                  )
                ]),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/step5_1.png"),
                    alt: ""
                  }
                }),
                _c("p", [
                  _vm._v(
                    " (2) 在第三方应用【零一无限增长】找到【配置到聊天工具栏】，点击【配置】，进行设置 "
                  )
                ]),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/step5_2.png"),
                    alt: ""
                  }
                }),
                _c("p", [_vm._v("(3) 继续点击【配置页面】进行配置。")]),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/step5_3.png"),
                    alt: ""
                  }
                }),
                _c("p", [
                  _vm._v(
                    "(4) 将右侧信息依次填写到【配置页面弹窗】对应的输入框中。"
                  )
                ]),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/step5_4.png"),
                    alt: ""
                  }
                }),
                _c("p", [
                  _vm._v(
                    "(4) 恭喜配置完成，请点击下一步验证配置，通过后即可使用。"
                  )
                ])
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "config-main-right" },
        [
          _c(
            "el-carousel",
            {
              ref: "formCarousel",
              attrs: {
                direction: "vertical",
                autoplay: false,
                "indicator-position": "none"
              }
            },
            [
              _c("el-carousel-item", { attrs: { name: "form1" } }, [
                _c(
                  "div",
                  { staticClass: "config-form" },
                  [
                    _c("div", { staticClass: "config-form-title" }, [
                      _vm._v("1.配置自建应用")
                    ]),
                    _c("div", { staticClass: "config-form-logo" }, [
                      _c("div", { staticClass: "logo" }, [
                        _c("img", {
                          staticStyle: { width: "100px" },
                          attrs: {
                            src:
                              "https://image.01lb.com.cn/FtV-30x2z5K6ZlyQSiTZ6ZbiilcI",
                            alt: ""
                          }
                        })
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "download",
                          on: { click: _vm.downloadLogo }
                        },
                        [
                          _c("i", { staticClass: "el-icon-download" }),
                          _vm._v(" 点击下载logo ")
                        ]
                      )
                    ]),
                    _c(
                      "el-form",
                      {
                        ref: "addForm1",
                        attrs: {
                          model: _vm.addForm1,
                          rules: _vm.rules1,
                          size: "medium"
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "agent_id", prop: "agent_id" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder:
                                  "请在企微后台复制，并在此粘贴agent_id"
                              },
                              model: {
                                value: _vm.addForm1.agent_id,
                                callback: function($$v) {
                                  _vm.$set(_vm.addForm1, "agent_id", $$v)
                                },
                                expression: "addForm1.agent_id"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "secret", prop: "secret" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder:
                                  "请在企微后台复制，并在此粘贴secret"
                              },
                              model: {
                                value: _vm.addForm1.secret,
                                callback: function($$v) {
                                  _vm.$set(_vm.addForm1, "secret", $$v)
                                },
                                expression: "addForm1.secret"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "config-form-btns" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", loading: _vm.btnLoading },
                            on: {
                              click: function($event) {
                                return _vm.submitForm("addForm1", "f1")
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.btnLoading ? "检测中" : "下一步"))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("el-carousel-item", { attrs: { name: "form2" } }, [
                _c(
                  "div",
                  { staticClass: "config-form" },
                  [
                    _c("div", { staticClass: "config-form-title" }, [
                      _vm._v("2.配置客户联系")
                    ]),
                    _c(
                      "el-form",
                      {
                        ref: "addForm2",
                        attrs: {
                          model: _vm.addForm2,
                          rules: _vm.rules2,
                          size: "medium"
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "客户联系secret", prop: "secret" }
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder:
                                  "请在企微后台复制，并在此粘贴客户联系secret"
                              },
                              model: {
                                value: _vm.addForm2.secret,
                                callback: function($$v) {
                                  _vm.$set(_vm.addForm2, "secret", $$v)
                                },
                                expression: "addForm2.secret"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "config-form-btns" },
                      [
                        _c("el-button", { on: { click: _vm.stepBack } }, [
                          _vm._v("上一步")
                        ]),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", loading: _vm.btnLoading },
                            on: {
                              click: function($event) {
                                return _vm.submitForm("addForm2", "f2")
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.btnLoading ? "检测中" : "下一步"))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("el-carousel-item", { attrs: { name: "form3" } }, [
                _c(
                  "div",
                  { staticClass: "config-form" },
                  [
                    _c("div", { staticClass: "config-form-title" }, [
                      _vm._v("3.对接应用接口")
                    ]),
                    _c(
                      "el-form",
                      { attrs: { model: _vm.addForm3, size: "medium" } },
                      [
                        _c("el-form-item", { attrs: { label: "URL" } }, [
                          _c(
                            "div",
                            { staticClass: "config-form-input" },
                            [
                              _c("el-input", {
                                ref: "url_3",
                                attrs: { readonly: true },
                                model: {
                                  value: _vm.addForm3.url,
                                  callback: function($$v) {
                                    _vm.$set(_vm.addForm3, "url", $$v)
                                  },
                                  expression: "addForm3.url"
                                }
                              }),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", plain: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleCopy("url_3")
                                    }
                                  }
                                },
                                [_vm._v("复制")]
                              )
                            ],
                            1
                          )
                        ]),
                        _c("el-form-item", { attrs: { label: "Token" } }, [
                          _c(
                            "div",
                            { staticClass: "config-form-input" },
                            [
                              _c("el-input", {
                                ref: "token",
                                attrs: { readonly: true },
                                model: {
                                  value: _vm.addForm3.token,
                                  callback: function($$v) {
                                    _vm.$set(_vm.addForm3, "token", $$v)
                                  },
                                  expression: "addForm3.token"
                                }
                              }),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", plain: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleCopy("token")
                                    }
                                  }
                                },
                                [_vm._v("复制")]
                              )
                            ],
                            1
                          )
                        ]),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "EncodingAESKey", prop: "aes_key" }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "config-form-input" },
                              [
                                _c("el-input", {
                                  ref: "aes_key",
                                  attrs: { readonly: true },
                                  model: {
                                    value: _vm.addForm3.aes_key,
                                    callback: function($$v) {
                                      _vm.$set(_vm.addForm3, "aes_key", $$v)
                                    },
                                    expression: "addForm3.aes_key"
                                  }
                                }),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", plain: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleCopy("aes_key")
                                      }
                                    }
                                  },
                                  [_vm._v("复制")]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "config-form-btns" },
                      [
                        _c("el-button", { on: { click: _vm.stepBack } }, [
                          _vm._v("上一步")
                        ]),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", loading: _vm.btnLoading },
                            on: { click: _vm.callbackVerify }
                          },
                          [_vm._v(_vm._s(_vm.btnLoading ? "检测中" : "下一步"))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("el-carousel-item", { attrs: { name: "form4" } }, [
                _c(
                  "div",
                  { staticClass: "config-form" },
                  [
                    _c("div", { staticClass: "config-form-title" }, [
                      _vm._v("4.可信域名验证")
                    ]),
                    _c(
                      "el-form",
                      { attrs: { model: _vm.addForm4, size: "medium" } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "复制链接，填写至企微后台【可信域名】"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "config-form-input" },
                              [
                                _c("el-input", {
                                  ref: "url",
                                  attrs: {
                                    placeholder:
                                      "请在企微后台复制，并在此粘贴客户联系secret",
                                    readonly: true
                                  },
                                  model: {
                                    value: _vm.addForm4.domain,
                                    callback: function($$v) {
                                      _vm.$set(_vm.addForm4, "domain", $$v)
                                    },
                                    expression: "addForm4.domain"
                                  }
                                }),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", plain: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleCopy("url")
                                      }
                                    }
                                  },
                                  [_vm._v("复制")]
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { error: _vm.fileError } },
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "uploadFile",
                                staticClass: "upload-demo",
                                attrs: {
                                  accept: ".txt",
                                  action: "",
                                  "http-request": _vm.getFile,
                                  "on-remove": _vm.removeFile,
                                  multiple: false,
                                  limit: 1,
                                  drag: ""
                                }
                              },
                              [
                                _c("i", { staticClass: "el-icon-upload" }),
                                _c("div", { staticClass: "el-upload__text" }, [
                                  _vm._v(" 将文件拖到此处，或"),
                                  _c("em", [_vm._v("点击选择")])
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "el-upload__tip",
                                    attrs: { slot: "tip" },
                                    slot: "tip"
                                  },
                                  [_vm._v(" 只能上传txt文件，且不超过10kb ")]
                                )
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.uploadState,
                                    expression: "uploadState"
                                  }
                                ],
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  loading: _vm.btnLoading,
                                  disabled: _vm.uploadSuccess
                                },
                                on: { click: _vm.uploadFile }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.btnLoading ? "上传中" : "上传文件")
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("el-form-item")
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "config-form-btns" },
                      [
                        _c("el-button", { on: { click: _vm.stepBack } }, [
                          _vm._v("上一步")
                        ]),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.checkUpload }
                          },
                          [_vm._v("下一步")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("el-carousel-item", { attrs: { name: "form5" } }, [
                _c(
                  "div",
                  { staticClass: "config-form" },
                  [
                    _c("div", { staticClass: "config-form-title" }, [
                      _vm._v("5. 配置聊天工具栏")
                    ]),
                    _c(
                      "el-form",
                      { attrs: { model: _vm.addForm5, size: "medium" } },
                      [
                        _c("el-form-item", { attrs: { label: "页面名称" } }, [
                          _c(
                            "div",
                            { staticClass: "config-form-input" },
                            [
                              _c("el-input", {
                                ref: "sideName",
                                attrs: { readonly: true },
                                model: {
                                  value: _vm.addForm5.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.addForm5, "name", $$v)
                                  },
                                  expression: "addForm5.name"
                                }
                              }),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", plain: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleCopy("sideName")
                                    }
                                  }
                                },
                                [_vm._v("复制")]
                              )
                            ],
                            1
                          )
                        ]),
                        _c("el-form-item", { attrs: { label: "页面名称" } }, [
                          _c(
                            "div",
                            { staticClass: "config-form-input" },
                            [
                              _c("el-input", {
                                ref: "groupUrl",
                                attrs: { readonly: true },
                                model: {
                                  value: _vm.addForm5.group_url,
                                  callback: function($$v) {
                                    _vm.$set(_vm.addForm5, "group_url", $$v)
                                  },
                                  expression: "addForm5.group_url"
                                }
                              }),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", plain: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleCopy("groupUrl")
                                    }
                                  }
                                },
                                [_vm._v("复制")]
                              )
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "config-form-btns" },
                      [
                        _c("el-button", { on: { click: _vm.stepBack } }, [
                          _vm._v("上一步")
                        ]),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.finish }
                          },
                          [_vm._v("下一步")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("el-carousel-item", { attrs: { name: "form6" } }, [
                _c("div", { staticClass: "config-form login" }, [
                  _c("div", { staticClass: "flex" }, [
                    _c("img", {
                      staticClass: "login-img",
                      attrs: {
                        src: require("../../assets/images/ic_success.png"),
                        alt: ""
                      }
                    }),
                    _c("span", { staticClass: "login-title" }, [
                      _vm._v("配置成功")
                    ])
                  ]),
                  _c("div", { staticStyle: { "margin-top": "50px" } }, [
                    _c("div", { staticClass: "login-avatar" }, [
                      _c("img", {
                        attrs: { src: _vm.userInfo.avatar_url, alt: "" }
                      })
                    ]),
                    _c("div", { staticClass: "login-name" }, [
                      _vm._v(_vm._s(_vm.userInfo.nickname))
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "flex",
                      staticStyle: { "margin-top": "28px" }
                    },
                    [
                      _c("img", {
                        staticClass: "login-img login-icon-loading",
                        attrs: {
                          src: require("../../assets/images/loading.png"),
                          alt: ""
                        }
                      }),
                      _c("span", { staticClass: "login-tips" }, [
                        _vm._v("即将进入企业…")
                      ])
                    ]
                  ),
                  _vm.company
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "10px",
                            color: "rgba(0, 0, 0, 0.65)"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.company) + " ")]
                      )
                    : _vm._e()
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ]),
    _c("canvas", { attrs: { id: "codeCanvas", width: "180", height: "180" } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }