var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ly-tag-select" },
    [
      _c("el-input", {
        attrs: { "suffix-icon": "iconfont icon-down", readonly: "" },
        on: { focus: _vm.handleFocus },
        model: {
          value: _vm.placeholderVal,
          callback: function($$v) {
            _vm.placeholderVal = $$v
          },
          expression: "placeholderVal"
        }
      }),
      _c("ly-tag-dialog", {
        ref: "tagDialog",
        attrs: { checked: _vm.value || "" },
        on: { getSelect: _vm.fn_getSelect }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }