/*
 * @Author: zhanln
 * @Date: 2021-09-17 10:42:29
 * @LastEditTime: 2022-02-27 14:28:08
 * @LastEditors: zhanln
 * @Description:
 */
import axios from '@/common/ajax.js'
const { stringify } = require('qs')

export const authapp = {
  insteadCode: () => axios.get('/account/auth/customized-auth-url'), // 获取代自建应用授权二维码
  insteadRes: params => axios.get(`/account/auth/customized-auth-res?${stringify(params)}`), // 获取代自建应用授权结果
  insteadConfirm: params => axios.get(`/account/auth/customized-online-confirm?${stringify(params)}`), // 代自建应用上线确认
  authCode: params => axios.get(`/account/auth/customized-setting-code?${stringify(params)}`), // 获取代自建应用配置二维码
  authRes: params => axios.get(`/account/auth/customized-setting-res?${stringify(params)}`), // 获取代自建应用配置状态
  internal: params => axios.post('/account/auth/customized-setting-internal', params) // 获取代自建应用配置状态
}
