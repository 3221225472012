/*
 * @Author: zhan
 * @Date: 2022-05-18 17:12:50
 * @LastEditTime: 2022-07-15 10:49:48
 * @LastEditors: zhan
 */
export default [
  {
    title: '直播引流',
    id: 2001,
    path: '/live',
    icon: ['live.png', 'live_active.png'],
    children: [
      {
        title: '直播预约',
        id: 200101,
        path: '/live/reserve',
        badge: '',
        children: [
          {
            title: '活动列表',
            id: 20010101,
            path: '/live/reserve/list',
            component: '/live/reserve/list.vue'
          },
          {
            title: '活动编辑',
            id: 20010102,
            path: '/live/reserve/edit',
            component: '/live/reserve/edit.vue',
            is_menu: 0,
            backPath: '/live/reserve/list',
            pid: 20010101
          },
          {
            title: '参与用户',
            id: 20010103,
            path: '/live/reserve/users',
            component: '/live/reserve/users.vue',
            is_menu: 0,
            backPath: '/live/reserve/list',
            pid: 20010101
          },
          {
            title: '推广',
            id: 20010103,
            path: '/live/reserve/popularize',
            component: '/live/reserve/popularize.vue',
            is_menu: 0,
            backPath: '/live/reserve/list',
            pid: 20010101
          },
          {
            title: '预约记录',
            id: 20010104,
            path: '/live/reserve/record',
            component: '/live/reserve/record.vue',
            is_menu: 0,
            backPath: '/live/reserve/list',
            pid: 20010101
          }
        ]
      },
      {
        title: '预约抽奖',
        id: 200102,
        path: '/live/lottery',
        badge: '',
        children: [
          {
            title: '活动列表',
            id: 20010201,
            path: '/live/lottery/list',
            component: '/live/lottery/list.vue'
          },
          {
            title: '活动编辑',
            id: 20010202,
            path: '/live/lottery/edit',
            component: '/live/lottery/edit.vue',
            is_menu: 0,
            backPath: '/live/lottery/list',
            pid: 20010201
          },
          {
            title: '参与用户',
            id: 20010203,
            path: '/live/lottery/users',
            component: '/live/lottery/users.vue',
            is_menu: 0,
            backPath: '/live/lottery/list',
            pid: 20010201
          },
          {
            title: '推广',
            id: 20010203,
            path: '/live/lottery/popularize',
            component: '/live/lottery/popularize.vue',
            is_menu: 0,
            backPath: '/live/lottery/list',
            pid: 20010201
          },
          {
            title: '预约记录',
            id: 20010204,
            path: '/live/lottery/record',
            component: '/live/lottery/record.vue',
            is_menu: 0,
            backPath: '/live/lottery/list',
            pid: 20010201
          },
          {
            title: '奖品记录',
            id: 20010205,
            path: '/live/lottery/rewards',
            component: '/live/lottery/rewards.vue',
            is_menu: 0,
            backPath: '/live/lottery/list',
            pid: 20010201
          }
        ]
      },
      {
        title: '预约任务宝',
        id: 200103,
        path: '/live/task',
        badge: '',
        children: [
          {
            title: '活动列表',
            id: 20010301,
            path: '/live/task/list',
            component: '/live/task/list.vue'
          },
          {
            title: '活动编辑',
            id: 20010302,
            path: '/live/task/edit',
            component: '/live/task/edit.vue',
            is_menu: 0,
            backPath: '/live/task/list',
            pid: 20010301
          },
          {
            title: '参与用户',
            id: 20010303,
            path: '/live/task/users',
            component: '/live/task/users.vue',
            is_menu: 0,
            backPath: '/live/task/list',
            pid: 20010301
          },
          {
            title: '推广',
            id: 20010303,
            path: '/live/task/popularize',
            component: '/live/task/popularize.vue',
            is_menu: 0,
            backPath: '/live/task/list',
            pid: 20010301
          },
          {
            title: '预约记录',
            id: 20010304,
            path: '/live/task/record',
            component: '/live/task/record.vue',
            is_menu: 0,
            backPath: '/live/task/list',
            pid: 20010301
          },
          {
            title: '奖品记录',
            id: 20010305,
            path: '/live/task/rewards',
            component: '/live/task/rewards.vue',
            is_menu: 0,
            backPath: '/live/task/list',
            pid: 20010301
          }
        ]
      }
    ]
  }
]
