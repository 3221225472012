/*
 * @Author: zhanln
 * @Date: 2021-06-18 15:12:38
 * @LastEditTime: 2021-11-03 18:07:15
 * @LastEditors: zhanln
 * @Description: 社群
 */

const base = '/wecom/wecomGroups'

export default {
  // 群列表
  wecomGroups: base,
  // 群列表v2
  wecomGroupsV2: '/wecom/wecom-groups/list',
  // 群统计
  wecomGroupStatistic: base + '/statistic',
  // 群图表
  wecomGroupAnalysis: base + '/analysis',
  // 群详情
  groupInfo: base + '/info',
  // 群成员列表
  groupUsers: base + '/users',
  // 客户群数据同步
  groupSync: base + '/sync'
}
