<!--
 * @Author: zhan
 * @Date: 2022-03-15 16:25:47
 * @LastEditTime: 2022-03-17 18:13:02
 * @Description: 下拉框样式
-->

<template>
  <div>
    <el-select
      v-model="selectValue"
      popper-class="hide-select"
      class="robot-select"
      :popper-append-to-body="false"
      :placeholder="selectList.length > 0 ? '' : placeholder"
      @focus="fn_open"
    >
      <template slot="prefix" v-if="selectList.length > 0">
        <span
          class="robot-select__item"
          v-for="(item, index) in selectList.slice(0, showLength)"
          :key="item.id"
        >
          <img :src="item.avatar" alt="" class="avatar" />
          <span class="name">{{ item.name }}</span>
          <span class="robot-select__gap" v-if="index !== selectList.length - 1"
            >,</span
          >
        </span>
        <span class="robot-select__more" v-if="selectList.length - showLength > 0"
          >+{{ selectList.length - showLength }}</span
        >
      </template>
    </el-select>

    <ly-robot ref="lyRobotRef" @callback="fn_getList" :setDisable="setDisable"></ly-robot>
  </div>
</template>

<script>
export default {
  name: 'lyRobotSelect',

  props: {
    placeholder: {
      type: String,
      default: '请选择'
    },
    showLength: {
      type: Number,
      default: 2
    },
    robotIds: {
      type: String,
      defualt: ''
    },
    setDisable: {
      type: Boolean,
      default: true
    }
  },

  watch: {
    robotIds (val) {
      if (!val) {
        this.selectList = []
      }
    }
  },

  data () {
    return {
      selectValue: '',
      selectList: []
    }
  },

  methods: {
    fn_open () {
      console.log('opentbtan', this.robotIds)
      this.$refs.lyRobotRef.fn_open(this.robotIds)
    },

    fn_getList (list) {
      this.selectList = list
      const selects = list.length > 0 ? list.map(item => +item.id).join() : null
      console.log('selectlist', selects)
      this.$emit('callback', selects)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/utils.scss";
::v-deep .hide-select {
  display: none;
}
.robot-select {
  width: 100%;

  ::v-deep .el-input__prefix {
    display: flex;
  }

  &__item {
    display: inline-flex;
    align-items: center;
    color: #454d5b;

    &:not(:last-child) {
      margin-right: 8px;
    }
    .avatar {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      margin-right: 4px;
    }
    .name {
      max-width: 56px;
      @include ellipsis;
    }
  }

  &__more {
    color: #454d5b;
  }
}
</style>
