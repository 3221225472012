/*
 * @Author: zhan
 * @Date: 2022-03-17 10:29:04
 * @LastEditTime: 2022-03-17 10:36:04
 * @Description:
 */

const base = '/wecom/agg-chat'

export default {
  // 查询机器人列表
  aggRobotList: base + '/user-select'
}
