var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ly-upload" },
    [
      _vm.type !== "file" || (_vm.type === "file" && _vm.fileList.length === 0)
        ? _c(
            "el-upload",
            _vm._b(
              {
                attrs: {
                  action: "#",
                  accept: _vm.accept,
                  "file-list": _vm.fileList,
                  "auto-upload": false,
                  "on-change": _vm.fn_uploadImg
                }
              },
              "el-upload",
              _vm.selfSetting,
              false
            ),
            [
              _c(
                "div",
                {
                  class: [
                    "ly-upload__body",
                    _vm.fileList && _vm.fileList.length ? "has-img" : "",
                    _vm.mini ? "is-mini" : "",
                    _vm.error ? "is-error" : ""
                  ]
                },
                [
                  _vm.fileList && _vm.fileList.length
                    ? [
                        _vm.type === "image"
                          ? [
                              _c("img", {
                                staticClass: "ly-upload__img",
                                attrs: { src: _vm.fileList[0].url, alt: "" }
                              })
                            ]
                          : _vm.type === "video"
                          ? [
                              _c("video", {
                                ref: "videoDom",
                                staticClass: "ly-upload__video",
                                attrs: { src: _vm.fileList[0].url }
                              })
                            ]
                          : _vm._e(),
                        _vm.canRemove
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass: "ly-upload__mask",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.fn_removeFile($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "iconfont icon-delete ly-upload__tipIcon"
                                  })
                                ]
                              )
                            ]
                          : [
                              _vm.changeText
                                ? _c(
                                    "div",
                                    { staticClass: "ly-upload__changeText" },
                                    [_vm._v(" " + _vm._s(_vm.changeText) + " ")]
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "ly-upload__mask" }, [
                                _c("i", {
                                  staticClass:
                                    "el-icon-upload2 ly-upload__tipIcon"
                                })
                              ])
                            ]
                      ]
                    : [
                        _c("i", {
                          staticClass: "iconfont ly-upload__tipIcon",
                          class: [_vm.tipIcon]
                        }),
                        _vm.tipText
                          ? _c("div", { staticClass: "ly-upload__tipText" }, [
                              _vm._v(_vm._s(_vm.tipText))
                            ])
                          : _vm._e()
                      ]
                ],
                2
              )
            ]
          )
        : _c(
            "div",
            { staticClass: "ly-upload__file" },
            [
              _c("file-card", { attrs: { file: _vm.fileInfo } }),
              _c("div", { staticClass: "ly-upload__file--remove" }, [
                _c("i", {
                  staticClass: "iconfont icon-delete",
                  on: { click: _vm.fn_removeFile }
                })
              ])
            ],
            1
          ),
      _c("ly-cropper", {
        ref: "lyCropperRef",
        on: { finish: _vm.fn_cropperFinish }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }