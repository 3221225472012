import axios from '@/common/ajax'
import { Message } from 'element-ui'

const getAsyncPurcharse = async () => {
  const data = await axios.get('appPurchaseList')

  if ((data && data.code) || (data && data.code === 0)) {
    Message.error(data.msg)
    return false
  }

  const { app_purchase_list: saleList, app_purchased_record: boughtList } = data
  const time = new Date().getTime()
  return { saleList, boughtList, time }
}

export default {
  state: {
    wecomId: null,
    appid: null,
    appName: '',
    mapAppId: null,
    saleList: [],
    boughtList: [],
    getTime: null,
    isSale: null,
    isBought: null,
    remainDay: null,
    expiredTime: null
  },

  mutations: {
    SET_WECOM_ID: (state, id) => {
      state.wecomId = id
    },
    SET_ID: (state, id) => {
      state.appid = id
    },
    SET_NAME: (state, name) => {
      state.appName = name
    },
    SET_MAP_ID: (state, id) => {
      state.mapAppId = id
    },
    SET_LIST: (state, list) => {
      state.saleList = list.saleList
      state.boughtList = list.boughtList
      state.getTime = list.time
    },
    SET_ISSALE: (state, isSale) => {
      state.isSale = isSale
    },
    SET_BOUGHT: (state, isBought) => {
      state.isBought = isBought
    },
    SET_REMAIN: (state, remain) => {
      state.remainDay = remain
    },
    SET_EXPIRED: (state, expired) => {
      state.expiredTime = expired
    }
  },

  actions: {
    async GeneratePurchase ({ state, commit }, id) {
      commit('SET_MAP_ID', id)
      commit('SET_BOUGHT', false)
      commit('SET_ISSALE', false)
      commit('SET_REMAIN', null)
      commit('SET_EXPIRED', null)

      let obj = null
      let isSale = false
      let isBuy = false

      // 如果切换了企业
      const wecom_id = localStorage.getItem('companyId')
      if (!state.wecomId || state.wecomId !== wecom_id) {
        obj = await getAsyncPurcharse()
        commit('SET_LIST', obj)
      } else {
        // 获取应用购买列表，2分钟获取一次
        const time = state.getTime
        const timeGap = new Date().getTime() - time
        if (!time || timeGap > 60000 * 2) {
          obj = await getAsyncPurcharse()
          commit('SET_LIST', obj)
        } else {
          obj = {
            saleList: state.saleList,
            boughtList: state.boughtList,
            time: state.time
          }
        }
      }

      // 是否需要购买
      let appId = null
      for (const list of obj.saleList) {
        if (list.app_id === id) {
          isSale = true
          appId = list.app_id
          commit('SET_ISSALE', isSale)
          break
        }
      }

      // 是否已经购买
      if (appId) {
        obj.boughtList.forEach(item => {
          if (item.app_id === appId) {
            isBuy = true
            commit('SET_BOUGHT', true)
            commit('SET_REMAIN', item.remain_days)
            commit('SET_EXPIRED', item.expired_time)
          }
        })
      }

      commit('SET_WECOM_ID', wecom_id)
      commit('SET_ID', appId)
      return { isBuy }
    }
  }
}
