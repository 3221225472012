<!--
 * @Descripttion:
 * @version:
 * @Author: lw
 * @Date: 2021-05-17 11:20:44
 * @LastEditTime: 2021-05-18 15:31:27
-->
<template>
  <el-popover
    v-model="visible"
    popper-class="xl-time-select"
    placement="bottom-start"
    :append-to-body="false"
    @show="initOptions"
  >
    <div slot="reference" class="form-box">
      <!-- <el-input v-model="time" readonly :size="size" :placeholder="placeholder" /> -->
      <div class="el-date-editor el-range-editor el-input__inner el-date-editor--daterange" :class="`el-range-editor--${size}`">
        <i class="el-input__icon el-range__icon el-icon-time"></i>
        <input autocomplete="off" readonly v-model="startTime.label" placeholder="开始时间" name="" class="el-range-input">
        <span class="el-range-separator">~</span>
        <input autocomplete="off" readonly v-model="endTime.label" placeholder="结束时间" name="" class="el-range-input">
      </div>
      <i v-if="clearable && endTime.value" class="clearicon el-icon-circle-close" @click.stop="reset" />
    </div>
    <div class="main-warp">
      <div class="left">
        <p class="title">开始时间</p>
        <div class="items">
          <el-scrollbar>
            <div class="time-item" :class="{active: startTime.value === item.value}" v-for="item in options" :key="item.value" @click="setStartTime(item)">{{item.label}}</div>
          </el-scrollbar>
        </div>

      </div>
      <div class="right">
        <p class="title">结束时间</p>
        <div class="items">
          <el-scrollbar>
            <div class="time-item" :class="{active: endTime.value === item.value, disable: item.value < startTime.value}" v-for="item in options" :key="item.value" @click="setEndTime(item)">{{item.label}}</div>
          </el-scrollbar>
        </div>
      </div>
    </div>
  </el-popover>
</template>
<script>
export default {
  props: {
    value: {},
    placeholder: {
      type: String,
      default: '请选择时间段范围'
    },
    pickerOptions: {
      type: Object,
      default: () => {
        return {
          start: '00:00',
          step: '00:60',
          end: '24:00'
        }
      }
    },
    clearable: {
      type: Boolean,
      default: () => true
    },
    size: {
      type: String,
      default: 'medium'
    }
  },
  data () {
    return {
      visible: false,
      startTime: {},
      endTime: {},
      options: []
    }
  },
  watch: {
    value (n) {
      if (n.length) {
        this.startTime = {
          label: this.getTimeStr(n[0] / 60),
          value: n[0] / 60
        }
        this.endTime = {
          label: this.getTimeStr(n[1] / 60),
          value: n[1] / 60
        }
      } else {
        this.startTime = { }
        this.endTime = { }
      }
    }
  },
  methods: {
    initOptions () {
      this.options = []
      const tempStart = this.pickerOptions.start.split(':')
      const tempEnd = this.pickerOptions.end.split(':')
      const tempStep = this.pickerOptions.step.split(':')
      const startTime = Number(tempStart[0]) * 60 + Number(tempStart[1])
      const entTime = Number(tempEnd[0]) * 60 + Number(tempEnd[1])
      const step = Number(tempStep[0]) * 60 + Number(tempStep[1])
      for (let t = startTime; t <= entTime; t += step) {
        this.options.push({
          label: this.getTimeStr(t),
          value: t
        })
      }
    },
    // 分钟转为时间点
    getTimeStr (t) {
      const hour = Math.floor(t / 60)
      const min = t - hour * 60
      return `${hour < 10 ? '0' + hour : hour}:${min < 10 ? '0' + min : min}`
    },
    setStartTime (item) {
      this.startTime = item
    },
    setEndTime (item) {
      if (item.value >= this.startTime.value) {
        this.endTime = item
        this.visible = false
        this.$emit('input', [this.startTime.value * 60, this.endTime.value * 60])
        this.$emit('change', [this.startTime.value * 60, this.endTime.value * 60])
      }
    },
    reset () {
      this.startTime = ''
      this.endTime = ''
      this.$emit('input', [])
    }
  }
}
</script>
<style lang="scss" scoped>
.main-warp{
  overflow: hidden;
  .left,.right{
    float: left;
    width: 50%;
    text-align: center;
    .title{
      color: #606266;
      margin: 5px 0;
    }
    .time-item{
      padding: 6px 0;
      cursor: pointer;
      &:hover{
        background: #f5f7fa;
        font-weight: bold;
      }
      &.active{
        color: #409eff;
      }
      &.disable{
        color: #c0c4cc;
        cursor: not-allowed;
      }
    }

  }
  .el-scrollbar{
    ::v-deep{
      .el-scrollbar__wrap{
        max-height: 200px;;
      }
    }
  }
}
.form-box{
  position: relative;
  .clearicon{
    display: none;
    position: absolute;
    right: 12px;
    top: 11px;
    color: #BFBFBF;
    cursor: pointer;
  }
  &:hover .clearicon{
    display: block;
  }
}
::v-deep{
  .xl-time-select{
    width: 100%;
    box-sizing: border-box;
  }
}
</style>
