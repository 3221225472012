var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    _vm._b(
      {
        staticClass: "ly-dialog",
        attrs: {
          visible: _vm.selfVisible,
          title: _vm.title,
          width: _vm.setting.width || "600px",
          "before-close": _vm.fn_cancle
        },
        on: {
          "update:visible": function($event) {
            _vm.selfVisible = $event
          },
          open: function($event) {
            return _vm.$emit("open")
          },
          opende: function($event) {
            return _vm.$emit("opende")
          },
          close: function($event) {
            return _vm.$emit("close")
          },
          closed: function($event) {
            return _vm.$emit("closed")
          }
        }
      },
      "el-dialog",
      _vm.setting,
      false
    ),
    [
      _c("div", { staticClass: "ly-dialog__body" }, [_vm._t("default")], 2),
      _vm._t(
        "footer",
        [
          _vm._t("footer-left"),
          _c(
            "div",
            { staticClass: "ly-dialog__footer-right" },
            [
              _vm.cancle
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        round: "",
                        disabled: _vm.setting.confirmLoading
                      },
                      on: { click: _vm.fn_cancle }
                    },
                    [_vm._v(_vm._s(_vm.setting.cancleText || "取消"))]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: {
                    round: "",
                    type: "primary",
                    disabled: _vm.setting.confirmDisable,
                    loading: _vm.setting.confirmLoading
                  },
                  on: { click: _vm.fn_confirm }
                },
                [_vm._v(_vm._s(_vm.setting.confirmText || "确定"))]
              )
            ],
            1
          )
        ],
        { slot: "footer" }
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }