var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "ly-status ly-status-" + _vm.type },
    [
      _c("i", { staticClass: "iconfont", class: _vm.icon || "dot" }),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }