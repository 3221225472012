<template>
  <el-container class="main-container-inner">
    <el-header height="48px" class="main-header">
      <el-row type="flex" class="row-bg" justify="space-between">
        <el-col :span="12">
          <el-menu mode="horizontal" default-active="1" class="main-menu">
            <el-menu-item index="1">外部应用</el-menu-item>
          </el-menu>
        </el-col>
      </el-row>
    </el-header>
    <el-main v-loading="loading" element-loading-text="页面加载中">
      <div v-html="appInfo.detail" class="main-apps"></div>
    </el-main>
    <el-footer v-if="appInfo.is_jump">
      <el-button @click="fn_jumpUrl" type="primary">{{
        appInfo.button_text
      }}</el-button>
    </el-footer>
  </el-container>
</template>

<script>
import apis from '@/config'
export default {
  name: 'lyApps',

  props: {
    id: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      loading: true,
      appInfo: {
        detail: '',
        is_jump: false,
        button_text: '',
        jump_url: ''
      }
    }
  },

  watch: {
    id (val) {
      // 页面修改
      this.fn_getAppInfo(val)
    }
  },

  created () {
    // 首次进入页面
    const id = this.$route.params.id
    this.fn_getAppInfo(id)
  },

  methods: {
    // 获取外部应用
    async fn_getAppInfo (id) {
      const data = await this.axios.get(apis.appsInfo + `/${id}`)

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      this.loading = false
      this.appInfo = data
    },

    // 按钮跳转
    fn_jumpUrl () {
      const _rex = /(http:\/\/|https:\/\/|^\/\/)((\w|=|\?|\.|\/|&|-)+)/g
      if (!_rex.test(this.appInfo.jump_url)) {
        this.$message.error('配置的路径有误！')
        return false
      }

      window.open(this.appInfo.jump_url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/layout.scss";
.main {
  &-apps {
    max-width: 1000px;
    ::v-deep p {
      margin: 0;
    }

    ::v-deep img {
      max-width: 100%;
    }
  }
}
</style>
