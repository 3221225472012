var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user" },
    [
      _vm.showHeader
        ? _c("login-header", { attrs: { showUserInfo: "" } })
        : _vm._e(),
      _c("div", { staticClass: "user-main" }, [
        _c("div", { staticClass: "user-container" }, [
          _c("div", { staticClass: "ly-tool user-tool-bar" }, [
            _c(
              "div",
              { staticClass: "ly-tool__back", on: { click: _vm.goBack } },
              [_c("i", { staticClass: "iconfont icon-left1" }), _vm._v("返回 ")]
            )
          ]),
          _c("div", { staticClass: "user-layout" }, [
            _c("div", { staticClass: "user-layout-left" }, [
              _c("div", { staticClass: "user-layout-left_top user-card" }, [
                _vm.userInfo.avatar_url || _vm.userInfo.avatar_url !== ""
                  ? _c("img", {
                      staticClass: "avatar",
                      attrs: { src: _vm.userInfo.avatar_url, alt: "" }
                    })
                  : _c("img", {
                      staticClass: "avatar",
                      attrs: { src: _vm.defaultAvatar, alt: "" }
                    }),
                _c("div", { staticClass: "info" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(_vm.userInfo.nickname))
                  ]),
                  _c("div", { staticClass: "num" }, [
                    _vm._v(_vm._s(_vm.userInfo.mobile))
                  ])
                ])
              ]),
              _vm._m(0)
            ]),
            _c("div", { staticClass: "user-layout-right user-card" }, [
              _c("div", { staticClass: "user-layout-right_header" }, [
                _vm._v("个人信息")
              ]),
              _c("div", { staticClass: "user-layout-right_main" }, [
                _c("div", { staticClass: "user-list" }, [
                  _c("div", { staticClass: "user-list-item" }, [
                    _c("div", { staticClass: "user-list-label" }, [
                      _vm._v("头像")
                    ]),
                    _c("div", { staticClass: "user-list-center" }, [
                      _vm.userInfo.avatar_url || _vm.userInfo.avatar_url !== ""
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.userInfo.avatar_url, alt: "" }
                          })
                        : _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.defaultAvatar, alt: "" }
                          })
                    ])
                  ]),
                  _c("div", { staticClass: "user-list-item" }, [
                    _c("div", { staticClass: "user-list-label" }, [
                      _vm._v("账户ID")
                    ]),
                    _c("div", { staticClass: "user-list-center" }, [
                      _vm._v(" " + _vm._s(_vm.userInfo.id) + " ")
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "user-list-item" },
                    [
                      _c("div", { staticClass: "user-list-label" }, [
                        _vm._v("微信")
                      ]),
                      _c("div", { staticClass: "user-list-center" }, [
                        _c("div", { staticClass: "flex" }, [
                          _c("i", {
                            staticClass: "iconfont icon-logo-wechat",
                            staticStyle: {
                              color: "#49c660",
                              "font-size": "20px",
                              "margin-right": "8px"
                            }
                          }),
                          _vm._v(" " + _vm._s(_vm.userInfo.nickname) + " ")
                        ])
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.getUpdateWechatQrcode }
                        },
                        [_vm._v("去更换")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "user-list-item" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "user-list-label",
                          attrs: { "data-value": "手机号" }
                        },
                        [_vm._v("手机号")]
                      ),
                      _c("div", { staticClass: "user-list-center" }, [
                        _vm._v(" " + _vm._s(_vm.userInfo.mobile) + " ")
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.updateMobile }
                        },
                        [_vm._v("去更换")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "身份验证",
            visible: _vm.checkDialog,
            width: "428px",
            top: "20vh",
            "lock-scroll": "",
            "close-on-click-modal": false,
            "custom-class": "q-dialog"
          },
          on: {
            "update:visible": function($event) {
              _vm.checkDialog = $event
            }
          }
        },
        [
          _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
            _vm._v(" 为了你的帐户安全，请验证身份。验证成功后进行下一步操作。 ")
          ]),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: { model: _vm.ruleForm, rules: _vm.rules, size: "medium" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "", type: "tel", disabled: "" },
                    model: {
                      value: _vm.ruleForm.mobile,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "mobile", $$v)
                      },
                      expression: "ruleForm.mobile"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { label: "", prop: "code" },
                  scopedSlots: _vm._u([
                    {
                      key: "error",
                      fn: function(ref) {
                        var error = ref.error
                        return [
                          _c("div", { staticClass: "el-form-item__error" }, [
                            _c("i", {
                              staticClass: "el-icon-error",
                              staticStyle: { "margin-right": "3px" }
                            }),
                            _vm._v(_vm._s(error) + " ")
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "el-row",
                    {
                      attrs: {
                        gutter: 20,
                        type: "flex",
                        justify: "space-between"
                      }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 17 } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入验证码",
                              maxlength: "6",
                              minlength: "6",
                              clearable: ""
                            },
                            model: {
                              value: _vm.ruleForm.code,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "code", $$v)
                              },
                              expression: "ruleForm.code"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-button",
                            {
                              class: [
                                "user-btn-check",
                                _vm.sendCheckCode ? "" : "info"
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: _vm.sendCheckCode ? "" : "",
                                disabled: !_vm.sendCheckCode
                              },
                              on: { click: _vm.getCheckCode }
                            },
                            [
                              _vm.sendCheckCode
                                ? [_vm._v(" 发送验证码 ")]
                                : [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.checkCodeTimeout / 1000) +
                                        "s "
                                    )
                                  ]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div"),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { round: "" },
                    on: {
                      click: function($event) {
                        return _vm.resetCheckForm("ruleForm")
                      }
                    }
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { round: "", type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.checkConfirm("ruleForm")
                      }
                    }
                  },
                  [_vm._v("验证")]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "绑定微信",
            visible: _vm.bindWechatDialog,
            width: "428px",
            top: "20vh",
            "lock-scroll": "",
            "close-on-click-modal": false,
            "custom-class": "q-dialog"
          },
          on: {
            "update:visible": function($event) {
              _vm.bindWechatDialog = $event
            },
            close: _vm.closeUpdataWechat
          }
        },
        [
          _c("div", { staticClass: "flex flex-center flex-column" }, [
            _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
              _vm._v("请使用微信扫码，绑定该账号")
            ]),
            _c(
              "div",
              {
                staticClass: "user-qrcode",
                staticStyle: { "margin-bottom": "78px" }
              },
              [_c("img", { attrs: { src: _vm.updateWechatQrcode, alt: "" } })]
            )
          ])
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "更换手机号码",
            visible: _vm.bindmobileDialog,
            width: "428px",
            top: "20vh",
            "lock-scroll": "",
            "close-on-click-modal": false,
            "custom-class": "q-dialog"
          },
          on: {
            "update:visible": function($event) {
              _vm.bindmobileDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm2",
              attrs: { model: _vm.ruleForm2, rules: _vm.rules2, size: "medium" }
            },
            [
              _c(
                "el-form-item",
                {
                  ref: "formmobile",
                  class: _vm.customError ? "login-error" : "",
                  attrs: { label: "", prop: "mobile", error: _vm.mobileTips },
                  scopedSlots: _vm._u([
                    {
                      key: "error",
                      fn: function(ref) {
                        var error = ref.error
                        return [
                          _c(
                            "div",
                            {
                              class: [
                                "el-form-item__error",
                                _vm.customErrorClass
                              ]
                            },
                            [
                              _c("i", {
                                class: _vm.customErrorIcon,
                                staticStyle: { "margin-right": "3px" }
                              }),
                              _vm._v(_vm._s(error) + " ")
                            ]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入新手机号码",
                      type: "tel",
                      clearable: ""
                    },
                    model: {
                      value: _vm.ruleForm2.mobile,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm2, "mobile", $$v)
                      },
                      expression: "ruleForm2.mobile"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { label: "", prop: "code" },
                  scopedSlots: _vm._u([
                    {
                      key: "error",
                      fn: function(ref) {
                        var error = ref.error
                        return [
                          _c("div", { staticClass: "el-form-item__error" }, [
                            _c("i", {
                              staticClass: "el-icon-error",
                              staticStyle: { "margin-right": "3px" }
                            }),
                            _vm._v(_vm._s(error) + " ")
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "el-row",
                    {
                      attrs: {
                        gutter: 20,
                        type: "flex",
                        justify: "space-between"
                      }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 17 } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入验证码",
                              maxlength: "6",
                              minlength: "6",
                              clearable: ""
                            },
                            model: {
                              value: _vm.ruleForm2.code,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm2, "code", $$v)
                              },
                              expression: "ruleForm2.code"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-button",
                            {
                              class: [
                                "user-btn-check",
                                _vm.sendCheckCode ? "" : "info"
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: _vm.sendCheckCode ? "" : "",
                                disabled: !_vm.sendCheckCode
                              },
                              on: { click: _vm.getCheckCodeForMobile }
                            },
                            [
                              _vm.sendCheckCode
                                ? [_vm._v(" 发送验证码 ")]
                                : [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.checkCodeTimeout / 1000) +
                                        "s "
                                    )
                                  ]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div"),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { round: "" },
                    on: {
                      click: function($event) {
                        _vm.bindmobileDialog = false
                      }
                    }
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { round: "", type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.editRoleConfirm("ruleForm2")
                      }
                    }
                  },
                  [_vm._v("确认")]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "user-layout-left_bottom user-card" }, [
      _c("div", { staticClass: "user-tabs" }, [
        _c("div", { staticClass: "user-tabs_item active" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/svg/sidebar/number_active.png"),
              alt: ""
            }
          }),
          _vm._v(" 个人信息 ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }