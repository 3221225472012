/*
 * @Author: zhanln
 * @Date: 2021-05-11 14:10:46
 * @LastEditTime: 2021-12-09 14:54:25
 * @LastEditors: Please set LastEditors
 * @Description:
 */
import axios from 'axios'
import { Message } from 'element-ui'
import router from '../router'
import store from '@/store/store'
import apis from '@/config'

// 本地调试
import { proxyConfig, isOpenProxy } from '@/common/proxy-dev.js'
const urlArr = Object.keys(proxyConfig)
let msgBox = null

axios.defaults.timeout = 30000

axios.defaults.baseURL = apis.host

axios.interceptors.request.use(
  config => {
    // 拦截地址，并且重定义地址
    !config.headers['Content-Type'] && (config.headers['Content-Type'] = 'application/json')
    let _has = false
    if (process.env.NODE_ENV === 'dev' && isOpenProxy) {
      _has = urlArr.filter(_ => {
        return config.url.indexOf(_) > -1
      })
    }
    if (_has.length) {
      config.baseURL = '/'
    } else {
      config.url = apis[config.url] || config.url
    }
    const token = localStorage.getItem('token')
    const companyId = localStorage.getItem('companyId')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
      config.headers['X-WECOM-ID'] = companyId
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    const data = response.data
    const code = data.code
    if (code === 200) {
      return data.data
    } else if (code === 401) {
      if (msgBox) {
        // 防止出现多个msgbox
        msgBox.close()
      }
      msgBox = Message.error('请重新登录')
      store.dispatch('permission/Reset')
      localStorage.clear()
      router.replace({ path: '/login' })
      return false
    } else if (code === 403) {
      // 403 无权限
      const _curentPath = router.history._startLocation.split('/')
      if (_curentPath[1] && _curentPath[2]) {
        router.replace({ path: `/${_curentPath[1]}/${_curentPath[2]}/noPermissions` })
      }
      return false
    } else {
      return data
    }
  },
  error => {
    if (msgBox) {
      // 防止出现多个msgbox
      msgBox.close()
    }
    msgBox = Message({
      message: '网络异常，请刷新重试',
      duration: 1500,
      forbidClick: true
    })
    return error
  }
)

export default axios
