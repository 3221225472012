// 营销玩法路由
export default [
  {
    title: '营销玩法',
    id: 1001,
    path: '/marketing',
    icon: ['marketing.png', 'marketing_active.png'],
    children: [
      {
        title: '任务宝',
        id: 100101,
        path: '/marketing/task',
        badge: 3,
        children: [
          {
            title: '活动列表',
            id: 10010101,
            path: '/marketing/task/list',
            component: '/client/task/list.vue'
          },
          {
            title: '数据分析',
            id: 10010102,
            path: '/marketing/task/analysis',
            component: '/client/task/analysis.vue'
          },
          {
            title: '活动编辑',
            id: 10010103,
            path: '/marketing/task/edit',
            component: '/client/task/edit.vue',
            is_menu: 0,
            backPath: '/marketing/task/list',
            pid: 10010101
          },
          {
            title: '参与用户',
            id: 10010104,
            path: '/marketing/task/users',
            component: '/client/task/users.vue',
            is_menu: 0,
            backPath: '/marketing/task/list',
            pid: 10010101
          },
          {
            title: '奖品记录',
            id: 10010105,
            path: '/marketing/task/rewards',
            component: '/client/task/rewards.vue',
            is_menu: 0,
            backPath: '/marketing/task/list',
            pid: 10010101
          },
          {
            title: '渠道推广',
            id: 10010106,
            path: '/marketing/task/popularize',
            component: '/client/task/popularize.vue',
            is_menu: 0,
            backPath: '/marketing/task/list',
            pid: 10010101
          }
        ]
      },
      {
        title: '红包',
        id: 100102,
        path: '/marketing/redenvelope',
        badge: 3,
        children: [
          {
            title: '活动列表',
            id: 10010201,
            path: '/marketing/redenvelope/list',
            component: '/client/redenvelope/list.vue'
          },
          {
            title: '数据分析',
            id: 10010202,
            path: '/marketing/redenvelope/analysis',
            component: '/client/redenvelope/analysis.vue'
          },
          {
            title: '活动编辑',
            id: 10010203,
            path: '/marketing/redenvelope/edit',
            is_menu: 0,
            component: '/client/redenvelope/edit.vue',
            backPath: '/marketing/redenvelope/list',
            pid: 10010201
          },
          {
            title: '参与用户',
            id: 10010204,
            path: '/marketing/redenvelope/users',
            is_menu: 0,
            component: '/client/redenvelope/users.vue',
            backPath: '/marketing/redenvelope/list',
            pid: 10010201
          },
          {
            title: '红包记录',
            id: 10010205,
            path: '/marketing/redenvelope/record',
            is_menu: 0,
            component: '/client/redenvelope/record.vue',
            backPath: '/marketing/redenvelope/list',
            pid: 10010201
          },
          {
            title: '推广渠道',
            id: 10010206,
            path: '/marketing/redenvelope/popularize',
            is_menu: 0,
            component: '/client/redenvelope/popularize.vue',
            backPath: '/marketing/redenvelope/list',
            pid: 10010201
          }
        ]
      },
      {
        title: '群裂变',
        id: 100103,
        path: '/marketing/groupfission',
        badge: 3,
        children: [
          {
            title: '活动列表',
            id: 10010301,
            path: '/marketing/groupfission/list',
            component: '/client/groupfission/list.vue'
          },
          {
            title: '数据分析',
            id: 10010302,
            path: '/marketing/groupfission/analysis',
            component: '/client/groupfission/analysis.vue'
          },
          {
            title: '活动编辑',
            id: 10010303,
            path: '/marketing/groupfission/edit',
            is_menu: 0,
            component: '/client/groupfission/edit.vue',
            backPath: '/marketing/groupfission/list',
            pid: 10010301
          },
          {
            title: '活动群',
            id: 10010304,
            path: '/marketing/groupfission/group',
            is_menu: 0,
            component: '/client/groupfission/group.vue',
            backPath: '/marketing/groupfission/list',
            pid: 10010301
          },
          {
            title: '参与用户',
            id: 10010305,
            path: '/marketing/groupfission/users',
            is_menu: 0,
            component: '/client/groupfission/users.vue',
            backPath: '/marketing/groupfission/list',
            pid: 10010301
          },
          {
            title: '奖品记录',
            id: 10010306,
            path: '/marketing/groupfission/record',
            is_menu: 0,
            component: '/client/groupfission/record.vue',
            backPath: '/marketing/groupfission/list',
            pid: 10010301
          },
          {
            title: '渠道推广',
            id: 10010307,
            path: '/marketing/groupfission/popularize',
            is_menu: 0,
            component: '/client/groupfission/popularize.vue',
            backPath: '/marketing/groupfission/list',
            pid: 10010301
          }
        ]
      },
      {
        title: '抽奖',
        id: 100104,
        path: '/marketing/lottery',
        badge: 2,
        children: [
          {
            title: '活动列表',
            id: 10010401,
            path: '/marketing/lottery/list',
            component: '/client/lottery/list.vue'
          },
          {
            title: '活动编辑',
            id: 10010402,
            path: '/marketing/lottery/edit',
            is_menu: 0,
            component: '/client/lottery/edit.vue',
            backPath: '/marketing/lottery/list',
            pid: 10010401
          },
          {
            title: '参与用户',
            id: 10010403,
            path: '/marketing/lottery/users',
            is_menu: 0,
            component: '/client/lottery/users.vue',
            backPath: '/marketing/lottery/list',
            pid: 10010401
          },
          {
            title: '奖品记录',
            id: 10010404,
            path: '/marketing/lottery/record',
            is_menu: 0,
            component: '/client/lottery/record.vue',
            backPath: '/marketing/lottery/list',
            pid: 10010401
          },
          {
            title: '推广渠道',
            id: 10010405,
            path: '/marketing/lottery/popularize',
            is_menu: 0,
            component: '/client/lottery/popularize.vue',
            backPath: '/marketing/lottery/list',
            pid: 10010401
          }
        ]
      }
    ]
  }
]
