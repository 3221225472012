var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        "custom-class": "card bind",
        width: "800px",
        top: "0",
        modal: _vm.hasClose,
        "close-on-click-modal": _vm.hasClose,
        "close-on-press-escape": _vm.hasClose
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("empty-card", {
        attrs: { hasClose: true },
        on: {
          handleClose: function($event) {
            _vm.visible = false
          },
          handleBind: _vm.fn_handleBind
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }