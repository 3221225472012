<!--
 * @Author: zhanln
 * @Date: 2021-07-08 11:49:16
 * @LastEditTime: 2021-07-08 11:58:24
 * @LastEditors: zhanln
 * @Description:
-->
<template>
  <div class="admin-login">
    <img src="../../assets/images/logo.png" alt="" />
    正在接管，请稍等...
  </div>
</template>

<script>
export default {
  created () {
    localStorage.clear()
    const token = this.$route.query.access_token
    const corp_id = this.$route.query.wecom_id
    if (!token || !corp_id) {
      this.$message.info('未传参数或参数有误')
    } else {
      localStorage.setItem('token', token)
      this.fn_jumpToHome(corp_id)
    }
  },

  methods: {

    // 获取路由,进入后台
    async fn_jumpToHome (corp_id) {
      const accessRoutesPromise = await this.$store.dispatch('permission/GenerateRoutes', corp_id)
      this.$router.addRoutes(accessRoutesPromise)
      this.$router.replace({
        path: accessRoutesPromise[0].path
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.admin {
  &-login {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 20px;
    font-weight: bold;

    img {
      margin-bottom: 20px;
    }
  }
}
</style>
