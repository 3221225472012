/*
 * @Author: zhanln
 * @Date: 2021-07-26 11:07:50
 * @LastEditTime: 2022-12-15 14:07:47
 * @LastEditors: zhanln
 * @Description: groupfission
 */
export default {
  namespaced: true,

  state: {
    isReady: false,

    // 创建或编辑
    isEdit: false,

    // 是否复制
    isCopy: false,

    // 是否草稿
    isDaft: false,

    // 是否已结束
    isEnd: false,

    // 活动id
    id: 0,

    // 活动信息
    basic_title: '',
    basic_end_at: null,
    basic_msg_mode: 2,
    basic_wel_status: 1,
    basic_wel_gap: 60,
    basic_wel_user: 5,
    basic_wel_words: '，欢迎你加入专属福利群，参与免费领奖品活动[笑脸]\n\n点击下方活动链接，邀请好友领取奖品：\n1. 邀请2人进群，即可获得a大礼包\n2. 邀请5人进群，即可获得b大礼包\n3. 邀请8人进加，即可获得c大礼包',
    basic_group: [],
    basic_link_type: 1,
    basic_link_logo: [{
      url: 'https://image.01lb.com.cn//uploads/wecom/21/0820/16294310504J3kf6dh.png'
    }],
    basic_link_title: '与我一起领取奖品👇',
    basic_link_desc: '点击链接，领取福利',

    // 海报
    poster_img_url: [],
    poster_avatar_sharp: 2,
    poster_avatar_status: 1,
    poster_avatar_x: 30,
    poster_avatar_y: 40,
    poster_avatar_width: 50,
    poster_avatar_height: 50,
    poster_nickname_status: 1,
    poster_nickname_size: 12,
    poster_nickname_color: '#000000',
    poster_nickname_x: 90,
    poster_nickname_y: 55,
    poster_nickname_offsetX: 0,
    poster_nickname_align: 'left',
    poster_qrcode_x: 107.5,
    poster_qrcode_y: 427,
    poster_qrcode_width: 130,
    poster_qrcode_height: 130,
    poster_share_msg: '我想要领取奖品，点击入群就能帮我助力成功啦[笑脸]',

    // default
    default_banner: 'https://image.01lb.com.cn//uploads/wecom/21/0810/1628578937q5BlAL8l.jpg',
    default_color: '#F4503A',

    // 任务设置
    // 一阶
    first_disable: false,
    first_id: 0,
    first_status: 1,
    first_target_count: 2,
    first_reward_title: '',
    first_reward_desc: '',
    first_reward_coverList: [],
    first_unreal: 1000,
    first_reward_type: 1,
    first_reward_detail: '',
    first_reward_detail_imgList: [],
    first_total: 9999,
    first_give_num: 0,
    first_remain: 9999,
    first_reward_type_id: null,
    first_codeRules: '1. 每个新好友添加客服微信后，即助力成功；<br />2. 企业老用户助力无效；<br />3. 每个账号只能助力一次，添加好友后删除，则助力失败；<br />4. 主办方可以根据本活动的实际举办情况对活动进行变动和调整，本次活动解释权归主办方所有。',
    first_formRules: '1. 每个新好友添加客服微信后，即助力成功；<br />2. 企业老用户助力无效；<br />3. 每个账号只能助力一次，添加好友后删除，则助力失败；<br />4. 主办方可以根据本活动的实际举办情况对活动进行变动和调整，本次活动解释权归主办方所有。',
    first_tag_open: false,
    first_finish_task_tags: '',

    // 二阶
    second_disable: false,
    second_id: 0,
    second_status: 0,
    second_target_count: 0,
    second_reward_title: '',
    second_reward_desc: '',
    second_reward_coverList: [],
    second_unreal: 1000,
    second_reward_type: 1,
    second_reward_detail: '',
    second_reward_detail_imgList: [],
    second_total: 9999,
    second_give_num: 0,
    second_remain: 9999,
    second_reward_type_id: null,
    second_codeRules: '1. 每个新好友添加客服微信后，即助力成功；<br />2. 企业老用户助力无效；<br />3. 每个账号只能助力一次，添加好友后删除，则助力失败；<br />4. 主办方可以根据本活动的实际举办情况对活动进行变动和调整，本次活动解释权归主办方所有。',
    second_formRules: '1. 每个新好友添加客服微信后，即助力成功；<br />2. 企业老用户助力无效；<br />3. 每个账号只能助力一次，添加好友后删除，则助力失败；<br />4. 主办方可以根据本活动的实际举办情况对活动进行变动和调整，本次活动解释权归主办方所有。',
    second_tag_open: false,
    second_finish_task_tags: '',

    // 三阶
    third_disable: false,
    third_id: 0,
    third_status: 0,
    third_target_count: 0,
    third_reward_title: '',
    third_reward_desc: '',
    third_reward_coverList: [],
    third_unreal: 1000,
    third_reward_type: 1,
    third_reward_detail: '',
    third_reward_detail_imgList: [],
    third_total: 9999,
    third_give_num: 0,
    third_remain: 9999,
    third_reward_type_id: null,
    third_codeRules: '1. 每个新好友添加客服微信后，即助力成功；<br />2. 企业老用户助力无效；<br />3. 每个账号只能助力一次，添加好友后删除，则助力失败；<br />4. 主办方可以根据本活动的实际举办情况对活动进行变动和调整，本次活动解释权归主办方所有。',
    third_formRules: '1. 每个新好友添加客服微信后，即助力成功；<br />2. 企业老用户助力无效；<br />3. 每个账号只能助力一次，添加好友后删除，则助力失败；<br />4. 主办方可以根据本活动的实际举办情况对活动进行变动和调整，本次活动解释权归主办方所有。',
    third_tag_open: false,
    third_finish_task_tags: '',

    // 领奖路径
    way_type: 1,
    way_ids: null,
    way_words: '#用户昵称#，超级多朋友为你助力，已经可以领取奖品啦\n请点击下方链接领取你的奖品',
    way_tag_open: 1,
    way_tags: '',

    // 消息推送
    notice_open_help: 1,
    notice_help_send_second: 120,
    notice_help_send_add: 5,
    notice_help_msg: '又有新的好友支持你啦，你离奖品又近了一步~[太阳][太阳][太阳]\n当前活动已有#领奖人数#人领取奖品，快看看你的进度~',
    notice_open_reward: 1,
    notice_reward_send_second: 120,
    notice_reward_send_add: 5,
    notice_reward_msg: '超级多朋友为你助力，已经可以领取奖品啦~[庆祝][庆祝][庆祝]',

    // 其他设置
    other_show_title: '邀请加入群聊',
    other_banner_type: 1,
    other_banner: [],
    other_banner_title: '加入专属福利群\n0元领奖品',
    other_bg_color: 'f4503a',
    other_rule: '1、邀请3人即可获得奖品。\n2、任务完成后，用户所在群发放奖品链接，点击即可领取。用户也可自行保存活动链接，实时了解任务进度。\n3、若邀请进群的用户为老用户或该用户已为他人助力，则此次助力无效。\n4、活动截止时间为【活动结束时间】，活动结束后，用户入群助力无法获得奖品。\n5、好友退群后助力无效。',
    other_customer_service_user: null,
    other_customer_wel_come_msg: '',

    // 奖品弹框
    currentTask: 'first',
    defaultFocus: false
  },

  getters: {
    GET_READY: state => {
      return state.isReady
    },
    GET_TYPE: state => {
      return state.isEdit
    },
    GET_BASIC: state => {
      return {
        title: state.basic_title,
        end_at: state.basic_end_at,
        msg_mode: state.basic_msg_mode,
        wel_status: state.basic_wel_status,
        wel_gap: state.basic_wel_gap,
        wel_user: state.basic_wel_user,
        wel_words: state.basic_wel_words,
        group: state.basic_group,
        link_type: state.basic_link_type,
        link_logo: state.basic_link_logo,
        link_title: state.basic_link_title,
        link_desc: state.basic_link_desc
      }
    },
    GET_POSTER: state => {
      return {
        img_url: state.poster_img_url,
        avatar_sharp: state.poster_avatar_sharp,
        avatar_status: state.poster_avatar_status,
        avatar_x: state.poster_avatar_x,
        avatar_y: state.poster_avatar_y,
        avatar_width: state.poster_avatar_width,
        avatar_height: state.poster_avatar_height,
        nickname_status: state.poster_nickname_status,
        nickname_size: state.poster_nickname_size,
        nickname_color: state.poster_nickname_color,
        nickname_x: state.poster_nickname_x,
        nickname_y: state.poster_nickname_y,
        nickname_offsetX: state.poster_nickname_offsetX,
        nickname_align: state.poster_nickname_align,
        qrcode_x: state.poster_qrcode_x,
        qrcode_y: state.poster_qrcode_y,
        qrcode_width: state.poster_qrcode_width,
        qrcode_height: state.poster_qrcode_height,
        share_msg: state.poster_share_msg
      }
    },
    GET_WAY: state => {
      return {
        type: state.way_type,
        ids: state.way_ids,
        words: state.way_words,
        tag_open: state.way_tag_open,
        tags: state.way_tags
      }
    },
    GET_NOTICE: state => {
      return {
        open_help: state.notice_open_help,
        help_send_second: state.notice_help_send_second,
        help_send_add: state.notice_help_send_add,
        help_msg: state.notice_help_msg,
        open_reward: state.notice_open_reward,
        reward_send_second: state.notice_reward_send_second,
        reward_send_add: state.notice_reward_send_add,
        reward_msg: state.notice_reward_msg
      }
    },
    GET_BANNER: state => {
      return {
        type: state.banner_type,
        title: state.banner_title,
        list: state.banner_list,
        color: state.banner_color
      }
    },
    GET_DEFAULT: state => {
      return {
        banner: state.default_banner,
        color: state.default_color
      }
    },
    GET_TASK: state => name => {
      return {
        disable: state[name + '_disable'],
        id: state[name + '_id'],
        status: state[name + '_status'],
        target_count: state[name + '_target_count'],
        reward_title: state[name + '_reward_title'],
        reward_desc: state[name + '_reward_desc'],
        reward_coverList: state[name + '_reward_coverList'],
        unreal: state[name + '_unreal'],
        reward_type: state[name + '_reward_type'],
        reward_detail: state[name + '_reward_detail'],
        reward_detail_imgList: state[name + '_reward_detail_imgList'],
        total: state[name + '_total'],
        give_num: state[name + '_give_num'],
        remain: state[name + '_remain'],
        reward_type_id: state[name + '_reward_type_id'],
        form_id: state[name + '_form_id'],
        codeRules: state[name + '_codeRules'],
        formRules: state[name + '_formRules'],
        tag_open: state[name + '_tag_open'],
        finish_task_tags: state[name + '_finish_task_tags']
      }
    },
    GET_OTHER: state => {
      return {
        show_title: state.other_show_title,
        banner_type: state.other_banner_type,
        banner: state.other_banner,
        banner_title: state.other_banner_title,
        bg_color: state.other_bg_color,
        customer_service_user: state.other_customer_service_user,
        customer_wel_come_msg: state.other_customer_wel_come_msg,
        rule: state.other_rule
      }
    }
  },

  mutations: {
    RESET: state => {
      state.isReady = false

      // 创建或编辑
      state.isEdit = false

      // 是否复制
      state.isCopy = false

      // 是否草稿
      state.isDaft = false

      // 是否已结束
      state.isEnd = false

      // 活动id
      state.id = 0

      // 活动信息
      state.basic_title = ''
      state.basic_end_at = null
      state.basic_msg_mode = 2
      state.basic_wel_status = 1
      state.basic_wel_gap = 120
      state.basic_wel_user = 5
      state.basic_wel_words = '欢迎加入群聊，参与活动即可免费领取奖品哦~[笑脸]\n\n❤️❤️【免费领取规则】：\n① 需邀请2人进群，即可领取A大礼包\n② 需邀请5人进群，即可领取B大礼包\n③ 需邀请8人进群，即可领取C大礼包\n👇点下方活动链接，生成专属海报！'
      state.basic_group = []
      state.basic_link_type = 1
      state.basic_link_logo = [{
        url: 'https://image.01lb.com.cn//uploads/wecom/21/0820/16294310504J3kf6dh.png'
      }]
      state.basic_link_title = '与我一起领取奖品👇'
      state.basic_link_desc = '点击链接，领取福利'

      // 海报
      state.poster_img_url = []
      state.poster_avatar_sharp = 2
      state.poster_avatar_status = 1
      state.poster_avatar_x = 30
      state.poster_avatar_y = 40
      state.poster_avatar_width = 50
      state.poster_avatar_height = 50
      state.poster_nickname_status = 1
      state.poster_nickname_size = 12
      state.poster_nickname_color = '#000000'
      state.poster_nickname_x = 90
      state.poster_nickname_y = 55
      state.poster_nickname_offsetX = 0
      state.poster_nickname_align = 'left'
      state.poster_qrcode_x = 107.5
      state.poster_qrcode_y = 427
      state.poster_qrcode_width = 130
      state.poster_qrcode_height = 130
      state.poster_share_msg = '我想要领取奖品，点击入群就能帮我助力成功啦[笑脸]'

      // banner
      state.banner_type = 1
      state.banner_title = '加入专属福利群\n0元领奖品'
      state.banner_list = []
      state.banner_color = ''

      // 任务设置
      // 一阶
      state.first_disable = false
      state.first_id = 0
      state.first_status = 1
      state.first_target_count = 2
      state.first_reward_title = ''
      state.first_reward_desc = ''
      state.first_reward_coverList = []
      state.first_unreal = 1000
      state.first_reward_type = 1
      state.first_reward_detail = ''
      state.first_reward_detail_imgList = []
      state.first_total = 9999
      state.first_give_num = 0
      state.first_remain = 9999
      state.first_reward_type_id = null
      state.first_codeRules = '1. 每个新好友添加客服微信后，即助力成功；<br />2. 企业老用户助力无效；<br />3. 每个账号只能助力一次，添加好友后删除，则助力失败；<br />4. 主办方可以根据本活动的实际举办情况对活动进行变动和调整，本次活动解释权归主办方所有。'
      state.first_formRules = '1. 每个新好友添加客服微信后，即助力成功；<br />2. 企业老用户助力无效；<br />3. 每个账号只能助力一次，添加好友后删除，则助力失败；<br />4. 主办方可以根据本活动的实际举办情况对活动进行变动和调整，本次活动解释权归主办方所有。'
      state.first_tag_open = false
      state.first_finish_task_tags = ''

      // 二阶
      state.second_disable = false
      state.second_id = 0
      state.second_status = 0
      state.second_target_count = 0
      state.second_reward_title = ''
      state.second_reward_desc = ''
      state.second_reward_coverList = []
      state.second_unreal = 1000
      state.second_reward_type = 1
      state.second_reward_detail = ''
      state.second_reward_detail_imgList = []
      state.second_total = 9999
      state.second_give_num = 0
      state.second_remain = 9999
      state.second_reward_type_id = null
      state.second_codeRules = '1. 每个新好友添加客服微信后，即助力成功；<br />2. 企业老用户助力无效；<br />3. 每个账号只能助力一次，添加好友后删除，则助力失败；<br />4. 主办方可以根据本活动的实际举办情况对活动进行变动和调整，本次活动解释权归主办方所有。'
      state.second_formRules = '1. 每个新好友添加客服微信后，即助力成功；<br />2. 企业老用户助力无效；<br />3. 每个账号只能助力一次，添加好友后删除，则助力失败；<br />4. 主办方可以根据本活动的实际举办情况对活动进行变动和调整，本次活动解释权归主办方所有。'
      state.second_tag_open = false
      state.second_finish_task_tags = ''

      // 三阶
      state.third_disable = false
      state.third_id = 0
      state.third_status = 0
      state.third_target_count = 0
      state.third_reward_title = ''
      state.third_reward_desc = ''
      state.third_reward_coverList = []
      state.third_unreal = 1000
      state.third_reward_type = 1
      state.third_reward_detail = ''
      state.third_reward_detail_imgList = []
      state.third_total = 9999
      state.third_give_num = 0
      state.third_remain = 9999
      state.third_reward_type_id = null
      state.third_codeRules = '1. 每个新好友添加客服微信后，即助力成功；<br />2. 企业老用户助力无效；<br />3. 每个账号只能助力一次，添加好友后删除，则助力失败；<br />4. 主办方可以根据本活动的实际举办情况对活动进行变动和调整，本次活动解释权归主办方所有。'
      state.third_formRules = '1. 每个新好友添加客服微信后，即助力成功；<br />2. 企业老用户助力无效；<br />3. 每个账号只能助力一次，添加好友后删除，则助力失败；<br />4. 主办方可以根据本活动的实际举办情况对活动进行变动和调整，本次活动解释权归主办方所有。'
      state.third_tag_open = false
      state.third_finish_task_tags = ''

      // 领奖路径
      state.way_type = 1
      state.way_ids = null
      state.way_words = '#用户昵称# 你的好友已为你助力成功啦，点下方链接领取你的专属奖品吧~'
      state.way_tag_open = 1
      state.way_tags = ''

      // 消息推送
      state.notice_open_help = 1
      state.notice_help_send_second = 120
      state.notice_help_send_add = 5
      state.notice_help_msg = '又有新的好友支持你啦，你离奖品又近了一步~[太阳][太阳][太阳]\n当前活动已有#领奖人数#人领取奖品，快看看你的进度~'
      state.notice_open_reward = 1
      state.notice_reward_send_second = 120
      state.notice_reward_send_add = 5
      state.notice_reward_msg = '超级多朋友为你助力，已经可以领取奖品啦~[庆祝][庆祝][庆祝]'

      // 其他设置
      state.other_show_title = '邀请加入群聊'
      state.other_banner_type = 1
      state.other_banner = []
      state.other_banner_title = '加入专属福利群\n0元领奖品'
      state.other_bg_color = 'f4503a'
      state.other_rule = '1、邀请3人即可获得奖品。\n2、任务完成后，用户所在群发放奖品链接，点击即可领取。用户也可自行保存活动链接，实时了解任务进度。\n3、若邀请进群的用户为老用户或该用户已为他人助力，则此次助力无效。\n4、活动截止时间为【活动结束时间】，活动结束后，用户入群助力无法获得奖品。\n5、好友退群后助力无效。'
      state.other_customer_service_user = null
      state.other_customer_wel_come_msg = ''
      state.other_show_title = ' 邀请加入群聊'
      state.other_rule = '1、邀请3人即可获得奖品。\n2、任务完成后，用户所在群发放奖品链接，点击即可领取。用户也可自行保存活动链接，实时了解任务进度。\n3、若邀请进群的用户为老用户或该用户已为他人助力，则此次助力无效。\n4、活动截止时间为【活动结束时间】，活动结束后，用户入群助力无法获得奖品。\n5、好友退群后助力无效。'

      // 奖品弹框
      state.currentTask = 'first'
      state.defaultFocus = false
    },
    SET_FORM: (state, n) => {
      state[`${n.form}_${n.name}`] = n.val
    }
  }

}
