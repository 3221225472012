var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "500px",
        "append-to-body": "",
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "ly-cropper" },
        [
          _c("vueCropper", {
            ref: "cropper",
            attrs: {
              img: _vm.option.img,
              outputSize: _vm.option.outputSize,
              outputType: _vm.option.outputType,
              info: _vm.option.info,
              canScale: _vm.option.canScale,
              autoCrop: _vm.option.autoCrop,
              autoCropWidth: _vm.option.autoCropWidth,
              autoCropHeight: _vm.option.autoCropHeight,
              fixed: _vm.option.fixed,
              fixedNumber: _vm.option.fixedNumber,
              full: _vm.option.full,
              fixedBox: _vm.option.fixedBox,
              canMoveBox: _vm.option.canMoveBox,
              original: _vm.option.original,
              centerBox: _vm.option.centerBox,
              high: _vm.option.high,
              infoTrue: _vm.option.infoTrue,
              maxImgSize: _vm.option.maxImgSize,
              enlarge: _vm.option.enlarge,
              mode: _vm.option.mode
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ly-cropper__tool" },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", icon: "el-icon-plus" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.cropper.changeScale(1)
                    }
                  }
                },
                [_vm._v("放大")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", icon: "el-icon-minus" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.cropper.changeScale(-1)
                    }
                  }
                },
                [_vm._v("缩小")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", icon: "el-icon-refresh-right" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.cropper.rotateRight()
                    }
                  }
                },
                [_vm._v("旋转")]
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "", size: "small" },
              on: { click: _vm.fn_finish }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }