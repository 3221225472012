/*
 * @Author: zhanln
 * @Date: 2021-12-01 10:53:15
 * @LastEditTime: 2022-07-28 12:11:46
 * @LastEditors: zhan
 */
import table from './table'
import weChatQr from './WeChatQr'
import toolTip from './toolTip'
import LyStatus from './LyStatus'
import LyMobile from './LyMobile'
import LyAction from './LyAction'
import LyUpload from './LyUpload'
import LyTagDialog from './wecomTags/select.vue'
import LyTagSelect from './LyTagSelect'
import LyEditor from './lyEditor'
import LyEmp from './LyEmpSelect'
import LyEmpBtn from './LyEmpSelect/btn.vue'
import LyEmpSelect from './LyEmpSelect/select.vue'
import LyCropper from './LyCropper'
import LyRobot from './LyRobotSelect'
import LyRobotBtn from './LyRobotSelect/btn.vue'
import LyRobotSelect from './LyRobotSelect/select.vue'
import LyForm from './LyForm'

export default {
  install (Vue) {
    Vue.component('ly-table', table)
    Vue.component('ly-wechat-qr', weChatQr)
    Vue.component('ly-tool-tip', toolTip)
    Vue.component('ly-status', LyStatus)
    Vue.component('ly-mobile', LyMobile)
    Vue.component('ly-action', LyAction)
    Vue.component('ly-upload', LyUpload)
    Vue.component('ly-tag-dialog', LyTagDialog)
    Vue.component('ly-tag-select', LyTagSelect)
    Vue.component('ly-editor', LyEditor)
    Vue.component('ly-emp', LyEmp)
    Vue.component('ly-emp-btn', LyEmpBtn)
    Vue.component('ly-emp-select', LyEmpSelect)
    Vue.component('ly-cropper', LyCropper)
    Vue.component('ly-robot', LyRobot)
    Vue.component('ly-robot-btn', LyRobotBtn)
    Vue.component('ly-robot-select', LyRobotSelect)
    Vue.component('ly-form', LyForm)
  }
}
