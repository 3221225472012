<!--
 * @Author: zhanln
 * @Date: 2021-07-29 18:52:50
 * @LastEditTime: 2022-03-21 16:52:14
 * @LastEditors: zhanln
 * @Description: title：标题；content：初始内容；insetMenu：插入变量；emoji：开启插入表情；recoverContent：默认文案；error：校验（用与样式）；
    <ly-editor
      title="入群欢迎语"
      :content="basicForm.wel_words"
      :insetMenu="['用户昵称', '用户昵称', '用户昵称']"
      emoji
      :recoverContent="initHtml"
      :error="wordError !== ''"
      @getContent="fn_getWelWords"
    ></ly-editor>
-->

<template>
  <div :class="['ly-editor', error ? 'is-error' : '']" :ref="'lyEditor_' + id">
    <div class="ly-editor__header" v-if="insetMenu.length > 0 || emoji">
      <div class="ly-editor__header-left">
        <ly-emoji @change="handleEmojiChange" v-if="emoji">
          <div class="emojibox">
            <i class="iconfont icon-emo"></i>
          </div>
        </ly-emoji>
        <el-button
          class="ly-editor__menus"
          v-for="(item, index) in insetMenu"
          :key="index"
          :icon="getIcon(item)"
          @click="fn_insetText(item, '#')"
          >{{ item }}</el-button
        >
      </div>
    </div>

    <div class="ly-editor__body">
      <el-input
        type="textarea"
        resize="none"
        v-model="selfContent"
        :placeholder="`请输入${title}`"
        :id="id"
        :maxlength="maxLength"
        @focus="fn_focus"
        @blur="fn_blur"
      ></el-input>
    </div>

    <div class="ly-editor__footer">
      <span class="ly-editor__recover" @click="fn_recover" v-if="recoverContent"
        >恢复默认文案</span
      >
      <span class="ly-editor__count"
        >{{ selfContent.length }} / {{ maxLength }}</span
      >
    </div>
  </div>
</template>

<script>
import lyEmoji from '@/components/emoji/index.vue'
export default {
  name: 'lyEditor',

  components: {
    lyEmoji
  },

  props: {
    id: {
      type: String,
      default: 'ly-edit'
    },
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    insetMenu: {
      type: Array,
      default: () => {
        return []
      }
    },
    emoji: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: 1000
    },
    recoverContent: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      selfContent: ''
    }
  },

  watch: {
    content (val) {
      this.selfContent = val
    },
    selfContent (val) {
      this.$emit('getContent', val)
    }
  },

  mounted () {
    this.selfContent = this.content
  },

  methods: {
    getIcon (item) {
      let icon = ''
      switch (item) {
        case '用户昵称':
          icon = 'icon-user1'
          break
        case '活动名称':
          icon = 'icon-user1'
          break
        case '活动链接':
          icon = 'icon-link'
          break
        case '任务阶段':
          icon = 'icon-stage'
          break
        case '奖品名称':
          icon = 'icon-jiangpin'
          break
        case '助力好友昵称':
          icon = 'icon-boost'
          break
        case '已完成任务量':
          icon = 'icon-selected-circle'
          break
        case '未完成任务量':
          icon = 'icon-unfinished'
          break
        case '涨粉账号':
          icon = 'icon-satff'
          break
      }
      return `iconfont ${icon}`
    },
    // 插入emoji
    handleEmojiChange (emoji) {
      this.fn_insetText(emoji)
    },
    // 插入变量
    fn_insetText (txt, sym = '') {
      if (this.id === 'ly-edit') {
        console.log('使用插入变量需要设置唯一id')
        return false
      }
      if (this.selfContent.length + txt.length > this.maxLength) {
        this.fn_lyMsg('info', `字数不可超过${this.maxLength}`)
        return false
      }
      txt = sym + txt + sym
      const elem = document.querySelector('#' + this.id)
      const sPos = elem.selectionStart
      const ePos = elem.selectionEnd
      if (sPos === undefined || ePos === undefined) return
      let value = elem.value
      value = value.substring(0, sPos) + txt + value.substring(ePos)
      elem.value = value
      elem.focus()
      elem.selectionStart = sPos + txt.length
      elem.selectionEnd = ePos + txt.length
      this.selfContent = value
      this.showEmoji = false
    },

    // 恢复默认文案
    fn_recover () {
      this.$lyConfirm({
        title: `确定将${this.title}恢复为默认文案吗？`,
        text: ''
      }).then(() => {
        this.selfContent = this.recoverContent
      }).catch(() => { })
    },

    fn_focus () {
      if (this.$listeners.focus) {
        this.$emit('focus')
      }
    },

    fn_blur () {
      if (this.$listeners.blur) {
        this.$emit('blur')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";
.ly-editor {
  border: 1px solid $--color-border;
  border-radius: 8px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow: hidden;
  &.is-error {
    border-color: $--color-danger !important;
  }

  &:hover {
    border-color: $--color-primary;
  }
  &__header {
    background: #f7f8fc;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #dfe1e8;
    padding: 8px 12px;
    box-sizing: border-box;
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-left,
    &-right {
      display: flex;
      // height: 40px;
      align-items: center;
    }

    &-left {
      flex-wrap: wrap;
    }

    .emojibox i {
      font-size: 16px;
      cursor: pointer;
      &:hover {
        color: $--color-primary;
      }
    }
  }

  &__menus {
    display: inline-block;
    background: #fff;
    line-height: 28px;
    padding: 0 8px;
    border-radius: 28px;
    transition: 0.25s;
    border: unset;
    font-weight: normal;
    &:not(:first-child) {
      margin-left: 6px;
    }

    &:hover {
      cursor: pointer;
      background-color: $--color-primary;
      color: #fff;
    }
  }

  &__body {
    ::v-deep .el-textarea__inner {
      border-radius: 4px;
      border: none;
      height: 180px;
      padding-top: 12px;
    }
  }

  &__footer {
    height: 34px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__recover {
    display: inline-block;
    font-size: 12px;
    padding: 0 8px;
    height: 28px;
    line-height: 28px;
    border-radius: 14px;
    transition: 0.25s;
    color: $--color-text-regular;
    margin-right: 2px;

    &:hover {
      cursor: pointer;
      background-color: #f0f1f8;
    }
  }

  &__count {
    color: $--color-text-placeholder;
    font-size: 12px;
  }

  &__emoji {
    position: relative;
  }

  &__drow {
    position: absolute;
    left: 0;
    top: 43px;
    width: 280px;
    height: 160px;
    background-color: #fff;
    z-index: 1;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    &:before {
      position: absolute;
      content: "";
      top: -12px;
      left: 12px;
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-bottom-color: #fff;
    }

    .scroll {
      padding: 8px;
      height: 100%;
      overflow: hidden;
      box-sizing: border-box;
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      overflow: auto;

      .item {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        transition: 0.25s;

        &:hover {
          cursor: context-menu;
          background-color: #f5f5f5;
        }
      }
    }
  }
  .el-button .iconfont {
    font-size: 16px;
  }
}

.is-error {
  .ly-editor {
    border-color: $--color-danger;
  }
}
</style>
