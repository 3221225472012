var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "file fx" }, [
    _c("div", { staticClass: "file-info flex-1 text-ellipsis" }, [
      _c("div", { staticClass: "file-name text-ellipsis fx" }, [
        _c("div", { staticClass: "text-ellipsis" }, [
          _vm._v(_vm._s(_vm.computedName(_vm.file.name)))
        ]),
        _c("div", [_vm._v("." + _vm._s(_vm.getFileExt(_vm.file.name)))])
      ]),
      _c("div", { staticClass: "file-size" }, [
        _vm._v(_vm._s(_vm.computedSize(_vm.file.size)))
      ])
    ]),
    _c("div", { staticClass: "file-icon" }, [
      _c("img", { attrs: { src: _vm.computedExt(_vm.file.name), alt: "" } })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }