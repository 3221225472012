var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.visible,
            width: "780px",
            "close-on-click-modal": false,
            modal: _vm.modal,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "emp-box" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.dataLoading,
                    expression: "dataLoading"
                  }
                ],
                ref: "empBoxRef",
                staticClass: "emp-box__left",
                attrs: { "element-loading-text": "数据加载中，请稍后~" }
              },
              [
                _c("div", { staticClass: "emp-box__left--header" }, [
                  _c(
                    "div",
                    { staticClass: "emp-box__search" },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "lydebounce",
                            rawName: "v-lydebounce",
                            value: ["input", _vm.fn_search],
                            expression: "['input', fn_search]"
                          }
                        ],
                        staticClass: "emp-box__search--input",
                        attrs: {
                          placeholder: "搜索成员",
                          "prefix-icon": "el-icon-search"
                        },
                        model: {
                          value: _vm.searchName,
                          callback: function($$v) {
                            _vm.searchName = $$v
                          },
                          expression: "searchName"
                        }
                      }),
                      _vm.searchName
                        ? _c("el-button", {
                            staticClass: "emp-box__search--close",
                            attrs: {
                              icon: "el-icon-circle-close",
                              type: "text",
                              circle: ""
                            },
                            on: { click: _vm.fn_clearSearch }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("div", { staticClass: "emp-tree__total" }, [
                    _vm._v("全部成员（" + _vm._s(_vm.total) + "）")
                  ])
                ]),
                _c(
                  "el-scrollbar",
                  { ref: "empScollRef", staticClass: "emp-tree__scroll" },
                  [
                    _vm.visible
                      ? _c("el-tree", {
                          ref: "empTreeRef",
                          staticClass: "emp-tree",
                          attrs: {
                            load: _vm.fn_loadNode,
                            lazy: "",
                            nodeKey: "id",
                            props: _vm.treeProps,
                            "default-expanded-keys": _vm.defaultExpanded
                          },
                          on: { "node-click": _vm.fn_nodeClick },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var node = ref.node
                                  var data = ref.data
                                  return _c(
                                    "div",
                                    { staticClass: "tree-content" },
                                    [
                                      data.corp_department_id
                                        ? [
                                            _c("i", {
                                              staticClass:
                                                "iconfont icon-folder-fill q-primary"
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "emp-tree__label"
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(data.name) + " "
                                                )
                                              ]
                                            )
                                          ]
                                        : _vm._e(),
                                      data.base_id
                                        ? [
                                            _c(
                                              "div",
                                              { staticClass: "emp-tree__item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "emp-tree__avatar"
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src:
                                                          data.avatar_url ||
                                                          _vm.defaultAvatar,
                                                        alt: ""
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "emp-tree__info"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "name" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(data.name) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "role",
                                                        class: [
                                                          data.role_id === 4 ||
                                                          data.role_id === 1
                                                            ? "super"
                                                            : "normal"
                                                        ]
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.role_name
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "dep" },
                                                      [
                                                        _vm._v(
                                                          " 部门：" +
                                                            _vm._s(
                                                              data.wecom_departments.join(
                                                                "，"
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c("el-checkbox", {
                                                  attrs: {
                                                    disabled: data.isDisable
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                    }
                                                  },
                                                  model: {
                                                    value: node.checked,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        node,
                                                        "checked",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "node.checked"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        : _vm._e(),
                                      data.load_more
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "emp-tree__load",
                                                class: { active: data.loading }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-refresh-right"
                                                }),
                                                _vm._v("加载更多 ")
                                              ]
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                }
                              }
                            ],
                            null,
                            false,
                            847505167
                          )
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c("search-box", {
                  ref: "searchBoxRef",
                  attrs: {
                    checkList: _vm.checkList,
                    diabledList: _vm.diabledList,
                    datafilter: _vm.datafilter
                  },
                  on: { setCheck: _vm.fn_setCheck }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "emp-box__right" },
              [
                _c("div", { staticClass: "emp-checked__count" }, [
                  _vm._v(" 已选择 "),
                  _c(
                    "span",
                    {
                      class: [
                        _vm.max && _vm.checkList.length > _vm.max
                          ? "q-danger"
                          : "q-primary"
                      ]
                    },
                    [_vm._v(_vm._s(_vm.checkList.length) + " ")]
                  ),
                  _vm._v(_vm._s(_vm.max ? "/ " + _vm.max : "") + " 成员 ")
                ]),
                _c(
                  "el-scrollbar",
                  { staticClass: "emp-checked__scroll" },
                  _vm._l(_vm.checkList, function(item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "emp-checked__item" },
                      [
                        _c("div", { staticClass: "emp-checked__info" }, [
                          _c("img", {
                            staticClass: "emp-checked__avatar",
                            attrs: {
                              src: item.avatar_url || _vm.defaultAvatar,
                              alt: ""
                            }
                          }),
                          _c("span", { staticClass: "emp-checked__name" }, [
                            _vm._v(_vm._s(item.name))
                          ])
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "emp-checked__remove",
                            on: {
                              click: function($event) {
                                return _vm.fn_removeItem(item, index)
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-close" })]
                        )
                      ]
                    )
                  }),
                  0
                )
              ],
              1
            )
          ]),
          _c("span", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c(
              "div",
              { staticClass: "flex flex-between" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      round: "",
                      disabled: _vm.dataLoading || _vm.checkList.length === 0
                    },
                    on: { click: _vm.fn_clearCheck }
                  },
                  [_vm._v("清空所选")]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { round: "", disabled: _vm.dataLoading },
                        on: {
                          click: function($event) {
                            _vm.visible = false
                          }
                        }
                      },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "最多可选 " + _vm.max + " 名成员",
                          placement: "top",
                          disabled:
                            _vm.max === null ||
                            (_vm.max !== null &&
                              _vm.checkList.length <= _vm.max)
                        }
                      },
                      [
                        _c(
                          "span",
                          { staticStyle: { "margin-left": "12px" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  round: "",
                                  type: "primary",
                                  loading: _vm.btnLoading,
                                  disabled:
                                    _vm.dataLoading ||
                                    (_vm.max && _vm.checkList.length > _vm.max)
                                },
                                on: { click: _vm.fn_confirm }
                              },
                              [_vm._v("确定")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      ),
      _c("emp-tip", { ref: "empTipRef", attrs: { searchName: _vm.searchName } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }