<template>
  <div class="config">
    <!-- header -->
    <header>
      <div class="config-container">
        <div class="flex">
          <img src="~@assets/svg/01sk.svg" alt="" style="margin-right: 24px" />
          <el-dropdown @command="handleCorpCommand" v-if="company">
            <span class="el-dropdown-link">
              <div class="login-container_user">
                {{ company }}<i class="el-icon-arrow-down el-icon--right"></i>
              </div>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="company">选择企业</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div style="display: flex; align-items: center">
          <el-button type="text" style="margin-right: 24px" @click="jumpToLogin"
            >返回自动配置，扫码一次搞定</el-button
          >
          <el-dropdown @command="handleHeaderCommand">
            <span class="el-dropdown-link">
              <div class="config-container_user">
                <img :src="userInfo.avatar_url" alt="" class="avatar" />
                {{ userInfo.nickname
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </div>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="user">个人中心</el-dropdown-item>
              <el-dropdown-item command="signOut">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </header>

    <!-- main -->
    <div class="config-main">
      <div class="config-main-left">
        <div class="config-timeline">
          <div
            class="config-timeline-item"
            v-for="(item, index) in activities"
            :key="index"
          >
            <div
              :class="[
                'dot',
                item.success ? 'success' : '',
                index + 1 === active ? 'active' : ''
              ]"
            >
              <template v-if="item.success && index + 1 !== active">
                <i class="el-icon-check"></i>
              </template>
              <template v-else>
                {{ index + 1 }}
              </template>
            </div>
            <div :class="['text', index + 1 === active ? 'bold' : '']">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>

      <div class="config-main-center">
        <!-- <el-button @click="configSuccess(1)">下一步（测试用）</el-button> -->
        <el-scrollbar style="height: 100%" v-if="active === 1">
          <h1>简单对接后可免费使用</h1>
          <p class="title">一. 创建零一裂变自建应用</p>
          <p>
            (1) 打开
            <a
              href="https://work.weixin.qq.com/wework_admin/frame#apps"
              target="_blank"
              class="q-primary"
              >企业微信后台</a
            >
            【应用管理】，在【自建】下选择【创建应用】。
          </p>
          <img src="../../assets/images/step1_1.png" alt="" />
          <p>
            (2)
            应用名称填写【我的通知】，请在右侧下载应用logo进行上传，不用填写应用介绍。
          </p>
          <p>
            (3)
            在【可见范围】内【选择部门/成员】，可先选择授权给顶级部门（部门列表第一个），避免部分员工无法使用功能。
          </p>
          <img src="../../assets/images/step1_2.png" alt="" />
          <p>
            (4)
            创建成功后，在【我的通知】内找到secret和Agentld，复制并填写到右侧输入框内。
          </p>
          <img src="../../assets/images/step1_3.png" alt="" />
          <p>(5) 完成后请点击右侧【下一步】</p>
        </el-scrollbar>
        <el-scrollbar style="height: 100%" v-if="active === 2">
          <p class="title">二. 配置客户权限</p>
          <p>
            (1)
            选择【客户联系】-【权限配置】，点击【添加/修改】添加使用范围，请选择授权给顶级部门（部门列表第一个），避免部分员工无法使用功能。
          </p>
          <img src="../../assets/images/step2_1.png" alt="" />
          <p>
            (2) 切换到【客户联系】-
            【客户】，点击【API】展开选择【可调用应用】，点击【设置】。
          </p>
          <img src="../../assets/images/step2_2.png" alt="" />
          <p>(3) 勾选之前创建的【我的通知】。</p>
          <img src="../../assets/images/step2_3.png" alt="" />
          <p>(4) 复制secret，填写到右侧输入框完成后请点击【下一步】。</p>
          <img src="../../assets/images/step2_4.png" alt="" />
        </el-scrollbar>
        <el-scrollbar style="height: 100%" v-if="active === 3">
          <p class="title">三. 对接自建应用</p>
          <p>(1) 点击【应用管理】-【自建】中创建的【我的通知】。</p>
          <img src="../../assets/images/step3_1.png" alt="" />
          <p>
            (2) 找到【功能】中的【接收消息】，点击【设置API接收】，进行设置。
          </p>
          <img src="../../assets/images/step3_2.png" alt="" />
          <p>(3) 将右侧信息依次填写到【接收消息服务器配置】对应的输入框中。</p>
          <p class="q-danger">
            在【选择需要接受的事件类型】中，一定要勾选【用户发送的普通消息】和【外部联系人变更回调】，否则会导致部分功能无法使用
          </p>
          <img src="../../assets/images/step3_3.png" alt="" />
        </el-scrollbar>
        <el-scrollbar style="height: 100%" v-if="active === 4">
          <p class="title">四. 域名验证</p>
          <p>
            (1)
            在创建应用成功后，进入【我的通知详情页】找到【开发者接口】中的【网页授权及JS-SDK】页面，点击【设置可信域名】，进行设置
          </p>
          <img src="../../assets/images/step4_1.png" alt="" />
          <p>(2) 复制右侧链接填写到输入框，填写完成后点击【申请校验域名】。</p>
          <img src="../../assets/images/step4_2.png" alt="" />
          <p>
            (3)
            下载txt文件，在右侧上传，上传成功后直接勾选【已上传域名归属校验文件】，确认即可验证。
          </p>
          <img src="../../assets/images/step4_3.png" alt="" />
        </el-scrollbar>
        <el-scrollbar style="height: 100%" v-if="active === 5 || active === 6">
          <p class="title">五. 配置聊天工具栏</p>
          <p>(1) 点击【应用管理】-【第三方】中创建的【零一无限增长】。</p>
          <img src="../../assets/images/step5_1.png" alt="" />
          <p>
            (2)
            在第三方应用【零一无限增长】找到【配置到聊天工具栏】，点击【配置】，进行设置
          </p>
          <img src="../../assets/images/step5_2.png" alt="" />
          <p>(3) 继续点击【配置页面】进行配置。</p>
          <img src="../../assets/images/step5_3.png" alt="" />
          <p>(4) 将右侧信息依次填写到【配置页面弹窗】对应的输入框中。</p>
          <img src="../../assets/images/step5_4.png" alt="" />
          <p>(4) 恭喜配置完成，请点击下一步验证配置，通过后即可使用。</p>
        </el-scrollbar>
      </div>

      <div class="config-main-right">
        <el-carousel
          direction="vertical"
          :autoplay="false"
          ref="formCarousel"
          indicator-position="none"
        >
          <el-carousel-item name="form1">
            <div class="config-form">
              <div class="config-form-title">1.配置自建应用</div>
              <div class="config-form-logo">
                <div class="logo">
                  <img
                    src="https://image.01lb.com.cn/FtV-30x2z5K6ZlyQSiTZ6ZbiilcI"
                    alt=""
                    style="width: 100px"
                  />
                </div>
                <div class="download" @click="downloadLogo">
                  <i class="el-icon-download"></i> 点击下载logo
                </div>
              </div>
              <el-form
                :model="addForm1"
                :rules="rules1"
                ref="addForm1"
                size="medium"
              >
                <el-form-item label="agent_id" prop="agent_id">
                  <el-input
                    v-model="addForm1.agent_id"
                    placeholder="请在企微后台复制，并在此粘贴agent_id"
                  ></el-input>
                </el-form-item>
                <el-form-item label="secret" prop="secret">
                  <el-input
                    v-model="addForm1.secret"
                    placeholder="请在企微后台复制，并在此粘贴secret"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div class="config-form-btns">
                <el-button
                  type="primary"
                  @click="submitForm('addForm1', 'f1')"
                  :loading="btnLoading"
                  >{{ btnLoading ? '检测中' : '下一步' }}</el-button
                >
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item name="form2">
            <div class="config-form">
              <div class="config-form-title">2.配置客户联系</div>
              <el-form
                :model="addForm2"
                :rules="rules2"
                ref="addForm2"
                size="medium"
              >
                <el-form-item label="客户联系secret" prop="secret">
                  <el-input
                    v-model="addForm2.secret"
                    placeholder="请在企微后台复制，并在此粘贴客户联系secret"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div class="config-form-btns">
                <el-button @click="stepBack">上一步</el-button>
                <el-button
                  type="primary"
                  @click="submitForm('addForm2', 'f2')"
                  :loading="btnLoading"
                  >{{ btnLoading ? '检测中' : '下一步' }}</el-button
                >
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item name="form3">
            <div class="config-form">
              <div class="config-form-title">3.对接应用接口</div>
              <el-form :model="addForm3" size="medium">
                <el-form-item label="URL">
                  <div class="config-form-input">
                    <el-input
                      v-model="addForm3.url"
                      :readonly="true"
                      ref="url_3"
                    ></el-input>
                    <el-button type="primary" plain @click="handleCopy('url_3')"
                      >复制</el-button
                    >
                  </div>
                </el-form-item>
                <el-form-item label="Token">
                  <div class="config-form-input">
                    <el-input
                      v-model="addForm3.token"
                      :readonly="true"
                      ref="token"
                    ></el-input>
                    <el-button type="primary" plain @click="handleCopy('token')"
                      >复制</el-button
                    >
                  </div>
                </el-form-item>
                <el-form-item label="EncodingAESKey" prop="aes_key">
                  <div class="config-form-input">
                    <el-input
                      v-model="addForm3.aes_key"
                      :readonly="true"
                      ref="aes_key"
                    ></el-input>
                    <el-button
                      type="primary"
                      plain
                      @click="handleCopy('aes_key')"
                      >复制</el-button
                    >
                  </div>
                </el-form-item>
              </el-form>
              <div class="config-form-btns">
                <el-button @click="stepBack">上一步</el-button>
                <el-button
                  type="primary"
                  @click="callbackVerify"
                  :loading="btnLoading"
                  >{{ btnLoading ? '检测中' : '下一步' }}</el-button
                >
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item name="form4">
            <div class="config-form">
              <div class="config-form-title">4.可信域名验证</div>
              <el-form :model="addForm4" size="medium">
                <el-form-item label="复制链接，填写至企微后台【可信域名】">
                  <div class="config-form-input">
                    <el-input
                      v-model="addForm4.domain"
                      placeholder="请在企微后台复制，并在此粘贴客户联系secret"
                      :readonly="true"
                      ref="url"
                    ></el-input>
                    <el-button type="primary" plain @click="handleCopy('url')"
                      >复制</el-button
                    >
                  </div>
                </el-form-item>
                <el-form-item :error="fileError">
                  <el-upload
                    class="upload-demo"
                    accept=".txt"
                    action=""
                    :http-request="getFile"
                    :on-remove="removeFile"
                    :multiple="false"
                    :limit="1"
                    ref="uploadFile"
                    drag
                  >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      将文件拖到此处，或<em>点击选择</em>
                    </div>
                    <div class="el-upload__tip" slot="tip">
                      只能上传txt文件，且不超过10kb
                    </div>
                  </el-upload>
                  <el-button
                    type="primary"
                    size="mini"
                    @click="uploadFile"
                    :loading="btnLoading"
                    v-show="uploadState"
                    :disabled="uploadSuccess"
                    >{{ btnLoading ? '上传中' : '上传文件' }}</el-button
                  >
                </el-form-item>
                <el-form-item> </el-form-item>
              </el-form>
              <div class="config-form-btns">
                <el-button @click="stepBack">上一步</el-button>
                <el-button type="primary" @click="checkUpload"
                  >下一步</el-button
                >
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item name="form5">
            <div class="config-form">
              <div class="config-form-title">5. 配置聊天工具栏</div>
              <el-form :model="addForm5" size="medium">
                <el-form-item label="页面名称">
                  <div class="config-form-input">
                    <el-input
                      v-model="addForm5.name"
                      :readonly="true"
                      ref="sideName"
                    ></el-input>
                    <el-button
                      type="primary"
                      plain
                      @click="handleCopy('sideName')"
                      >复制</el-button
                    >
                  </div>
                </el-form-item>
                <el-form-item label="页面名称">
                  <div class="config-form-input">
                    <el-input
                      v-model="addForm5.group_url"
                      :readonly="true"
                      ref="groupUrl"
                    ></el-input>
                    <el-button
                      type="primary"
                      plain
                      @click="handleCopy('groupUrl')"
                      >复制</el-button
                    >
                  </div>
                </el-form-item>
              </el-form>
              <div class="config-form-btns">
                <el-button @click="stepBack">上一步</el-button>
                <el-button type="primary" @click="finish">下一步</el-button>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item name="form6">
            <div class="config-form login">
              <div class="flex">
                <img
                  class="login-img"
                  src="../../assets/images/ic_success.png"
                  alt=""
                />
                <span class="login-title">配置成功</span>
              </div>

              <div style="margin-top: 50px">
                <div class="login-avatar">
                  <img :src="userInfo.avatar_url" alt="" />
                </div>
                <div class="login-name">{{ userInfo.nickname }}</div>
              </div>

              <div class="flex" style="margin-top: 28px">
                <img
                  class="login-img login-icon-loading"
                  src="../../assets/images/loading.png"
                  alt=""
                />
                <span class="login-tips">即将进入企业…</span>
              </div>

              <div
                style="margin-top: 10px; color: rgba(0, 0, 0, 0.65)"
                v-if="company"
              >
                {{ company }}
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <canvas id="codeCanvas" width="180" height="180"></canvas>
  </div>
</template>

<script>
import api from '@/config'
export default {
  data () {
    return {
      userInfo: '',
      company: '',
      fileActionPath: '',
      activities: [{
        title: '自建应用',
        success: false
      }, {
        title: '客户权限',
        success: false
      }, {
        title: '应用对接',
        success: false
      }, {
        title: '域名验证',
        success: false
      }, {
        title: '聊天栏配置',
        success: false
      }, {
        title: '开始使用',
        success: false
      }],
      active: 1,
      addForm1: {
        agent_id: '',
        secret: ''
      },
      addForm2: {
        secret: ''
      },
      addForm3: {
        url: '',
        token: '',
        aes_key: ''
      },
      addForm4: {
        domain: ''
      },
      addForm5: {
        group_url: '',
        name: '客户画像'
      },
      fileObj: null,
      fileError: '',
      rules1: {
        agent_id: [
          { required: true, message: '请在企微后台复制，并在此粘贴agent_id', trigger: 'blur' }
        ],
        secret: [
          { required: true, message: '请在企微后台复制，并在此粘贴secret', trigger: 'blur' }
        ]
      },
      rules2: {
        secret: [
          { required: true, message: '请在企微后台复制，并在此粘贴secret', trigger: 'blur' }
        ]
      },
      btnLoading: false,
      uploadState: false,
      uploadSuccess: false,
      logoImg: null,
      copyMsg: null
    }
  },
  async created () {
    const type = localStorage.getItem('type')
    if (type === 'account') {
      await this.getToken()
    }
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.company = localStorage.getItem('companyName')
    this.fileActionPath = api.host + '/api/wecom/uploadVerifyFile'
    this.getConfigInfo()
    this.getMenuInfo()
    this.getCallbackUrl()
  },
  methods: {

    // 获取企业token
    async getToken () {
      const data = await this.axios.post('getToken')
      localStorage.setItem('token', data.token)
      localStorage.setItem('type', 'wecom')
    },

    // 获取上次配置信息
    async getConfigInfo () {
      const data = await this.axios.get('configInfo')
      if (data) {
        this.addForm1.agent_id = data.app_agent_id
        this.addForm1.secret = data.app_secret
        this.addForm2.secret = data.internal_secret
      }
    },

    // 获取回调url
    async getCallbackUrl () {
      const data = await this.axios.get('getCallbackUrl')
      this.addForm3.url = data.url.trim()
      this.addForm3.token = data.token.trim()
      this.addForm3.aes_key = data.aes_key.trim()
      this.addForm4.domain = data.domain.trim()
    },

    // 获取侧边栏配置
    async getMenuInfo () {
      const data = await this.axios.get('menuInfo')
      this.addForm5.group_url = data.group_url
    },

    submitForm (formName, form) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true
          if (form === 'f1') {
            this.appsecretCheck()
          }
          if (form === 'f2') {
            this.internalsecretCheck()
          }
        } else {
          return false
        }
      })
    },

    // 查询secret是否设置成功
    async appsecretCheck () {
      const data = await this.axios.get('appSecretCheck', {
        params: {
          ...this.addForm1
        }
      })

      if (data.success === 1) {
        this.configSuccess()
      } else {
        this.configError()
      }
    },

    // 查询内部secret是否设置成功
    async internalsecretCheck () {
      const data = await this.axios.get('internalSecretCheck', {
        params: {
          ...this.addForm2
        }
      })

      if (data.success === 1) {
        this.configSuccess()
      } else {
        this.configError()
      }
    },

    // 设置文件对象
    getFile (item) {
      const fileObj = item.file
      if (fileObj.size / 1024 > 10) {
        this.fileError = '文件不能超过10kb'
        this.$refs.uploadFile.clearFiles()
      } else {
        this.fileObj = null
        this.fileObj = new FormData()
        this.fileObj.append('file', fileObj)
        this.fileError = null
        this.uploadState = true
      }
    },

    // 删除文件
    removeFile (file, fileList) {
      this.fileObj = null
      this.uploadState = false
      this.uploadSuccess = false
    },

    // 上传文件, 最后一步
    async uploadFile () {
      if (!this.fileObj) {
        this.fileError = '请选择域名归属校验文件'
      } else {
        this.btnLoading = true
        const data = await this.axios.post('uploadVerifyFile', this.fileObj)
        if (data.success === 1) {
          this.uploadState = true
          this.uploadSuccess = true
          this.btnLoading = false
          this.$message.success('文件上传成功')
        } else {
          this.configError()
        }
      }
    },

    checkUpload () {
      if (!this.uploadState) {
        this.fileError = '请选择域名归属校验文件'
        return
      }
      if (this.uploadSuccess) {
        this.configSuccess()
      } else {
        this.fileError = '请点击上传归属校验文件'
      }
    },

    async callbackVerify () {
      this.axios.get('callbackVerify')
      this.configSuccess()
    },

    // 配置成功
    configSuccess (index) {
      if (this.successMsg) {
        this.successMsg.close()
      }
      this.successMsg = this.$message.success('配置成功')
      let _index = index || this.active
      if (_index > 4) {
        _index = 1
      }
      this.activities[_index - 1].success = true
      this.active += 1
      this.$refs.formCarousel.setActiveItem(`form${this.active}`)
      this.btnLoading = false
    },

    // 失败提示
    configError () {
      this.$message.warning('未配置成功')
      this.btnLoading = false
    },

    // 验证
    async finish () {
      await this.axios.post('finish')
      this.configSuccess()
      this.fn_jumpToHome()
    },

    // 获取路由,进入后台
    async fn_jumpToHome () {
      // 卡片信息
      const corp_id = localStorage.getItem('companyId')
      localStorage.removeItem('config')
      const accessRoutesPromise = await this.$store.dispatch('permission/GenerateRoutes', corp_id)
      this.$router.addRoutes(accessRoutesPromise)
      this.$router.push({
        path: accessRoutesPromise[0].path
      })
    },

    // 上一步
    stepBack () {
      this.active -= 1
      this.$refs.formCarousel.setActiveItem(`form${this.active}`)
    },

    // 复制
    handleCopy (name) {
      if (this.copyMsg) {
        this.copyMsg.close()
      }
      const input = document.createElement('input')
      input.value = this.$refs[name].value
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      this.copyMsg = this.$message.success('已复制')
    },

    // 下载logo
    downloadLogo () {
      const _img = new Image()
      let cvs, ctx
      _img.crossOrigin = 'anonymous'
      _img.src = 'https://image.01lb.com.cn/FtV-30x2z5K6ZlyQSiTZ6ZbiilcI'
      _img.onload = () => {
        cvs = document.getElementById('codeCanvas')
        ctx = cvs.getContext('2d')
        ctx.drawImage(_img, 0, 0, 180, 180)
        this.logoImg = cvs.toDataURL('image/png')
        const a = document.createElement('a')
        a.setAttribute('download', '01lb_app_logo')
        a.setAttribute('href', this.logoImg)
        a.click()
      }
    },

    // 监听下拉框
    handleHeaderCommand (command) {
      if (command === 'user') {
        this.$router.push('user')
      } else if (command === 'signOut') {
        this.signOut()
      }
    },

    // 切换企业
    async handleCorpCommand (command) {
      if (command === 'company') {
        this.$router.push('company')
      }
    },

    // 登出
    signOut () {
      localStorage.clear()
      this.$router.replace('login')
    },

    // 返回自动配置
    jumpToLogin () {
      this.$router.push({
        path: '/configapp'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/var.scss';
.config {
  height: 100%;
  width: 100%;

  &-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_user {
      display: flex;
      align-items: center;

      .avatar {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        border-radius: 50%;
      }
    }
  }

  header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    height: 64px;
    padding: 0 30px;
    z-index: 99;
  }

  &-main {
    background-color: #f2f3f5;
    height: 100%;
    padding-top: 64px;
    box-sizing: border-box;
    display: flex;

    &-left {
      width: 220px;
      padding: 32px 0;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
    }

    &-center {
      flex: 1;
      background-color: #fff;
      padding: 32px;
      box-sizing: border-box;

      ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
      }

      h1 {
        font-size: 24px;
        font-weight: 500;
        padding-bottom: 32px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      }

      p.title {
        font-size: 16px;
        font-weight: 500;
      }
    }

    &-right {
      width: 368px;
      height: calc(100% - 64px);
      padding: 32px;
      background-color: #fff;
      box-shadow: 0px 2px 12px 0px rgba(98, 107, 132, 0.2);

      ::v-deep .el-carousel,
      ::v-deep.el-carousel__container {
        height: 100%;
      }
    }
  }

  &-timeline {
    &-item {
      position: relative;
      display: flex;
      position: relative;
      align-items: center;

      &:before {
        position: absolute;
        content: '';
        width: 1px;
        height: 64px;
        top: 40px;
        left: 16px;
        background-color: rgba(0, 0, 0, 0.15);
      }

      &:not(:last-child) {
        margin-bottom: 80px;
      }

      &:last-child {
        &:before {
          display: none;
        }
      }

      .icon {
        width: 34px;
        height: 34px;
        overflow: hidden;
        margin-right: 12px;
        i {
          font-size: 34px;
          color: $--color-primary;
        }
      }

      .dot {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 12px;
        color: rgba(0, 0, 0, 0.15);
        border: 2px solid rgba(0, 0, 0, 0.15);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;

        &.success {
          background-color: transparent;
          border-color: $--color-primary;
          color: $--color-primary;

          i {
            font-weight: bold;
          }
        }

        &.active {
          background-color: $--color-primary;
          border-color: $--color-primary;
          color: #fff;
        }
      }

      .text {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.65);

        &.bold {
          color: #000;
          // font-weight: bold;
        }
      }
    }
  }

  &-form {
    .el-form {
      margin-top: 48px;
    }

    &-title {
      font-size: 16px;
    }

    &-logo {
      margin: 48px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: #f9f9f9;
        box-shadow: 0px 2px 12px 0px rgba(98, 107, 132, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
        border-radius: 50%;
      }

      .download {
        color: $--color-primary;
        line-height: 20px;
        cursor: pointer;
      }
    }

    &-input {
      display: flex;
      width: 100%;

      .el-button {
        margin-left: 10px;
      }
    }

    &-btns {
      display: flex;
      margin-top: 48px;

      .el-button {
        flex: 1;
      }
    }
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &-title {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }

  &-img {
    margin-right: 6px;
  }

  &-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 10px;
    border: 1px solid #eee;
    box-sizing: border-box;

    img {
      width: 100%;
      border-radius: 50%;
    }
  }

  &-name {
    margin-top: 10px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    text-align: center;
  }

  &-icon-loading {
    animation: t_rotate 1s linear infinite;
  }

  &-tips {
    color: rgba(0, 0, 0, 0.45);
  }
}

#codeCanvas {
  position: absolute;
  left: -999999px;
  top: -999999px;
}

@keyframes t_rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
