/*
 * @Author: zhanln
 * @Date: 2021-06-22 14:10:58
 * @LastEditTime: 2022-01-05 14:59:13
 * @LastEditors: zhanln
 * @Description: msgbox 唯一
 */

const lyMsg = {
  data () {
    return {
      lyMsg: null
    }
  },

  methods: {
    fn_lyMsg (type = 'success', text, duration = 3000) {
      if (this.lyMsg) {
        this.lyMsg.close()
      }

      this.lyMsg = this.$message[type]({
        message: text,
        duration
      })
    }
  }
}
export default lyMsg
