/*
 * @Author: zhanln
 * @Date: 2021-06-18 15:13:06
 * @LastEditTime: 2021-06-18 16:38:38
 * @LastEditors: zhanln
 * @Description: 员工
 */

const base = '/wecom/wecomUsers'

export default {
  // 员工列表
  empList: base,
  // 员工列表（不校验）
  empAll: base + '/all',
  // 员工详情
  empInfo: base + '/info',
  // 该员工客户列表
  empClients: base + '/clients',
  // 根据id获取员工数据
  empUsers: base + '/ids',
  // '员工数据同步
  empSync: base + '/sync',
  // 聊天分析
  chatAnalysis: base + '/chatAnalysis'
}
