/*
 * @Author: zhanln
 * @Date: 2021-06-24 10:39:36
 * @LastEditTime: 2021-08-03 14:21:19
 * @LastEditors: zhanln
 * @Description:
 */
export default {
  state: {
    ready: false,
    list: null,
    total: 0
  }
}
