/*
 * @Author: zhan
 * @Date: 2022-08-19 16:37:52
 * @LastEditTime: 2022-08-19 16:38:49
 * @LastEditors: zhan
 */
export default {
  namespaced: true,

  stata: {
    info: null
  }
}
