<template>
  <div class="user">
    <!-- header -->

    <login-header v-if="showHeader" showUserInfo></login-header>

    <!-- main -->
    <div class="user-main">
      <div class="user-container">
        <!-- toolBar -->
        <div class="ly-tool user-tool-bar">
          <div class="ly-tool__back" @click="goBack">
            <i class="iconfont icon-left1"></i>返回
          </div>
        </div>
        <!-- toolBar End -->

        <div class="user-layout">
          <div class="user-layout-left">
            <div class="user-layout-left_top user-card">
              <img
                :src="userInfo.avatar_url"
                alt=""
                class="avatar"
                v-if="userInfo.avatar_url || userInfo.avatar_url !== ''"
              />
              <img :src="defaultAvatar" alt="" class="avatar" v-else />
              <div class="info">
                <div class="name">{{ userInfo.nickname }}</div>
                <div class="num">{{ userInfo.mobile }}</div>
              </div>
            </div>
            <div class="user-layout-left_bottom user-card">
              <div class="user-tabs">
                <div class="user-tabs_item active">
                  <img src="@/assets/svg/sidebar/number_active.png" alt="">
                  个人信息
                </div>
                <!-- <div class="user-tabs_item" @click="handleTabs(2)">
                  积分管理
                </div> -->
              </div>
            </div>
          </div>
          <div class="user-layout-right user-card">
            <div class="user-layout-right_header">个人信息</div>
            <div class="user-layout-right_main">
              <div class="user-list">
                <div class="user-list-item">
                  <div class="user-list-label">头像</div>
                  <div class="user-list-center">
                    <img
                      :src="userInfo.avatar_url"
                      alt=""
                      class="avatar"
                      v-if="userInfo.avatar_url || userInfo.avatar_url !== ''"
                    />
                    <img :src="defaultAvatar" alt="" class="avatar" v-else />
                  </div>
                </div>
                <div class="user-list-item">
                  <div class="user-list-label">账户ID</div>
                  <div class="user-list-center">
                    {{ userInfo.id }}
                  </div>
                </div>
                <div class="user-list-item">
                  <div class="user-list-label">微信</div>
                  <div class="user-list-center">
                    <div class="flex">
                      <i class="iconfont icon-logo-wechat" style="color: #49c660; font-size: 20px; margin-right: 8px"></i>
                      {{ userInfo.nickname }}
                    </div>
                  </div>
                  <el-button type="text" @click="getUpdateWechatQrcode"
                    >去更换</el-button
                  >
                </div>
                <div class="user-list-item">
                  <div class="user-list-label" data-value="手机号">手机号</div>
                  <div class="user-list-center">
                    {{ userInfo.mobile }}
                  </div>
                  <el-button type="text" @click="updateMobile"
                    >去更换</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 身份验证 -->
    <el-dialog
      title="身份验证"
      :visible.sync="checkDialog"
      width="428px"
      top="20vh"
      lock-scroll
      :close-on-click-modal="false"
      custom-class="q-dialog"
    >
      <div style="margin-bottom: 20px">
        为了你的帐户安全，请验证身份。验证成功后进行下一步操作。
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        size="medium"
      >
        <el-form-item label="">
          <el-input
            v-model="ruleForm.mobile"
            placeholder=""
            type="tel"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="code" style="margin-bottom: 0">
          <el-row :gutter="20" type="flex" justify="space-between">
            <el-col :span="17">
              <el-input
                v-model="ruleForm.code"
                placeholder="请输入验证码"
                maxlength="6"
                minlength="6"
                clearable
              ></el-input>
            </el-col>
            <el-col :span="7">
              <el-button
                :type="sendCheckCode ? '' : ''"
                :class="['user-btn-check', sendCheckCode ? '' : 'info']"
                @click="getCheckCode"
                style="width: 100%"
                :disabled="!sendCheckCode"
              >
                <template v-if="sendCheckCode"> 发送验证码 </template>
                <template v-else> {{ checkCodeTimeout / 1000 }}s </template>
              </el-button>
            </el-col>
          </el-row>
          <template v-slot:error="{ error }">
            <div class="el-form-item__error">
              <i class="el-icon-error" style="margin-right: 3px"></i>{{ error }}
            </div>
          </template>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <div></div>
        <div>
          <el-button round @click="resetCheckForm('ruleForm')"
            >取消</el-button
          >
          <el-button
            round
            type="primary"
            @click="checkConfirm('ruleForm')"
            >验证</el-button
          >
        </div>
      </div>
    </el-dialog>

    <!-- 绑定微信 -->
    <el-dialog
      title="绑定微信"
      :visible.sync="bindWechatDialog"
      width="428px"
      top="20vh"
      lock-scroll
      :close-on-click-modal="false"
      custom-class="q-dialog"
      @close="closeUpdataWechat"
    >
      <div class="flex flex-center flex-column">
        <div style="margin-bottom: 20px">请使用微信扫码，绑定该账号</div>
        <div class="user-qrcode" style="margin-bottom: 78px">
          <img :src="updateWechatQrcode" alt="" />
        </div>
      </div>
    </el-dialog>

    <!-- 更换手机号码 -->
    <el-dialog
      title="更换手机号码"
      :visible.sync="bindmobileDialog"
      width="428px"
      top="20vh"
      lock-scroll
      :close-on-click-modal="false"
      custom-class="q-dialog"
    >
      <el-form
        :model="ruleForm2"
        :rules="rules2"
        ref="ruleForm2"
        size="medium"
      >
        <el-form-item
          label=""
          :class="customError ? 'login-error' : ''"
          prop="mobile"
          ref="formmobile"
          :error="mobileTips"
        >
          <el-input
            v-model="ruleForm2.mobile"
            placeholder="请输入新手机号码"
            type="tel"
            clearable
          ></el-input>
          <template v-slot:error="{ error }">
            <div :class="['el-form-item__error', customErrorClass]">
              <i :class="customErrorIcon" style="margin-right: 3px"></i
              >{{ error }}
            </div>
          </template>
        </el-form-item>
        <el-form-item label="" prop="code" style="margin-bottom: 0">
          <el-row :gutter="20" type="flex" justify="space-between">
            <el-col :span="17">
              <el-input
                v-model="ruleForm2.code"
                placeholder="请输入验证码"
                maxlength="6"
                minlength="6"
                clearable
              ></el-input>
            </el-col>
            <el-col :span="7">
              <el-button
                :type="sendCheckCode ? '' : ''"
                :class="['user-btn-check', sendCheckCode ? '' : 'info']"
                @click="getCheckCodeForMobile"
                style="width: 100%"
                :disabled="!sendCheckCode"
              >
                <template v-if="sendCheckCode"> 发送验证码 </template>
                <template v-else> {{ checkCodeTimeout / 1000 }}s </template>
              </el-button>
            </el-col>
          </el-row>
          <template v-slot:error="{ error }">
            <div class="el-form-item__error">
              <i class="el-icon-error" style="margin-right: 3px"></i>{{ error }}
            </div>
          </template>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <div></div>
        <div>
          <el-button round @click="bindmobileDialog = false"
            >取消</el-button
          >
          <el-button
            round
            type="primary"
            @click="editRoleConfirm('ruleForm2')"
            >确认</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import loginHeader from '../loginNew/components/LoginHeader'
export default {
  data () {
    return {
      defaultAvatar: require('@/assets/images/default_avatar.png'),
      headerUserInfo: {},
      userInfo: {},
      changeInfo: '',
      checkDialog: false,
      ruleForm: {
        mobile: '',
        code: ''
      },
      rules: {
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      customError: false,
      customErrorIcon: 'el-icon-error',
      customErrorClass: '',
      checkCodeTimeout: 60000,
      sendCheckCode: true,
      bindWechatDialog: false,
      bindmobileDialog: false,
      ruleForm2: {
        mobile: '',
        code: ''
      },
      rules2: {
        mobile: [
          { required: true, message: '请输入新手机号码', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 6, max: 6, message: '验证码错误', trigger: 'blur' }
        ]
      },
      mobileTips: '',
      customError2: false,
      customErrorIcon2: 'el-icon-error',
      customErrorClass2: 'default',
      checkCodeTimeout2: 60000,
      sendCheckCode2: true,
      bindingDialog2: true,
      loginOut: false,
      updateWechatQrcode: '',
      scene: '',
      loginSecond: 2000,
      loginInterval: null,
      checkInterval: null,
      pathInterval: null,
      showHeader: false
    }
  },
  async created () {
    await this.$store.dispatch('permission/GenerateAccountToken')
    this.showHeader = true

    this.pathInterval = setInterval(() => {
      const _path = localStorage.getItem('path')
      if (_path && _path.indexOf('login') <= -1) {
        this.$router.push(_path)
        this.fn_removePath()
      }
    }, 200)

    this.userInfo = await this.$store.dispatch('permission/GenerateUserInfo')
    this.ruleForm.mobile = this.userInfo.mobile
  },

  async beforeRouteLeave (to, from, next) {
    // 如果返回企业管理
    const id = localStorage.getItem('backCompanyId')
    if (id && to.path !== '/login') {
      localStorage.removeItem('backCompanyId')
      await this.getToken()
    }
    next()
  },

  destroyed () {
    if (this.pathInterval) {
      clearInterval(this.pathInterval)
    }
  },

  methods: {

    // 获取企业后台token
    async getToken () {
      const data = await this.axios.post('getToken')
      localStorage.setItem('type', 'wecom')
      localStorage.setItem('token', data.token)
    },

    // 账号token
    async getAccountToken () {
      const data = await this.axios.post('getAccountToken')
      localStorage.setItem('token', data.token)
      localStorage.setItem('type', 'account')
    },

    // 账号信息
    async getInfo () {
      const data = await this.axios.get('getInfo')
      this.userInfo = data
      this.ruleForm.mobile = this.userInfo.mobile
    },

    // 更换二维码后登录
    async updateWechatResLogin () {
      const data = await this.axios.get('updateWechatRes', {
        params: {
          scene: this.scene
        }
      })
      if (data.status === '1') {
        this.$lyConfirm({
          type: 'success',
          title: '微信更换成功！',
          text: '',
          showCancelButton: false,
          confirmButtonText: '重新登录'
        }).then(() => {
          this.$store.dispatch('permission/Reset')
          localStorage.removeItem('token')
          this.$router.push('/login')
        }).catch(() => { })
        this.bindWechatDialog = false
      } else if (data.status === '2') {
        this.$message.warning('更换微信失败，请勿更换同一微信或已绑定过的微信')
        this.bindWechatDialog = false
        this.closeUpdataWechat()
      }
    },

    // 清除路径信息
    fn_removePath () {
      setTimeout(() => {
        localStorage.removeItem('path')
      }, 100)
    },

    // 是否需要验证
    async isVerify () {
      const data = await this.axios.get('isVerify')
      return data.is_verify
    },

    // 更换二维码
    async getUpdateWechatQrcode () {
      const isVerify = await this.isVerify()
      if (isVerify) {
        this.changeInfo = 'wechat'
        this.checkDialog = true
      } else {
        const data = await this.axios.get('updateWechatQrcode')
        this.bindWechatDialog = true
        this.updateWechatQrcode = data.url
        this.scene = data.scene
        // 监听扫码
        this.loginInterval = setInterval(() => {
          this.updateWechatResLogin()
        }, this.loginSecond)
      }
    },

    // 关闭更换二维码
    closeUpdataWechat () {
      clearInterval(this.loginInterval)
    },

    // 更换手机号
    async updateMobile () {
      const isVerify = await this.isVerify()
      if (isVerify) {
        this.changeInfo = 'mobile'
        this.checkDialog = true
      } else {
        this.bindmobileDialog = true
      }
    },

    // 调起身份验证
    checkUser (type) {
      this.changeInfo = type
      this.checkDialog = true
    },

    // 身份验证
    checkConfirm (formName) {
      this.$refs[formName].validate(async (valid) => { // 验证表单
        if (valid) {
          // 后台验证
          const data = await this.axios.post('verifyCaptcha', this.ruleForm)
          if (data.success === 1) {
            clearInterval(this.checkInterval)
            this.sendCheckCode = true
            this.checkCodeTimeout = 60000
            this.checkDialog = false
            // 验证通过，判断需要更换的是哪个信息
            if (this.changeInfo === 'wechat') {
              const data = await this.axios.get('updateWechatQrcode')
              this.bindWechatDialog = true
              this.updateWechatQrcode = data.url
              this.scene = data.scene
              // 监听扫码
              this.loginInterval = setInterval(() => {
                this.updateWechatResLogin()
              }, this.loginSecond)
            } else {
              this.bindmobileDialog = true
            }
          } else {
            this.$message.error('验证码有误，请重新输入')
          }
        } else {
          return false
        }
      })
    },

    // 更换手机号确定按钮
    editRoleConfirm (formName) {
      this.$refs[formName].validate(async (valid) => { // 验证表单
        if (valid) {
          const data = await this.axios.put('updateMobile', this.ruleForm2)
          if (data.code === 0) {
            this.$message.error(data.msg)
          } else {
            this.$message.success('更换手机号成功')
            this.bindmobileDialog = false
            this.getInfo()
            this.ruleForm2 = {
              mobile: '',
              code: ''
            }
          }
        } else {
          return false
        }
      })
    },

    // 关闭手机验证
    resetCheckForm (formName) {
      this.checkDialog = false
      this.$refs[formName].resetFields()
    },

    // 获取手机验证码
    async getCheckCode () {
      // 间隔 1 分钟
      if (!this.sendCheckCode) return
      // 验证手机号
      const data = await this.axios.post('checkCode', {
        mobile: this.ruleForm.mobile
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      this.$message.success('验证码已发送')

      this.sendCheckCode = false
      this.checkInterval = setInterval(() => {
        this.checkCodeTimeout -= 1000
        if (this.checkCodeTimeout === 0) {
          clearInterval(this.checkInterval)
          this.sendCheckCode = true
          this.checkCodeTimeout = 60000
        }
      }, 1000)
    },

    // 获取更换手机号验证码
    getCheckCodeForMobile () {
      if (!this.sendCheckCode) return
      // 验证手机号
      this.$refs.ruleForm2.validateField('mobile', async error => {
        if (!error) {
          this.customErrorClass = 'default'
          this.customError = true
          this.customErrorIcon = 'el-icon-loading'
          this.mobileTips = '检测中'

          const data = await this.axios.post('checkCode', {
            mobile: this.ruleForm2.mobile
          })

          if (data.code === 0) { // 后台验证，手机号是否存在，验证码是否正确
            this.$nextTick(() => {
              this.customError = true
              this.customErrorClass = 'warning'
              this.customErrorIcon = 'el-icon-warning'
              this.mobileTips = data.message
            })
          } else {
            this.customErrorClass = 'success'
            this.customErrorIcon = 'el-icon-success'
            this.mobileTips = '验证码已发送'
            this.sendCheckCode = false
            const _timeout = setInterval(() => {
              this.checkCodeTimeout -= 1000
              if (this.checkCodeTimeout === 0) {
                clearInterval(_timeout)
                this.sendCheckCode = true
                this.checkCodeTimeout = 60000
              }
            }, 1000)
          }
        }
      })
    },

    // 登出
    handleHeaderCommand (command) {
      if (command === 'signOut') {
        this.loginOut = true
        this.$router.replace('login')
        localStorage.clear()
      }
    },

    // 返回企业后台
    goBack () {
      this.$router.go(-1)
    }
  },

  components: {
    loginHeader
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";
@import "@/assets/scss/layout/table.scss";
.user {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 64px;
  .ly-tool__back{
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 16px;
  }
  &-container {
    width: 1200px;
  }

  &-card {
    border-radius: 16px;
    background: #ffffff;
    overflow: hidden;
    box-shadow: 0px 16px 32px -4px rgba(145, 158, 171, 0.24), 0px 0px 2px 0px rgba(145, 158, 171, 0.24);;
  }

  &-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    height: 64px;
    z-index: 1;

    &-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-dropdown {
      display: flex;
      align-items: center;
    }

    &-avatar {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }

  &-btn {
    &-check {
      width: 100%;
      border-radius: 8px;
      padding: 10px 0;
      &.info {
        cursor: not-allowed;
      }
    }

    &-block {
      width: 100%;
    }
  }

  &-qrcode {
    img {
      width: 180px;
      height: 180px;
    }
  }

  ::v-deep .el-form-item {
    margin-bottom: 24px;
  }

  // ::v-deep .el-form-item__error {
  //   position: relative;
  //   text-align: left;
  //   padding-top: 10px;
  // }

  ::v-deep .el-form-item__error {
    // position: relative;
    // text-align: left;
    &.default {
      color: rgba(0, 0, 0, 0.45);
    }
    &.success {
      color: #00A86E;
    }
    &.warning {
      color: #faad14;
    }
  }

  &-main {
    margin: 20px 0;
  }

  &-layout {
    display: flex;

    &-left {
      width: 226px;
      margin-right: 20px;

      &_top {
        height: 96px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        padding: 0 24px;

        .avatar {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          margin-right: 16px;
        }

        .num {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.45);
          margin-top: 4px;
        }
      }

      &_bottom {
        height: calc(100% - 116px);
      }
    }

    &-right {
      flex: 1;

      &_header {
        height: 58px;
        align-items: center;
        font-size: 16px;
        display: flex;
        padding: 0 30px;
        font-weight: 500;
      }

      &_main {
        padding: 52px 48px;
      }
    }
  }

  &-list {
    &-item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #EAEBF2;
      padding: 20px 0;
    }

    &-label {
      width: 130px;
    }

    &-center {
      flex: 1;

      .avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
    }
  }

  &-tabs {
    &_item {
      padding: 14px 24px;
      border-bottom: 1px solid #DFE1E8;
      border-left: 4px solid transparent;
      cursor: pointer;
      display: flex;
      align-items: center;
      img{
        width: 24px;
        margin-right: 5px;
      }
      &.active {
        color: #0064FF;
        background: #F6FAFF;
      }
    }
  }

  .el-form-item.login-error {
    ::v-deep .el-input__inner {
      &,
      &:focus {
        border-color: #dcdfe6;
      }
    }
    ::v-deep .el-form-item__error {
      &.default {
        color: rgba(0, 0, 0, 0.45);
      }
      &.success {
        color: #00A86E;
      }
      &.warning {
        color: #faad14;
      }
    }
  }
}
</style>
