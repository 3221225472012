<!--
 * @Author: zhan
 * @Date: 2022-07-28 12:00:50
 * @LastEditTime: 2022-08-29 11:10:32
 * @LastEditors: zhan
-->
<template>
  <el-form :model="form" :rules="rules" v-bind="formObj.setting" size="medium" :label-width="formObj.labelWidth || ''"
    ref="formRef">

    <template v-for="(item, key) of formObj.item">

      <!-- 显示条件判断 -->
      <template v-if="item.show ? item.show() : true">
        <slot v-if="item.slot" :name="item.slot"></slot>

        <el-form-item :key="key" :label="item.label + '：'" :prop="item.prop" v-else>

          <template slot="label" v-if="item.labelTip">{{ item.label }}
            <el-tooltip class="item" effect="dark" placement="top" popper-class="q-tooltip">
              <div slot="content">
                {{ item.labelTip }}
              </div>
              <i class="iconfont icon-info-fill tool-info"></i>
            </el-tooltip>：
          </template>

          <slot :name="item.itemSlot" v-if="item.itemSlot"></slot>

          <template v-else>
            <slot :name="item.headSlot" v-if="item.headSlot"></slot>

            <!-- 文本框 -->
            <template v-if="item.type === 'text'">
              <el-input v-model.trim="form[item.prop]" v-bind="item.attr"
                :placeholder="(item.attr && item.attr.placeholder) || `请输入${item.label}`"></el-input>
            </template>

            <!-- 计数器 -->
            <template v-if="item.type === 'inputNumber'">
              <el-input-number v-model="form[item.prop]" v-bind="item.attr"
                @change="fn_changeNumber($event, item.prop, item.attr)"></el-input-number>
            </template>

            <!-- 时间日期选择器 -->
            <template v-if="item.type === 'date'">
              <el-date-picker :type="item.dateType" v-model.trim="form[item.prop]" v-bind="item.attr"
                :picker-options="item.pickerOptions"></el-date-picker>
            </template>

            <!-- 文件上传 -->
            <template v-if="item.type === 'upload'">
              <ly-upload :img="form[item.prop]" @getImg="fn_setData($event, item.prop)" :tipText="item.label"
                :accept="item.accept || '.png,.jpg,.jpeg'" v-bind="item.attr">
              </ly-upload>
            </template>

            <!-- 员工选择器 -->
            <template v-if="item.type === 'emp'">
              <ly-emp-btn btnText="添加涨粉账号" :max="100" :isReal="true" :datafilter="true" :checkList="form[item.prop]"
                v-bind="item.attr" @getEmpId="fn_setData($event, item.prop)"></ly-emp-btn>
            </template>

            <!-- 文本编辑器 -->
            <template v-if="item.type === 'textEditor'">
              <ly-editor :id="item.prop" :title="item.label" :content="form[item.prop]" v-bind="item.attr"
                @getContent="fn_setData($event, item.prop)" @focus="fn_setFocus(true, item)"
                @blur="fn_setFocus(false, item)">
              </ly-editor>
            </template>

            <!-- switch 开关 -->
            <template v-if="item.type === 'switch'">
              <div class="flex" style="height: 36px">
                <el-switch v-model="form[item.prop]" :inactive-value="item.key ? item.key[0] : 0"
                  :active-value="item.key ? item.key[1] : 1" v-bind="item.attr">
                </el-switch>
                <slot :name="item.switchTipSlot" v-if="item.switchTipSlot"></slot>
                <span class="q-info" style="line-height: 20px; margin-left: 10px" v-else>{{ item.switchTip }}</span>
              </div>
            </template>

            <!-- 单选框组 -->
            <template v-if="item.type === 'radio'">
              <el-radio-group v-model="form[item.prop]" v-bind="item.attr">
                <el-radio :label="item.value" v-for="(item, index) of item.options" :key="index"
                  :disabled="item.disabled">{{
                      item.name
                  }}
                </el-radio>
              </el-radio-group>
            </template>

            <slot :name="item.bottomSlot" v-if="item.bottomSlot"></slot>
          </template>
        </el-form-item>
      </template>

    </template>
  </el-form>
</template>

<script>
export default {
  name: 'ly-form',

  props: {
    formObj: {
      type: Object,
      default: () => {
        return {}
      },
      require: true
    },
    form: {
      type: Object,
      require: true
    },
    rules: {
      type: Object,
      require: true
    }
  },

  methods: {

    // 计数器清空后设置默认
    fn_changeNumber (val, name, attr) {
      if (!val) {
        const min = (attr && attr.min) || 0
        this.fn_setData(min, name)
      }
    },

    // 内容回调
    fn_setData (val, name) {
      this.$nextTick(() => {
        this.$emit('setData', val, name)
      })
    },

    clearValidate (name) {
      this.$refs.formRef.clearValidate(name)
    },

    // 文本框聚焦
    fn_setFocus (e, item) {
      if (item.focus) {
        item.focus(e)
      }
    },

    // 返回单个校验
    verifyItem (type) {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validateField(type, (err) => {
          resolve(!err)
        })
      })
    },

    // 表单校验
    async verify (type) {
      let formValid = false
      if (type) {
        formValid = await this.verifyItem(type)
      } else {
        formValid = await this.$refs.formRef.validate().catch(err => err)
      }

      return formValid
    }
  }
}
</script>

<style lang="scss" scoped>
.tool-info {
  color: #b4bbcc;
  position: relative;
  top: 1px;
  line-height: 1
}
</style>
