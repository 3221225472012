var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible"
        }
      ],
      staticClass: "emp-search"
    },
    [
      _c("div", { staticClass: "emp-result__total" }, [
        _c("div", { staticClass: "left" }, [
          _vm._v(" 搜索结果（"),
          _c("span", { staticClass: "q-primary" }, [
            _vm._v(_vm._s(_vm.resultList.length))
          ]),
          _vm._v(" / " + _vm._s(_vm.total) + "） ")
        ]),
        this.resultList.length > 0
          ? _c(
              "div",
              { staticClass: "right", on: { click: _vm.fn_checkAll } },
              [
                _c("label", { staticClass: "label" }, [_vm._v("全选")]),
                _c("el-checkbox", {
                  attrs: { indeterminate: _vm.indeterminate },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                    }
                  },
                  model: {
                    value: _vm.allCheck,
                    callback: function($$v) {
                      _vm.allCheck = $$v
                    },
                    expression: "allCheck"
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "el-scrollbar",
        { staticClass: "emp-search__scroll" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "emp-default"
            },
            [
              _c("div", { staticClass: "load-lottie" }),
              _c("div", { staticClass: "text" }, [_vm._v("成员搜索中...")])
            ]
          ),
          !_vm.loading
            ? [
                _vm.resultList.length === 0
                  ? _c("div", { staticClass: "emp-default" }, [
                      _c("img", {
                        attrs: {
                          src: require("@assets/svg/default/no_res.svg"),
                          alt: ""
                        }
                      }),
                      _vm._v(" 无搜索结果 ")
                    ])
                  : [
                      _vm._l(_vm.resultList, function(data, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "emp-result__item",
                            on: {
                              click: function($event) {
                                return _vm.fn_nodeClick(data)
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "emp-result__avatar" }, [
                              _c("img", {
                                attrs: {
                                  src: data.avatar_url || _vm.defaultAvatar,
                                  alt: ""
                                }
                              })
                            ]),
                            _c("div", { staticClass: "emp-result__info" }, [
                              _c("span", { staticClass: "name" }, [
                                _vm._v(" " + _vm._s(data.name) + " ")
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass: "role",
                                  class: [
                                    data.role_id === 4 || data.role_id === 1
                                      ? "super"
                                      : "normal"
                                  ]
                                },
                                [_vm._v(" " + _vm._s(data.role_name) + " ")]
                              ),
                              data.wecom_departments
                                ? _c("span", { staticClass: "dep" }, [
                                    _vm._v(
                                      " 部门：" +
                                        _vm._s(
                                          data.wecom_departments
                                            ? data.wecom_departments.join("，")
                                            : "-"
                                        ) +
                                        " "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _c(
                              "div",
                              { staticClass: "emp-result__check" },
                              [
                                _c("el-checkbox", {
                                  attrs: { disabled: data.isDisable },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.preventDefault()
                                    }
                                  },
                                  model: {
                                    value: data.checked,
                                    callback: function($$v) {
                                      _vm.$set(data, "checked", $$v)
                                    },
                                    expression: "data.checked"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      }),
                      _vm.total > _vm.resultList.length
                        ? _c(
                            "div",
                            {
                              staticClass: "emp-result__more",
                              class: { active: _vm.loadMore },
                              on: { click: _vm.fn_loadMore }
                            },
                            [
                              _c("i", { staticClass: "el-icon-refresh-right" }),
                              _vm._v("加载更多 ")
                            ]
                          )
                        : _vm._e()
                    ]
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }