var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c(
        "div",
        { staticClass: "global-tips" },
        [
          _c("i", { staticClass: "iconfont icon-warning-fill" }),
          _vm._v(_vm._s(_vm.tips) + " "),
          _vm.btnText
            ? _c(
                "el-button",
                {
                  staticClass: "global-tips_btn",
                  attrs: { type: "text" },
                  on: { click: _vm.fn_config }
                },
                [
                  _vm._v(_vm._s(_vm.btnText)),
                  _c("i", { staticClass: "iconfont icon-right1" })
                ]
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }