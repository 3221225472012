<!--
 * @Author: zhanln
 * @Date: 2021-06-21 19:31:04
 * @LastEditTime: 2022-02-09 16:45:28
 * @LastEditors: zhanln
 * @Description: empty Card
-->

<template>
  <div class="empty">
    <div :class="['empty-card', hasClose ? 'un-shadow' : '']">
      <div
        class="empty-card_close"
        v-if="hasClose"
        @click="$emit('handleClose')"
      >
        <i class="el-icon-close"></i>
      </div>
      <div class="empty-card_header">
        <div class="item">
          <img src="~@assets/svg/lylb.svg" class="wechat" />
        </div>
        <i class="el-icon-close gap"></i>
        <div class="item">
          <img src="~@assets/svg/wc_work_ic.svg" alt="" class="wework" />
          <span class="text">企业微信</span>
        </div>
      </div>

      <div class="empty-card_body">
        <div class="block">
          <div class="item">
            <div class="title">企业已入驻平台</div>
            <el-button type="primary" plain class="btn" @click="fn_jumgToLogin"
              >员工扫码加入企业</el-button
            >
            <div class="tips">企业已经入驻平台，员工直接扫码加入即可</div>
          </div>
          <div class="item">
            <div class="title">企业未入驻平台</div>
            <el-button type="primary" class="btn" @click="fn_jumpToBindWecom"
              >超管扫码入驻平台</el-button
            >
            <div class="tips">
              请使用<b>企业微信超级管理员</b>的身份扫码授权
            </div>
          </div>
        </div>
      </div>
      <div class="empty-card_contact">
        <el-tooltip effect="light" placement="right">
          <div slot="content" class="empty-card_contact--block">
            <div class="title">添加专属客服</div>
            <div class="text">一对一为您答疑解惑</div>
            <div class="code">
              <img src="~@assets/images/contact_me_qr.png" alt="" />
            </div>
          </div>
          <span
            >如有疑问，联系客服处理<i
              class="iconfont icon-a-customerservice"
              style="margin-left: 5px"
            ></i
          ></span>
        </el-tooltip>
      </div>
    </div>
    <div :class="['empty-card_footer', hasClose ? 'un-shadow' : '']">
      <i class="iconfont icon-info-fill"></i>还没有企业微信？
      <el-button type="text" @click="fn_registerWecom"
        >点击这里，官方注册 <i class="iconfont icon-right1"></i
      ></el-button>
    </div>
  </div>
</template>

<script>
import api from '@/config'
export default {
  name: 'emptyCard',

  props: {
    hasClose: {
      type: Boolean,
      default: false
    }
  },

  methods: {

    // 关联企业
    async fn_jumpToBindWecom () {
      this.$emit('handleBind')
      // const data = await this.axios.get('authUrl', {
      //   params: {
      //     redirect: api.frontHost + '/company?auth_type=bind'
      //   }
      // })
      // window.location.href = data.url
    },

    // 员工登录
    async fn_jumgToLogin () {
      const data = await this.axios.get('qrcodeUrl',
        {
          params: {
            redirect: api.frontHost + '/company?auth_type=login'
          }
        }
      )
      window.location.href = data.url
    },

    // 注册企业
    async fn_registerWecom () {
      window.open('https://work.weixin.qq.com/wework_admin/register_wx?from=myhome_openApi', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/var.scss";

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.empty-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 10px 0px #eeeff2;
  width: 800px;
  margin: 166px auto 0 auto;
  padding-top: 52px;
  box-sizing: border-box;

  &_header {
    display: flex;
    align-items: center;
    justify-content: center;

    .item {
      display: flex;
      align-items: center;
    }

    .wechat,
    .wework {
      height: 30px;
    }

    .gap {
      margin: 0 15px;
    }

    .text {
      font-size: 24px;
      font-weight: 500;
      color: #0082ef;
      margin-left: 8px;
    }
  }

  &_body {
    width: 800px;
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 70px;
    margin-bottom: 82px;

    .block {
      display: flex;
    }

    .item {
      flex: 1;
      display: flex;
      min-height: 137px;
      flex-direction: column;
      align-items: center;

      &:first-child {
        border-right: 1px solid #eeeff2;
      }
    }

    .title {
      font-size: 20px;
      font-weight: 500;
      color: #212b36;
    }

    .btn {
      width: 266px;
      height: 44px;
      border-radius: 44px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;
      margin: 70px 0 20px 0;
    }

    .tips {
      color: #6e788a;
    }
  }

  &_footer {
    background: #ffffff;
    border-radius: 48px;
    box-shadow: 0px 2px 10px 0px #eeeff2;
    margin-top: 38px;
    width: 400px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #373434;

    .el-icon-warning {
      font-size: 18px;
      margin-right: 8px;
    }
    .icon-info-fill {
      color: #2b7cff;
      line-height: 1;
      margin-right: 8px;
    }
    .el-button {
      margin-left: 12px;
    }
  }

  &_contact {
    text-align: center;
    color: #6e788a;
    margin-bottom: 40px;

    &--block {
      text-align: center;
      .title {
        color: $--color-text-primary;
      }

      .text {
        font-size: 12px;
        margin: 6px 0 10px 0;
      }

      .code {
        img {
          height: 100px;
        }
      }
    }

    i {
      margin-right: 6px;
    }
  }

  &_close {
    position: absolute;
    right: 24px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    top: 16px;
    font-size: 16px;
    color: #454d5b;
    cursor: pointer;
    border-radius: 2px;

    i {
      font-weight: bold;
    }

    &:hover {
      color: $--color-primary;
    }
  }
}

.un-shadow {
  box-shadow: none;
}
</style>
