<!--
 * @Author: zhanln
 * @Date: 2021-12-27 16:33:55
 * @LastEditTime: 2021-12-27 16:37:44
 * @LastEditors: zhanln
 * @Description:
-->

<template>
  <el-dialog class="q-dialog real-dialog" width="424px" :visible.sync="visible">
    <div class="real-dialog__title">
      <i class="real-dialog__icon ri-information-fill q-warning"></i
      >{{ validTitle }}
    </div>
    <div class="real-dialog__content">
      <p>
        {{ validContent }}
      </p>
      <p>
        <a :href="validUrl" target="_blank" class="ly-link">{{
          validUrlText
        }}</a>
      </p>
    </div>
    <div style="text-align: right">
      <el-button type="primary" @click="visible = false" round>确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'empTip',
  data () {
    return {
      visible: false,
      validTitle: '未实名员工提醒',
      validContent: '当前所选择的员工中存在未实名的账号，对于未实名的员工账号，企业微信官方无法生成员工二维码，请联系对应的员工进行实名。',
      validUrl: 'https://shimo.im/docs/WrD9hxqGPx9grCdC/read',
      validUrlText: '查看未实名员工指引 >'
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.real-dialog {
  &__title {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    padding-top: 12px;
  }

  &__icon {
    font-size: 32px;
    margin-right: 16px;
  }

  &__content {
    padding-left: 48px;
  }
  .el-dialog__header {
    display: none;
  }
}
</style>
