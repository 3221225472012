/*
 * @Author: zhanln
 * @Date: 2021-06-18 15:12:19
 * @LastEditTime: 2021-06-18 16:59:22
 * @LastEditors: zhanln
 * @Description: 客户
 */

const base = '/wecom/wecomExternalUser'

export default {
  // 客户列表
  wecomExternalUsers: base + 's',
  // 客户详情（不校验）
  clientAll: base + 'sCnt',
  // 客户详情
  clientInfo: base + '/info',
  // 客户动态
  clientDynamic: base + '/elist',
  // 所属群列表
  clientGroups: base + '/groups',
  // 所属员工列表
  clientUsers: base + '/users',
  // 客户数据同步
  clientSync: base + '/sync'
}
