var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "企业快速入驻",
        visible: _vm.visible,
        width: "740px",
        "custom-class": "setup",
        "close-on-click-modal": false,
        "close-on-press-escape": false
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        closed: _vm.fn_close
      }
    },
    [
      _c(
        "div",
        { staticClass: "setup-steps" },
        _vm._l(_vm.steps, function(item, index) {
          return _c("div", { key: index, staticClass: "setup-steps__item" }, [
            _c(
              "div",
              {
                staticClass: "step",
                class: [
                  _vm.currentStep === index + 1 || item.success ? "active" : ""
                ]
              },
              [
                item.success
                  ? [_c("i", { staticClass: "iconfont icon-selected" })]
                  : [_vm._v(" " + _vm._s(index + 1) + " ")]
              ],
              2
            ),
            _c(
              "div",
              {
                staticClass: "title",
                class: [
                  _vm.currentStep === index + 1 || item.success ? "active" : ""
                ]
              },
              [_vm._v(" " + _vm._s(item.label) + " ")]
            )
          ])
        }),
        0
      ),
      _c(
        "div",
        {
          class: [
            "setup-container",
            _vm.secretShow || _vm.currentStep !== 1
              ? "setup-secret"
              : "setup-code",
            _vm.currentStep === 3 ? "setup-setting" : ""
          ]
        },
        [
          _c("div", { staticClass: "setup-code__title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.secretShow
                    ? _vm.steps[_vm.currentStep - 1].title1
                    : _vm.steps[_vm.currentStep - 1].title
                ) +
                " "
            )
          ]),
          _vm.currentStep === 1
            ? [
                _c(
                  "div",
                  { class: ["setup-code_img", !_vm.codeUrl ? "is-load" : ""] },
                  [
                    _vm.codeLoading
                      ? _c(
                          "div",
                          {
                            class: [
                              "setup-code_load",
                              _vm.stopHandleCode ? "q-primary is-point" : ""
                            ],
                            on: {
                              click: function($event) {
                                return _vm.fn_openCode(1)
                              }
                            }
                          },
                          [
                            _c("i", { class: _vm.codeLoadingIcon }),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.codeLoadingText)
                              }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm.codeUrl
                      ? _c("img", { attrs: { src: _vm.codeUrl, alt: "" } })
                      : _vm._e()
                  ]
                ),
                _c("div", { staticClass: "setup-code_tips flex flex-center" }, [
                  _vm._v(" 请使用"),
                  _c("img", {
                    staticClass: "setup-code_logo",
                    attrs: { src: _vm.wecomLogo, alt: "" }
                  }),
                  _c("span", { staticClass: "setup-code_text" }, [
                    _vm._v("企业微信")
                  ]),
                  _vm._v("管理员手机app扫码 ")
                ]),
                _c("div", { staticClass: "setup-code_tips" }, [
                  _vm._v("并授权完成添加")
                ])
              ]
            : _vm._e(),
          _vm.currentStep === 2
            ? [
                _c(
                  "div",
                  { staticClass: "text-small q-info mt-16" },
                  [
                    _vm._v(
                      " 配置过程大约需要5-15分钟，请耐心等候，如超时请点击 "
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: { effect: "light", placement: "right" }
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _c("div", { staticClass: "setup-contact" }, [
                              _c(
                                "div",
                                { staticClass: "setup-contact_title" },
                                [_vm._v("添加专属客服")]
                              ),
                              _c("div", { staticClass: "setup-contact_text" }, [
                                _vm._v("一对一为您答疑解惑")
                              ]),
                              _c("div", { staticClass: "setup-contact_code" }, [
                                _c("img", {
                                  attrs: { src: _vm.contactCode, alt: "" }
                                })
                              ])
                            ])
                          ]
                        ),
                        _c(
                          "span",
                          { staticClass: "setup-contact_link q-primary" },
                          [_vm._v("联系客服")]
                        )
                      ]
                    ),
                    _vm._v(" 完成快速配置 ")
                  ],
                  1
                ),
                _vm.showLottie
                  ? _c("div", [
                      _c("div", {
                        staticClass: "load-lottie",
                        staticStyle: {
                          width: "200px",
                          height: "160px",
                          margin: "0 auto"
                        }
                      })
                    ])
                  : _vm._e()
              ]
            : _vm._e(),
          _vm.currentStep === 3 && !_vm.secretShow
            ? [
                _c("div", { staticClass: "setup-code__title" }, [
                  _vm._v("配置代自建应用可见范围 "),
                  _c(
                    "span",
                    {
                      staticClass: "q-primary text-small ml-8",
                      staticStyle: { cursor: "help" },
                      on: { click: _vm.fn_jumpToGuide }
                    },
                    [_vm._v("如何配置可见范围？")]
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "mt-8 mb-12",
                    staticStyle: { color: "#454D5B" }
                  },
                  [
                    _vm._v(
                      "可见范围内的成员，能够显示在零一SCRM系统内，建议选择全部企业。"
                    )
                  ]
                ),
                _c("img", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    src:
                      "https://image.01lb.com.cn//uploads/wecom/22/0802/1659422583468MDCPx.png",
                    alt: ""
                  }
                }),
                _c(
                  "el-button",
                  {
                    staticClass: "setting-btn",
                    attrs: { round: "", type: "primary" },
                    on: { click: _vm.fn_customizedFinish }
                  },
                  [_vm._v("完成配置")]
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "setup-contact_block" },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: { effect: "light", placement: "right" }
            },
            [
              _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _c("div", { staticClass: "setup-contact" }, [
                  _c("div", { staticClass: "setup-contact_title" }, [
                    _vm._v("添加专属客服")
                  ]),
                  _c("div", { staticClass: "setup-contact_text" }, [
                    _vm._v("一对一为您答疑解惑")
                  ]),
                  _c("div", { staticClass: "setup-contact_code" }, [
                    _c("img", { attrs: { src: _vm.contactCode, alt: "" } })
                  ])
                ])
              ]),
              _c("span", { staticClass: "setup-contact_link" }, [
                _c("i", { staticClass: "iconfont icon-a-customerservice" }),
                _vm._v("联系客服处理")
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }