var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "q-dialog real-dialog",
      attrs: { width: "424px", visible: _vm.visible },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "real-dialog__title" }, [
        _c("i", {
          staticClass: "real-dialog__icon ri-information-fill q-warning"
        }),
        _vm._v(_vm._s(_vm.validTitle) + " ")
      ]),
      _c("div", { staticClass: "real-dialog__content" }, [
        _c("p", [_vm._v(" " + _vm._s(_vm.validContent) + " ")]),
        _c("p", [
          _c(
            "a",
            {
              staticClass: "ly-link",
              attrs: { href: _vm.validUrl, target: "_blank" }
            },
            [_vm._v(_vm._s(_vm.validUrlText))]
          )
        ])
      ]),
      _c(
        "div",
        { staticStyle: { "text-align": "right" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }