var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "选择云端账号",
        visible: _vm.visible,
        width: "705px",
        "close-on-click-modal": false,
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "robots"
        },
        [
          _c(
            "div",
            { staticClass: "robots-left" },
            [
              _c("div", { staticClass: "robots-title" }, [_vm._v("云端账号")]),
              _c(
                "div",
                { staticClass: "robots-search" },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入云端账号",
                      "prefix-icon": "el-icon-search",
                      clearable: ""
                    },
                    model: {
                      value: _vm.searchText,
                      callback: function($$v) {
                        _vm.searchText = $$v
                      },
                      expression: "searchText"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-scrollbar",
                { staticClass: "robots-list" },
                _vm._l(
                  _vm.robotList.filter(function(item) {
                    return item.show
                  }),
                  function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        class: [
                          "robots-item",
                          item.disable ? "is-disable" : ""
                        ],
                        on: {
                          click: function($event) {
                            return _vm.fn_checkItem(item)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "robots-info" }, [
                          _c(
                            "span",
                            { staticClass: "robots-info__avatar" },
                            [
                              _c("el-image", {
                                staticClass: "robots-info__avatar--img",
                                attrs: {
                                  src: item.avatar,
                                  fit: "cover",
                                  lazy: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c("span", { staticClass: "robots-info__name" }, [
                            _vm._v(" " + _vm._s(item.name) + " ")
                          ]),
                          _c("span", { staticClass: "robots-info__type" }, [
                            item.status === 2
                              ? _c("span", { staticClass: "q-success" }, [
                                  _vm._v("（在线）")
                                ])
                              : _c("span", { staticClass: "q-info" }, [
                                  _vm._v("（离线）")
                                ])
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "robots-check" },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "此账号已被其他客服账号接入",
                                  disabled: !item.disable,
                                  placement: "top-end"
                                }
                              },
                              [
                                _c("el-checkbox", {
                                  attrs: { disabled: item.disable },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    }
                                  },
                                  model: {
                                    value: item.selected,
                                    callback: function($$v) {
                                      _vm.$set(item, "selected", $$v)
                                    },
                                    expression: "item.selected"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  }
                ),
                0
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "robots-right" },
            [
              _c("div", { staticClass: "robots-title" }, [
                _vm._v(" 已选账号"),
                _c("span", { staticClass: "q-primary" }, [
                  _vm._v("（" + _vm._s(_vm.checkCount) + "）")
                ])
              ]),
              _c(
                "el-scrollbar",
                { staticClass: "robots-list right" },
                _vm._l(
                  _vm.robotList.filter(function(item) {
                    return item.selected
                  }),
                  function(item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "robots-item right" },
                      [
                        _c("div", { staticClass: "robots-info" }, [
                          _c(
                            "span",
                            { staticClass: "robots-info__avatar" },
                            [
                              _c("el-image", {
                                staticClass: "robots-info__avatar--img",
                                attrs: {
                                  src: item.avatar,
                                  fit: "cover",
                                  lazy: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "robots-info__name right" },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "robots-close",
                            on: {
                              click: function($event) {
                                return _vm.fn_checkItem(item, "remove")
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-close" })]
                        )
                      ]
                    )
                  }
                ),
                0
              )
            ],
            1
          )
        ]
      ),
      _c("div", { staticClass: "robots-footer" }, [
        _c(
          "div",
          { staticClass: "robots-footer__left" },
          [
            _c(
              "el-button",
              {
                attrs: { round: "", disabled: _vm.loading },
                on: { click: _vm.fn_clearSelect }
              },
              [_vm._v("清空所选")]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "robots-footer__right" },
          [
            _c(
              "el-button",
              {
                attrs: { round: "", disabled: _vm.loading },
                on: {
                  click: function($event) {
                    _vm.visible = false
                  }
                }
              },
              [_vm._v("取消")]
            ),
            _c(
              "el-button",
              {
                attrs: { round: "", type: "primary", disabled: _vm.loading },
                on: { click: _vm.fn_confirm }
              },
              [_vm._v("确定")]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }