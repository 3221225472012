var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "company" },
    [
      _vm.showHeader
        ? _c("login-header", {
            attrs: { showUserInfo: "", showUserCenter: "" }
          })
        : _vm._e(),
      _vm.companyLoading
        ? _c("div", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.companyLoading,
                expression: "companyLoading"
              }
            ],
            staticClass: "company-width company-loading",
            attrs: { "element-loading-text": "正在加载您的企业，请稍等" }
          })
        : _vm._e(),
      !_vm.companyLoading
        ? [
            _c(
              "div",
              { staticClass: "company-width" },
              [
                _vm.serviceList.length > 0
                  ? _c("div", { staticClass: "company-container" }, [
                      _c("div", { staticClass: "company-title" }, [
                        _vm._v("我的服务商")
                      ]),
                      _c(
                        "div",
                        { staticClass: "company-main" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 24 } },
                            _vm._l(_vm.serviceList, function(item, index) {
                              return _c(
                                "el-col",
                                { key: index, attrs: { span: 8 } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "company-card service",
                                      on: {
                                        click: function($event) {
                                          return _vm.fn_jumpToService(
                                            item.corp_id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "company-card_header" },
                                        [
                                          _c("div", { staticClass: "left" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.corp_name.substr(0, 1) ||
                                                    item.corp_full_name.substr(
                                                      0,
                                                      1
                                                    )
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("div", { staticClass: "right" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "company-card_title"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.corp_name ||
                                                        item.corp_full_name
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "company-card_user"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "avatar" },
                                                  [
                                                    item.avatar_url !== ""
                                                      ? _c("img", {
                                                          attrs: {
                                                            src:
                                                              item.avatar_url,
                                                            alt: ""
                                                          }
                                                        })
                                                      : _c("img", {
                                                          attrs: {
                                                            src:
                                                              _vm.avatarDefault,
                                                            alt: ""
                                                          }
                                                        })
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "name" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "text",
                                                        attrs: {
                                                          title: item.nickname
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.nickname
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c("div", {
                                                      staticClass: "gap"
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        class: [
                                                          "title",
                                                          item.role_type_color
                                                        ]
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.role_type
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm.companyTotal > 0
                  ? _c("div", { staticClass: "company-container enterprise" }, [
                      _c(
                        "div",
                        {
                          staticClass: "company-header",
                          staticStyle: { margin: "0" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "company-title" },
                            [
                              _vm._v(" 我的企业 "),
                              _vm.showReg
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "ml-12",
                                      attrs: {
                                        type: "primary",
                                        size: "small",
                                        round: "",
                                        icon: "iconfont icon-plus "
                                      },
                                      on: {
                                        click: _vm.fn_showBindCompanyDialog
                                      }
                                    },
                                    [_vm._v("关联企业")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.companyTotal > 0
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "16px" },
                                  attrs: { type: "text" },
                                  on: { click: _vm.fn_showDel }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.showDelIcon ? "取消" : "账号解绑"
                                    )
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "company-main" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 24 } },
                            _vm._l(_vm.companyList, function(item, index) {
                              return _c(
                                "el-col",
                                { key: index, attrs: { span: 8 } },
                                [
                                  _c(
                                    "div",
                                    {
                                      class: [
                                        "company-card",
                                        _vm.showDelIcon ? "silence" : ""
                                      ],
                                      on: {
                                        click: function($event) {
                                          return _vm.fn_companyClick(
                                            item,
                                            index
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm.showDelIcon
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "company-del",
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.fn_delCorp(item)
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-remove"
                                              })
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "company-card_header" },
                                        [
                                          _c("div", { staticClass: "left" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.corp_name.substr(0, 1) ||
                                                    item.corp_full_name.substr(
                                                      0,
                                                      1
                                                    )
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("div", { staticClass: "right" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "company-card_title",
                                                attrs: {
                                                  title:
                                                    item.corp_name ||
                                                    item.corp_full_name
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.corp_name ||
                                                        item.corp_full_name
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "company-card_user"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "avatar" },
                                                  [
                                                    item.avatar_url !== ""
                                                      ? _c("img", {
                                                          attrs: {
                                                            src:
                                                              item.avatar_url,
                                                            alt: ""
                                                          }
                                                        })
                                                      : _c("img", {
                                                          attrs: {
                                                            src:
                                                              _vm.avatarDefault,
                                                            alt: ""
                                                          }
                                                        })
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "name" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "text",
                                                        attrs: {
                                                          title: item.nickname
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.nickname
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c("div", {
                                                      staticClass: "gap"
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        class: [
                                                          "title",
                                                          item.role_type_color
                                                        ]
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.role_type
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "company-card_content" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "company-card_list"
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "item" },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "label" },
                                                    [_vm._v("企业状态：")]
                                                  ),
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "dark",
                                                        placement: "top",
                                                        "popper-class":
                                                          "q-tooltip",
                                                        disabled: !_vm
                                                          .corpTypeObj[
                                                          item.corp_status
                                                        ].showTool
                                                      }
                                                    },
                                                    [
                                                      _c("div", {
                                                        attrs: {
                                                          slot: "content"
                                                        },
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.corpTypeObj[
                                                              item.corp_status
                                                            ].tool
                                                          )
                                                        },
                                                        slot: "content"
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          class: [
                                                            "text",
                                                            !_vm.corpTypeObj[
                                                              item.corp_status
                                                            ].showTool
                                                              ? "q-success"
                                                              : "q-danger"
                                                          ]
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.corpTypeObj[
                                                                item.corp_status
                                                              ].type
                                                            ) + " "
                                                          ),
                                                          _vm.corpTypeObj[
                                                            item.corp_status
                                                          ].showTool
                                                            ? _c("i", {
                                                                staticClass:
                                                                  "iconfont icon-warning-fill"
                                                              })
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "item" },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "label" },
                                                    [_vm._v("员工状态：")]
                                                  ),
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "dark",
                                                        placement: "top",
                                                        "popper-class":
                                                          "q-tooltip",
                                                        disabled: !_vm
                                                          .empTypeObj[item.type]
                                                          .showTool
                                                      }
                                                    },
                                                    [
                                                      _c("div", {
                                                        attrs: {
                                                          slot: "content"
                                                        },
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.empTypeObj[
                                                              item.type
                                                            ].tool
                                                          )
                                                        },
                                                        slot: "content"
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          class: [
                                                            "text",
                                                            !_vm.empTypeObj[
                                                              item.type
                                                            ].showTool
                                                              ? "q-success"
                                                              : "q-danger"
                                                          ]
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.empTypeObj[
                                                                item.type
                                                              ].type
                                                            ) + " "
                                                          ),
                                                          _vm.empTypeObj[
                                                            item.type
                                                          ].showTool
                                                            ? _c("i", {
                                                                staticClass:
                                                                  "iconfont icon-warning-fill"
                                                              })
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "item" },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "label" },
                                                    [_vm._v("员工数量：")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "text" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.corp_count)
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _c("empty-card", { on: { handleBind: _vm.fn_handleBind } })
              ],
              1
            )
          ]
        : _vm._e(),
      _c("login-footer"),
      _c("bind-card", {
        ref: "bindCardRef",
        on: { handleBind: _vm.fn_handleBind }
      }),
      _c("instead-app", {
        ref: "insteadAppRef",
        on: { enter: _vm.fn_enter, updateWecoms: _vm.fn_getCompnayList }
      }),
      _c("enter-card", { ref: "enterCardRef" }),
      _c("error-card", {
        ref: "errorCardRef",
        attrs: { title: _vm.errorCardTit, content: _vm.errorCardContent },
        on: {
          handleBack: function($event) {
            return _vm.fn_initDate(true)
          }
        }
      }),
      _c("tip-card", { ref: "tipCardRef" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }