var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "emp" },
    [
      _vm.showSelect
        ? _vm._l(_vm.selected, function(item, index) {
            return _c("div", { key: index, staticClass: "emp-item" }, [
              _c("img", {
                staticClass: "emp-item__avatar",
                attrs: { src: item.avatar_url || _vm.defaultAvatar, alt: "" }
              }),
              _c("span", { staticClass: "emp-item__name" }, [
                _vm._v(
                  _vm._s(
                    item.name.length > 4
                      ? item.name.slice(0, 3) + "..."
                      : item.name
                  )
                )
              ]),
              _c(
                "span",
                {
                  staticClass: "emp-item__close",
                  on: {
                    click: function($event) {
                      return _vm.fn_removeItem(index)
                    }
                  }
                },
                [_c("i", { staticClass: "iconfont icon-close-fill" })]
              )
            ])
          })
        : _vm._e(),
      _c(
        "el-button",
        {
          staticClass: "emp-btn",
          attrs: {
            size: "small",
            plain: "",
            round: "",
            icon: "iconfont icon-plus",
            type: "primary"
          },
          on: { click: _vm.fn_showSelect }
        },
        [_vm._v(_vm._s(_vm.btnText))]
      ),
      _c("ly-emp", {
        ref: "lyEmpSelectRef",
        attrs: {
          title: _vm.title || _vm.btnText,
          checkListProp: _vm.selected,
          max: _vm.max,
          isReal: _vm.isReal,
          modal: _vm.modal,
          datafilter: _vm.datafilter,
          diabledList: _vm.diabledList
        },
        on: { getEmpId: _vm.fn_getEmpId }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }