<template>
  <div class="header-setting">
    <el-popover
      placement="bottom-end"
      width="224"
      trigger="hover"
      popper-class="header-setting-panel"
    >
      <div slot="reference">
        <el-avatar
          :size="32"
          :src="userInfo.avatar_url || defaultAvatar"
        ></el-avatar
        >{{ userInfo.nickname }}
      </div>
      <div class="panel">
        <div>
          <el-avatar
            :size="48"
            :src="userInfo.avatar_url || defaultAvatar"
          ></el-avatar>
          <div class="nickname">{{ userInfo.nickname }}</div>
          <div class="wecom" v-if="userInfo.wecom">
            {{ userInfo.wecom.corp_name }}
          </div>
        </div>
        <el-divider></el-divider>
        <div>
          <div
            class="item"
            @click="$router.push('/company')"
            v-if="showCompany"
          >
            <i class="iconfont icon-company"></i>切换企业
          </div>
          <div class="item" @click="$router.push('/user')" v-if="showUser">
            <i class="iconfont icon-user1"></i>个人中心
          </div>
          <div class="logout" @click="handleLogout">退出登录</div>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  props: {
    userInfo: {
      type: Object,
      default () { }
    }
  },
  data () {
    return {
      defaultAvatar: require('@/assets/images/default_avatar.png')
    }
  },
  computed: {
    showCompany () {
      return this.$route.name !== 'company' && this.$route.name !== 'user'
    },
    showUser () {
      return this.$route.name !== 'user'
    }
  },
  methods: {
    handleLogout () {
      this.$router.replace('/login')
      localStorage.clear()
    }
  }
}
</script>

<style lang="scss" scoped>
.header-setting {
  ::v-deep {
    .el-popover__reference {
      display: flex;
      align-items: center;
      .el-avatar {
        margin-right: 8px;
      }
    }
  }
}
</style>

<style lang="scss">
.header-setting-panel {
  padding: 25px 0 0;
  border-radius: 8px;
  .panel > div {
    padding: 8px;
    text-align: center;
  }
  .nickname {
    font-weight: 500;
  }
  .wecom {
    color: #6e788a;
    margin: 8px 0 8px;
  }
  .item {
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    cursor: pointer;
    text-align: left;
    padding: 0 10px;
    &:hover {
      background: #f7f8fc;
    }
    i {
      margin-right: 8px;
    }
  }
  .logout {
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #eaebf2;
    margin: 10px 8px 16px;
    font-weight: 500;
  }
  .el-divider--horizontal {
    margin: 0;
    padding: 0 !important;
    background: #eaebf2;
  }
}
</style>
