import Vue from 'vue'
import store from '@/store/store'
import VueRouter from 'vue-router'
import axios from '@/common/ajax'
import { Message } from 'element-ui'

Vue.use(VueRouter)

export const constantsRoutes = [
  {
    path: '/',
    redirect: 'login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/loginNew/Index.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/bindmobile',
    name: 'bindmobile',
    component: () => import('@/pages/loginNew/BindMobile.vue'),
    meta: {
      title: '绑定手机',
      isSystem: true
    }
  },
  {
    path: '/configapp',
    name: 'configapp',
    component: () => import('@/pages/loginNew/Config.vue'),
    meta: {
      title: '应用配置',
      isSystem: true,
      isWecom: true
    }
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('@/pages/loginNew/Company.vue'),
    meta: {
      title: '选择企业',
      isSystem: true
    }
  },
  {
    path: '/configuration',
    name: 'configuration',
    component: () => import('@/pages/configuration/app.vue'),
    meta: {
      title: '创建应用',
      isSystem: true,
      isWecom: true
    }
  },
  {
    path: '/adminlogin',
    name: 'adminlogin',
    component: () => import('@/pages/adminlogin/adminlogin.vue'),
    meta: {
      title: '接管后台',
      isSystem: true
    }
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('@/pages/user/user.vue'),
    meta: {
      title: '个人中心',
      isSystem: true
    }
  },
  {
    path: '/log',
    name: 'log',
    component: () => import('@/pages/log/log.vue'),
    meta: {
      title: '更新日志',
      isSystem: true
    }
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/pages/layout/404NotFound.vue'),
    meta: {
      title: 'Page Not Found',
      isSystem: true
    }
  }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routerReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return routerReplace.call(this, location).catch((error) => error)
}

const createRouter = () => new VueRouter({
  routes: constantsRoutes,
  base: '/',
  linkActiveClass: 'active',
  mode: 'history'
})

const router = createRouter()

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // the relevant part
}

const getToken = () => {
  return localStorage.getItem('token')
}

const userLogout = () => {
  localStorage.clear()
}

// 获取企业token
const fn_getWecomToken = async (type) => {
  const data = await axios.post('getToken')

  if ((data && data.code) || (data && data.code === 0)) {
    Message.error(data.msg)
    return false
  }
  localStorage.setItem('type', 'wecom')
  localStorage.setItem('token', data.token)
}
// 获取授权状态
const fn_getAuthStatus = async (type = true) => {
  const data = await axios.get('authStatus')

  if ((data && data.code) || (data && data.code === 0)) {
    Message.info(data.msg)
    return false
  }

  store.commit('_SET', {
    'global.appConfig': data.err_status
  })
}

// 判断任务宝是否有数据
// const getTaskList = async () => {
//   const data = await axios.get('taskRecord')
//   return data.has_record
// }

let _toPath = null

router.beforeEach(async (to, from, next) => {
  // 没有权限
  if (to.meta.noPermission === true) {
    const current = to.fullPath.split('/')
    next(`/${current[1]}/${current[2]}/noPermissions`)
    return false
  }
  store.commit('_SET', {
    'global.startLoading': Date.now()
  })
  const _route = to.name
  const hasToken = getToken()
  const tokenType = localStorage.getItem('type')
  // 设置页面标题
  if (to.meta.title) {
    document.title = to.meta.title
  }

  // 设置页面操作权限，重置内页返回标题
  store.commit('_SET', {
    'global.pageActions': to.meta.auth,
    'global.firstTitle': '',
    'global.cusTitle': ''
  })

  // 设置当前菜单
  store.commit('permission/SET_PATH', to)

  if (to.path === '/login') { // 如果是登录页,判断有无token
    if (hasToken === null) { // 没有token,清除缓存
      userLogout()
      next()
    } else { // 有token
      if (to.meta.isSystem) {
        next()
      } else {
        if (tokenType === 'wecom') {
          const wecom_id = localStorage.getItem('companyId')
          if (!wecom_id) {
            if (to.path !== '/login') {
              next({
                path: '/login',
                replace: true
              })
            } else {
              next()
            }
            userLogout()
          } else {
            store.commit('permission/SET_WECOM_ID', wecom_id)
            const asyncRoutes = store.state.permission.asyncRoutes[`wecom_${wecom_id}`]
            if (asyncRoutes) { // 如果 store 已有缓存路由
              next()
            } else {
              const accessRoutesPromise = await store.dispatch('permission/GenerateRoutes', wecom_id)
              router.addRoutes(accessRoutesPromise)
              next({ path: accessRoutesPromise[0].path, replace: true })
            }
          }
        } else {
          next('/company')
        }
      }
    }
  } else if (to.name === 'adminlogin') {
    next()
  } else { // 非登录页,判断有无token
    if (hasToken === null) { // 没有token, 跳转登录页
      next({
        path: '/login',
        replace: true
      })
      userLogout()
    } else {
      if (to.meta.isSystem) {
        next()
      } else {
        // 如果从个人页面进入
        if (tokenType === 'account') {
          await fn_getWecomToken()
        }

        // 根据应用id获取购买信息
        if (to.meta.pid) {
          await store.dispatch('GeneratePurchase', to.meta.pid)
          //   const { isBuy } = await store.dispatch('GeneratePurchase', to.meta.pid)

          //   if (to.path === '/client/task/list') {
          //     const hasRecord = await getTaskList()

          //     // 没有购买且没有历史数据
          //     if (!isBuy && !hasRecord) {
          //       next('/client/task/edit')
          //     } else {
          //       next()
          //     }
          //   }
        }

        // 如果没有企业id
        const wecom_id = localStorage.getItem('companyId')
        if (!wecom_id) {
          next({
            path: '/login',
            replace: true
          })
          userLogout()
        } else {
          // 获取自建应用授权状态
          if (_toPath !== to.path && _toPath !== '/loading' && _route !== '/company' && _route !== 'user') {
            await fn_getAuthStatus()
          }
          const asyncRoutes = store.state.permission.asyncRoutes[`wecom_${wecom_id}`]
          if (asyncRoutes) { // 如果 store 已有缓存路由
            await store.dispatch('permission/GenerateWecomInfo', {
              id: wecom_id
            })
            localStorage.setItem('companyName', store.state.permission.wecomUserInfo.wecom.corp_name || store.state.permission.wecomUserInfo.wecom.corp_full_name)
            next()
          } else {
            const accessRoutesPromise = await store.dispatch('permission/GenerateRoutes', wecom_id)
            router.addRoutes(accessRoutesPromise)
            next({ ...to, replace: true })
          }
        }
      }
      _toPath = to.path
    }
  }
})

router.afterEach(async (to, from) => {
  store.commit('_SET', {
    'global.endLoading': Date.now()
  })
})

export default router
