/*
 * @Author: zhanln
 * @Date: 2021-05-11 14:10:46
 * @LastEditTime: 2022-07-07 17:32:45
 * @LastEditors: zhan
 * @Description: main
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import ElementUI from 'element-ui'
import axios from './common/ajax'
import components from './components'
import TWEEN from '@tweenjs/tween.js' // 动画插件

// 根据 element msgbox 自定义的 confimbox
import { lyConfirm } from './components/LyConfirm'

// 样式重置
import 'normalize.css'

// 字体图标
import 'remixicon/fonts/remixicon.css'

// 自定义 iconfont
import '@/assets/font/iconfont.css'

// 自定义element ui 主题
import '@/assets/style/index.css'

// 全局通用样式
import '@/assets/scss/global.scss'

// 注入全局方法
import util from '@/common/util'

// 防抖指令
import './common/derectives/debounce'
// 安装组件

import lyMsg from '@/components/lyMsg'
Vue.prototype.util = util

Vue.config.productionTip = false
Vue.prototype.axios = axios
Vue.prototype.$tween = TWEEN
Vue.prototype.$day = require('dayjs')
Vue.use(ElementUI, { size: 'medium' })
Vue.prototype.$lyConfirm = lyConfirm
Vue.use(components)

// 防止一个页面出现多个msgBox
Vue.mixin(lyMsg)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
