/*
 * @Author: zhanln
 * @Date: 2021-06-18 15:12:43
 * @LastEditTime: 2021-12-15 17:46:58
 * @LastEditors: Please set LastEditors
 * @Description: 充值相关
 */

const base = '/wecom'

export default {
  // 获取企微云豆余额
  beanBalance: base + '/assets',
  // 获取云豆购买列表
  buyList: base + '/assets/buy/list',
  // 获取购买金额
  amount: base + '/assets/amount',
  // 获取付款码
  paymentCode: base + '/wxpay/getPayCodeUrl',
  // 查看支付订单
  paymentOrder: base + '/wxpay/searchOrder'
}
