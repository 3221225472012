var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "no-authorized flex flex-center flex-column" },
    [
      _c("img", {
        attrs: { src: require("@assets/svg/default/404.svg"), alt: "" }
      }),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/")
                }
              }
            },
            [_vm._v("前往首页")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }