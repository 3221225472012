var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c("login-header", {
        attrs: { showCompany: "", showUserInfo: "", showUserCenter: "" }
      }),
      _c("login-card", [
        _c(
          "div",
          { staticClass: "login-title", staticStyle: { "margin-top": "40px" } },
          [_vm._v(" 还差最后一步啦～扫码自动完成配置 ")]
        ),
        _c(
          "div",
          { staticClass: "login-info", staticStyle: { "margin-top": "10px" } },
          [
            _vm._v(" 因api企业微信接口权限比较复杂，"),
            _c("br"),
            _vm._v("需企业微信扫码自动配置 ")
          ]
        ),
        _c(
          "div",
          { staticStyle: { "margin-top": "16px", position: "relative" } },
          [
            _c("img", {
              staticStyle: { width: "190px", height: "190px" },
              attrs: { src: _vm.configCodeImg, alt: "" }
            }),
            _vm.codeLoading
              ? _c("div", { staticClass: "config-loading flex flex-center" }, [
                  _c("div", { staticClass: "flex flex-center flex-column" }, [
                    _c("i", { staticClass: "el-icon-loading" }),
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.codeLoadingText) }
                    })
                  ])
                ])
              : _vm._e()
          ]
        ),
        _c(
          "div",
          {
            staticClass: "flex login-info",
            staticStyle: { "margin-top": "10px" }
          },
          [
            _vm._v(" 请使用 "),
            _c("img", {
              staticStyle: { "margin-left": "6px", "margin-right": "6px" },
              attrs: {
                src: require("../../assets/svg/wc_work_ic.svg"),
                width: "21",
                alt: ""
              }
            }),
            _c("span", { staticClass: "login-wecom__title" }, [
              _vm._v("企业微信")
            ]),
            _vm._v(" 手机app扫码 ")
          ]
        ),
        _c("div", { staticClass: "config-name" }, [
          _vm._v(_vm._s(_vm.companyName))
        ]),
        _c(
          "div",
          {
            staticClass: "config-self",
            on: { click: _vm.fn_jumpToConfigSetting }
          },
          [_vm._v(" 自有技术人员手动配置（不推荐） ")]
        )
      ]),
      _c("login-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }