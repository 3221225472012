var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        "custom-class": "card error",
        width: "380px",
        top: "22vh"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "card-body error-body" },
        [
          _c("div", { staticClass: "card-title error-title" }, [
            _c("i", { staticClass: "iconfont icon-close-fill" }),
            _vm._v(
              " " +
                _vm._s(
                  _vm.type === 1
                    ? "登录失败，企业自建应用未完成配置"
                    : "登录失败，员工账号未授权"
                ) +
                " "
            )
          ]),
          _c("div", { staticClass: "error-img" }, [
            _c("img", {
              attrs: { src: require("@assets/svg/login_default.svg"), alt: "" }
            })
          ]),
          _c(
            "div",
            { staticClass: "error-center" },
            [
              _c("div", {
                staticClass: "error-content",
                domProps: { innerHTML: _vm._s(_vm.content) }
              }),
              _c(
                "el-button",
                {
                  staticClass: "error-btn",
                  attrs: { type: "primary", round: "" },
                  on: { click: _vm.fn_back }
                },
                [_vm._v("返回")]
              )
            ],
            1
          ),
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: { effect: "light", placement: "right-start" }
            },
            [
              _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _c("div", { staticClass: "error-contact" }, [
                  _c("div", { staticClass: "error-contact_title" }, [
                    _vm._v("添加专属客服")
                  ]),
                  _c("div", { staticClass: "error-contact_text" }, [
                    _vm._v("一对一为您答疑解惑")
                  ]),
                  _c("div", { staticClass: "error-contact_code" }, [
                    _c("img", {
                      attrs: {
                        src: require("@assets/images/contact_me_qr.png"),
                        alt: ""
                      }
                    })
                  ])
                ])
              ]),
              _c("div", { staticClass: "error-contact_item q-primary" }, [
                _vm._v("联系客服处理")
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }