/*
 * @Author: zhanln
 * @Date: 2021-08-23 11:05:51
 * @LastEditTime: 2021-12-28 15:13:31
 * @LastEditors: zhanln
 * @Description: 个人活码
 */

export default {
  namespaced: true,

  state: {
    isReady: false,
    // 是否编辑
    isEdit: false,

    // basic
    basic_title: '',
    basic_group_id: '0',
    basic_wecom_user_ids: null,
    basic_wel_words: '#用户昵称# 你终于找到我了，有什么问题都可以咨询我哦~',
    basic_attachments: [],
    basic_expire_type: 1,
    basic_expire_time: null,

    // cus
    cus_tag_open: true,
    cus_tags: [],
    cus_name_suffix_open: true,
    cus_name_suffix: '',

    // link
    link_link_open: false,
    link_link_type: 1,
    link_guide: '',
    link_sevice_open: true,
    link_sevice_ids: null,
    link_link_bg: [],
    link_unreal_expire_open: true,
    link_unreal_expire_text: '活动剩余时间',
    link_unreal_expire_day: 0,
    link_unreal_expire_hour: 0,
    link_unreal_expire_minute: 0,
    link_unreal_expire_second: 0,
    link_qrcode_width: 120,
    link_qrcode_height: 120,
    link_qrcode_x: 140,
    link_qrcode_y: 280

  },

  getters: {
    GET_READY: state => {
      return state.isReady
    },
    GET_EDIT: state => {
      return state.isEdit
    },
    GET_BASIC: state => {
      return {
        title: state.basic_title,
        group_id: state.basic_group_id,
        wecom_user_ids: state.basic_wecom_user_ids,
        wel_words: state.basic_wel_words,
        attachments: state.basic_attachments,
        expire_type: state.basic_expire_type,
        expire_time: state.basic_expire_time
      }
    },
    GET_CUS: state => {
      return {
        tag_open: state.cus_tag_open,
        tags: state.cus_tags,
        name_suffix_open: state.cus_name_suffix_open,
        name_suffix: state.cus_name_suffix
      }
    },
    GET_LINK: state => {
      return {
        link_open: state.link_link_open,
        link_type: state.link_link_type,
        guide: state.link_guide,
        sevice_open: state.link_sevice_open,
        sevice_ids: state.link_sevice_ids,
        link_bg: state.link_link_bg,
        unreal_expire_open: state.link_unreal_expire_open,
        unreal_expire_text: state.link_unreal_expire_text,
        unreal_expire_day: state.link_unreal_expire_day,
        unreal_expire_hour: state.link_unreal_expire_hour,
        unreal_expire_minute: state.link_unreal_expire_minute,
        unreal_expire_second: state.link_unreal_expire_second,
        qrcode_width: state.link_qrcode_width,
        qrcode_height: state.link_qrcode_height,
        qrcode_x: state.link_qrcode_x,
        qrcode_y: state.link_qrcode_y
      }
    },
    GET_EXPIRE: state => {
      return state.link_unreal_expire_day + state.link_unreal_expire_hour + state.link_unreal_expire_minute + state.link_unreal_expire_second
    }
  },

  mutations: {
    SET_READY: state => {
      state.isReady = true
    },
    SET_EDIT: (state, n) => {
      state.isEdit = n
    },
    RESET: state => {
      state.isReady = false
      // 是否编辑
      state.isEdit = false

      // basic
      state.basic_title = ''
      state.basic_group_id = '0'
      state.basic_wecom_user_ids = null
      state.basic_wel_words = '#用户昵称# 你终于找到我了，有什么问题都可以咨询我哦~'
      state.basic_attachments = []
      state.basic_expire_type = 1
      state.basic_expire_time = null

      // cus
      state.cus_tag_open = true
      state.cus_tags = []
      state.cus_name_suffix_open = true
      state.cus_name_suffix = ''

      // link
      state.link_link_open = 0
      state.link_link_type = 1
      state.link_guide_open = true
      state.link_guide = ''
      state.link_sevice_open = true
      state.link_sevice_ids = null
      state.link_link_bg = []
      state.link_unreal_expire_open = true
      state.link_unreal_expire_text = '活动剩余时间'
      state.link_unreal_expire_day = 0
      state.link_unreal_expire_hour = 0
      state.link_unreal_expire_minute = 0
      state.link_unreal_expire_second = 0
      state.link_qrcode_width = 120
      state.link_qrcode_height = 120
      state.link_qrcode_x = 140
      state.link_qrcode_y = 280
    },
    SET_FORM: (state, n) => {
      state[`${n.form}_${n.name}`] = n.val
    }
  }
}
