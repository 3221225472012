/*
 * @Author: zhanln
 * @Date: 2021-08-19 17:42:22
 * @LastEditTime: 2022-05-20 11:06:52
 * @LastEditors: zhan
 * @Description:
 */

import { Message } from 'element-ui'

// 文件转换为base64
const typeOfbase = (opts) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(opts.file)
  })
}

// 图片类型正则，注：jpg 与 jpeg 相同
const imgType = (list) => {
  const _typeList = []
  const types = {
    png: /^iVBO/,
    jpg: /^\/9j/,
    jpeg: /^\/9j/,
    gif: /^R0/
  }

  if (list) {
    for (let i = 0; i < list.length; i++) {
      _typeList.push(types[list[i]])
    }
  }
  return _typeList
}

// 判断类型
const isImg = async (opts, type) => {
  let _varify = false
  const _exg = imgType(type)
  const data = await typeOfbase(opts)
  const _index = data.indexOf(',')

  if (_index > -1) {
    const _base = data.slice(_index + 1)

    for (let i = 0; i < _exg.length; i++) {
      if (_exg[i].test(_base)) {
        _varify = true
        break
      }
    }
  }

  if (!_varify) {
    Message.warning('请选择正确的图片类型！')
  }

  return _varify
}

export default isImg
