// 企微群运营
export default [
  {
    title: '企微群运营',
    id: 1005,
    path: '/groupOperation',
    icon: ['group.png', 'group_active.png'],
    children: [
      {
        title: '群管理',
        id: 100501,
        path: '/groupOperation/management',
        badge: '',
        children: [
          {
            title: '群列表',
            id: 10050101,
            path: '/groupOperation/management/list',
            component: '/customer/management/list.vue'
          },
          {
            title: '群详情',
            id: 10050102,
            path: '/groupOperation/management/detail',
            component: '/customer/management/detail.vue',
            backPath: '/groupOperation/management/list',
            is_menu: 0,
            pid: 10050101
          },
          {
            title: '群分析',
            id: 10050103,
            path: '/groupOperation/management/analysis',
            component: '/customer/management/analysis.vue'
          }
        ]
      }
    ]
  }
]
