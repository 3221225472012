var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      staticClass: "ly-emoji",
      attrs: { placement: "top-start", trigger: "click" },
      model: {
        value: _vm.visible,
        callback: function($$v) {
          _vm.visible = $$v
        },
        expression: "visible"
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "emoji-list",
          attrs: { "element-loading-spinner": "lyloading" }
        },
        [
          _vm._l(105, function(item, i) {
            return _c("span", {
              key: item,
              staticClass: "item",
              style: _vm.getPosition(i),
              on: {
                click: function($event) {
                  return _vm.setEmoji(i)
                }
              }
            })
          }),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false"
              }
            ],
            attrs: { src: require("./emoji.png") },
            on: {
              load: function($event) {
                _vm.loading = false
              }
            }
          })
        ],
        2
      ),
      _vm._t("default", null, { slot: "reference" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }