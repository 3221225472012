var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "log" },
    [
      _c("div", { staticClass: "log-title" }, [_vm._v("更新日志（测试服)")]),
      _c(
        "el-timeline",
        { staticClass: "log-timeline" },
        _vm._l(_vm.logList, function(item, index) {
          return _c(
            "el-timeline-item",
            {
              key: index,
              attrs: { timestamp: item.version, placement: "top" }
            },
            [
              item.desc
                ? _c("p", { staticClass: "log-desc" }, [
                    _vm._v(_vm._s(item.desc))
                  ])
                : _vm._e(),
              _c(
                "ul",
                { staticClass: "log-list" },
                _vm._l(item.children, function(item1, index1) {
                  return _c("li", { key: index1 }, [
                    _c(
                      "div",
                      { staticClass: "log-version flex" },
                      [
                        _vm._v(" " + _vm._s(item1.version)),
                        _c(
                          "el-tag",
                          {
                            staticClass: "log-time",
                            attrs: { type: "info", size: "mini" }
                          },
                          [_vm._v(_vm._s(item1.time))]
                        )
                      ],
                      1
                    ),
                    _c(
                      "ul",
                      { staticClass: "log-list" },
                      _vm._l(item1.list, function(item2, index2) {
                        return _c(
                          "li",
                          { key: index2 },
                          [
                            item2.type
                              ? [
                                  item2.type === "add"
                                    ? _c(
                                        "span",
                                        { staticClass: "log-type q-success" },
                                        [_vm._v("【新增】")]
                                      )
                                    : _vm._e(),
                                  item2.type === "ud"
                                    ? _c(
                                        "span",
                                        { staticClass: "log-type q-warning" },
                                        [_vm._v("【优化】")]
                                      )
                                    : _vm._e(),
                                  item2.type === "fix"
                                    ? _c(
                                        "span",
                                        { staticClass: "log-type q-danger" },
                                        [_vm._v("【修复】")]
                                      )
                                    : _vm._e(),
                                  item2.type === "ui"
                                    ? _c(
                                        "span",
                                        { staticClass: "log-type q-primary" },
                                        [_vm._v("【UI】")]
                                      )
                                    : _vm._e(),
                                  item2.type === "code"
                                    ? _c(
                                        "span",
                                        { staticClass: "log-type q-info" },
                                        [_vm._v("【代码】")]
                                      )
                                    : _vm._e()
                                ]
                              : _vm._e(),
                            _vm._v(" " + _vm._s(item2.desc) + " ")
                          ],
                          2
                        )
                      }),
                      0
                    )
                  ])
                }),
                0
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }