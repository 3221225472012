// 企微号运营
export default [
  {
    title: '企微号运营',
    id: 1004,
    path: '/accountOperation',
    icon: ['number.png', 'number_active.png'],
    children: [
      {
        title: '客户管理',
        id: 100401,
        path: '/accountOperation/overview',
        badge: '',
        children: [
          {
            title: '客户列表',
            id: 10040101,
            path: '/accountOperation/overview/list',
            component: '/client/overview/list.vue'
          },
          {
            title: '客户分析',
            id: 10040102,
            path: '/accountOperation/overview/analysis',
            component: '/client/overview/analysis.vue'
          },
          {
            title: '客户详情',
            id: 10040103,
            path: '/accountOperation/overview/detail',
            component: '/client/overview/detail.vue',
            backPath: '/accountOperation/overview/list',
            is_menu: 0,
            pid: 10040101
          }
        ]
      },
      {
        title: '个人欢迎语',
        id: 100402,
        path: '/accountOperation/message',
        badge: '',
        children: [
          {
            title: '好友欢迎语',
            id: 10040201,
            path: '/accountOperation/message/welcome',
            routeName: '欢迎语列表',
            component: '/client/welcome/list.vue'
          },
          {
            title: '新增欢迎语',
            id: 10040202,
            path: '/accountOperation/message/editWelcome',
            component: '/client/welcome/edit.vue',
            backPath: '/accountOperation/message/welcome',
            is_menu: 0,
            pid: 10040201
          }
        ]
      },
      {
        title: '标签管理',
        id: 100403,
        path: '/accountOperation/tags',
        badge: '',
        children: [
          {
            title: '企业标签',
            id: 10040301,
            path: '/accountOperation/tags/list',
            component: '/client/tags/list.vue'
          }
        ]
      }
    ]
  }
]
