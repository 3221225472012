/*
 * @Author: zhanln
 * @Date: 2021-06-18 15:13:02
 * @LastEditTime: 2021-06-18 16:37:26
 * @LastEditors: zhanln
 * @Description: 服务商相关
 */

const base = '/service_provider'

export default {
  // 服务商列表
  serviceList: base + '/location/providerList',
  // 服务商信息
  getProvider: base + '/provider/getProvider'
}
