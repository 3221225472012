/*
 * @Author: zhan
 * @Date: 2022-06-01 14:10:39
 * @LastEditTime: 2022-06-22 10:47:51
 * @LastEditors: zhan
 */

export default [
  {
    title: '设置',
    id: 2002,
    path: '/liveSetting',
    icon: ['setting.png', 'setting_active.png'],
    children: [
      {
        title: '授权管理',
        id: 200201,
        path: '/liveSetting/authorization',
        badge: '',
        children: [
          {
            title: '小程序授权',
            id: 20020101,
            path: '/liveSetting/authorization/applet',
            component: '/liveSetting/authorization/applet.vue'
          }
        ]
      }
    ]
  }
]
