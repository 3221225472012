/*
 * @Author: zhan
 * @Date: 2022-05-26 14:19:53
 * @LastEditTime: 2022-07-07 16:39:02
 * @LastEditors: zhan
 */
export default {
  namespaced: true,

  state: {
    editType: '',

    btn_title: '参与活动',
    rule: '',
    end_time: null,

    // 奖品设置
    // 当前编辑的阶段
    current_step: 0,

    reward_type_0: 1,
    reward_type_1: 1,
    reward_type_2: 1,
    reward_type_3: 1,

    reward_title_0: '',
    reward_title_1: '',
    reward_title_2: '',
    reward_title_3: '',

    reward_cover_0: '',
    reward_cover_1: '',
    reward_cover_2: '',
    reward_cover_3: '',

    reward_rate_0: '',
    reward_rate_1: '',
    reward_rate_2: '',
    reward_rate_3: '',

    reward_prize_type: null,
    diy_content: null,
    diy_img: null,
    code_rule: null,
    form_rule: null,

    // 奖品聚焦
    prize_focus: false,

    // 海报背景
    poster_img: null,
    avatar_status: null,
    avatar_sharp: null,
    nickname_status: null,
    nickname_size: null,
    nickname_color: null,
    nickname_align: null,

    // 海报配置
    avatar_x: null,
    avatar_y: null,
    avatar_width: null,
    avatar_height: null,
    nickname_x: null,
    nickname_y: null,
    nickname_offsetX: null,
    qrcode_x: null,
    qrcode_y: null,
    qrcode_width: null,
    qrcode_height: null,

    rule_focus: false
  },

  getters: {
    GET_INFO: state => name => {
      return state[name]
    },
    GET_REWARD: state => {
      const arr = []
      for (let i = 0; i < 4; i++) {
        const type = state[`reward_type_${i}`]
        const title = state[`reward_title_${i}`]
        const cover = state[`reward_cover_${i}`]
        arr.push({
          type, title, cover
        })
      }
      return arr
    },
    GET_POSTER_INFO: state => {
      return {
        avatar_x: state.avatar_x,
        avatar_y: state.avatar_y,
        avatar_width: state.avatar_width,
        avatar_height: state.avatar_height,
        nickname_x: state.nickname_x,
        nickname_y: state.nickname_y,
        nickname_offsetX: state.nickname_offsetX,
        qrcode_x: state.qrcode_x,
        qrcode_y: state.qrcode_y,
        qrcode_width: state.qrcode_width,
        qrcode_height: state.qrcode_height
      }
    }
  },

  mutations: {
    // 存储数据
    SET_INFO: (state, n) => {
      state[n.name] = n.val
    },

    // 重置
    RESET: state => {
      state.editType = ''

      state.btn_title = '参与活动'
      state.rule = ''
      state.end_time = null

      // 奖品设置
      // 当前编辑的阶段
      state.current_step = 0

      state.reward_type_0 = 1
      state.reward_type_1 = 1
      state.reward_type_2 = 1
      state.reward_type_3 = 1

      state.reward_title_0 = ''
      state.reward_title_1 = ''
      state.reward_title_2 = ''
      state.reward_title_3 = ''

      state.reward_cover_0 = ''
      state.reward_cover_1 = ''
      state.reward_cover_2 = ''
      state.reward_cover_3 = ''

      state.reward_rate_0 = ''
      state.reward_rate_1 = ''
      state.reward_rate_2 = ''
      state.reward_rate_3 = ''

      state.reward_prize_type = null
      state.diy_content = null
      state.diy_img = null
      state.code_rule = null
      state.form_rule = null

      state.prize_focus = false

      // 海报背景
      state.poster_img = null
      state.avatar_status = null
      state.avatar_sharp = null
      state.nickname_status = null
      state.nickname_size = null
      state.nickname_color = null
      state.nickname_align = null

      // 海报配置
      state.avatar_x = null
      state.avatar_y = null
      state.avatar_width = null
      state.avatar_height = null
      state.nickname_x = null
      state.nickname_y = null
      state.nickname_offsetX = null
      state.qrcode_x = null
      state.qrcode_y = null
      state.qrcode_width = null
      state.qrcode_height = null

      state.rule_focus = false
    }
  }
}
