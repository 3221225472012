<template>
  <el-container class="layout-container" v-loading="isLoading" element-loading-text="页面加载中"
    element-loading-spinner="lyloading" element-loading-background="hsla(0,0%,100%,.8)">

    <!-- header -->
    <global-tip @handleClick="fn_config"></global-tip>
    <el-header height="54px" class="layout-header">
      <div class="temp flex" style="flex: 1">
        <img src="~@assets/svg/01sk.svg" alt="logo" class="logo" />
        <div class="main-tab flex">
          <div :class="['main-tab__item', rtype === 'private' ? 'active' : '']" @click="changeRouteType('private')">
            私域工作台
          </div>
          <!-- <div :class="['main-tab__item', rtype === 'live' ? 'active' : '']" @click="changeRouteType('live')">直播
          </div> -->
        </div>
      </div>
      <div class="helper">
        <i class="bg iconfont icon-help"></i>
        <a href="https://www.yuque.com/books/share/487e7162-7d33-4079-800b-f78ea891e1fd" target="_blank">帮助中心</a>
      </div>
      <el-popover placement="bottom-end" width="200" trigger="hover" :append-to-body="false">
        <div class="customer-content">
          <div class="title flex">
            <img src="@assets/svg/wc_work_ic.svg" />专属客服
          </div>
          <div class="qr">
            <img src="@assets/images/contact_me_qr.png" alt="" srcset="" />
            <div>微信扫码添加客服</div>
          </div>
        </div>
        <div class="customer" slot="reference">
          <i class="bg iconfont icon-a-customerservice"></i>联系客服
        </div>
      </el-popover>
      <div class="layout-header_right">
        <el-button size="small" round class="btn-service" v-if="(isSuperManager || isManager) && hasProvider"
          @click="fn_jumpToService" style="font-size: 14px">
          <span class="flex"><i class="iconfont icon-Switch"></i>服务商后台</span>
        </el-button>
        <header-setting :userInfo="userInfo"></header-setting>
      </div>
    </el-header>
    <!-- header end-->
    <el-container class="bottom-area">
      <side-bar></side-bar>
      <el-container class="right-area">
        <router-view></router-view>
      </el-container>
    </el-container>
    <setup ref="setupRef" @close="fn_getAuthStatus"></setup>
  </el-container>
</template>

<script>
import { mapGetters } from 'vuex'
import setup from './components/setup'
import globalTip from './components/globalTip'
import SideBar from './components/SideBar.vue'
import HeaderSetting from './components/HeaderSetting.vue'
import allAsyncRoutes from '@/router/routes'
export default {
  components: {
    setup,
    globalTip,
    SideBar,
    HeaderSetting
  },
  data () {
    return {
      setPath: true,
      company: '',
      pathInterval: null,
      hasProvider: false,
      outPopShow: true,
      cid: null,
      isLoading: false
    }
  },

  computed: {
    ...mapGetters({
      userInfo: 'permission/GET_USERINFO',
      isSuperManager: 'permission/GET_SUPERMANAGER',
      isManager: 'permission/GET_MANAGER'
    }),
    rtype () {
      return this.$store.state.permission.rtype
    },
    appConfig () {
      return this.$store.state.global.appConfig
    },
    startLoading () {
      return this.$store.state.global.startLoading
    },
    endLoading () {
      return this.$store.state.global.endLoading
    }
  },

  watch: {
    appConfig (val) {
      if (val === 3 && this.outPopShow) {
        this.fn_getThirdAppStatus()
      }
    },
    startLoading (nVal) {
      if (nVal) {
        this.timer = setTimeout(() => {
          this.isLoading = true
        }, 300)
        // 最后loading 10S 避免loading不消失
        this.timerTemp = setTimeout(() => {
          this.isLoading = false
        }, 10000)
      }
    },
    endLoading (nVal) {
      if (nVal - this.startLoading < 300) {
        clearTimeout(this.timer)
      } else if (nVal - this.startLoading < 600) {
        this.timer = setTimeout(() => {
          this.isLoading = false
        }, 300)
      } else if (nVal) {
        this.isLoading = false
      }
    }
  },
  beforeRouteUpdate (to, from, next) {
    clearTimeout(this.timer)
    clearTimeout(this.timerTemp)
    next()
  },
  beforeRouteLeave (to, from, next) {
    clearTimeout(this.timer)
    clearTimeout(this.timerTemp)
    localStorage.setItem('backCompanyId', localStorage.getItem('companyId'))
    next()
  },

  async created () {
    // 刷新页面时判断第三方应用是否卸载
    if (this.appConfig === 3 && this.outPopShow) {
      this.fn_getThirdAppStatus()
    }

    this.cid = localStorage.getItem('companyId')
    localStorage.removeItem('backCompanyId')

    // 实时监听多标签：切换，退出
    this.watchTokenInterval = setInterval(() => {
      const token = localStorage.getItem('token')
      const localCid = localStorage.getItem('companyId')

      // 检测退出
      if (!token) {
        this.fn_lyMsg('info', '请重新登录')
        this.$router.replace('/login')
      }

      // 检测切换企业
      if (this.cid !== localCid) {
        this.$router.go(0)
      }
    }, 500)

    this.fn_getProvider()
  },

  mounted () {
    if (this.$route.meta && this.$route.meta.pid && (this.$route.meta.pid + '').substring(0, 1) === '2') {
      localStorage.setItem('routeType', 'live')
      this.$store.commit('_SET', {
        'permission.rtype': 'live'
      })
    } else {
      localStorage.setItem('routeType', 'private')
      this.$store.commit('_SET', {
        'permission.rtype': 'private'
      })
    }
    // this.fn_showConfig(true)
  },

  methods: {

    fn_getThirdAppStatus () {
      this.outPopShow = false
      setTimeout(() => {
        this.$lyConfirm({
          title: '因企业微信接口升级，您的企业还未完成代自建应用授权，请重新入驻企业或加入新企业。',
          text: '',
          confirmButtonText: '确定',
          showCancelButton: false,
          closeOnClickModal: false
        }).then(() => {
          this.$store.dispatch('permission/RemoveWecomUser', { wecomId: localStorage.getItem('companyId') })
          this.$router.push('/company')
        }).catch(() => { })
      }, 200)
    },

    fn_showConfig (type = true) {
      const appConfig = this.$store.state.global.appConfig
      const appConfigShow = this.$store.state.global.appConfigShow
      const isAdmin = this.$store.state.permission.isAdmin
      if (appConfigShow && type && appConfig === 1 && isAdmin) {
        setTimeout(() => {
          this.fn_config()
        }, 200)
        this.$store.commit('_SET', {
          'global.appConfigShow': false
        })
      }
    },

    // 获取授权状态
    async fn_getAuthStatus (type = true) {
      const data = await this.axios.get('authStatus')

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      this.$store.commit('_SET', {
        'global.appConfig': data.err_status
      })

      if (data.err_status === 3 && this.outPopShow) {
        this.fn_getThirdAppStatus()
      }
    },

    // 顶部提示框点击
    fn_config () {
      this.$refs.setupRef.visible = true
    },

    // 获取服务商信息
    async fn_getProvider () {
      const data = await this.axios.post('getProvider')

      if ((data && data.code)) {
        if (data && data.code === 1001500) {
          return
        } else {
          this.fn_lyMsg('info', data.msg)
        }
        return false
      }

      this.hasProvider = true
    },

    // 个人中心
    jumpToUser () {
      this.$router.push('/user')
    },

    // 账号token
    async fn_getAccountToken () {
      const data = await this.axios.post('getAccountToken')

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      return data.token
    },

    // 跳转服务商
    async fn_jumpToService () {
      const accountToken = await this.fn_getAccountToken()
      const wecom_id = localStorage.getItem('companyId')
      let http = 'https://partner.01lb.com.cn'
      if (process.env.NODE_ENV === 'test') {
        http = 'http://partner.test.01lb.com.cn'
      } else if (process.env.NODE_ENV === 'dev') {
        http = 'http://partner.dev.01lb.com.cn'
      } else if (process.env.NODE_ENV === 'prod') {
        http = 'https://partner.01lb.com.cn'
      }
      window.location.href = `${http}/transfer?token=${accountToken}&companyId=${wecom_id}`
    },

    changeRouteType (type) {
      localStorage.setItem('routeType', type)
      this.$store.commit('_SET', {
        'permission.rtype': type
      })
      if (type === 'private') {
        this.$router.push(allAsyncRoutes[0].path)
      } else {
        this.$router.push('/live/reserve/list')
      }
    }
  },

  destroyed () {
    if (this.watchTokenInterval) {
      clearInterval(this.watchTokenInterval)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/layout.scss";

.logo {
  margin-right: 115px;
}

.helper,
.customer {
  display: flex;
  align-items: center;
  margin-right: 24px;

  i {
    margin-right: 3px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    line-height: 24px;
    cursor: pointer;

    &:hover {
      background: #f0f1f8;
    }

    &::before {
      margin-left: 4px;
    }
  }
}

.el-avatar {
  margin-right: 8px;
}

.bottom-area {
  overflow: hidden;
}

.right-area {
  flex-basis: 0%;
}

.btn-service {
  margin-right: 24px;
  font-weight: normal;

  i {
    margin-right: 4px;
  }

  &:hover {
    border-color: #2b7cff;
    color: #2b7cff;
  }
}

.customer-content {
  padding-bottom: 25px;
  align-items: unset;

  .title {
    color: #454d5b;
    padding: 0 5px;

    img {
      margin-right: 5px;
      height: 16px;
      width: 16px;
    }
  }

  .qr {
    img {
      width: 150px;
      height: 150px;
      display: block;
      margin: 20px auto 10px;
    }

    div {
      text-align: center;
    }
  }
}

.main-tab {
  &__item {
    position: relative;
    font-size: 20px;
    margin-right: 48px;
    padding: 0 12px;
    cursor: pointer;

    &:hover {
      color: $--color-primary;
    }

    &.active {
      font-weight: 600;
      color: $--color-primary;

      &::after {
        position: absolute;
        left: 50%;
        bottom: -11px;
        content: '';
        transform: translateX(-50%);
        height: 3px;
        width: 100%;
        background-color: $--color-primary;
      }
    }
  }
}
</style>
