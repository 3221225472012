<!--
 * @Author: zhanln
 * @Date: 2022-02-09 16:15:16
 * @LastEditTime: 2022-08-02 15:16:58
 * @LastEditors: zhan
 * @Description: 代自建应用
-->

<template>
  <el-dialog title="企业快速入驻" :visible.sync="visible" width="740px" custom-class="setup" :close-on-click-modal="false"
    :close-on-press-escape="false" @closed="fn_close">
    <!-- 步骤条 -->
    <div class="setup-steps">
      <div class="setup-steps__item" v-for="(item, index) of steps" :key="index">
        <div class="step" :class="[currentStep === index + 1 || item.success ? 'active' : '']">
          <template v-if="item.success">
            <i class="iconfont icon-selected"></i>
          </template>
          <template v-else>
            {{ index + 1 }}
          </template>
        </div>
        <div class="title" :class="[currentStep === index + 1 || item.success ? 'active' : '']">
          {{ item.label }}
        </div>
      </div>
    </div>

    <!-- container -->
    <div :class="[
      'setup-container',
      secretShow || currentStep !== 1 ? 'setup-secret' : 'setup-code',
      currentStep === 3 ? 'setup-setting' : ''
    ]">
      <div class="setup-code__title">
        {{
            secretShow
              ? steps[currentStep - 1].title1
              : steps[currentStep - 1].title
        }}
      </div>

      <!-- 授权创建自建应用 -->
      <template v-if="currentStep === 1">
        <div :class="['setup-code_img', !codeUrl ? 'is-load' : '']">
          <div :class="[
            'setup-code_load',
            stopHandleCode ? 'q-primary is-point' : '',
          ]" v-if="codeLoading" @click="fn_openCode(1)">
            <i :class="codeLoadingIcon"></i>
            <span v-html="codeLoadingText"></span>
          </div>
          <img :src="codeUrl" alt="" v-if="codeUrl" />
        </div>
        <div class="setup-code_tips flex flex-center">
          请使用<img :src="wecomLogo" alt="" class="setup-code_logo" /><span class="setup-code_text">企业微信</span>管理员手机app扫码
        </div>
        <div class="setup-code_tips">并授权完成添加</div>
      </template>

      <!-- 配置中 -->
      <template v-if="currentStep === 2">
        <div class="text-small q-info mt-16">
          配置过程大约需要5-15分钟，请耐心等候，如超时请点击 <el-tooltip class="item" effect="light" placement="right">
            <div slot="content">
              <div class="setup-contact">
                <div class="setup-contact_title">添加专属客服</div>
                <div class="setup-contact_text">一对一为您答疑解惑</div>
                <div class="setup-contact_code">
                  <img :src="contactCode" alt="" />
                </div>
              </div>
            </div>
            <span class="setup-contact_link q-primary">联系客服</span>
          </el-tooltip> 完成快速配置
        </div>
        <div v-if="showLottie">
          <div class="load-lottie" style="width: 200px; height: 160px; margin: 0 auto"></div>
        </div>
      </template>

      <!-- 授权 -->
      <template v-if="currentStep === 3 && !secretShow">
        <div class="setup-code__title">配置代自建应用可见范围
          <span class="q-primary text-small ml-8" @click="fn_jumpToGuide" style="cursor: help">如何配置可见范围？</span>
        </div>
        <div class="mt-8 mb-12" style="color: #454D5B;">可见范围内的成员，能够显示在零一SCRM系统内，建议选择全部企业。</div>
        <img src="https://image.01lb.com.cn//uploads/wecom/22/0802/1659422583468MDCPx.png" alt="" style="width: 100%;">
        <el-button round type="primary" @click="fn_customizedFinish" class="setting-btn">完成配置</el-button>
      </template>

    </div>

    <!-- 客服 -->
    <div class="setup-contact_block">
      <el-tooltip class="item" effect="light" placement="right">
        <div slot="content">
          <div class="setup-contact">
            <div class="setup-contact_title">添加专属客服</div>
            <div class="setup-contact_text">一对一为您答疑解惑</div>
            <div class="setup-contact_code">
              <img :src="contactCode" alt="" />
            </div>
          </div>
        </div>
        <span class="setup-contact_link"><i class="iconfont icon-a-customerservice"></i>联系客服处理</span>
      </el-tooltip>
    </div>
  </el-dialog>
</template>

<script>
import Clipboard from 'clipboard'
import { authapp } from '../http'
import lottie from 'lottie-web'
export default {
  name: 'insteadApp',

  data () {
    return {
      COMM_HTTP: authapp,
      visible: false,
      steps: [
        {
          success: false,
          label: '授权创建自建应用',
          title: '扫码开始授权创建'
        },
        {
          success: false,
          label: '配置自建应用',
          title: '我们正在为您配置代自建应用'
        },
        {
          success: false,
          label: '授权成员和客户权限'
        }
      ],
      currentStep: 1,
      secretShow: false,
      insteapKey: null,
      authKey: null,
      appform: {
        internal_secret: ''
      },
      appFormRules: {
        internal_secret: [
          { required: true, message: '请输入客户联系Secret', trigger: 'change' }
        ]
      },
      internalError: '',
      btnLoading: false,
      codeUrl: '',
      codeLoading: true,
      codeLoadingIcon: 'el-icon-loading',
      codeLoadingText: '二维码加载中...',
      wecomLogo: require('@assets/svg/wc_work_ic.svg'),
      contactCode: require('@assets/images/contact_me_qr.png'),
      codeHandle: null,
      codeSecond: 2000,
      contactText: 'HI~ 您好，我的自建应用已经完成配置啦！\n企业名称：#corp_name#\n请尽快协助我完成新企业入驻哦~',
      corpData: {},
      overTime: 0,
      maxTime: 600000,
      authMaxTime: 100000,
      loadingType: '',
      stopHandleCode: false,
      hasChange: false,
      showLottie: false,
      lottie: null
    }
  },

  watch: {
    'appform.internal_secret' () {
      this.internalError = ''
    }
  },

  mounted () {
    // 调试
    // this.fn_setStep(3, false, { corp_id: 4 })
  },

  methods: {

    // 判断目前步骤
    fn_setStep (step, secret, corpData) {
      if (corpData) {
        this.corpData = corpData
      }
      for (let i = 0; i < step - 1; i++) {
        this.steps[i].success = true
      }
      this.currentStep = step
      this.secretShow = secret
      this.visible = true

      if (step === 1) {
        this.fn_getInsteadCode()
        return
      }

      if (step === 2) {
        this.contactText = this.contactText.replace('#corp_name#', this.corpData.corp_name || this.corpData.corp_full_name)
        this.showLottie = true
        this.$nextTick(() => {
          lottie.loadAnimation({
            container: document.querySelector('.load-lottie'),
            renderer: 'svg',
            loop: true,
            animationData: require('../../../assets/loading.json')
          })
        })
        this.fn_insteadConfirm()
      }
    },

    // 获取代自建应用二维码
    async fn_getInsteadCode () {
      const data = await this.COMM_HTTP.insteadCode()

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      // 链接生成二维码
      this.codeUrl = await this.util.qrCode(data.qrcode_url, 280)
      this.codeLoading = false
      this.insteapKey = data.code_key

      // 监听扫码
      setTimeout(() => {
        this.fn_handleInsteadCode()
      }, 1000)
    },

    // 监听代自建应用扫码
    async fn_handleInsteadCode () {
      const data = await this.COMM_HTTP.insteadRes({ code_key: this.insteapKey })

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      if (this.overTime >= this.maxTime) {
        this.stopHandleCode = true
        this.fn_setLoading(true, 'iconfont icon-icon-hourglass', '点击开始扫码')
        return
      }

      // 0 则继续监听
      if (data.status === 0) {
        this.codeHandle = setTimeout(() => {
          this.overTime += this.codeSecond
          this.fn_handleInsteadCode()
        }, this.codeSecond)
        return
      }

      this.hasChange = true
      this.stopHandleCode = false
      this.corpData.corp_id = data.wecom_id
      this.corpData.corp_name = data.corp_name
      this.corpData.corp_full_name = data.corp_full_name
      this.corpData.avatar_url = data.avatar
      this.corpData.nickname = data.name
      this.fn_setStep(2)
    },

    // 代自建应用上线确认
    async fn_insteadConfirm () {
      const data = await this.COMM_HTTP.insteadConfirm({ wecom_id: this.corpData.corp_id })

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      if (data.status === 1) {
        this.showLottie = false
        clearTimeout(this.codeHandle)
        this.hasChange = true
        this.fn_setLoading(true, 'el-icon-refresh', '二维码加载中...')
        this.$nextTick(() => {
          this.fn_setStep(3)
        })
        return
      }
      this.codeHandle = setTimeout(() => {
        this.fn_insteadConfirm()
      }, this.codeSecond)
    },

    // 自建应用配置二维码authCode
    async fn_getAuthCode () {
      this.fn_setLoading()
      this.overTime = 0
      this.codeUrl = ''
      const data = await this.COMM_HTTP.authCode({
        wecom_id: this.corpData.corp_id
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      this.authKey = data.qrcode_key
      this.codeUrl = data.url
      this.codeLoading = false

      // 监听扫码
      setTimeout(() => {
        this.fn_handleAuthCode()
      }, 1000)
    },

    // 监听自建应用配置扫码
    async fn_handleAuthCode () {
      const data = await this.COMM_HTTP.authRes({ qrcode_key: this.authKey })

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      // "status":"0 未扫码 1 已扫码 2 已扫码确认 3 已扫码取消 4 配置中 5 配置失败 6配置失败 应用还在开发中 7 配置成功 8 配置成功 下发secret"
      const status = data.status

      if (status === '0' && this.overTime >= this.authMaxTime) {
        this.stopHandleCode = true
        this.loadingType = 'q-primary'
        this.fn_setLoading(true, 'el-icon-refresh', '二维码已失效<br />点击刷新')
        return
      }

      // 先处理异常、成功，不需轮询的状态
      if (status === '3') {
        this.codeLoading = false
        this.codeLoadingText = ''
        this.fn_lyMsg('info', '自动扫码配置已取消')
        this.fn_getAuthCode()
        return
      }

      if (status === '5') {
        this.$lyConfirm({
          title: '配置异常，请重新扫码配置',
          text: '',
          showCancelButton: false
        }).then(() => {
          this.fn_getAuthCode()
        }).catch(() => { })
        return
      }

      if (status === '6') {
        this.stopHandleCode = true
        this.loadingType = 'q-primary'
        this.fn_setLoading(true, 'el-icon-refresh', '企业未完成配置<br>请联系客服是否配置成功')
        return
      }

      if (status === '7') {
        this.hasChange = false
        this.corpData.corp_status = 1
        this.$emit('enter', this.corpData)
        return
      }

      if (status === '8') {
        this.hasChange = true
        this.secretShow = true
        return
      }

      if (status === '9') {
        this.$lyConfirm({
          title: '您扫码的企业未安装代自建应用，<br>请确认企业是否一致',
          text: '',
          showCancelButton: false
        }).then(() => {
          this.fn_getAuthCode()
        }).catch(() => { })
        return
      }

      // 需要轮询的状态
      if (status === '1') {
        this.fn_setLoading(true, 'el-icon-loading', '扫码成功<br>请在手机点击登录')
      }

      if (status === '2') {
        this.fn_setLoading(true, 'el-icon-loading', '应用配置中<br>请勿刷新或跳转此页面')
      }

      this.codeHandle = setTimeout(() => {
        this.overTime += this.codeSecond
        this.fn_handleAuthCode()
      }, this.codeSecond)
    },

    // 重新开始监听扫码
    fn_openCode (step) {
      if (!this.stopHandleCode) return

      this.codeLoading = false
      this.stopHandleCode = false
      this.overTime = 0

      if (step === 1) {
        this.fn_handleInsteadCode()
      } else {
        this.fn_getAuthCode()
      }
    },

    // 客户联系
    async fn_internal () {
      let verify = true
      this.$refs.appFormRef.validate((valid) => {
        verify = valid
      })

      if (!verify) return false

      const data = await this.COMM_HTTP.internal(
        {
          internal_secret: this.appform.internal_secret,
          wecom_id: this.corpData.corp_id
        }
      )

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      if (data.internal_check === 1) {
        this.internalError = '客户联系Secret异常，请重新输入'
        return
      }

      this.hasChange = false
      this.corpData.corp_status = 1
      this.$emit('enter', this.corpData)
    },

    // 代自建应用手动完成配置
    async fn_customizedFinish (item) {
      const data = await this.axios.post('customizedFinish', {
        wecom_id: +this.corpData.corp_id
      })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      clearTimeout(this.codeHandle)
      this.hasChange = false
      this.$emit('enter', this.corpData)
    },

    // 下一步
    fn_nextStep () {
      this.fn_internal()
    },

    // loading 处理
    fn_setLoading (loading = true, icon = 'el-icon-loading', text = '二维码加载中...') {
      this.codeLoading = loading
      this.codeLoadingIcon = icon
      this.codeLoadingText = text
    },

    // 复制话术
    fn_copy (el) {
      const clipboard = new Clipboard(`.${el}`)
      clipboard.on('success', () => {
        clipboard.destroy()
        this.fn_lyMsg('success', '复制成功')
      })
      clipboard.on('error', (e) => {
        this.fn_lyMsg('error', '复制失败，请手动复制')
        console.error('E:', e)
      })
    },

    // 配置指引
    fn_jumpToGuide () {
      window.open('https://www.yuque.com/docs/share/687fab5b-1396-44d1-aec2-1709e6922447', '_black')
    },

    // 关闭弹框时，取消扫码监听，重置默认状态
    fn_close () {
      this.showLottie = false
      this.overTime = 0
      if (this.codeHandle) {
        clearTimeout(this.codeHandle)
      }
      this.steps.forEach(item => {
        item.success = false
      })
      this.currentStep = 1
      this.secretShow = false
      this.loadingType = ''
      this.corpData = {}
      this.fn_setLoading()
      if (this.hasChange) {
        this.$emit('updateWecoms')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/var.scss";

::v-deep .setup {
  border-radius: 16px;
}

.setup-steps {
  display: flex;
  justify-content: center;
  margin-top: 12px;

  &__item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 33.333%;

    &:not(:last-child) {
      &::before {
        position: absolute;
        content: "";
        top: 12px;
        left: calc(50% + 18px);
        width: calc(100% - 36px);
        height: 1px;
        background-color: #dfe1e8;
        z-index: 1;
      }
    }
  }

  .step {
    position: relative;
    z-index: 2;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #b4bbcc;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;

    &.active {
      background-color: $--color-primary;
    }
  }

  .title {
    margin-top: 12px;
    color: #6e788a;

    &.active {
      color: #212b36;
    }
  }
}

.setup-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 332px;
  box-shadow: 0px 8px 16px 0px rgba(145, 158, 171, 0.24);
  border-radius: 8px;
  margin: 34px auto 22px;
  padding: 24px 0 14px;

  &__title {
    color: #262626;
    font-size: 16px;
  }

  &_load {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.95);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    i {
      font-size: 32px;
    }

    span {
      margin-top: 16px;
      text-align: center;
    }

    &.is-point {
      cursor: pointer;
    }
  }

  &_img {
    position: relative;
    width: 180px;
    height: 180px;
    margin: 16px 0;
    padding: 3px;
    box-sizing: border-box;
    border: 1px dashed #a5a5a5;

    &.is-load {
      background-color: #f8f9fb;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &_tips {
    text-align: right;
    color: #6e788a;
    font-size: 12px;
  }

  &_logo {
    margin: 0 4px 0 4px;
    height: 16px;
  }

  &_text {
    color: #2f7dcd;
  }
}

.setup-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 14px;

  &_block {
    text-align: right;
    font-size: 12px;
    color: #454d5b;

    .icon-a-customerservice {
      margin-right: 5px;
      font-size: 14px;
    }
  }

  &_title {
    color: $--color-text-primary;
  }

  &_text {
    font-size: 12px;
    margin: 6px 0 10px 0;
  }

  &_code {
    img {
      height: 100px;
    }
  }

  &_item {
    margin: 40px 0;

    i {
      font-size: 16px;
      margin-left: 4px;
    }
  }
}

.setup-secret {
  width: 480px;
  margin: 65px auto 36px;
  text-align: center;

  &__title {
    color: #212b36;
    font-size: 16px;
    text-align: center;
    margin-bottom: 40px;
  }
}

.setup-setting {
  width: 610px;
  margin-top: 32px;
  margin-bottom: 0;
}

.setup-form {
  margin-top: 40px;

  ::v-deep {
    .el-form-item {
      margin-bottom: 14px;
    }

    .el-form-item__label {
      width: 100% !important;
      text-align: left;
    }
  }

  &_tips {
    text-align: right;
    font-size: 12px;
    color: $--color-primary;
    cursor: pointer;
  }

  &_btn {
    margin-bottom: 62px !important;
  }
}

.contact-text {
  cursor: pointer;
  margin-left: 8px;
}

.setting-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 200px;
  height: 40px;
  border-radius: 24px;
  margin: 20px auto 0;
}

.setup-confirm-2 {
  display: block;
  height: 40px;
  margin: 0 auto;
  width: 200px;
  font-size: 16px;
}
</style>
