var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wx-qr" }, [
    _c("div", { staticClass: "left-area" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.$attrs.loading,
              expression: "$attrs.loading"
            }
          ],
          staticClass: "wxpay-box",
          attrs: { "element-loading-spinner": "el-icon-loading" }
        },
        [
          _vm.$attrs.error
            ? _c("div", { staticClass: "net-error" }, [
                _c("div", { staticClass: "error-desc" }, [_vm._v("网络错误")]),
                _c(
                  "span",
                  {
                    staticClass: "recharge-btn",
                    on: {
                      click: function($event) {
                        return _vm.$emit("refresh")
                      }
                    }
                  },
                  [_vm._v("点击刷新")]
                )
              ])
            : _vm._e(),
          _vm.$attrs.qr
            ? _c("img", {
                staticClass: "wxpay-qr",
                attrs: { src: _vm.$attrs.qr }
              })
            : _vm._e()
        ]
      )
    ]),
    _c("div", { staticClass: "right-area" }, [
      _c("div", { staticClass: "wx-pay-desc" }, [
        _vm._m(0),
        _c("span", { staticClass: "amount" }, [
          _c("span", { staticStyle: { "font-size": "18px" } }, [_vm._v("￥")]),
          _vm._v(_vm._s(_vm.$attrs.total))
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex" }, [
      _c("img", {
        staticClass: "wx-pay-icon",
        attrs: { src: require("@assets/images/wx_pay.png"), alt: "wx" }
      }),
      _c("span", [_vm._v("微信扫码支付")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }