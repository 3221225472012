var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "empty" }, [
    _c("div", { class: ["empty-card", _vm.hasClose ? "un-shadow" : ""] }, [
      _vm.hasClose
        ? _c(
            "div",
            {
              staticClass: "empty-card_close",
              on: {
                click: function($event) {
                  return _vm.$emit("handleClose")
                }
              }
            },
            [_c("i", { staticClass: "el-icon-close" })]
          )
        : _vm._e(),
      _vm._m(0),
      _c("div", { staticClass: "empty-card_body" }, [
        _c("div", { staticClass: "block" }, [
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("企业已入驻平台")]),
              _c(
                "el-button",
                {
                  staticClass: "btn",
                  attrs: { type: "primary", plain: "" },
                  on: { click: _vm.fn_jumgToLogin }
                },
                [_vm._v("员工扫码加入企业")]
              ),
              _c("div", { staticClass: "tips" }, [
                _vm._v("企业已经入驻平台，员工直接扫码加入即可")
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("企业未入驻平台")]),
              _c(
                "el-button",
                {
                  staticClass: "btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.fn_jumpToBindWecom }
                },
                [_vm._v("超管扫码入驻平台")]
              ),
              _vm._m(1)
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "empty-card_contact" },
        [
          _c("el-tooltip", { attrs: { effect: "light", placement: "right" } }, [
            _c(
              "div",
              {
                staticClass: "empty-card_contact--block",
                attrs: { slot: "content" },
                slot: "content"
              },
              [
                _c("div", { staticClass: "title" }, [_vm._v("添加专属客服")]),
                _c("div", { staticClass: "text" }, [
                  _vm._v("一对一为您答疑解惑")
                ]),
                _c("div", { staticClass: "code" }, [
                  _c("img", {
                    attrs: {
                      src: require("@assets/images/contact_me_qr.png"),
                      alt: ""
                    }
                  })
                ])
              ]
            ),
            _c("span", [
              _vm._v("如有疑问，联系客服处理"),
              _c("i", {
                staticClass: "iconfont icon-a-customerservice",
                staticStyle: { "margin-left": "5px" }
              })
            ])
          ])
        ],
        1
      )
    ]),
    _c(
      "div",
      { class: ["empty-card_footer", _vm.hasClose ? "un-shadow" : ""] },
      [
        _c("i", { staticClass: "iconfont icon-info-fill" }),
        _vm._v("还没有企业微信？ "),
        _c(
          "el-button",
          { attrs: { type: "text" }, on: { click: _vm.fn_registerWecom } },
          [
            _vm._v("点击这里，官方注册 "),
            _c("i", { staticClass: "iconfont icon-right1" })
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-card_header" }, [
      _c("div", { staticClass: "item" }, [
        _c("img", {
          staticClass: "wechat",
          attrs: { src: require("@assets/svg/lylb.svg") }
        })
      ]),
      _c("i", { staticClass: "el-icon-close gap" }),
      _c("div", { staticClass: "item" }, [
        _c("img", {
          staticClass: "wework",
          attrs: { src: require("@assets/svg/wc_work_ic.svg"), alt: "" }
        }),
        _c("span", { staticClass: "text" }, [_vm._v("企业微信")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tips" }, [
      _vm._v(" 请使用"),
      _c("b", [_vm._v("企业微信超级管理员")]),
      _vm._v("的身份扫码授权 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }