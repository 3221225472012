var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          staticClass: "robot-select",
          attrs: {
            "popper-class": "hide-select",
            "popper-append-to-body": false,
            placeholder: _vm.selectList.length > 0 ? "" : _vm.placeholder
          },
          on: { focus: _vm.fn_open },
          model: {
            value: _vm.selectValue,
            callback: function($$v) {
              _vm.selectValue = $$v
            },
            expression: "selectValue"
          }
        },
        [
          _vm.selectList.length > 0
            ? _c(
                "template",
                { slot: "prefix" },
                [
                  _vm._l(_vm.selectList.slice(0, _vm.showLength), function(
                    item,
                    index
                  ) {
                    return _c(
                      "span",
                      { key: item.id, staticClass: "robot-select__item" },
                      [
                        _c("img", {
                          staticClass: "avatar",
                          attrs: { src: item.avatar, alt: "" }
                        }),
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.name))
                        ]),
                        index !== _vm.selectList.length - 1
                          ? _c("span", { staticClass: "robot-select__gap" }, [
                              _vm._v(",")
                            ])
                          : _vm._e()
                      ]
                    )
                  }),
                  _vm.selectList.length - _vm.showLength > 0
                    ? _c("span", { staticClass: "robot-select__more" }, [
                        _vm._v(
                          "+" + _vm._s(_vm.selectList.length - _vm.showLength)
                        )
                      ])
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ],
        2
      ),
      _c("ly-robot", {
        ref: "lyRobotRef",
        attrs: { setDisable: _vm.setDisable },
        on: { callback: _vm.fn_getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }