<!--
 * @Author: zhanln
 * @Date: 2022-04-18 14:10:40
 * @LastEditTime: 2022-04-18 17:08:04
 * @LastEditors: zhanln
-->
<template>
  <div class="file fx">
    <div class="file-info flex-1 text-ellipsis">
      <div class="file-name text-ellipsis fx">
        <div class="text-ellipsis">{{ computedName(file.name) }}</div>
        <div>.{{ getFileExt(file.name) }}</div>
      </div>
      <div class="file-size">{{ computedSize(file.size) }}</div>
    </div>
    <div class="file-icon">
      <img :src="computedExt(file.name)" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'fileCard',

  props: ['file'],

  methods: {

    // 文件名后缀分离
    computedName (name) {
      const lastIndex = name.lastIndexOf('.')
      return name.slice(0, lastIndex)
    },

    // 根据后缀设置icon图
    computedExt (name) {
      const suffix = this.getFileExt(name)
      const typeEnum = ['txt', 'doc', 'docx', 'pdf', 'ppt', 'pptx', 'xls', 'xlsx', 'csv']
      return typeEnum.includes(suffix) ? require(`@/assets/images/fileImg/${suffix}.png`) : require('@/assets/images/fileImg/default.png')
    },

    // 文件后缀
    getFileExt (name) {
      const lastIndex = name.lastIndexOf('.')
      const suffix = lastIndex > -1 ? name.slice(lastIndex + 1, name.length) : ''
      return suffix
    },

    // 计算文件size
    computedSize (size) {
      const gCount = 1024 * 1024 * 1024
      const mCount = 1024 * 1024
      const kCount = 1024

      if (size >= gCount) {
        return parseInt(size / gCount * 10) / 10 + 'G'
      }

      if (size >= mCount) {
        return parseInt(size / mCount * 10) / 10 + 'M'
      }

      return (size / kCount * 10) / 10 < 0.1 ? '0.1k' : parseInt(size / kCount * 10) / 10 + 'k'
    }
  }
}
</script>

<style lang="scss" scoped>
.file {
  padding: 16px 20px;
  border-radius: 4px;

  &-info {
    height: 48px;
    line-height: 22px;
  }

  &-name {
    margin-bottom: 8px;
    margin-right: 16px;
  }

  &-size {
    color: #b4bbcc;
  }

  &-icon {
    height: 48px;
    width: 48px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
