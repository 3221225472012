/*
 * @Author: zhanln
 * @Date: 2021-12-31 16:36:12
 * @LastEditTime: 2022-01-18 16:47:45
 * @LastEditors: zhanln
 * @Description: 抽奖
 */

export default {
  namespaced: true,

  state: {

    // 活动状态
    isEdit: false,
    isCopy: false,
    isEnd: false,
    isDraft: false,
    actId: null,

    // 账户信息
    // 余额
    open_packet: null,
    account_amount: null,
    account_amount_fmt: null,

    // 活动信息
    welcome_msg: '',
    end_time: '',
    link_type: 1,
    link_logo: '',
    link_title: '',
    link_desc: '',

    // 海报
    poster_img: '',
    avatar_status: null,
    avatar_sharp: null,
    nickname_status: null,
    nickname_size: null,
    nickname_color: null,
    nickname_align: null,
    avatar_x: null,
    avatar_y: null,
    avatar_width: null,
    avatar_height: null,
    nickname_x: null,
    nickname_y: null,
    nickname_offsetX: null,
    qrcode_x: null,
    qrcode_y: null,
    qrcode_width: null,
    qrcode_height: null,

    // 活动页设置
    lottery_type: 1,
    show_title: '',
    cover_title: '',
    rule: '',
    rule_focus: false,

    // 抽奖规则
    activity_amount: null,
    need_invite: null,
    invite_lottery_num: null,
    total_num: null,

    // 奖品设置
    // 当前编辑的阶段
    current_step: 0,
    reward_title_0: '',
    reward_title_1: '',
    reward_title_2: '',
    reward_title_3: '',
    reward_title_4: '',
    reward_title_5: '',
    reward_title_6: '',
    reward_title_7: '',
    reward_cover_0: '',
    reward_cover_1: '',
    reward_cover_2: '',
    reward_cover_3: '',
    reward_cover_4: '',
    reward_cover_5: '',
    reward_cover_6: '',
    reward_cover_7: '',
    rate_0: '',
    rate_1: '',
    rate_2: '',
    rate_3: '',
    rate_4: '',
    rate_5: '',
    rate_6: '',
    rate_7: '',
    reward_type: null,
    diy_content: null,
    diy_img: null,
    code_rule: null,
    form_rule: null,

    // 奖品聚焦
    reward_focus: false,

    // 裂变文案
    help_msg: '',

    // 默认
    default_welcome_msg: '#用户昵称# 😊 终于等到你~，快来参与本次抽奖活动~\n点击下方活动链接，生成你的专属海报\n邀请好友扫码助力，即可获取抽奖机会哦~\n快来参加吧~',
    default_link_logo: 'https://image.01lb.com.cn//uploads/wecom/22/0113/1642070007U4SJpU2J.png',
    default_link_title: '点击链接，参与活动',
    default_link_desc: '快来参加抽奖吧',
    default_reward_cover_1: 'https://image.01lb.com.cn//uploads/wecom/22/0113/1642062161ty8rNml0.png',
    default_reward_cover_2: 'https://image.01lb.com.cn//uploads/wecom/22/0105/1641371126wl0x8nqG.png'
  },

  getters: {
    GET_POSTER_INFO: state => {
      return {
        avatar_x: state.avatar_x,
        avatar_y: state.avatar_y,
        avatar_width: state.avatar_width,
        avatar_height: state.avatar_height,
        nickname_x: state.nickname_x,
        nickname_y: state.nickname_y,
        nickname_offsetX: state.nickname_offsetX,
        qrcode_x: state.qrcode_x,
        qrcode_y: state.qrcode_y,
        qrcode_width: state.qrcode_width,
        qrcode_height: state.qrcode_height
      }
    }
  },

  mutations: {
    // 设置为编辑
    SET_EDIT: state => {
      state.isEdit = true
    },

    // 设置为复制
    SET_COPY: state => {
      state.isCopy = true
    },

    // 设置为草稿
    SET_DRAFT: state => {
      state.isDraft = true
    },

    // 设置为结束
    SET_END: state => {
      state.isEnd = true
    },

    // 存储数据
    SET_INFO: (state, n) => {
      state[n.type] = n.val
    },

    // 当前所在阶段
    SET_CURRENT_STEP: (state, n) => {
      state.current_step = n
    },

    // 重置数据
    RESET: state => {
      // 活动状态
      state.isEdit = false
      state.isCopy = false
      state.isEnd = false
      state.isDraft = false
      state.actId = null

      // 账户信息
      // 余额
      state.open_packet = null
      state.account_amount = null
      state.account_amount_fmt = null

      // 活动信息
      state.welcome_msg = ''
      state.end_time = ''
      state.link_type = 1
      state.link_logo = ''
      state.link_title = ''
      state.link_desc = ''

      // 海报
      state.poster_img = ''
      state.avatar_status = null
      state.avatar_sharp = null
      state.nickname_status = null
      state.nickname_size = null
      state.nickname_color = null
      state.nickname_align = null
      state.avatar_x = null
      state.avatar_y = null
      state.avatar_width = null
      state.avatar_height = null
      state.nickname_x = null
      state.nickname_y = null
      state.nickname_offsetX = null
      state.qrcode_x = null
      state.qrcode_y = null
      state.qrcode_width = null
      state.qrcode_height = null

      // 活动页设置
      state.lottery_type = 1
      state.show_title = ''
      state.cover_title = ''
      state.rule = ''
      state.rule_focus = false

      // 抽奖规则
      state.activity_amount = null
      state.need_invite = null
      state.invite_lottery_num = null
      state.total_num = null

      // 奖品设置
      // 当前编辑的阶段
      state.current_step = 0
      state.reward_title_0 = ''
      state.reward_title_1 = ''
      state.reward_title_2 = ''
      state.reward_title_3 = ''
      state.reward_title_4 = ''
      state.reward_title_5 = ''
      state.reward_title_6 = ''
      state.reward_title_7 = ''
      state.reward_cover_0 = ''
      state.reward_cover_1 = ''
      state.reward_cover_2 = ''
      state.reward_cover_3 = ''
      state.reward_cover_4 = ''
      state.reward_cover_5 = ''
      state.reward_cover_6 = ''
      state.reward_cover_7 = ''
      state.rate_0 = ''
      state.rate_1 = ''
      state.rate_2 = ''
      state.rate_3 = ''
      state.rate_4 = ''
      state.rate_5 = ''
      state.rate_6 = ''
      state.rate_7 = ''
      state.reward_type = null
      state.diy_content = null
      state.diy_img = null
      state.code_rule = null
      state.form_rule = null

      // 奖品聚焦
      state.reward_focus = false

      // 裂变文案
      state.help_msg = ''
    }
  }
}
