<template>
  <div class="no-authorized flex flex-center flex-column">
    <img src="@assets/svg/default/404.svg" alt="" />
    <div>
      <el-button type="primary" round @click="$router.push('/')">前往首页</el-button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    fn_goBack () {
      this.$router.back(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.no-authorized {
  height: 100%;
  color: #ccc;
  font-size: 28px;
  img{
    margin-top: -100px;
    width: 400px;
  }
  &__text {
    margin: 24px 0;
  }
}
</style>
