<!-- 文件上传组件 -->
<template>
  <div class="ly-upload">
    <el-upload
      v-bind="selfSetting"
      action="#"
      :accept="accept"
      :file-list="fileList"
      :auto-upload="false"
      :on-change="fn_uploadImg"
      v-if="type !== 'file' || (type === 'file' && fileList.length === 0)"
    >
      <div
        :class="[
          'ly-upload__body',
          fileList && fileList.length ? 'has-img' : '',
          mini ? 'is-mini' : '',
          error ? 'is-error' : '',
        ]"
      >
        <template v-if="fileList && fileList.length">
          <!-- 图片 -->
          <template v-if="type === 'image'">
            <img :src="fileList[0].url" alt="" class="ly-upload__img" />
          </template>
          <!-- 视频 -->
          <template v-else-if="type === 'video'">
            <video
              :src="fileList[0].url"
              ref="videoDom"
              class="ly-upload__video"
            />
          </template>
          <template v-if="canRemove">
            <div class="ly-upload__mask" @click.stop="fn_removeFile">
              <i class="iconfont icon-delete ly-upload__tipIcon"></i>
            </div>
          </template>
          <template v-else>
            <div class="ly-upload__changeText" v-if="changeText">
              {{ changeText }}
            </div>
            <div class="ly-upload__mask">
              <i class="el-icon-upload2 ly-upload__tipIcon"></i>
            </div>
          </template>
        </template>
        <template v-else>
          <i class="iconfont ly-upload__tipIcon" :class="[tipIcon]"></i>
          <div class="ly-upload__tipText" v-if="tipText">{{ tipText }}</div>
        </template>
      </div>
    </el-upload>

    <div class="ly-upload__file" v-else>
      <file-card :file="fileInfo"></file-card>
      <div class="ly-upload__file--remove">
        <i class="iconfont icon-delete" @click="fn_removeFile"></i>
      </div>
    </div>
    <ly-cropper ref="lyCropperRef" @finish="fn_cropperFinish"></ly-cropper>
  </div>
</template>

<script>
import qiniuUpload from '@/common/mixins/qiniuUpload'
import isImg from '@/common/isImg'
import fileCard from '@/components/material/file'
export default {
  name: 'ly-upload',

  mixins: [qiniuUpload],

  components: {
    fileCard
  },

  props: {
    setting: {
      type: Object,
      default: () => {
        return {}
      }
    },
    fileInfoSet: {
      type: Object,
      default: () => {
        return {}
      }
    },
    type: {
      type: String,
      default: 'image'
    },
    mini: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: '.png, .jpg, .jpeg'
    },
    img: {
      type: String,
      default: ''
    },
    tipIcon: {
      type: String,
      default: 'icon-picture'
    },
    tipText: {
      type: String,
      default: '上传图片'
    },
    changeText: {
      type: String,
      default: '点击修改'
    },
    size: {
      type: [String, Number],
      default: '2MB'
    },
    cropper: {
      type: Boolean,
      default: false
    },
    canRemove: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    getTime: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      file: null,
      fileList: [],
      selfSetting: {
        'show-file-list': false
      },
      fileInfo: null,
      cropperImg: ''
    }
  },

  computed: {
    uploadSetting () {
      return this.setting
    }
  },

  watch: {
    uploadSetting: {
      handler (val) {
        Object.assign(this.selfSetting, val)
      },
      immediate: true,
      deep: true
    },
    img (val) {
      if (val) {
        if (this.fileList.length === 0 || (this.fileList.length > 0 && this.fileList[0].url !== val)) {
          this.fileList = [{ url: val }]
        }
        if (this.fileInfoSet && !this.fileInfo) {
          this.fileInfo = this.fileInfoSet
        }
      } else {
        this.fileList = []
      }
    }
  },

  mounted () {
    if (this.img) {
      this.fileList = [{
        url: this.img
      }]
    }
  },

  methods: {

    // 选择图片
    async fn_uploadImg (files) {
      // 不需要裁切，直接上传
      if (!this.cropper) {
        files.file = files.raw
        this.fn_requestFile(files)
        return true
      }

      // 需要裁切，调用裁切
      this.$refs.lyCropperRef.fn_init(files)
    },

    // 裁切回调
    fn_cropperFinish (file) {
      this.fn_requestFile({ file: file })
    },

    // 上传
    async fn_requestFile (opts) {
      const baseFile = this.fileList
      this.fileList = []
      const accept = this.accept.split(',').map(item => {
        item = item.trim().replace(/^\./, '')
        return item
      })

      const _varify = this.type === 'image' ? await isImg(opts, accept) : true

      // 判断类型是否正确
      if (_varify) {
        const error = await this.qiniuUpload2(
          opts.file,
          path => {
            this.file = opts
            this.fileInfo = opts.file
            this.fileList.push({
              url: path
            })
            this.fn_getImg()
          },
          null,
          {
            types: accept,
            size: this.size
          }
        )

        if (error === 'error') {
          this.fileList = baseFile
          this.fn_getImg()
        }
      } else {
        this.fileList = baseFile
        this.fn_getImg()
      }
    },

    // 视频时长
    getVideoTime (file) {
      return new Promise((resolve) => {
        const videoElement = document.createElement('video')
        videoElement.src = URL.createObjectURL(file.raw)
        videoElement.addEventListener('loadedmetadata', function () {
          resolve(videoElement.duration)
        })
      })
    },

    // 移除
    fn_removeFile () {
      this.fileList = []
      this.fn_getImg()
    },

    // 回调
    async fn_getImg () {
      if (this.$listeners.getFile) {
        if (this.getTime && this.fileList.length > 0) {
          this.fileInfo.time = parseInt(await this.getVideoTime(this.file))
        }
        this.$emit('getFile', this.fileList.length > 0 ? this.fileList[0].url : '', this.fileList.length > 0 ? this.fileInfo : null)
      }

      if (this.$listeners.getImg) {
        this.$emit('getImg', this.fileList.length > 0 ? this.fileList[0].url : '', this.fileList.length > 0 ? this.fileInfo : null)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/utils.scss";
.ly-upload {
  line-height: 0;

  &__body {
    position: relative;
    width: 104px;
    height: 104px;
    line-height: 1;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px dashed #c0ccda;
    background-color: #f7f8fc;
    box-sizing: border-box;
    color: #212b36;

    &:hover:not(.has-img) {
      border-color: $--color-primary;
    }

    &.is-mini {
      width: 70px;
      height: 70px;

      .ly-upload__tipIcon {
        font-size: 20px;
      }

      .ly-upload__tipText {
        font-size: 12px;
      }
    }

    &.has-img {
      border-style: solid;
    }

    &.is-error {
      border-color: $--color-danger;
    }
  }

  &__tipIcon {
    font-size: 28px;
  }

  &__tipText {
    margin-top: 6px;
  }

  &__img {
    max-width: 100%;
    max-height: 100%;
  }

  &__video {
    max-height: 100%;
  }

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    border-radius: 0 0 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }

  &__changeText {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 12px;
    line-height: 28px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border-radius: 0 0 4px 4px;
  }
}

.ly-upload__file {
  width: 246px;
  line-height: 1.6;
  border: 1px solid #eaebf2;
  box-sizing: border-box;
  position: relative;
  border-radius: 8px;

  &--remove {
    position: absolute;
    color: $--color-danger;
    padding-left: 6px;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);

    i {
      cursor: pointer;
    }
  }
}
</style>
