<!--
 * @Author: zhanln
 * @Date: 2021-12-27 16:50:53
 * @LastEditTime: 2022-09-15 10:24:40
 * @LastEditors: zhanln
 * @Description: 搜索结果
-->

<template>
  <div class="emp-search" v-show="visible">
    <div class="emp-result__total">
      <div class="left">
        搜索结果（<span class="q-primary">{{ resultList.length }}</span> /
        {{ total }}）
      </div>
      <div class="right" @click="fn_checkAll" v-if="this.resultList.length > 0">
        <label class="label">全选</label>
        <el-checkbox
          @click.prevent.native
          v-model="allCheck"
          :indeterminate="indeterminate"
        ></el-checkbox>
      </div>
    </div>
    <el-scrollbar class="emp-search__scroll">
      <div class="emp-default" v-show="loading">
        <div class="load-lottie"></div>
        <div class="text">成员搜索中...</div>
      </div>
      <template v-if="!loading">
        <div class="emp-default" v-if="resultList.length === 0">
          <img src="@assets/svg/default/no_res.svg" alt="" />
          无搜索结果
        </div>
        <template v-else>
          <div
            class="emp-result__item"
            v-for="(data, index) in resultList"
            :key="index"
            @click="fn_nodeClick(data)"
          >
            <div class="emp-result__avatar">
              <img :src="data.avatar_url || defaultAvatar" alt="" />
            </div>

            <div class="emp-result__info">
              <span class="name">
                {{ data.name }}
              </span>
              <span
                class="role"
                :class="[
                  data.role_id === 4 || data.role_id === 1 ? 'super' : 'normal'
                ]"
              >
                {{ data.role_name }}
              </span>
              <span class="dep" v-if="data.wecom_departments">
                部门：{{
                  data.wecom_departments
                    ? data.wecom_departments.join('，')
                    : '-'
                }}
              </span>
            </div>
            <div class="emp-result__check">
              <el-checkbox
                v-model="data.checked"
                :disabled="data.isDisable"
                @click.prevent.native
              ></el-checkbox>
            </div>
          </div>
          <div
            class="emp-result__more"
            :class="{ active: loadMore }"
            v-if="total > resultList.length"
            @click="fn_loadMore"
          >
            <i class="el-icon-refresh-right"></i>加载更多
          </div>
        </template>
      </template>
    </el-scrollbar>
  </div>
</template>

<script>
import lottie from 'lottie-web'
import scaleAvatar from '@/common/scaleAvatar'
export default {
  name: 'searchBox',

  props: {
    checkList: {
      type: Array,
      default: () => {
        return []
      }
    },
    diabledList: {
      type: Array,
      default: () => {
        return []
      }
    },
    datafilter: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      visible: false,
      loading: true,
      page: 1,
      per_page: 50,
      searchName: '',
      resultList: [],
      defaultAvatar: require('@assets/images/default_avatar.png'),
      total: null,
      loadMore: false,
      checkCount: 0
    }
  },

  computed: {
    allCheck () {
      return this.resultList.length > 0 && this.checkCount === this.resultList.length
    },
    indeterminate () {
      return this.checkCount > 0 && this.checkCount < this.resultList.length && !this.allCheck
    }
  },

  mounted () {
    this.lottie = lottie.loadAnimation({
      container: document.querySelector('.load-lottie'),
      renderer: 'svg',
      loop: true,
      animationData: require('@/assets/loading.json')
    })
  },

  methods: {

    async fn_getData (name) {
      this.searchName = name
      this.page = 1
      this.loading = true
      this.resultList = await this.fn_getEmps()
      setTimeout(() => {
        this.loading = false
      }, 150)
    },

    // 搜索员工
    async fn_getEmps () {
      this.checkCount = 0
      const data = await this.axios.get('empAll', {
        params: {
          page: this.page,
          per_page: this.per_page,
          name: this.searchName,
          status: this.datafilter ? 1 : -1,
          is_auth_app: this.datafilter ? 1 : -1,
          is_auth_third_app: -1
        }
      })

      if ((data && data.code) || (data && data.code === 0)) {
        return this.fn_lyMsg('info', data.msg)
      }

      if (!data.data) {
        this.total = 0
        return []
      }

      this.total = data.total

      this.fn_setCheck(data.data)

      return data.data.map(item => {
        item.avatar_url = scaleAvatar(item.avatar_url)
        return item
      })
    },

    // 加载更多
    async fn_loadMore () {
      this.loadMore = true
      this.page += 1
      const data = await this.fn_getEmps()
      this.resultList = this.resultList.concat(data)
      this.loadMore = false
    },

    // 设置选中
    fn_setCheck (data) {
      for (let i = 0; i < data.length; i++) {
        const index = this.checkList.findIndex(item => {
          const id = item.base_id || item.id
          return id === data[i].id
        })
        if (index > -1) {
          data[i].checked = true
          this.checkCount += 1
        }
        const isDisable = this.diabledList.includes(data[i].role_id)
        if (isDisable) {
          data[i].checked = true
          data[i].isDisable = isDisable
        }
      }
    },

    // 点击节点
    fn_nodeClick (data) {
      if (data.isDisable) return
      if (data.checked) {
        this.$emit('setCheck', data, false, true)
        data.checked = false
        this.checkCount -= 1
      } else {
        this.$emit('setCheck', data, true, true)
        data.checked = true
        this.checkCount += 1
      }
    },

    // 右侧移除
    fn_removeItem (data) {
      const id = data.base_id || data.id
      const emp = this.resultList.findIndex(item => item.id === id)
      if (emp > -1) {
        this.resultList[emp].checked = false
        this.checkCount -= 1
      }
    },

    // 全选
    fn_checkAll () {
      if (this.allCheck) {
        for (let i = 0; i < this.resultList.length; i++) {
          this.resultList[i].checked = false
          this.$emit('setCheck', this.resultList[i], false, true)
          this.checkCount -= 1
        }
      } else {
        for (let i = 0; i < this.resultList.length; i++) {
          this.resultList[i].checked = true
          this.$emit('setCheck', this.resultList[i], true, true)
          this.checkCount += 1
        }
        this.checkCount = this.resultList.length
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@assets/scss/var.scss';
.emp {
  &-search {
    position: absolute;
    left: 0;
    top: 52px;
    background-color: #fff;
    height: calc(100% - 52px);
    width: 100%;

    &__scroll {
      height: calc(100% - 40px);
    }
  }

  &-default {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 320px;
    flex-direction: column;

    .load-lottie {
      width: 50%;
    }

    .text {
      color: $--color-primary;
    }
  }

  &-result {
    &__total {
      display: flex;
      justify-content: space-between;
      color: #212b36;
      margin: 10px 0;
      align-items: center;
      height: 20px;
      line-height: 20px;
      padding: 0 32px 0 16px;

      .right {
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;
          color: $--color-primary;

          ::v-deep .el-checkbox__inner {
            border-color: $--color-primary;
          }
        }
      }

      .label {
        margin-right: 6px;
        cursor: pointer;
      }
    }

    &__item {
      display: flex;
      padding: 8px 32px;

      &:hover {
        background-color: #f5f7fa;
        cursor: pointer;
      }
    }

    &__info {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      overflow: hidden;

      span {
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }

      .role {
        font-size: 12px;
        background-color: #f0f1f8;
        border-radius: 2px;
        padding: 4px;
        color: #454d5b;
        margin: 4px 0;
        line-height: 1;

        &.super {
          background-color: rgba(#ffc107, 0.1);
          color: #ffc107;
        }
      }

      .dep {
        font-size: 12px;
        color: #b4bbcc;
      }
    }

    &__avatar {
      flex: 0 0 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #f2f2f2;
      margin-right: 12px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__check {
      display: flex;
      align-items: center;
    }

    &__more {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $--color-primary;
      height: 48px;

      &:hover {
        background-color: #f5f7fa;
        cursor: pointer;
      }

      i {
        margin-right: 8px;
        font-size: 16px;
      }

      &.active i {
        animation: rotating 0.5s linear infinite;
      }
    }
  }
}
</style>
