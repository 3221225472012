import { constantsRoutes, resetRouter } from '@/router'
import axios from '@/common/ajax'
import { Message } from 'element-ui'
// 所有路由 使用前需要先根据权限过滤
import allAsyncRoutes from '../../router/routes'
import util from '@/common/util.js'
// 需要缓存的页面路径 对应接口中path字段
const keepAlive = [
  // '/client/redenvelope/list', // 红包列表
  // '/client/task/list', // 任务宝列表
  // '/client/groupfission/list', // 群裂变列表
  '/client/welcome/list' // 客户欢迎语列表
]

// 用于查询页面是否存在
const PAGE_MAP = new Map()

// 过滤仅超管可访问的页面（三级菜单）
const onlySuperRouter = [
  '/enterprise/management/config' // 系统配置
]

// 过滤仅{管理}可访问的页面（三级菜单）
const onlyManagerRouter = [
  ...onlySuperRouter,
  '/cloudService/aggregate/list', // 企微客服
  '/cloudService/aggregate/record', // 聊天记录
  '/material/library/enterprise' // 企业素材
]

// 获取个人账号token
const getAccountToken = async () => {
  const localAccountToken = localStorage.getItem('accountToken')
  if (localAccountToken) {
    return localAccountToken
  } else {
    const data = await axios.post('getAccountToken')

    if ((data && data.code) || (data && data.code === 0)) {
      Message.error(data.msg)
      return false
    }
    return data.token
  }
}

// 判断本地是否缓存个人用户信息（微信）
const getAccountInfo = async () => {
  let accountInfo = {}
  const localAccountInfo = localStorage.getItem('userInfo')
  if (localAccountInfo) {
    accountInfo = JSON.parse(localAccountInfo)
  } else {
    accountInfo = await getAccountInfoAsync()
    localStorage.setItem('userInfo', JSON.stringify({
      avatar_url: accountInfo.avatar_url,
      id: accountInfo.id,
      nickname: accountInfo.nickname,
      mobile: accountInfo.mobile
    }))
  }
  return accountInfo
}

// 获取个人信息（微信）
const getAccountInfoAsync = async () => {
  const data = await axios.get('getInfo')

  if ((data && data.code) || (data && data.code === 0)) {
    Message.error(data.msg)
    return false
  }

  return data
}

// 企业用户信息
const getUserInfo = async (id, refresh) => {
  let userInfo = {}
  localStorage.removeItem('wecomUserInfo')

  const _asyncUserInfo = await getUserInfoAsync()
  userInfo = _asyncUserInfo
  // setLocalInfo(id, _asyncUserInfo, {})
  return userInfo
}

// 获取企业用户信息
const getUserInfoAsync = async () => {
  const data = await axios.get('wecomUser')

  if ((data && data.code) || (data && data.code === 0)) {
    Message.error(data.msg)
    return false
  }

  return data
}

// 移除企业用户信息
const removeWecomUser = (id) => {
  const localUserInfo = localStorage.getItem('wecomUserInfo')
  if (localUserInfo) {
    setLocalInfo(id, null, JSON.parse(localUserInfo))
  }
}

// 缓存企业用户信息
const setLocalInfo = (id, info, data) => {
  console.log('setLocalInfo', id, info, data)
  const menus = data
  menus[`wecom_${id}`] = info
  localStorage.setItem('wecomUserInfo', JSON.stringify(menus))
}

// 配置路由
const generateAsyncRoutes = async (id) => {
  await queryAuth()
  const _allAsyncRoutes = util.extend(true, [], allAsyncRoutes)
  const asyncRoutes = await initRoutes(filterDisplay(_allAsyncRoutes))
  asyncRoutes.push(
    {
      path: '*',
      redirect: 'error',
      hidden: true
    }
  )

  return asyncRoutes
}

// 有权限的菜单id组成的数组
let ids = []
// 下架的应用id
let isOff = []
/**
 * @description: 查询菜单权限
 * @param {*}
 * @return {Array} 返回有权限的菜单id
 */
const queryAuth = async () => {
  ids = []
  isOff = []
  const res = await axios.get('apps')
  res.map(({ app_id, is_show, have_permission }) => {
    // is_show !== 1 表示应用暂时下架  have_permission !==1 表示没有权限
    if (have_permission === 1) {
      ids.push(app_id)
    }
    if (is_show === 0) {
      isOff.push(app_id)
    }
  })
}

// 过滤下架的应用
const filterDisplay = (data) => {
  return data.filter(e => {
    e.children = e.children.filter(v => {
      v.children = v.children.filter(m => !isOff.includes(m.id) && !isOff.includes(m.pid))
      return !isOff.includes(v.id)
    })
    return !isOff.includes(e.id)
  })
}

// 配置菜单
const initRoutes = async (data) => {
  const _routes = []
  PAGE_MAP.clear()
  setMenuOne(_routes, data)
  buildPageKey(_routes)
  return _routes
}

// 一级菜单
const setMenuOne = (routes, data) => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].children) {
      routes.push({
        path: replacePath(data[i].path),
        meta: {
          id: data[i].id,
          title: data[i].title,
          noPermission: !ids.includes(data[i].id)
        },
        icon: data[i].icon,
        redirect: setRedirect(data[i]),
        component: () => import('@/pages/layout/Layout.vue'),
        children: setMenuTwo(data[i])
      })
    }
  }
}

// 二级菜单
const setMenuTwo = (data) => {
  if (!data.children) return false
  const children = data.children
  const arr = []
  arr.push({
    path: `${data.path}/apps/:id`,
    hidden: true,
    component: () => import('@/pages/layout/Main.vue')
  })
  for (let i = 0; i < children.length; i++) {
    if (children[i].path) {
      children[i].path = replacePath(children[i].path)
      if (children[i].children) { // 没有子页面，则不配置此二级菜单
        arr.push({
          ...children[i],
          meta: {
            title: children[i].title,
            noPermission: !ids.includes(children[i].id)
          },
          redirect: setRedirect(children[i]),
          component: () => import('@/pages/layout/Main.vue'),
          children: setMenuThree(children[i], `${data.title} - ${children[i].title}`, children[i].id)
        })
      }
    }
  }
  return arr
}

// 三级菜单
const setMenuThree = (data, title, pid) => {
  if (!data.children) return false
  const children = data.children
  const arr = []
  for (let i = 0; i < children.length; i++) {
    const path = replacePath(children[i].path)
    if (path) {
      // 判断超管可见和非超管可见的页面
      if (!store.state.isAdmin) {
        if (!store.state._isAdmin) {
          if (onlyManagerRouter.indexOf(children[i].path) > -1) {
            continue
          }
        } else if (onlySuperRouter.indexOf(children[i].path) > -1) {
          continue
        }
      }
      arr.push({
        ...children[i],
        name: children[i].routeName,
        hidden: children[i].is_menu === 0,
        meta: {
          title: children[i].is_menu === 1 ? `${title} - ${children[i].title}` : children[i].title,
          // is_menu === 0 的页面无需单独控制权限 跟随父页面
          noPermission: children[i].is_menu === 0 ? !ids.includes(children[i].pid) : !ids.includes(children[i].id),
          auth: setAuth(children[i]),
          keepAlive: keepAlive.includes(path),
          backPath: children[i].backPath,
          pid
        },
        // 默认取配置中的component  没有配置则按路径获取vue文件
        component: resolve => require([`@/pages${children[i].component ? children[i].component : `${path}.vue`}`], resolve)
      })
    }
  }

  // 无权限页面
  arr.push({
    path: `${data.path}/noPermissions`,
    meta: {
      title: '无页面权限'
    },
    hidden: true,
    component: resolve => require(['@/pages/layout/NoAuthorized.vue'], resolve)
  })

  return arr
}

// 建立页面索引
const buildPageKey = (_routes) => {
  for (let i = 0; i < _routes.length; i++) {
    if (_routes[i].path.indexOf('/') >= 0) {
      PAGE_MAP.set(_routes[i].path, _routes[i].children)
    }
    if (_routes[i].children) {
      buildPageKey(_routes[i].children)
    }
  }
}

// 权限列表
const setAuth = (data) => {
  if (!data.children) return null
  const children = data.children
  const auth = {}
  for (let i = 0; i < children.length; i++) {
    auth[children[i].path] = true
  }
  return auth
}

// 路径去空
const replacePath = (path) => {
  return (path).replace(/[\r\n]/g, '')
}

// 重定向设置
const setRedirect = (data) => {
  if (!data.children || (data.children && data.children.length <= 0)) return
  let childpath = data.children[0].path

  // 非超管可见
  if (!store.state.isAdmin) {
    // 非管理
    if (!store.state._isAdmin) {
      for (let i = 0; i < data.children.length; i++) {
        if (onlyManagerRouter.indexOf(data.children[i].path) === -1) {
          childpath = data.children[i].path
          break
        }
      }
    } else {
      for (let i = 0; i < data.children.length; i++) {
        if (onlySuperRouter.indexOf(data.children[i].path) === -1) {
          childpath = data.children[i].path
          break
        }
      }
    }
  }

  return replacePath(childpath)
}

const store = {
  namespaced: true,

  state: {
    routes: {},
    asyncRoutes: {},
    wecomId: null,
    wecomUserInfo: {},
    userInfo: {},
    isAdmin: false, // 是否超管
    _isAdmin: false, // 是否管理员
    menu: {},
    path: '',
    pageMap: new Map(),
    rtype: null,
    hasCloudBean: 0
  },

  getters: {
    GET_MENU_ONE: state => {
      return state.asyncRoutes[state.wecomId].filter(item => !item.hidden)
    },
    GET_MENU_TWO: state => {
      if (state.path.path === '/login' || state.path.meta.isSystem) return []
      const path = state.path.path.split('/')[1]
      const ld = state.pageMap.get(`/${path}`) || []
      return ld.filter(item => !item.hidden && !(onlySuperRouter.indexOf(item.path) > (state.isAdmin ? 0 : -1)))
    },
    GET_TAB: (state, getters) => {
      const filterRoutePath = ['/client/task/users', '/client/task/rewards']
      const path = state.path.path.split('/')
      const M = path.slice(1, 3).join('/')
      const ld = state.pageMap.get(`/${M}`) || []
      return ld.filter(item => !item.hidden && !filterRoutePath.includes(item.path))
    },
    GET_TITLE: state => {
      const path = state.path.path.split('/')[1]
      const titleObj = {
        employees: '员工应用',
        client: '客户应用',
        customer: '社群应用',
        apps: '应用',
        enterprise: '企业应用',
        public: '应用'
      }
      return titleObj[path]
    },
    GET_USERINFO: state => {
      return state.wecomUserInfo
    },
    GET_ISADMIN: state => {
      return state.isAdmin
    },
    GET_IS_ADMIN: state => {
      return state._isAdmin
    },
    GET_SUPERMANAGER: state => {
      if (state.wecomUserInfo.role.role_id === 4) {
        return true
      } else {
        return false
      }
    },
    GET_MANAGER: state => {
      if (state.wecomUserInfo.role.role_id === 1) {
        return true
      } else {
        return false
      }
    },
    GET_PAGE: state => path => {
      return state.pageMap.has(path)
    }
  },

  mutations: {
    SET_PAGE_MAP: (state, val) => {
      state.pageMap = val
    },
    SET_ROUTES: (state, { id, asyncRoutes: routes }) => {
      state.asyncRoutes[`wecom_${id}`] = routes
      state.routes[`wecom_${id}`] = constantsRoutes.concat(routes)
    },

    SET_WECOMINFO: (state, { id, userInfo }) => {
      state.wecomUserInfo = userInfo
    },

    SET_CLOUDBEAN: (state, { userInfo }) => {
      state.hasCloudBean = userInfo.assets && userInfo.assets.bean > 0
    },

    SET_ISADMIN: (state, { userInfo }) => {
      state.isAdmin = userInfo.role.role_id === 4
      state._isAdmin = userInfo.role.role_id === 1
    },

    SET_INFO: (state, info) => {
      state.userInfo = info
    },

    SET_WECOM_ID: (state, id) => {
      state.wecomId = `wecom_${id}`
    },

    SET_PATH: (state, path) => {
      state.path = path
    },

    REMOVE_WECOM_USER: (state, wecomId) => {
      removeWecomUser(wecomId)
    },

    CLEAR_ALL: state => {
      state.routes = {}
      state.asyncRoutes = {}
      state.wecomId = null
      state.wecomUserInfo = {}
      state.isAdmin = false
      state.userInfo = {}
      state.menu = {}
      state.path = ''
    }
  },

  actions: {
    GenerateRoutes ({ commit }, id) {
      return new Promise(async (resolve) => {
        resetRouter()
        const userInfo = await getUserInfo(id)
        commit('SET_ISADMIN', { userInfo })

        const asyncRoutes = await generateAsyncRoutes(id)
        commit('SET_WECOM_ID', id)
        commit('SET_ROUTES', { id, asyncRoutes })
        commit('SET_PAGE_MAP', PAGE_MAP)
        commit('SET_WECOMINFO', { id, userInfo })
        localStorage.setItem('type', 'wecom')
        localStorage.setItem('companyId', id)
        resolve(asyncRoutes)
      })
    },

    async GenerateWecomInfo ({ commit }, { id, refresh }) {
      const userInfo = await getUserInfo(id, refresh)
      commit('SET_WECOMINFO', { id, userInfo })
      commit('SET_ISADMIN', { userInfo })
      commit('SET_CLOUDBEAN', { userInfo })
      return userInfo
    },

    async GenerateUserInfo ({ commit }) {
      const asyncUserInfo = await getAccountInfo()
      commit('SET_INFO', asyncUserInfo)
      return asyncUserInfo
    },

    async GenerateAccountToken ({ commit }) {
      const tokenType = localStorage.getItem('type')
      if (tokenType === 'wecom') {
        const accountToken = await getAccountToken()
        localStorage.setItem('token', accountToken)
        localStorage.setItem('type', 'account')
      } else {
        localStorage.setItem('accountToken', localStorage.getItem('token'))
        localStorage.setItem('type', 'account')
      }
    },

    // 重置该state
    Reset ({ commit }) {
      commit('CLEAR_ALL')
    },

    RemoveWecomUser ({ commit }, { wecomId }) {
      commit('REMOVE_WECOM_USER', wecomId)
    }
  }
}

export default store
