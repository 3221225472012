/* eslint-disable eqeqeq */
const isMobile = /Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(window.navigator.userAgent)
const Qiniu = {
  data () {
    return {
      imageOrigin: 'https://image.01lb.com.cn/',
      qiniuUrl: 'https://upload-z2.qiniup.com/'
    }
  },
  methods: {
    // 文件大小转换
    upload_getUploadFileSize (size, minSize) {
      size = size.toLowerCase()
      var g = size.indexOf('gb') > 0
      var m = size.indexOf('mb') > 0
      var k = size.indexOf('kb') > 0
      var s = parseFloat(size)
      s = isNaN(s) ? 0 : s
      minSize = minSize || 512000
      if (g) {
        return s ? s * 1024 * 1024 * 1024 : minSize
      };
      if (m) {
        return s ? s * 1024 * 1024 : minSize
      };
      if (k) {
        return s ? s * 1024 : minSize
      };
      return s || minSize
    },
    // 上传提示内容
    upload_uploadToastMessage (msg) {
      return setTimeout(() => {
        if (isMobile) {
          this.$toast(msg)
        } else {
          this.$message(msg)
        }
      })
    },
    // files 为文件或者文件数组
    // callback
    // ------- 当files为文件时，传递(path[文件路径], id[文件服务器id], name[原文件名称], extension[原文件后缀])
    // ------- 当files为文件数组时，传递(array[{path,id,name,extension}])
    // -------
    // term参数参考
    // types: [regexp, array, string] 例如[/^image\/*/, ['png', 'jpeg', 'jpg'], 'png'] 文件后缀字符串限制小写
    // typesTips: 自定义异常文案
    // size: [number, string, true] 例如：[57600, '500KB', true => 3MB]
    // sizeTips: 自定义异常文案
    // -------
    async qiniuUpload (files, callback, params, term, index, len, loading, arr = []) {
      if (!params) {
        params = {}
      }
      if (!term) {
        term = {}
      }
      let file = null
      if (this.util.type(files) == 'array') {
        if (!index) {
          index = 0
          len = files.length
        }
        file = files[index]
      } else if (this.util.type(files) == 'object') {
        file = files
      } else {
        return this.upload_uploadToastMessage('上传方法参数错误')
      }
      // 文件限制 ---- 开始
      // 判断文件类型
      if (term.types) {
        const names = file.name.split('.')
        if (this.util.type(term.types) == 'regexp') {
          // 正则
          if (!term.types.test(file.type)) {
            // 如果是多图上传，则提交上传成功之前所上传的文件，之后的就直接抛弃
            if (this.util.type(files) == 'array') {
              !!callback && callback(arr)
            }
            return this.upload_uploadToastMessage(term.typesTips || '上传的文件格式不正确')
          }
        } else if (this.util.type(term.types) == 'array') {
          // 数组
          if (!term.types.includes(names[names.length - 1].toLowerCase())) {
            // 如果是多图上传，则提交上传成功之前所上传的文件，之后的就直接抛弃
            if (this.util.type(files) == 'array') {
              !!callback && callback(arr)
            }
            return this.upload_uploadToastMessage(term.typesTips || '上传的文件格式不正确')
          }
        } else if (this.util.type(term.types) == 'string') {
          // 字符串
          if (term.types != names[names.length - 1].toLowerCase() || term.types != file.type) {
            // 如果是多图上传，则提交上传成功之前所上传的文件，之后的就直接抛弃
            if (this.util.type(files) == 'array') {
              !!callback && callback(arr)
            }
            return this.upload_uploadToastMessage(term.typesTips || '上传的文件格式不正确')
          }
        }
      }
      // 判断文件大小
      // size传递true时 默认使用3MB
      if (term.size != null) {
        let size = 3145728 // 默认值为3MB
        if (this.util.type(term.size) == 'number') {
          size = term.size
        } else if (this.util.type(term.size) == 'string') {
          // 如果传递的格式不是【['gb', 'mb', 'kb']，大小写不限】，则输出默认500KB大小
          size = this.upload_getUploadFileSize(term.size)
        }
        if (file.size > size) {
          // 如果是多图上传，则提交上传成功之前所上传的文件，之后的就直接抛弃
          if (this.util.type(files) == 'array') {
            !!callback && callback(arr)
          }
          return this.upload_uploadToastMessage(term.sizeTips || '上传的文件过大')
        }
      }
      if (!loading) {
        loading = this.$loading({
          lock: true,
          spinner: 'lyloading',
          background: 'hsla(0,0%,100%,.8)',
          text: '正在上传...'
        })
      }
      if (len) {
        loading.text = '上传中...' + (((index / len) * 100) | 0) + '%'
      }
      // 获取七牛云上传的token和key
      const res = await this.axios.get('getQiniuToken', {
        params: {
          ...params,
          file_name: file.name
        }
      })
      if (!res) {
        loading.close()
        return
      }
      // 组装七牛云需要的参数
      const formData = new FormData()
      formData.append('token', res.token)
      // formData.append('key', res.data.key);
      formData.append('file', file)
      const qinniuData = await this.axios.post(this.qiniuUrl, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        withCredentials: false // 用于跳过options链接
      })
      // 保存上传文件信息到服务器
      const ret = await this.axios.post('saveQiniuFile', qinniuData)
      if (!res) {
        loading.close()
        return this.upload_uploadToastMessage(this.util.offline)
      }
      if (len) {
        arr.push(ret.data)
        if (++index == len) {
          loading.close()
          !!callback && callback(arr)
          this.upload_uploadToastMessage({ message: '上传成功', type: 'success' })
        } else {
          this.qiniuUpload(files, callback, params, term, index, len, loading, arr)
        }
      } else {
        loading.close()
        !!callback && callback(ret.data.path, ret.data.id, ret.data.name, ret.data.extension)
        return this.upload_uploadToastMessage({ message: '上传成功', type: 'success' })
      }
    },
    // 方法同上
    // 返回参数为：
    // 一、[{
    //     path: '图片路径',
    //     name: '图片原名称',
    //     extension: '图片后缀'
    //     uuid: '七牛云给的动态id'
    // }]
    // 二、path[图片路径] uuid[七牛云给的动态id] name[图片原名称] extension[图片后缀]
    async qiniuUpload2 (files, callback, params, term, index, len, loading, arr = []) {
      if (!params) {
        params = {}
      }
      if (!term) {
        term = {}
      }
      let file = null
      if (this.util.type(files) == 'array') {
        if (!index) {
          index = 0
          len = files.length
        }
        file = files[index]
      } else if (this.util.type(files) == 'object') {
        file = files
      } else {
        return this.upload_uploadToastMessage('上传方法参数错误')
      }
      // 文件限制 ---- 开始
      // 判断文件类型
      if (term.types) {
        const names = file.name.split('.')
        if (this.util.type(term.types) == 'regexp') {
          // 正则
          if (!term.types.test(file.type)) {
            // 如果是多图上传，则提交上传成功之前所上传的文件，之后的就直接抛弃
            if (this.util.type(files) == 'array') {
              !!callback && callback(arr)
            }
            return this.upload_uploadToastMessage(term.typesTips || '上传的文件格式不正确')
          }
        } else if (this.util.type(term.types) == 'array') {
          // 数组
          if (!term.types.includes(names[names.length - 1].toLowerCase())) {
            // 如果是多图上传，则提交上传成功之前所上传的文件，之后的就直接抛弃
            if (this.util.type(files) == 'array') {
              !!callback && callback(arr)
            }
            return this.upload_uploadToastMessage(term.typesTips || '上传的文件格式不正确')
          }
        } else if (this.util.type(term.types) == 'string') {
          // 字符串
          if (term.types != names[names.length - 1].toLowerCase() || term.types != file.type) {
            // 如果是多图上传，则提交上传成功之前所上传的文件，之后的就直接抛弃
            if (this.util.type(files) == 'array') {
              !!callback && callback(arr)
            }
            return this.upload_uploadToastMessage(term.typesTips || '上传的文件格式不正确')
          }
        }
      }
      // 判断文件大小
      // size传递true时 默认使用3MB
      if (term.size != null) {
        let size = 3145728 // 默认值为3MB
        if (this.util.type(term.size) == 'number') {
          size = term.size
        } else if (this.util.type(term.size) == 'string') {
          // 如果传递的格式不是【['gb', 'mb', 'kb']，大小写不限】，则输出默认500KB大小
          size = this.upload_getUploadFileSize(term.size)
        }
        if (file.size > size) {
          // 如果是多图上传，则提交上传成功之前所上传的文件，之后的就直接抛弃
          if (this.util.type(files) == 'array') {
            !!callback && callback(arr)
          }

          this.upload_uploadToastMessage(term.sizeTips || '上传的文件过大')
          return 'error'
        }
      }
      if (!loading) {
        loading = this.$loading({
          lock: true,
          spinner: 'lyloading',
          background: 'hsla(0,0%,100%,.8)',
          text: '正在上传...'
        })
      }
      if (len) {
        loading.text = '上传中...' + (((index / len) * 100) | 0) + '%'
      }
      // 获取七牛云上传的token和key
      const res = await this.axios.get('getQiniuToken', {
        params: {
          ...params,
          file_name: file.name
        }
      })
      if (!res) {
        loading.close()
        return
      }
      // 组装七牛云需要的参数
      const formData = new FormData()
      formData.append('token', res.token)
      formData.append('key', res.upload)
      formData.append('file', file)
      try {
        const qinniuData = await this.axios.post(this.qiniuUrl, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          withCredentials: false // 用于跳过options链接
        })
        if (len) {
          arr.push({
            path: this.imageOrigin + qinniuData.key
          })
          if (++index == len) {
            loading.close()
            !!callback && callback(arr)
            this.upload_uploadToastMessage({ message: '上传成功', type: 'success' })
          } else {
            this.qiniuUpload2(files, callback, params, term, index, len, loading, arr)
          }
        } else {
          loading.close()
          !!callback && callback(this.imageOrigin + qinniuData.key)
          return this.upload_uploadToastMessage({ message: '上传成功', type: 'success' })
        }
      } catch (error) {
        loading.close()
        this.$message.error('上传图片失败')
      }
    }
  }
}

export default Qiniu
