// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./emoji.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".ly-emoji[data-v-22321b69] {\n  line-height: 1;\n}\n.emoji-list[data-v-22321b69] {\n  width: 420px;\n}\n.emoji-list .item[data-v-22321b69] {\n  display: inline-block;\n  width: 26px;\n  height: 24px;\n  margin: 0 1px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  cursor: pointer;\n  border-radius: 2px;\n}\n.emoji-list .item[data-v-22321b69]:hover {\n  background-color: #eaeaea;\n}", ""]);
// Exports
module.exports = exports;
