var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-setting" },
    [
      _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom-end",
            width: "224",
            trigger: "hover",
            "popper-class": "header-setting-panel"
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "reference" }, slot: "reference" },
            [
              _c("el-avatar", {
                attrs: {
                  size: 32,
                  src: _vm.userInfo.avatar_url || _vm.defaultAvatar
                }
              }),
              _vm._v(_vm._s(_vm.userInfo.nickname) + " ")
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "panel" },
            [
              _c(
                "div",
                [
                  _c("el-avatar", {
                    attrs: {
                      size: 48,
                      src: _vm.userInfo.avatar_url || _vm.defaultAvatar
                    }
                  }),
                  _c("div", { staticClass: "nickname" }, [
                    _vm._v(_vm._s(_vm.userInfo.nickname))
                  ]),
                  _vm.userInfo.wecom
                    ? _c("div", { staticClass: "wecom" }, [
                        _vm._v(" " + _vm._s(_vm.userInfo.wecom.corp_name) + " ")
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c("el-divider"),
              _c("div", [
                _vm.showCompany
                  ? _c(
                      "div",
                      {
                        staticClass: "item",
                        on: {
                          click: function($event) {
                            return _vm.$router.push("/company")
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "iconfont icon-company" }),
                        _vm._v("切换企业 ")
                      ]
                    )
                  : _vm._e(),
                _vm.showUser
                  ? _c(
                      "div",
                      {
                        staticClass: "item",
                        on: {
                          click: function($event) {
                            return _vm.$router.push("/user")
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "iconfont icon-user1" }),
                        _vm._v("个人中心 ")
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "logout", on: { click: _vm.handleLogout } },
                  [_vm._v("退出登录")]
                )
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }