var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-header" }, [
    _c(
      "div",
      { staticClass: "login-width flex" },
      [
        _c(
          "div",
          { staticClass: "flex" },
          [
            _c(
              "div",
              { staticClass: "flex flex-center" },
              [
                _c("img", {
                  staticStyle: { "margin-right": "24px" },
                  attrs: { src: require("@assets/svg/01sk.svg") }
                }),
                _vm.showWork
                  ? _c(
                      "el-link",
                      {
                        staticStyle: { "line-height": "0" },
                        attrs: {
                          href: "https://open.work.weixin.qq.com",
                          underline: false,
                          target: "_blank"
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@assets/svg/wechat_work.svg"),
                            referrerpolicy: "unsafe-url",
                            alt: "企业微信服务商"
                          }
                        })
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm.showCompany
              ? _c(
                  "el-dropdown",
                  { on: { command: _vm.fn_CompanyCommand } },
                  [
                    _c("span", { staticClass: "el-dropdown-link" }, [
                      _c("div", { staticClass: "login-header__user" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.companyName ? _vm.companyName : "") +
                            " "
                        ),
                        _c("i", {
                          staticClass: "el-icon-arrow-down el-icon--right"
                        })
                      ])
                    ]),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "company" } },
                          [_vm._v("选择企业")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _vm.showUserInfo
          ? _c("HeadSetting", { attrs: { userInfo: _vm.userInfo } })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }