/*
 * @Author: zhanln
 * @Date: 2021-06-18 15:12:26
 * @LastEditTime: 2021-09-30 14:45:08
 * @LastEditors: zhanln
 * @Description: 全局 api, 任何页面都可能使用
 */

const base = '/wecom'

export default {
  // 需购买应用列表
  appPurchaseList: base + '/appPurchase/getAppPurchaseList',
  // 应用购买列表
  appPackageList: base + '/appPurchase/packageList',
  // 上传图片
  uploadFile: base + '/asset/upload',
  // 获取url信息
  urlInfo: base + '/system/urlInfo',
  // 获取七牛token
  getQiniuToken: base + '/asset/qiniuToken',
  // 服务器时间
  getTime: base + '/sys/time',
  // 侧边栏配置信息
  menuInfo: base + '/menuInfo',
  // 标签列表
  wecomTags: base + '/wecomTags',
  // 标签列表
  wecomTagsV2: '/scrm-api/tag/group/list',
  // 部门列表
  wecomDepartments: base + '/wecomDepartments',
  // 部门列表(不校验)
  wecomDepartmentsNoauth: base + '/wecomDepartments/noauth',
  // 查询secret是否设置成功
  appSecretCheck: base + '/appSecretCheck',
  // 查询内部secret是否设置成功
  internalSecretCheck: base + '/internalSecretCheck',
  // 获取回调url
  getCallbackUrl: base + '/getCallbackUrl',
  // 保存第三步步骤
  callbackVerify: base + '/callbackVerify',
  // 上传验证文件
  uploadVerifyFile: base + '/uploadVerifyFile',
  // 完成配置
  finish: base + '/finish',
  // 获取上次配置信息
  configInfo: base + '/configInfo',
  // 外部应用
  appsInfo: base + '/apps',
  // 获取授权企微url
  authUrl: base + '/authUrl',
  // 获取注册企微推广url
  registerUrl: base + '/registerUrl',
  // 获取账号token
  getAccountToken: base + '/my/getAccountToken',
  // 查询注册是否成功
  registerCodeStatus: base + '/registerCodeStatus',
  // 配置自建二维码
  authAppQrcode: base + '/autoApp/qrcode',
  // 配置自建二维码获取状态
  authAppRes: base + '/autoApp/res',
  // 企业用户信息
  wecomUser: base + '/my/wecomUser',
  // 应用列表
  // apps: base + '/app/tree',
  apps: base + '/app/list',
  // 获取授权状态
  authStatus: base + '/autoApp/auth-status',
  // 自建应用配置信息
  appSetting: base + '/autoApp/setting-save'
}
