// 企业管理
export default [
  {
    title: '企业管理',
    id: 1008,
    path: '/enterprise',
    icon: ['enterprise.png', 'enterprise_active.png'],
    children: [
      {
        title: '企业管理',
        id: 100801,
        path: '/enterprise/management',
        badge: '',
        children: [
          {
            title: '财务管理',
            id: 10080101,
            path: '/enterprise/management/list',
            component: '/enterprise/finance/list.vue'
          },
          {
            title: '系统配置',
            id: 10080102,
            path: '/enterprise/management/config',
            component: '/enterprise/system/config.vue'
          }
        ]
      },

      {
        title: '账号管理',
        id: 100802,
        path: '/enterprise/employees',
        badge: '',
        children: [
          {
            title: '员工列表',
            id: 10080201,
            path: '/enterprise/employees/list',
            component: '/employees/management/list.vue'
          },
          {
            title: '聊天分析',
            id: 10080202,
            path: '/enterprise/employees/analysis',
            component: '/employees/management/analysis.vue'
          },
          {
            title: '权限管理',
            id: 10080203,
            path: '/enterprise/employees/auth',
            component: '/employees/management/auth.vue'
          },
          {
            title: '角色编辑',
            id: 10080204,
            path: '/enterprise/employees/editrole',
            component: '/employees/management/editrole.vue',
            backPath: '/enterprise/employees/auth',
            is_menu: 0,
            pid: 10080203
          },
          {
            title: '员工详情',
            id: 10080205,
            path: '/enterprise/employees/detail',
            component: '/employees/management/detail.vue',
            backPath: '/enterprise/employees/list',
            is_menu: 0,
            pid: 10080201
          }
        ]
      }
    ]
  }
]
