var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          staticClass: "emp-select",
          attrs: {
            "popper-class": "hide-select",
            "popper-append-to-body": false,
            placeholder: _vm.selected.length > 0 ? "" : _vm.placeholder
          },
          on: { focus: _vm.fn_showSelect },
          model: {
            value: _vm.selectValue,
            callback: function($$v) {
              _vm.selectValue = $$v
            },
            expression: "selectValue"
          }
        },
        [
          _vm.selected.length > 0
            ? _c(
                "template",
                { slot: "prefix" },
                [
                  _vm._l(_vm.selected.slice(0, _vm.showLength), function(
                    item,
                    index
                  ) {
                    return _c(
                      "span",
                      { key: item.id, staticClass: "emp-select__item" },
                      [
                        _c("img", {
                          staticClass: "avatar",
                          attrs: { src: item.avatar_url, alt: "" }
                        }),
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.name))
                        ]),
                        index !== _vm.selected.length - 1
                          ? _c("span", { staticClass: "emp-select__gap" }, [
                              _vm._v(",")
                            ])
                          : _vm._e()
                      ]
                    )
                  }),
                  _vm.selected.length - _vm.showLength > 0
                    ? _c("span", { staticClass: "emp-select__more" }, [
                        _vm._v(
                          "+" + _vm._s(_vm.selected.length - _vm.showLength)
                        )
                      ])
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ],
        2
      ),
      _c("ly-emp", {
        ref: "lyEmpSelectRef",
        attrs: {
          title: _vm.placeholder,
          checkListProp: _vm.selected,
          max: _vm.max,
          isReal: _vm.isReal,
          modal: _vm.modal,
          datafilter: _vm.datafilter
        },
        on: { getEmpId: _vm.fn_getEmpId }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }