/*
 * @Author: zhanln
 * @Date: 2021-11-02 10:03:09
 * @LastEditTime: 2021-12-02 16:18:15
 * @LastEditors: Please set LastEditors
 * @Description:
 */

export default {
  namespaced: true,

  state: {
    isEdit: false,
    isCopy: false,
    isEnd: false,
    editId: null,

    // 涨粉账号人数
    wecom_users: 0,
    welcom_msg: null,

    // 海报背景
    poster_img: null,
    avatar_status: null,
    avatar_sharp: null,
    nickname_status: null,
    nickname_size: null,
    nickname_color: null,
    nickname_align: null,

    // 海报配置
    avatar_x: null,
    avatar_y: null,
    avatar_width: null,
    avatar_height: null,
    nickname_x: null,
    nickname_y: null,
    nickname_offsetX: null,
    qrcode_x: null,
    qrcode_y: null,
    qrcode_width: null,
    qrcode_height: null,

    // 活动页设置
    show_title: null,
    rule: null,

    // 当前编辑的阶段
    current_step: 0,

    // 各阶开启状态
    status_0: null,
    status_1: null,
    status_2: null,

    // 各阶人数
    target_count_0: null,
    target_count_1: null,
    target_count_2: null,

    // 各阶奖品封面
    reward_cover_0: null,
    reward_cover_1: null,
    reward_cover_2: null,

    // 各阶奖品标题
    reward_title_0: null,
    reward_title_1: null,
    reward_title_2: null,

    // 各阶奖品简介
    reward_desc_0: null,
    reward_desc_1: null,
    reward_desc_2: null,

    // 真实库存展示
    total_0: null,
    total_1: null,
    total_2: null,

    // 已使用
    use_count_0: null,
    use_count_1: null,
    use_count_2: null,

    // 无库存规则
    open_stock_0: null,
    open_stock_1: null,
    open_stock_2: null,

    // 各阶虚拟人数
    unreal_0: null,
    unreal_1: null,
    unreal_2: null,

    // 奖品类型
    reward_type: null,
    diy_content: null,
    diy_img: null,
    code_rule: null,
    form_rule: null,

    // 奖品聚焦
    reward_focus: false,

    // 奖品提示
    reward_notice_text: null
  },

  getters: {
    GET_POSTER_INFO: state => {
      return {
        avatar_x: state.avatar_x,
        avatar_y: state.avatar_y,
        avatar_width: state.avatar_width,
        avatar_height: state.avatar_height,
        nickname_x: state.nickname_x,
        nickname_y: state.nickname_y,
        nickname_offsetX: state.nickname_offsetX,
        qrcode_x: state.qrcode_x,
        qrcode_y: state.qrcode_y,
        qrcode_width: state.qrcode_width,
        qrcode_height: state.qrcode_height
      }
    },
    GET_REWARD_INFO: state => obj => {
      return state[`${obj.type}_${obj.step}`]
    },
    GET_INFO: state => name => {
      return state[name]
    }
  },

  mutations: {
    // 设置为编辑
    SET_EDIT: state => {
      state.isEdit = true
    },

    // 设置为复制
    SET_COPY: state => {
      state.isCopy = true
    },

    // 海报背景，预览使用
    SET_POSTER_IMG: (state, n) => {
      state.poster_img = n
    },

    // 海报信息
    SET_POSTER_INFO: (state, n) => {
      state[n.type] = n.value
    },

    // 当前所在阶段
    SET_CURRENT_STEP: (state, n) => {
      state.current_step = n
    },

    // 各阶段信息
    SET_REWARD_INFO: (state, n) => {
      state[`${n.type}_${n.step}`] = n.val
    },

    // 存储数据
    SET_INFO: (state, n) => {
      state[n.type] = n.val
    },

    // 重置数据
    RESET: state => {
      state.isEdit = false
      state.isCopy = false
      state.isEnd = false
      state.editId = null

      // 涨粉账号人数
      state.wecom_users = 0
      state.welcom_msg = null

      // 海报背景
      state.poster_img = null
      state.avatar_status = null
      state.avatar_sharp = null
      state.nickname_status = null
      state.nickname_size = null
      state.nickname_color = null
      state.nickname_align = null

      // 海报配置
      state.avatar_x = null
      state.avatar_y = null
      state.avatar_width = null
      state.avatar_height = null
      state.nickname_x = null
      state.nickname_y = null
      state.nickname_offsetX = null
      state.qrcode_x = null
      state.qrcode_y = null
      state.qrcode_width = null
      state.qrcode_height = null

      // 活动页设置
      state.show_title = null
      state.rule = null

      // 当前编辑的阶段
      state.current_step = 0

      // 各阶开启状态
      state.status_0 = null
      state.status_1 = null
      state.status_2 = null

      // 各阶人数
      state.target_count_0 = null
      state.target_count_1 = null
      state.target_count_2 = null

      // 各阶奖品封面
      state.reward_cover_0 = null
      state.reward_cover_1 = null
      state.reward_cover_2 = null

      // 各阶奖品标题
      state.reward_title_0 = null
      state.reward_title_1 = null
      state.reward_title_2 = null

      // 各阶奖品简介
      state.reward_desc_0 = null
      state.reward_desc_1 = null
      state.reward_desc_2 = null

      // 真实库存展示
      state.total_0 = null
      state.total_1 = null
      state.total_2 = null

      // 已使用
      state.use_count_0 = null
      state.use_count_1 = null
      state.use_count_2 = null

      // 无库存规则
      state.open_stock_0 = null
      state.open_stock_1 = null
      state.open_stock_2 = null

      // 各阶虚拟人数
      state.unreal_0 = null
      state.unreal_1 = null
      state.unreal_2 = null

      // 奖品类型
      state.reward_type = null
      state.diy_content = null
      state.diy_img = null
      state.code_rule = null
      state.form_rule = null

      // 奖品聚焦状态
      state.reward_focus = false

      // 奖品推送
      state.reward_notice_text = null
    }
  }
}
