var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    _vm._b(
      {
        ref: "formRef",
        attrs: {
          model: _vm.form,
          rules: _vm.rules,
          size: "medium",
          "label-width": _vm.formObj.labelWidth || ""
        }
      },
      "el-form",
      _vm.formObj.setting,
      false
    ),
    [
      _vm._l(_vm.formObj.item, function(item, key) {
        return [
          (item.show
          ? item.show()
          : true)
            ? [
                item.slot
                  ? _vm._t(item.slot)
                  : _c(
                      "el-form-item",
                      {
                        key: key,
                        attrs: { label: item.label + "：", prop: item.prop }
                      },
                      [
                        item.labelTip
                          ? _c(
                              "template",
                              { slot: "label" },
                              [
                                _vm._v(_vm._s(item.label) + " "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      placement: "top",
                                      "popper-class": "q-tooltip"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content"
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.labelTip) + " "
                                        )
                                      ]
                                    ),
                                    _c("i", {
                                      staticClass:
                                        "iconfont icon-info-fill tool-info"
                                    })
                                  ]
                                ),
                                _vm._v("： ")
                              ],
                              1
                            )
                          : _vm._e(),
                        item.itemSlot
                          ? _vm._t(item.itemSlot)
                          : [
                              item.headSlot ? _vm._t(item.headSlot) : _vm._e(),
                              item.type === "text"
                                ? [
                                    _c(
                                      "el-input",
                                      _vm._b(
                                        {
                                          attrs: {
                                            placeholder:
                                              (item.attr &&
                                                item.attr.placeholder) ||
                                              "请输入" + item.label
                                          },
                                          model: {
                                            value: _vm.form[item.prop],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                item.prop,
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "form[item.prop]"
                                          }
                                        },
                                        "el-input",
                                        item.attr,
                                        false
                                      )
                                    )
                                  ]
                                : _vm._e(),
                              item.type === "inputNumber"
                                ? [
                                    _c(
                                      "el-input-number",
                                      _vm._b(
                                        {
                                          on: {
                                            change: function($event) {
                                              return _vm.fn_changeNumber(
                                                $event,
                                                item.prop,
                                                item.attr
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.form[item.prop],
                                            callback: function($$v) {
                                              _vm.$set(_vm.form, item.prop, $$v)
                                            },
                                            expression: "form[item.prop]"
                                          }
                                        },
                                        "el-input-number",
                                        item.attr,
                                        false
                                      )
                                    )
                                  ]
                                : _vm._e(),
                              item.type === "date"
                                ? [
                                    _c(
                                      "el-date-picker",
                                      _vm._b(
                                        {
                                          attrs: {
                                            type: item.dateType,
                                            "picker-options": item.pickerOptions
                                          },
                                          model: {
                                            value: _vm.form[item.prop],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                item.prop,
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "form[item.prop]"
                                          }
                                        },
                                        "el-date-picker",
                                        item.attr,
                                        false
                                      )
                                    )
                                  ]
                                : _vm._e(),
                              item.type === "upload"
                                ? [
                                    _c(
                                      "ly-upload",
                                      _vm._b(
                                        {
                                          attrs: {
                                            img: _vm.form[item.prop],
                                            tipText: item.label,
                                            accept:
                                              item.accept || ".png,.jpg,.jpeg"
                                          },
                                          on: {
                                            getImg: function($event) {
                                              return _vm.fn_setData(
                                                $event,
                                                item.prop
                                              )
                                            }
                                          }
                                        },
                                        "ly-upload",
                                        item.attr,
                                        false
                                      )
                                    )
                                  ]
                                : _vm._e(),
                              item.type === "emp"
                                ? [
                                    _c(
                                      "ly-emp-btn",
                                      _vm._b(
                                        {
                                          attrs: {
                                            btnText: "添加涨粉账号",
                                            max: 100,
                                            isReal: true,
                                            datafilter: true,
                                            checkList: _vm.form[item.prop]
                                          },
                                          on: {
                                            getEmpId: function($event) {
                                              return _vm.fn_setData(
                                                $event,
                                                item.prop
                                              )
                                            }
                                          }
                                        },
                                        "ly-emp-btn",
                                        item.attr,
                                        false
                                      )
                                    )
                                  ]
                                : _vm._e(),
                              item.type === "textEditor"
                                ? [
                                    _c(
                                      "ly-editor",
                                      _vm._b(
                                        {
                                          attrs: {
                                            id: item.prop,
                                            title: item.label,
                                            content: _vm.form[item.prop]
                                          },
                                          on: {
                                            getContent: function($event) {
                                              return _vm.fn_setData(
                                                $event,
                                                item.prop
                                              )
                                            },
                                            focus: function($event) {
                                              return _vm.fn_setFocus(true, item)
                                            },
                                            blur: function($event) {
                                              return _vm.fn_setFocus(
                                                false,
                                                item
                                              )
                                            }
                                          }
                                        },
                                        "ly-editor",
                                        item.attr,
                                        false
                                      )
                                    )
                                  ]
                                : _vm._e(),
                              item.type === "switch"
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex",
                                        staticStyle: { height: "36px" }
                                      },
                                      [
                                        _c(
                                          "el-switch",
                                          _vm._b(
                                            {
                                              attrs: {
                                                "inactive-value": item.key
                                                  ? item.key[0]
                                                  : 0,
                                                "active-value": item.key
                                                  ? item.key[1]
                                                  : 1
                                              },
                                              model: {
                                                value: _vm.form[item.prop],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "form[item.prop]"
                                              }
                                            },
                                            "el-switch",
                                            item.attr,
                                            false
                                          )
                                        ),
                                        item.switchTipSlot
                                          ? _vm._t(item.switchTipSlot)
                                          : _c(
                                              "span",
                                              {
                                                staticClass: "q-info",
                                                staticStyle: {
                                                  "line-height": "20px",
                                                  "margin-left": "10px"
                                                }
                                              },
                                              [_vm._v(_vm._s(item.switchTip))]
                                            )
                                      ],
                                      2
                                    )
                                  ]
                                : _vm._e(),
                              item.type === "radio"
                                ? [
                                    _c(
                                      "el-radio-group",
                                      _vm._b(
                                        {
                                          model: {
                                            value: _vm.form[item.prop],
                                            callback: function($$v) {
                                              _vm.$set(_vm.form, item.prop, $$v)
                                            },
                                            expression: "form[item.prop]"
                                          }
                                        },
                                        "el-radio-group",
                                        item.attr,
                                        false
                                      ),
                                      _vm._l(item.options, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "el-radio",
                                          {
                                            key: index,
                                            attrs: {
                                              label: item.value,
                                              disabled: item.disabled
                                            }
                                          },
                                          [_vm._v(_vm._s(item.name) + " ")]
                                        )
                                      }),
                                      1
                                    )
                                  ]
                                : _vm._e(),
                              item.bottomSlot
                                ? _vm._t(item.bottomSlot)
                                : _vm._e()
                            ]
                      ],
                      2
                    )
              ]
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }