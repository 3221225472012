var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wecom-tag" },
    [
      _c(
        "ly-dialog",
        {
          staticClass: "wecom-tag__dialog",
          attrs: {
            title: "选择标签",
            visible: _vm.visible,
            setting: _vm.dialogSetting
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            open: _vm.fn_open,
            confirm: _vm.fn_confirm,
            cancle: _vm.fn_cancle,
            close: _vm.fn_close
          },
          scopedSlots: _vm._u([
            {
              key: "footer-left",
              fn: function() {
                return [
                  _c(
                    "el-button",
                    {
                      staticClass: "add-group",
                      attrs: { type: "text", size: "medium" },
                      on: { click: _vm.fn_addGroup }
                    },
                    [
                      _c("i", {
                        staticClass: "iconfont icon-plus-fill",
                        staticStyle: {
                          "font-size": "24px",
                          "margin-right": "8px",
                          "vertical-align": "text-bottom"
                        }
                      }),
                      _vm._v("添加标签组")
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.pageLoading,
                  expression: "pageLoading"
                }
              ],
              staticClass: "wecom-tag__loading"
            },
            [
              _c(
                "div",
                { staticClass: "wecom-tag__search" },
                [
                  _c("el-input", {
                    staticClass: "wecom-tag__input",
                    attrs: {
                      size: "medium",
                      "suffix-icon": "iconfont icon-search",
                      clearable: "",
                      placeholder: "请输入要查找的标签组或标签",
                      maxlength: "255"
                    },
                    on: { clear: _vm.fn_resetList },
                    nativeOn: {
                      keyup: function($event) {
                        return _vm.fn_getFilter($event)
                      }
                    },
                    model: {
                      value: _vm.filterText,
                      callback: function($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "wecom-tag__list" },
                [
                  _vm.searchLen === 0
                    ? [
                        _c("div", { staticClass: "wecom-tag__empty" }, [
                          _c("img", {
                            staticClass: "mb-16",
                            attrs: {
                              src: require("@assets/svg/empty_tags.svg"),
                              alt: ""
                            }
                          }),
                          _c("span", [_vm._v("暂无标签~")])
                        ])
                      ]
                    : [
                        _vm._l(_vm.tagsData, function(g, gkey) {
                          return [
                            g.show
                              ? _c(
                                  "div",
                                  { key: gkey, staticClass: "wecom-tag__item" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "wecom-tag__group" },
                                      [_vm._v(_vm._s(g.tagGroupName))]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "wecom-tag__names" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "wecom-tag__name has-hover is-add",
                                            on: {
                                              click: function($event) {
                                                return _vm.fn_addTag(g, gkey)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "iconfont icon-plus"
                                            }),
                                            _vm._v("添加")
                                          ]
                                        ),
                                        _c("el-input", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: g.add,
                                              expression: "g.add"
                                            }
                                          ],
                                          ref: "group" + gkey + "Ref",
                                          refInFor: true,
                                          staticClass: "wecom-tag__add",
                                          attrs: {
                                            placeholder: "输入后回车",
                                            size: "medium",
                                            maxlength: "15"
                                          },
                                          on: {
                                            blur: function($event) {
                                              return _vm.fn_cancleAdd(g)
                                            }
                                          },
                                          nativeOn: {
                                            keyup: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.fn_addTagSync(g)
                                            }
                                          },
                                          model: {
                                            value: _vm.addTag,
                                            callback: function($$v) {
                                              _vm.addTag = $$v
                                            },
                                            expression: "addTag"
                                          }
                                        }),
                                        _vm._l(g.tagList, function(t, tkey) {
                                          return [
                                            t.show
                                              ? _c(
                                                  "span",
                                                  {
                                                    key: tkey,
                                                    staticClass:
                                                      "wecom-tag__name has-hover",
                                                    class: [
                                                      t.active || t.current
                                                        ? "active"
                                                        : ""
                                                    ],
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.fn_select(
                                                          gkey,
                                                          tkey
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(t.tagName))]
                                                )
                                              : _vm._e()
                                          ]
                                        })
                                      ],
                                      2
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        })
                      ]
                ],
                2
              )
            ]
          )
        ]
      ),
      _c("tag-edit", {
        ref: "tagEditRef",
        on: { confirm: _vm.fn_confirmGroup }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }