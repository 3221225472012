var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "main-container-inner" },
    [
      _c(
        "el-header",
        { staticClass: "main-header", attrs: { height: "48px" } },
        [
          _c(
            "el-row",
            {
              staticClass: "row-bg",
              attrs: { type: "flex", justify: "space-between" }
            },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-menu",
                    {
                      staticClass: "main-menu",
                      attrs: { mode: "horizontal", "default-active": "1" }
                    },
                    [
                      _c("el-menu-item", { attrs: { index: "1" } }, [
                        _vm._v("外部应用")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-main",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { "element-loading-text": "页面加载中" }
        },
        [
          _c("div", {
            staticClass: "main-apps",
            domProps: { innerHTML: _vm._s(_vm.appInfo.detail) }
          })
        ]
      ),
      _vm.appInfo.is_jump
        ? _c(
            "el-footer",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.fn_jumpUrl } },
                [_vm._v(_vm._s(_vm.appInfo.button_text))]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }