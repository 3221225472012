/*
 * @Author: zhan
 * @Date: 2022-08-05 11:50:38
 * @LastEditTime: 2022-08-23 11:38:13
 * @LastEditors: zhan
 */

export default {
  namespaced: true,

  state: {
    mode: 1,
    eType: 0, // 0:新建，1:编辑，2:复制, 3:草稿
    isEnd: false,

    // basic
    end_time: null,

    // reward,
    rewards: 1,
    target_count_0: null,
    target_count_1: null,
    target_count_2: null,
    reward_title_0: null,
    reward_title_1: null,
    reward_title_2: null,
    reward_cover_0: null,
    reward_cover_1: null,
    reward_cover_2: null,
    virtual_count_0: null,
    virtual_count_1: null,
    virtual_count_2: null,
    total_0: null,
    total_1: null,
    total_2: null,
    reward_type_0: '',
    reward_type_1: '',
    reward_type_2: '',
    reward_detail_0: '',
    reward_detail_1: '',
    reward_detail_2: '',
    reward_detail_img_0: '',
    reward_detail_img_1: '',
    reward_detail_img_2: '',
    reward_detail_code_0: '',
    reward_detail_code_1: '',
    reward_detail_code_2: '',
    show_stock_0: null,
    show_stock_1: null,
    show_stock_2: null,

    // detail focus
    current_step: 0,
    is_focus: false,

    // rule focus
    is_rule_focus: false,

    // way
    add_user: null,
    welcome_msg: '',

    // setting
    btn_title: '',
    cover_title: '',
    rule: '',

    // poster
    poster_url: null,
    avatar_status: null,
    avatar_sharp: null,
    nickname_status: null,
    nickname_size: null,
    nickname_color: null,
    nickname_align: null,
    avatar_x: null,
    avatar_y: null,
    avatar_width: null,
    avatar_height: null,
    nickname_x: null,
    nickname_y: null,
    nickname_offsetX: null,
    qrcode_x: null,
    qrcode_y: null,
    qrcode_width: null,
    qrcode_height: null,

    // share
    share_title: '',
    share_cover: ''

  },

  getters: {
    GET_REWARD: state => {
      const arr = []
      const rewards = state.rewards
      for (let i = 0; i < rewards; i++) {
        const count = state[`target_count_${i}`]
        const title = state[`reward_title_${i}`]
        const cover = state[`reward_cover_${i}`]
        const total = state[`total_${i}`]
        const show_stock = state[`show_stock_${i}`]
        const virtual_count = state[`virtual_count_${i}`]
        arr.push({
          count,
          title,
          cover,
          total,
          show_stock,
          virtual_count
        })
      }
      return arr
    },
    GET_DETAIL: state => {
      const type = state[`reward_type_${state.current_step}`]
      const img = state[`reward_detail_img_${state.current_step}`]
      let content = ''
      if (type === 1) {
        content = state[`reward_detail_${state.current_step}`]
      } else {
        content = state[`reward_detail_code_${state.current_step}`]
      }
      return {
        type, content, img
      }
    },
    GET_POSTER_INFO: state => {
      return {
        avatar_status: state.avatar_status,
        avatar_sharp: state.avatar_sharp,
        nickname_status: state.nickname_status,
        nickname_size: state.nickname_size,
        nickname_color: state.nickname_color,
        nickname_align: state.nickname_align,
        avatar_x: state.avatar_x,
        avatar_y: state.avatar_y,
        avatar_width: state.avatar_width,
        avatar_height: state.avatar_height,
        nickname_x: state.nickname_x,
        nickname_y: state.nickname_y,
        nickname_offsetX: state.nickname_offsetX,
        qrcode_x: state.qrcode_x,
        qrcode_y: state.qrcode_y,
        qrcode_width: state.qrcode_width,
        qrcode_height: state.qrcode_height
      }
    }
  },

  mutations: {
    // 存储数据
    SET_DATA: (state, n) => {
      state[n.name] = n.val
    },

    // 海报信息
    SET_POSTER_INFO: (state, n) => {
      state[n.type] = n.value
    },

    RESET_ITEM: (state, index) => {
      state[`target_count_${index}`] = null
      state[`virtual_count_${index}`] = null
    },

    RESET: (state) => {
      state.mode = 1
      state.eType = 0 // 0:新建，1:编辑，2:复制
      state.isEnd = false

      // basic
      state.end_time = null

      // reward,
      state.rewards = 1
      state.target_count_0 = null
      state.target_count_1 = null
      state.target_count_2 = null
      state.reward_title_0 = null
      state.reward_title_1 = null
      state.reward_title_2 = null
      state.reward_cover_0 = null
      state.reward_cover_1 = null
      state.reward_cover_2 = null
      state.virtual_count_0 = null
      state.virtual_count_1 = null
      state.virtual_count_2 = null
      state.total_0 = null
      state.total_1 = null
      state.total_2 = null
      state.reward_type_0 = ''
      state.reward_type_1 = ''
      state.reward_type_2 = ''
      state.reward_detail_0 = ''
      state.reward_detail_1 = ''
      state.reward_detail_2 = ''
      state.reward_detail_img_0 = ''
      state.reward_detail_img_1 = ''
      state.reward_detail_img_2 = ''
      state.reward_detail_code_0 = ''
      state.reward_detail_code_1 = ''
      state.reward_detail_code_2 = ''
      state.show_stock_0 = null
      state.show_stock_1 = null
      state.show_stock_2 = null

      // detail focus
      state.current_step = 0
      state.is_focus = false

      // rule focus
      state.is_rule_focus = false

      // way
      state.add_user = null
      state.welcome_msg = ''

      // setting
      state.btn_title = ''
      state.cover_title = ''
      state.rule = ''

      // poster
      state.poster_url = null
      state.avatar_status = null
      state.avatar_sharp = null
      state.nickname_status = null
      state.nickname_size = null
      state.nickname_color = null
      state.nickname_align = null
      state.avatar_x = null
      state.avatar_y = null
      state.avatar_width = null
      state.avatar_height = null
      state.nickname_x = null
      state.nickname_y = null
      state.nickname_offsetX = null
      state.qrcode_x = null
      state.qrcode_y = null
      state.qrcode_width = null
      state.qrcode_height = null

      // share
      state.share_title = ''
      state.share_cover = ''
    }
  }

}
