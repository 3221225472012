// 云端服务
export default [
  {
    title: '云端服务',
    id: 1009,
    path: '/cloudService',
    icon: ['cloud.png', 'cloud_active.png'],
    children: [
      {
        title: '云端账号',
        id: 100901,
        path: '/cloudService/account',
        badge: '',
        children: [
          {
            title: '云端列表',
            id: 10090101,
            path: '/cloudService/account/list',
            component: '/apps/cloud/list.vue'
          },
          {
            title: '扣费记录',
            id: 10090102,
            path: '/cloudService/account/records',
            component: '/apps/cloud/records.vue'
          }
        ]
      },
      {
        title: '群托管',
        id: 100902,
        path: '/cloudService/group',
        badge: '',
        children: [
          {
            title: '群托管',
            id: 10090201,
            path: '/cloudService/group/hosting',
            component: '/apps/groupManagement/list.vue'
          },
          {
            title: '群详情',
            id: 10090202,
            path: '/cloudService/group/detail',
            component: '/apps/groupManagement/detail.vue',
            backPath: '/cloudService/group/hosting',
            is_menu: 0,
            pid: 10090201
          }
        ]
      },
      {
        title: '无限制群发',
        id: 100903,
        path: '/cloudService/batchMessage',
        badge: '',
        children: [
          {
            title: '群发列表',
            id: 10090301,
            path: '/cloudService/batchMessage/list',
            component: '/apps/batchMessage/list.vue'
          },
          {
            title: '群发到群',
            id: 10090302,
            routeName: 'messageEdit',
            path: '/cloudService/batchMessage/edit',
            component: '/apps/batchMessage/edit.vue',
            backPath: '/cloudService/batchMessage/list',
            is_menu: 0,
            pid: 10090301
          }
        ]
      },
      {
        title: '批量发群公告',
        id: 100904,
        path: '/cloudService/batchAnnouncement',
        badge: '',
        children: [
          {
            title: '群公告列表',
            id: 10090401,
            path: '/cloudService/batchAnnouncement/list',
            component: '/apps/batchAnnouncement/list.vue'
          },
          {
            title: '批量发群公告',
            id: 10090402,
            routeName: 'announcementEdit',
            path: '/cloudService/batchAnnouncement/edit',
            component: '/apps/batchAnnouncement/edit.vue',
            backPath: '/cloudService/batchAnnouncement/list',
            is_menu: 0,
            pid: 10090401
          }
        ]
      },
      {
        title: '群数据',
        id: 100905,
        path: '/cloudService/groupData',
        badge: '',
        children: [
          {
            title: '群数据列表',
            id: 10090501,
            path: '/cloudService/groupData/list',
            component: '/apps/groupData/list.vue'
          },
          {
            title: '群明细数据',
            id: 10090502,
            path: '/cloudService/groupData/detail',
            component: '/apps/groupData/detail.vue',
            backPath: '/cloudService/groupData/list',
            is_menu: 0,
            pid: 10090501
          },
          {
            title: '群关键词统计',
            id: 10090503,
            path: '/cloudService/groupData/keyWords',
            component: '/apps/groupData/keyWords.vue'
          },
          {
            title: '群关键词详情',
            id: 10090504,
            path: '/cloudService/groupData/wordsDetail',
            component: '/apps/groupData/keyWordsDetail.vue',
            backPath: '/cloudService/groupData/keyWords',
            is_menu: 0,
            pid: 10090503
          }
        ]
      },
      {
        title: '企微客服聊天',
        id: 100906,
        path: '/cloudService/aggregate',
        badge: '',
        children: [
          {
            title: '企微客服',
            id: 10090601,
            path: '/cloudService/aggregate/list',
            component: '/apps/aggregate/list.vue'
          },
          {
            title: '聊天记录',
            id: 10090602,
            path: '/cloudService/aggregate/record',
            component: '/apps/aggregate/record.vue',
            pid: 10090601
          },
          {
            title: '客服工作台',
            id: 10090603,
            path: '/cloudService/aggregate/workbench',
            component: '/apps/aggregate/workbench.vue'
          },
          {
            title: '新建客服',
            id: 10090604,
            path: '/cloudService/aggregate/edit',
            component: '/apps/aggregate/edit.vue',
            is_menu: 0,
            backPath: '/cloudService/aggregate/list',
            pid: 10090601
          }
        ]
      }
    ]
  }
]
