/*
 * @Author: zhanln
 * @Date: 2021-05-11 14:10:46
 * @LastEditTime: 2022-03-09 15:44:04
 * @LastEditors: zhanln
 * @Description:
 */
export default {
  state: {
    firstTitle: '', // 自定义内页的返回标题（前部分）
    cusTitle: '', // 自定义内页的返回标题（后部分）
    asideWidth: '226px', // 二级菜单宽度
    depDatas: null, // 部门列表
    pageActions: null, // 权限列表
    isAdmin: 0, // 0、员工；2、超管
    appConfigShow: true,
    appConfig: 0, // 0无错误 1客户联系信息未配置 2客户联系信息配置异常 3自建应用未授权
    startLoading: 0, // 页面loading开始标识
    endLoading: 0, // 页面loading结束标识
    loading: 0 // 加载数据loading标识 大于0时loading
  },
  mutations: {
    ADD_LOADING (state, payload) {
      state.loading++
    },
    REMOVE_LOADING (state, payload) {
      state.loading--
    }
  },
  actions: {
    ADDLOADING ({ commit }, payload) {
      commit('ADD_LOADING')
    },
    REMOVELOADING ({ commit }, payload) {
      commit('REMOVE_LOADING')
    }
  }
}
