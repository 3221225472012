<!--
 * @Descripttion:
 * @version:
 * @Author: lw
 * @Date: 2021-05-19 11:48:01
 * @LastEditTime: 2021-09-07 15:16:28
-->
<template>
  <div id="tooltip">
    <el-tooltip
      ref="tlp"
      :content="content"
      effect="dark"
      :disabled="!tooltipFlag"
      :placement="placement"
      class="tooltip"
    >
      <span
        :class="line === 1 ? 'ellipsis-1' : 'ellipsis'"
        :style="line !== 1 ? { '-webkit-line-clamp': line} : ''"
        @mouseenter="visibilityChange($event)"
        >{{ content }}
      </span>
    </el-tooltip>
    <span class="contrast">{{ content }}</span>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: ''
    },
    placement: {
      type: String,
      default: 'top'
    },
    line: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      disabledTip: false,
      tooltipFlag: false
    }
  },
  methods: {
    // tooltip的可控
    visibilityChange (event) {
      const ev = event.target
      const ev_height = ev.offsetHeight // 文本的实际高度
      const content_height = ev.nextSibling.clientHeight // 文本容器高度
      if (content_height > ev_height) {
        // 文本容器高度 > 文本高度 => 内容溢出
        this.tooltipFlag = true
      } else {
        // 否则为不溢出
        this.tooltipFlag = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
#tooltip {
  display: flex;
  position: relative;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.ellipsis1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.contrast {
  position: absolute;
  visibility: hidden;
  top: 0;
  left: 0;
}
</style>
