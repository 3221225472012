var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "layout-container",
      attrs: {
        "element-loading-text": "页面加载中",
        "element-loading-spinner": "lyloading",
        "element-loading-background": "hsla(0,0%,100%,.8)"
      }
    },
    [
      _c("global-tip", { on: { handleClick: _vm.fn_config } }),
      _c(
        "el-header",
        { staticClass: "layout-header", attrs: { height: "54px" } },
        [
          _c("div", { staticClass: "temp flex", staticStyle: { flex: "1" } }, [
            _c("img", {
              staticClass: "logo",
              attrs: { src: require("@assets/svg/01sk.svg"), alt: "logo" }
            }),
            _c("div", { staticClass: "main-tab flex" }, [
              _c(
                "div",
                {
                  class: [
                    "main-tab__item",
                    _vm.rtype === "private" ? "active" : ""
                  ],
                  on: {
                    click: function($event) {
                      return _vm.changeRouteType("private")
                    }
                  }
                },
                [_vm._v(" 私域工作台 ")]
              )
            ])
          ]),
          _c("div", { staticClass: "helper" }, [
            _c("i", { staticClass: "bg iconfont icon-help" }),
            _c(
              "a",
              {
                attrs: {
                  href:
                    "https://www.yuque.com/books/share/487e7162-7d33-4079-800b-f78ea891e1fd",
                  target: "_blank"
                }
              },
              [_vm._v("帮助中心")]
            )
          ]),
          _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom-end",
                width: "200",
                trigger: "hover",
                "append-to-body": false
              }
            },
            [
              _c("div", { staticClass: "customer-content" }, [
                _c("div", { staticClass: "title flex" }, [
                  _c("img", {
                    attrs: { src: require("@assets/svg/wc_work_ic.svg") }
                  }),
                  _vm._v("专属客服 ")
                ]),
                _c("div", { staticClass: "qr" }, [
                  _c("img", {
                    attrs: {
                      src: require("@assets/images/contact_me_qr.png"),
                      alt: "",
                      srcset: ""
                    }
                  }),
                  _c("div", [_vm._v("微信扫码添加客服")])
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "customer",
                  attrs: { slot: "reference" },
                  slot: "reference"
                },
                [
                  _c("i", {
                    staticClass: "bg iconfont icon-a-customerservice"
                  }),
                  _vm._v("联系客服 ")
                ]
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "layout-header_right" },
            [
              (_vm.isSuperManager || _vm.isManager) && _vm.hasProvider
                ? _c(
                    "el-button",
                    {
                      staticClass: "btn-service",
                      staticStyle: { "font-size": "14px" },
                      attrs: { size: "small", round: "" },
                      on: { click: _vm.fn_jumpToService }
                    },
                    [
                      _c("span", { staticClass: "flex" }, [
                        _c("i", { staticClass: "iconfont icon-Switch" }),
                        _vm._v("服务商后台")
                      ])
                    ]
                  )
                : _vm._e(),
              _c("header-setting", { attrs: { userInfo: _vm.userInfo } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-container",
        { staticClass: "bottom-area" },
        [
          _c("side-bar"),
          _c(
            "el-container",
            { staticClass: "right-area" },
            [_c("router-view")],
            1
          )
        ],
        1
      ),
      _c("setup", { ref: "setupRef", on: { close: _vm.fn_getAuthStatus } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }