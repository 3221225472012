<!--
 * @Author: zhanln
 * @Date: 2021-09-18 20:15:53
 * @LastEditTime: 2021-12-09 15:24:07
 * @LastEditors: Please set LastEditors
 * @Description: 标签选择器2.0
-->

<template>
  <div class="wecom-tag">
    <!-- 弹框，标签列表 -->
    <ly-dialog
      title="选择标签"
      class="wecom-tag__dialog"
      :visible.sync="visible"
      :setting="dialogSetting"
      @open="fn_open"
      @confirm="fn_confirm"
      @cancle="fn_cancle"
      @close="fn_close"
    >
      <!-- 搜索 -->
      <div class="wecom-tag__loading" v-loading="pageLoading">
        <div class="wecom-tag__search">
          <el-input
            v-model="filterText"
            size="medium"
            suffix-icon="iconfont icon-search"
            clearable
            class="wecom-tag__input"
            placeholder="请输入要查找的标签组或标签"
            maxlength="255"
            @keyup.native="fn_getFilter"
            @clear="fn_resetList"
          >
          </el-input>
        </div>

        <!-- 列表 -->
        <div class="wecom-tag__list">
          <!-- 搜索为空 -->
          <template v-if="searchLen === 0">
            <div class="wecom-tag__empty">
              <img src="~@assets/svg/empty_tags.svg" class="mb-16" alt="" />
              <span>暂无标签~</span>
            </div>
          </template>
          <template v-else>
            <template v-for="(g, gkey) in tagsData">
              <div class="wecom-tag__item" :key="gkey" v-if="g.show">
                <div class="wecom-tag__group">{{ g.tagGroupName }}</div>
                <div class="wecom-tag__names">
                  <span
                    class="wecom-tag__name has-hover is-add"
                    @click="fn_addTag(g, gkey)"
                    ><i class="iconfont icon-plus"></i>添加</span
                  >
                  <el-input
                    v-show="g.add"
                    :ref="`group${gkey}Ref`"
                    v-model="addTag"
                    placeholder="输入后回车"
                    size="medium"
                    maxlength="15"
                    class="wecom-tag__add"
                    @blur="fn_cancleAdd(g)"
                    @keyup.enter.native="fn_addTagSync(g)"
                  ></el-input>
                  <template v-for="(t, tkey) in g.tagList">
                    <span
                      class="wecom-tag__name has-hover"
                      :class="[t.active || t.current ? 'active' : '']"
                      :key="tkey"
                      v-if="t.show"
                      @click="fn_select(gkey, tkey)"
                      >{{ t.tagName }}</span
                    >
                  </template>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>

      <template #footer-left>
        <el-button
          type="text"
          size="medium"
          @click="fn_addGroup"
          class="add-group"
          ><i
            class="iconfont icon-plus-fill"
            style="
              font-size: 24px;
              margin-right: 8px;
              vertical-align: text-bottom;
            "
          ></i
          >添加标签组</el-button
        >
      </template>
    </ly-dialog>

    <!-- 添加标签组 -->
    <tag-edit ref="tagEditRef" @confirm="fn_confirmGroup"></tag-edit>
  </div>
</template>

<script>
import lyDialog from '@components/global/lyDialog'
import tagEdit from '@components/wecomTags/edit'
import { tagApi } from '@components/wecomTags/http'
export default {
  name: 'tagSelect',

  props: {
    checked: {
      type: String,
      default: ''
    }
  },

  components: {
    lyDialog,
    tagEdit
  },

  data () {
    return {
      COMM_HTTP: tagApi,
      pageLoading: true,
      visible: false,
      dialogSetting: {
        'close-on-click-modal': false,
        'close-on-press-escape': false,
        'append-to-body': true
      },
      filterText: '',
      tagsData: [],
      searchLen: 0,
      addTag: '',
      newTag: {},
      addLoading: false,
      checkIds: new Set([])
    }
  },

  methods: {

    // 获取标签列表
    fn_open () {
      if (this.checked) {
        this.checked.split(',').map(item => {
          this.checkIds.add(+item)
        })
      }
      this.getTagsSync()
    },

    // 获取标签列表
    async getTagsSync (type) {
      const data = await this.COMM_HTTP.reqQuery()

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      this.searchLen = data.length
      this.tagsData = this.fn_initTags(data)
      this.pageLoading = false

      if (type) {
        this.fn_getFilter(type)
      }
    },

    // 数据显示处理
    fn_initTags (data) {
      return data.map(group => {
        group.show = true
        group.add = false
        group.tagList = group.tagList.map(item => {
          if (this.newTag[group.tagGroupId] && this.newTag[group.tagGroupId].name.indexOf(item.tagName) > -1) {
            item.current = true
            this.checkIds.add(item.tagId)
          } else {
            item.current = item.current || false
          }

          item.show = true
          item.active = this.checkIds.has(item.tagId)
          return item
        })
        return group
      })
    },

    // 模糊查询
    fn_getFilter (type = false) {
      if (!this.tagsData) return false

      const txt = this.filterText

      if (!txt) {
        this.fn_resetList()
        return false
      }

      if (!type) {
        this.newTag = {}
      }

      this.searchLen = 0

      for (let i = 0; i < this.tagsData.length; i++) {
        const ITEM = this.tagsData[i]
        const GROUPID = ITEM.tagGroupId
        const GROUPNAME = ITEM.tagGroupName
        if (GROUPNAME.indexOf(txt) > -1) {
          ITEM.show = true
          ITEM.tagList = ITEM.tagList.map(item => {
            item.show = true
            return item
          })
        } else {
          ITEM.show = false
          let len = 0
          for (let j = 0; j < ITEM.tagList.length; j++) {
            const TAG = ITEM.tagList[j]
            const NAME = TAG.tagName

            if (this.newTag[GROUPID] && this.newTag[GROUPID].name.indexOf(NAME) > -1) {
              len++
              TAG.show = true
            } else {
              if (NAME.indexOf(txt) > -1 || !txt) {
                len++
                TAG.show = true
              } else {
                TAG.show = false
              }
            }
          }

          ITEM.show = len > 0
        }

        if (ITEM.show) {
          this.searchLen++
        }
      }
    },

    // 选中
    fn_select (gkey, tkey) {
      const ITEM = this.tagsData[gkey].tagList[tkey]
      if (ITEM.active) {
        ITEM.active = false
        ITEM.current = false
      } else {
        ITEM.current = !ITEM.current
      }

      if (ITEM.current || this.active) {
        this.checkIds.add(ITEM.tagId)
      } else {
        this.checkIds.delete(ITEM.tagId)
      }
    },

    // 确定
    fn_confirm () {
      let selected = []
      for (let i = 0; i < this.tagsData.length; i++) {
        const _item = this.tagsData[i].tagList.filter(item => (item.active || item.current))
        if (_item.length > 0) {
          selected.push(_item)
        }
      }
      selected = selected.flat()
      this.$parent.tagList = this.tagsData
      this.$emit('getSelect', selected)
      this.visible = false
    },

    // 取消
    fn_cancle () {
      this.visible = false
    },

    // 关闭弹框
    fn_close () {
      this.filterText = ''
      this.tagsData = []
      this.checkIds.clear()
      this.pageLoading = true
      this.addLoading = false
    },

    // 重置搜索
    fn_resetList () {
      this.filterText = ''
      this.tagsData = this.fn_initTags(this.tagsData)
      this.searchLen = this.tagsData.length
      this.newTag = {}
    },

    // 添加标签按钮监听
    fn_addTag (g, key) {
      g.add = true
      this.$nextTick(() => {
        this.$refs[`group${key}Ref`][0].focus()
      })
    },

    // 添加标签
    async fn_addTagSync (g) {
      if (this.addLoading) {
        return false
      }

      this.addLoading = true

      const TAG = this.addTag
      const repeat = g.tagList.filter(item => item.tagName === TAG).length > 0
      if (repeat) {
        this.addLoading = false
        this.fn_lyMsg('info', '标签名称不可重复！')
        return false
      }

      const data = await this.COMM_HTTP.addTag({
        tagGroupId: g.tagGroupId,
        tagGroupVersion: g.tagGroupVersion,
        tagName: TAG
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.addLoading = false

        if (data.code === 1001005008) {
          this.fn_lyMsg('info', '数据存在冲突，请重新打开标签选择器操作')
        } else {
          this.fn_lyMsg('info', data.msg)
        }
        return false
      }

      this.fn_setNewTags(g.tagGroupId, TAG)

      this.getTagsSync(true)
      this.fn_lyMsg('success', '创建标签成功！')
      this.addLoading = false
    },

    // 显示新添加的标签
    fn_setNewTags (id, tag) {
      const newTag = this.newTag
      if (!newTag[id]) {
        newTag[id] = {
          name: []
        }
      }
      newTag[id].name.push(tag)
    },

    // 取消选中
    fn_removeNewTags (gid, tag) {
      const newTag = this.newTag
      newTag[gid].name.splice(newTag[gid].name.indexOf(tag), 1)
    },

    // 取消添加
    fn_cancleAdd (g) {
      g.add = false
      this.addTag = ''
    },

    // 添加标签组
    fn_addGroup () {
      this.$refs.tagEditRef.visible = true
    },

    // 添加标签组回调
    fn_confirmGroup () {
      this.getTagsSync()
      this.filterText = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";
.wecom-tag {
  line-height: 1.6;
  &__dialog {
    ::v-deep {
      .ly-dialog__body {
        min-height: auto;
        max-height: initial;
        overflow: hidden;
      }
    }
  }

  &__input {
    ::v-deep {
      .el-input__suffix {
        color: #d6d6d6;
      }
      .el-input__validateIcon {
        display: none !important;
      }
    }
  }

  &__list {
    height: calc(300px - 36px);
    overflow: auto;
    margin-top: 16px;
  }

  &__item {
    & + & {
      margin-top: 16px;
    }
  }

  &__group {
    color: #7a8599;
  }

  &__names {
    display: flex;
    flex-wrap: wrap;
  }

  &__name {
    height: 32px;
    border-radius: 32px;
    padding: 0 10px;
    display: inline-block;
    line-height: 32px;
    background-color: #f7f8fc;
    color: #212b36;
    margin: 12px 12px 0 0;
    font-size: 14px;
    box-sizing: border-box;
    transition: 0.15s linear;

    &.is-add {
      background-color: #fff;
      color: $--color-primary;
      border: 1px solid #dfe1e8;
      box-sizing: border-box;
      .icon-plus {
        font-size: 14px;
      }
      i {
        margin-right: 4px;
      }
    }

    &.has-hover {
      &:hover {
        cursor: pointer;
        background-color: #ebf3ff;
        color: $--color-primary;
      }
      &.active {
        cursor: pointer;
        background-color: #2b7cff;
        color: #fff;
      }
    }
  }

  &__add {
    width: 162px;
    margin: 12px 12px 0 0;

    ::v-deep .el-input__inner {
      height: 32px;
      line-height: 32p;
    }
  }

  &__empty {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $--color-text-secondary;
  }

  &__selected {
    padding: 16px 20px;
    background-color: #F7F8FC;
    border-radius: 4px;

    &-list {
      span {
        background-color: #fff;
        padding-right: 8px;
      }

      i {
        width: 16px;
        height: 16px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-left: 4px;
        color: #909399;

        &:hover {
          cursor: pointer;
          color: #fff;
          background-color: #909399;
        }
      }
    }
  }
}
.add-group{
  float: left;
  padding: 5px 0;
  ::v-deep{
    span{
      display: flex;
      align-items: center;
    }
  }
}
</style>
