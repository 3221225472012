var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "tooltip" } },
    [
      _c(
        "el-tooltip",
        {
          ref: "tlp",
          staticClass: "tooltip",
          attrs: {
            content: _vm.content,
            effect: "dark",
            disabled: !_vm.tooltipFlag,
            placement: _vm.placement
          }
        },
        [
          _c(
            "span",
            {
              class: _vm.line === 1 ? "ellipsis-1" : "ellipsis",
              style: _vm.line !== 1 ? { "-webkit-line-clamp": _vm.line } : "",
              on: {
                mouseenter: function($event) {
                  return _vm.visibilityChange($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.content) + " ")]
          )
        ]
      ),
      _c("span", { staticClass: "contrast" }, [_vm._v(_vm._s(_vm.content))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }