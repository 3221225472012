/*
 * @Author: zhanln
 * @Date: 2022-03-21 18:09:10
 * @LastEditTime: 2022-06-01 14:13:32
 * @LastEditors: zhan
 * @Description:
 */
// id规则：
//   例如10010101 由平台（两位）-模块（两位）-应用（两位）-功能组成（两位）
//   10为企微 所以该id表示 企微-营销玩法-任务宝-活动列表
// icon：[Array类型]在模块级别配置 第一个为默认图片  第二个为选中
// badge：应用角标 1为NEW 2为公测 可自定义新增
// routeName：路由名
// is_menu：是否是三级菜单，不设置或者不为0，将显示在顶部（比如任务宝-参与用户，is_menu设置为0）

import marketing from './marketing'
import attract from './attract'
import material from './material'
import enterpriseWeChatNumber from './enterpriseWeChatNumber'
import enterpriseWeChatGroup from './enterpriseWeChatGroup'
import ECommerce from './ECommerce'
import dataAnalysis from './dataAnalysis'
import enterprise from './enterprise'
import cloud from './cloud'
import live from './live'
import liveSetting from './liveSetting'

export default [
  ...marketing,
  ...attract,
  ...material,
  ...enterpriseWeChatNumber,
  ...enterpriseWeChatGroup,
  ...ECommerce,
  ...dataAnalysis,
  ...enterprise,
  ...cloud,
  ...live,
  ...liveSetting
]
