<!--
  * h5模拟组件
-->
<template>
  <div class="mobimodel">
    <!-- 左侧按钮组 -->
    <div class="mobimodel-side left"></div>
    <!-- 右侧按钮 -->
    <div class="mobimodel-side right"></div>

    <!-- 主视窗 -->
    <div class="mobimodel-view">
      <!-- 刘海栏 -->
      <div class="mobimodel-toolbar">
        <div class="item">{{ $day().format("HH:mm") }}</div>
        <div class="bangs"></div>
        <div class="item">
          <i class="iconfont icon-a-CellularConnection"></i>
          <i class="iconfont icon-Wifi"></i>
          <i class="iconfont icon-Battery"></i>
        </div>
      </div>

      <!-- 导航栏 -->
      <div class="mobimodel-title">
        <div class="mobimodel-title__left">
          <i :class="['iconfont', backIcon]"></i>
        </div>
        <div class="mobimodel-title__center">
          <div class="mobimodel-title__title">{{ title }}</div>
          <div class="mobimodel-title__sub">{{ sub }}</div>
        </div>
        <div class="mobimodel-title__right"><i class="el-icon-more"></i></div>
      </div>

      <!-- 内容 -->
      <el-scrollbar class="mobimodel-content" :style="{
        height: viewHeight,
      }">
        <!-- 添加好友官方提示 -->
        <div class="mobimodel-welmsg" v-if="showWel">
          您已添加了张三，现在可以开始聊天了。<br />
          对方为企业微信用户，<b>了解更多</b>。
        </div>

        <slot>
          <!-- 消息气泡 -->
          <template v-if="msgList.length > 0">
            <div class="mobimodel-content__item" v-for="(item, index) in msgList" :key="index">
              <div class="mobimodel-content__avatar">
                <img src="~@assets/images/avatar.jpg" alt="" />
              </div>

              <div class="mobimodel-content__pop" :class="[item.type === 'image' ? 'lh-1' : '']">
                <!-- 文本 -->
                <el-input type="textarea" v-model="item.content" autosize resize="none" readonly ref="refRule"
                  v-if="item.type === 'text'" class="mobimodel-content__pop--text"></el-input>

                <!-- 图片 -->
                <template v-if="item.type === 'image'">
                  <!-- 如果是海报，需要显示缺省 -->
                  <template v-if="item.isPoster">
                    <img :src="item.image" alt="" v-if="item.image" class="mobimodel-content__pop--image is-poster" />

                    <div class="mobimodel-content__pop--poster" v-else>
                      <img src="~@assets/images/poster-default.png" alt="" />
                      <div class="text">{{ item.defaultText }}</div>
                    </div>
                  </template>

                  <img :src="item.image" alt="" v-else class="mobimodel-content__pop--image" />
                </template>

                <!-- 链接 -->
                <div class="mobimodel-content__pop--link" v-if="item.type === 'link'">
                  <div class="title ellipsis2">{{ item.title }}</div>
                  <div class="flex flex-between flex-center">
                    <div class="desc ellipsis3">{{ item.desc }}</div>
                    <div class="cover">
                      <img :src="item.cover" alt="" v-if="item.cover" />
                      <img src="https://image.01lb.com.cn//uploads/wecom/21/1009/1633767898o4UJPl8q.png" alt=""
                        v-else />
                    </div>
                  </div>
                </div>

                <!-- 小程序 -->
                <div class="mobimodel-content__pop--applet" v-if="item.type === 'applet'">
                  <div class="name">
                    <div class="logo" v-if="item.logo"><img :src="item.logo" alt=""></div>
                    {{ item.name }}
                  </div>
                  <div class="title">{{ item.title || item.name }}</div>
                  <div class="cover">
                    <img :src="item.cover" alt="" />
                  </div>
                  <div class="sys"><span>S</span>小程序</div>
                </div>
              </div>
            </div>
          </template>
        </slot>
      </el-scrollbar>

      <!-- 底部占位，防止因为圆角看不全内容，可控 -->
      <div class="mobimodel-footer" v-if="hasFooter"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mobileModel',

  props: {
    backIcon: {
      type: String,
      default: 'el-icon-arrow-left'
    },
    title: {
      type: String,
      default: '张三'
    },
    sub: {
      type: String,
      default: '零一裂变（深圳）科技有限公司'
    },
    showWel: {
      type: Boolean,
      default: true
    },
    hasFooter: {
      type: Boolean,
      default: true
    },
    msgList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  computed: {
    viewHeight () {
      const titleH = (this.title ? 37 : 25) + 7
      const footerH = this.hasFooter ? 40 : 0
      const otherH = titleH + footerH + 22
      return `calc(100% - ${otherH}px)`
    }
  },

  data () {
    return {
      // 消息传入示例 msgList
      msgListBase: [{
        type: 'text',
        content: 'text'
      }, {
        type: 'image',
        image: 'https://image.01lb.com.cn//uploads/wecom/21/1127/1638002424Fqe9kOUU.png'
      }, {
        type: 'image',
        image: 'https://image.lingyiliebian.com/1609419290%E7%8A%B6%E6%80%81.png'
      }, {
        type: 'image',
        isPoster: true,
        defaultText: '海报图片',
        image: ''
      }, {
        type: 'link',
        title: '与我一起领取奖品👇',
        desc: '点击链接，领取福利',
        cover: 'https://image.01lb.com.cn//uploads/wecom/21/0820/16294310504J3kf6dh.png'
      }, {
        type: 'applet',
        name: '',
        title: '与我一起领取奖品👇',
        cover: 'https://image.01lb.com.cn//uploads/wecom/21/1129/1638179096FM8D3lcQ.png'
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/utils.scss";
$--mobile-gray: #F0F1F8;
$--mobile-border-color: #F7F8FC;
$--mobile-btn-color: #F7F8FC;

.mobimodel {
  width: 332px;
  height: 666px;
  border: 5.5px solid $--mobile-border-color;
  border-radius: 49px;
  box-sizing: border-box;
  position: relative;
  padding: 7px;

  &-side {
    position: absolute;
    width: 4px;
    height: 22px;
    border-radius: 2px;
    background-color: $--mobile-btn-color;
    border-right: 1px solid #F7F8FC;

    &.left {
      left: -11px;
      top: 75px;

      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 45px;
        width: 4px;
        height: 41px;
        border-radius: 2px;
        background-color: $--mobile-btn-color;
        border-right: 1px solid #F7F8FC;
      }

      &::after {
        position: absolute;
        content: "";
        left: 0;
        top: 98px;
        width: 4px;
        height: 42px;
        border-radius: 2px;
        background-color: $--mobile-btn-color;
        border-right: 1px solid #F7F8FC;
      }
    }

    &.right {
      top: 135px;
      right: -10px;
      height: 68px;
      border-left: 1px solid #F7F8FC;
      border-right: none;
    }
  }

  &-view {
    border-radius: 35px;
    height: 100%;
    background-color: $--mobile-gray;
    overflow: hidden;
  }

  &-toolbar {
    display: flex;
    position: relative;
    justify-content: space-between;
    font-weight: 600;

    .item {
      width: 85px;
      height: 22px;
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }

    .bangs {
      position: absolute;
      width: 105px;
      height: 22px;
      background-color: #fff;
      left: 50%;
      top: 0;
      transform: translateX(-50%);

      &::before {
        content: "";
        display: block;
        width: 35px;
        height: 22px;
        position: absolute;
        transform: skewX(16deg);
        background: #fff;
        border-bottom-left-radius: 12px;
        left: -25px;
        top: 0;
      }

      &::after {
        content: "";
        display: block;
        width: 35px;
        height: 22px;
        position: absolute;
        transform: skewX(-16deg);
        background: #fff;
        border-bottom-right-radius: 12px;
        top: 0;
        right: -25px;
      }
    }

    &,
    i {
      font-size: 12px;
    }
  }

  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 24px;

    &__left,
    &__right {
      line-height: 24px;
    }

    i {
      font-size: 16px;
    }

    &__center {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__title {
      font-weight: 500;
      line-height: 18px;
      width: 180px;
      text-align: center;
      @include ellipsis;
    }

    &__sub {
      font-size: 12px;
      margin-top: 1px;
      line-height: 18px;
      width: 180px;
      @include ellipsis;
    }
  }

  &-content {
    height: 100%;
    overflow: hidden;

    &__item {
      display: flex;
      padding: 0 14px;

      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }

    &__avatar {
      width: 42px;
      height: 42px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }

    &__pop {
      position: relative;
      flex: 1;
      margin-left: 12px;

      &.lh-1 {
        line-height: 1;
      }

      &::before {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border: 6px solid transparent;
        left: -12px;
        top: 12px;
        border-right-color: #fff;
      }

      &--text {
        ::v-deep .el-textarea__inner {
          border: none;
          overflow: hidden;
          font-size: 12px;
        }
      }

      &--image {
        max-width: 120px;
        max-height: 120px;
        border-radius: 8px;
        background-color: #fff;

        &.is-poster {
          max-width: none;
          max-height: none;
          width: 68px;
          height: 120px;
        }
      }

      &--poster {
        width: 68px;
        height: 120px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #fff;
        padding-top: 30px;
        box-sizing: border-box;

        img {
          width: 32px;
        }

        .text {
          font-size: 12px;
          margin-top: 8px;
        }
      }

      &--link {
        background-color: #fff;
        border-radius: 8px;
        padding: 14px;
        font-size: 12px;

        .flex {
          margin-top: 8px;
          align-items: flex-start;
        }

        .desc {
          flex: 1;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.45);
        }

        .cover {
          width: 48px;
          height: 48px;
          border-radius: 2px;
          margin-left: 8px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      &--applet {
        max-width: 205px;
        background-color: #fff;
        padding-top: 8px;
        box-sizing: border-box;
        border-radius: 8px;
        font-size: 12px;

        .logo {
          width: 20px;
          height: 20px;
          margin-right: 6px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        .name {
          display: flex;
          align-items: center;
          color: #6E788A;
          font-size: 12px;
          margin: 0 12px;
          line-height: 1;
          @include ellipsis;
        }

        .title {
          display: flex;
          align-items: center;
          color: #6E788A;
          margin: 0 12px;
          margin-top: 8px;
          line-height: 1;
          @include ellipsis;
        }

        .cover {
          width: 100%;
          height: 150px;
          padding: 8px 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .sys {
          color: #6E788A;
          padding-left: 2px;
          display: flex;
          align-items: center;
          height: 24px;
          padding: 0 12px;
          border-top: 1px solid #eaebf2;

          span {
            color: #2B7CFF;
            font-weight: bold;
            display: inline-block;
            transform: rotate(45deg);
            margin-right: 10px;
            font-family: cursive;
            font-size: 18px;
          }
        }
      }
    }
  }

  &-welmsg {
    text-align: center;
    font-size: 12px;
    color: #6E788A;
    margin-bottom: 14px;

    b {
      color: #454D5B;
    }
  }

  &-footer {
    height: 40px;
  }
}
</style>
