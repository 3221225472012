/*
 * @Author: zhanln
 * @Date: 2021-06-24 10:39:36
 * @LastEditTime: 2021-08-17 18:40:25
 * @LastEditors: zhanln
 * @Description:
 */
import Vue from 'vue'
import Vuex from 'vuex'
import util from '@/common/util'
Vue.use(Vuex)

const modulesFiles = require.context('./modules', false, /\.js$/)

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

export default new Vuex.Store({
  state: {},
  modules,
  mutations: {
    _SET (state, opts) {
      if (util.type(opts) !== 'object') {
        return
      }
      util.each(opts, (k, v) => {
        if (k.indexOf('.') > 0) {
          let newState = state
          const keys = k.split('.')
          const last = keys.pop()
          util.each(keys, (i, m) => {
            newState = newState[m]
          })
          newState[last] = v
        } else {
          state[k] = v
        }
      })
    }
  }
})
