<!--
 * @Author: zhanln
 * @Date: 2021-06-10 11:01:14
 * @LastEditTime: 2022-02-15 14:13:51
 * @LastEditors: zhanln
 * @Description:
-->

<template>
  <el-dialog
    :visible.sync="visible"
    custom-class="card bind"
    width="800px"
    top="0"
    :modal="hasClose"
    :close-on-click-modal="hasClose"
    :close-on-press-escape="hasClose"
  >
    <empty-card
      :hasClose="true"
      @handleClose="visible = false"
      @handleBind="fn_handleBind"
    ></empty-card>
  </el-dialog>
</template>

<script>
import emptyCard from './emptyCard.vue'
export default {
  name: 'bindCard',
  data () {
    return {
      visible: false,
      hasClose: true,
      lyLogo: require('@assets/images/logo_slogon.png'),
      wecomLogo: require('@assets/svg/wc_work_ic.svg'),
      wcLogo: require('@assets/svg/wc_work_ic.svg'),
      wcLogoWhite: require('@assets/svg/wc_work_white_ic.svg'),
      contactCode: require('@assets/images/contact_me_qr.png')
    }
  },

  components: {
    emptyCard
  },
  methods: {

    fn_handleBind () {
      this.$emit('handleBind')
    },

    // 注册企业
    async fn_registerWecom () {
      // this.bindCompanyDialog = false
      // const data = await this.axios.get('registerUrl')
      // this.registerCode = data.register_code
      // this.registerDialogVisable = true

      // 跳转企微自行注册企业（无任何绑定关系）
      window.open('https://work.weixin.qq.com/wework_admin/register_wx?from=myhome_openApi', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/var.scss";
@import "./card.scss";

::v-deep .el-dialog {
  background: transparent;
}
</style>
