/*
 * @Author: zhanln
 * @Date: 2021-06-18 15:12:58
 * @LastEditTime: 2021-06-18 16:37:19
 * @LastEditors: zhanln
 * @Description: 权限
 */

const base = '/wecom'

export default {
  // 角色详情
  roles: base + '/roles',
  // 角色列表
  rolesList: base + '/roles/list',
  // 角色列表（绕过校验）
  rolesListNoauth: base + '/roles/list/noauth',
  // 根据id查询角色详情
  rolesAppIds: base + '/app/roles/appids',
  // 设置默认角色
  rolesDefault: base + '/roles/default',
  // 角色添加员工
  rolesAddUser: base + '/roles/users/auth',
  // 角色添加员工
  rolesAddUserMore: base + '/roles/users/more',
  // 查询部门角色员工管理的部门id
  rolesDepartments: base + '/roles/users/departments'
}
