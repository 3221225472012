/*
 * @Author: zhanln
 * @Date: 2021-06-24 10:39:36
 * @LastEditTime: 2021-09-14 20:34:12
 * @LastEditors: zhanln
 * @Description:
 */
import { MessageBox } from 'element-ui'

export function lyConfirm ({ text = '确定执行此操作吗？', type = 'warning', title = '提示', confirmButtonText = '确定', cancelButtonText = '取消', showCancelButton = true, closeOnClickModal = true }) {
  const h = this.$createElement
  return MessageBox({
    showClose: false,
    customClass: 'ly-confirm',
    message: h('div', null, [
      h('div', { class: 'ly-confirm__title' }, title),
      h('div', { class: ['ly-confirm__text', text === '' ? 'hide' : ''] }, text)
    ]),
    confirmButtonText,
    showCancelButton,
    cancelButtonText,
    closeOnClickModal,
    type
  })
}
