var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "xl-ly-setting" },
    [
      _c("div", { staticClass: "handler-left" }, [_vm._t("default")], 2),
      _c(
        "div",
        { staticClass: "handler-right" },
        [
          _vm._t("handlerright"),
          _vm.setting
            ? _c("div", { staticClass: "sys-setting" }, [
                _c("i", {
                  staticClass: "el-icon-setting",
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = true
                    }
                  }
                })
              ])
            : _vm._e()
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置显示字段",
            visible: _vm.dialogVisible,
            width: "576px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            open: _vm.handleOpen
          }
        },
        [
          _c("div", { staticClass: "setting-warp" }, [
            _c("p", [_vm._v("当前显示字段")]),
            _c(
              "div",
              { ref: "tags", attrs: { id: "tags" } },
              _vm._l(_vm.finalColumns, function(tag) {
                return _c(
                  "el-tag",
                  {
                    key: tag.prop,
                    attrs: {
                      prop: tag.prop,
                      closable: tag.hideable,
                      size: "medium"
                    },
                    on: {
                      close: function($event) {
                        return _vm.addHide(tag)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(tag.label) + " ")]
                )
              }),
              1
            ),
            _c("p", [_vm._v("已隐藏字段")]),
            _c(
              "div",
              { ref: "hideTags", attrs: { id: "tags" } },
              _vm._l(_vm.finalHide, function(tag) {
                return _c(
                  "el-tag",
                  {
                    key: tag.prop,
                    attrs: { closable: "", size: "medium" },
                    on: {
                      close: function($event) {
                        return _vm.removeHide(tag)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(tag.label) + " ")]
                )
              }),
              1
            )
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "", size: "small" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { round: "", size: "small", type: "primary" },
                  on: { click: _vm.hideChange }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }