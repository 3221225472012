/*
 * @Author: zhanln
 * @Date: 2021-06-18 15:12:52
 * @LastEditTime: 2022-03-17 10:28:58
 * @LastEditors: Please set LastEditors
 * @Description: 机器人相关
 */

const base = '/wecom/robot'

export default {
  // 查询机器人列表
  robotList: base + '/list',
  // 机器人扣费记录
  historyList: base + '/login/history',
  // 查询登录状态
  loginStatus: base + '/login/status',
  // 下线账号操作
  offline: base + '/offline',
  // 请求登录二维码
  requestLogin: base + '/qrcode',
  // 获取二维码地址
  robotQrcode: base + '/qrcode',
  // 查询员工机器人账号
  robotListById: base + '/users/list'
}
