var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ly-dialog",
    {
      staticClass: "edit-dialog",
      attrs: {
        visible: _vm.visible,
        setting: _vm.dialogSetting,
        title: _vm.title
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        confirm: _vm.fn_confirm,
        open: _vm.fn_open,
        close: _vm.fn_close
      },
      scopedSlots: _vm._u(
        [
          _vm.type === "edit"
            ? {
                key: "footer-left",
                fn: function() {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { float: "left" },
                        attrs: {
                          round: "",
                          type: "text",
                          size: "medium",
                          loading: _vm.delLoading
                        },
                        on: { click: _vm.fn_delGroup }
                      },
                      [_vm._v("删除标签组")]
                    )
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm.type === "edit"
        ? _c("div", { staticClass: "edit-tips" }, [
            _c("i", { staticClass: "el-icon-message-solid" }),
            _vm._v("注：修改/删除标签，已添加到用户身上的相关标签也随之变化 ")
          ])
        : _vm._e(),
      _c(
        "el-form",
        {
          ref: "formRef",
          staticClass: "edit-form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            size: "medium",
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标签组名称：", prop: "tagGroupName" } },
            [
              _c("el-input", {
                attrs: {
                  maxlength: "15",
                  "show-word-limit": "",
                  placeholder: "请输入标签组名称"
                },
                model: {
                  value: _vm.form.tagGroupName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "tagGroupName", $$v)
                  },
                  expression: "form.tagGroupName"
                }
              })
            ],
            1
          ),
          _vm._l(_vm.form.tagList, function(tag, index) {
            return _c(
              "el-form-item",
              {
                key: tag.key,
                ref: "item_" + index + "_ref",
                refInFor: true,
                class: [index === _vm.form.tagList.length - 1 ? "is-last" : ""],
                attrs: { error: tag.error }
              },
              [
                index === 0
                  ? _c("template", { slot: "label" }, [
                      _c("span", { staticClass: "q-danger" }, [_vm._v("*")]),
                      _vm._v(" 标签名称：")
                    ])
                  : _vm._e(),
                _c("el-input", {
                  attrs: {
                    maxlength: "15",
                    "show-word-limit": "",
                    placeholder: "请输入标签名称"
                  },
                  on: { change: _vm.fn_checkSameTag },
                  model: {
                    value: tag.tagName,
                    callback: function($$v) {
                      _vm.$set(tag, "tagName", $$v)
                    },
                    expression: "tag.tagName"
                  }
                }),
                _vm.form.tagList.length > 1
                  ? _c(
                      "span",
                      {
                        staticClass: "edit-remove",
                        on: {
                          click: function($event) {
                            return _vm.fn_removeTag(tag)
                          }
                        }
                      },
                      [_c("i", { staticClass: "el-icon-remove-outline" })]
                    )
                  : _vm._e()
              ],
              2
            )
          }),
          _c(
            "el-form-item",
            { staticStyle: { "margin-left": "-15px" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    round: "",
                    type: "text",
                    size: "medium",
                    icon: "iconfont icon-plus"
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.fn_addTag()
                    }
                  }
                },
                [_vm._v("添加标签")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }