// 表格操作列容器组件：超过三个按钮被收起
import './index.scss'
export default {
  render () {
    return (
      <div class="ly-action">
        {
          this.part1.map((e, i) => {
            if (i !== this.part1.length - 1) {
              return [e, <el-divider direction="vertical"></el-divider>]
            } else return e
          })
        }
        {
          this.part2.length > 0 ? [
            <el-divider direction="vertical"></el-divider>,
            <el-popover
              popper-class="ly-action-popver"
              placement="bottom-end"
              trigger="hover"
              width="142">
              <i slot="reference" class="el-icon-more"></i>
              {
                this.part2.map(e => {
                  return <div class="ly-action-item">{e}</div>
                })
              }
            </el-popover>
          ] : ''
        }
      </div>
    )
  },
  props: {
    display: {
      type: Number,
      default: 3
    }
  },
  computed: {
    part1 () {
      return this.$slots.default.slice(0, this.display)
    },
    part2 () {
      return this.$slots.default.slice(this.display)
    }
  }
}
