<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      :width="width"
      top="25vh"
      custom-class="q-dialog"
      :close-on-click-modal="false"
      class="pay-dialog__state"
    >
      <div class="pay-header">
        <el-row :gutter="16" type="flex" justify="space-between">
          <el-col v-for="(item, index) in payList" :key="index">
            <div
              :class="['pay-card', cardIndex === index ? 'active' : '']"
              @click="fn_changeCard(item, index)"
            >
              <div class="pay-card--title">{{ item.valid_days }}天</div>
              <div class="pay-card--price">
                <template v-if="payType === 'first'">
                  <span class="pay-card--price__rmb">￥</span
                  >{{ item.first_price }}
                </template>
                <template v-else-if="payType === 'renewal'">
                  <span class="pay-card--price__rmb">￥</span
                  >{{ item.renewal_price }}
                </template>
              </div>
              <div class="pay-card--original">
                原价￥{{ item.underline_price }}
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="pay-tool">
        <div class="pay-code">
          <img :src="payCode" class="pay-code--img" alt="" />
          <div class="q-loading" v-if="codeLoading">
            <img :src="codeLoadingIcon" alt="" />
          </div>
          <div class="pay-code__overdue" v-if="codeTimeout">
            {{ codeTimeoutType === 1 ? "二维码已失效" : "生成二维码超时" }}
            <el-button round size="small" type="primary" @click="fn_getPayCode"
              >点击刷新</el-button
            >
          </div>
        </div>
        <div class="pay-desc">
          <div class="temp">
            <img :src="wechartLogo" class="pay-tool--icon" alt="" />微信扫码支付
          </div>
          <div class="pay-tool--price"><span style="font-size: 20px">￥</span>{{ toolPrice }}</div
          >
        </div>
      </div>
    </el-dialog>

    <!-- success -->
    <el-dialog
      :title="title"
      :visible.sync="stateDialogVisible"
      :append-to-body="true"
      width="560px"
      top="25vh"
      custom-class="q-dialog"
      :close-on-click-modal="false"
      class="pay-dialog__state"
      @close="fn_closeStateDialog"
    >
      <div class="pay-state flex flex-column flex-center">
        <i
          class="el-icon-success pay-state__icon success"
          v-if="stateType === 'success'"
        ></i>
        <i class="el-icon-error pay-state__icon error" v-else></i>
        <div class="pay-state__text">
          {{ stateType === "success" ? "支付成功" : "支付失败" }}
        </div>
        <el-tag
          effect="plain"
          type="warning"
          size="medium"
          v-if="stateType === 'success'"
          >已成功购买{{ successDay }}天{{ appTitle }}，到期时间为{{
            successOverTime
          }}</el-tag
        >
        <el-tag effect="plain" type="warning" size="medium" v-else>
          {{ errorText }}
        </el-tag>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import qs from 'qs'
export default {
  name: 'LyPay',
  props: {
    payDialogVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '立即续费'
    },
    appTitle: {
      type: String,
      default: '任务宝'
    },
    payType: {
      type: String,
      default: ''
    },
    appName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      payList: [],
      width: '560px',
      cardIndex: 0,
      payIndex: 0,
      wechartLogo: require('@assets/svg/paySuccess_icon.svg'),
      payCode: require('@assets/images/wechat_code_default.png'),
      outTradeNo: null,
      codeLoadingIcon: require('@assets/images/loading_m.png'),
      codeLoading: true,
      codeTimeOutFn: null,
      codeTimelate: 1000 * 60 * 3, // 失效时间，3分钟
      codeTimeout: false,
      codeTimeoutType: 1,
      payInterval: null,
      stateDialogVisible: false,
      stateType: '',
      successOverTime: null,
      successDay: 0,
      errorText: ''
    }
  },

  computed: {
    appId () {
      return this.$store.state.buy.appid
    },
    dialogVisible: {
      get () {
        return this.payDialogVisible
      },
      set (val) {
        if (!val) {
          if (this.payInterval) {
            clearTimeout(this.payInterval)
          }

          if (this.codeTimeOutFn) {
            this.cardIndex = 0
            this.codeTimeout = false
            clearTimeout(this.codeTimeOutFn)
          }
        }
        this.$emit('update:payDialogVisible', val)
      }
    },
    toolPrice () {
      if (this.payList[this.cardIndex]) {
        if (this.payType === 'first') {
          return this.payList[this.cardIndex].first_price
        } else {
          return this.payList[this.cardIndex].renewal_price
        }
      } else {
        return []
      }
    }
  },

  watch: {
    dialogVisible (val) {
      if (val) {
        this.fn_getPrice()
      }
    }
  },

  created () {

  },

  methods: {

    // 获取规格
    async fn_getPrice () {
      const data = await this.axios.post('appPackageList', qs.stringify({ app_id: this.appId }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      if (this.payList.length === 2) {
        this.width = '464px'
      }

      const sortType = this.payType === 'first' ? 'first_price' : 'renewal_price'

      if (!data || data.length === 0) {
        this.$message.error('获取价格失败，请重新点击购买')
        this.fn_closePayDialog()
        return false
      }

      this.fn_initDate(data)

      this.payList = data.sort((a, b) => {
        return a[sortType] < b[sortType] ? -1 : 1
      })

      this.bid = this.payList[0].id
      this.successDay = data[0].valid_days
      this.fn_getPayCode()
    },

    // 排序
    fn_initDate (data) {
      for (let i = 0; i < data.length; i++) {
        data[i].index = i
      }
    },

    // 获取支付码
    async fn_getPayCode () {
      this.successDay = this.payList[this.cardIndex].valid_days
      if (this.codeTimeOutFn) {
        clearTimeout(this.codeTimeOutFn)
      }
      this.codeTimeout = false
      this.codeLoading = true

      try {
        const data = await this.axios.post('paymentCode',
          qs.stringify(
            {
              trade_type: this.payType === 'renewal' ? 3 : 1,
              bid: this.bid,
              app_id: this.appId
            }
          ),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )

        if ((data && data.code) || (data && data.code === 0)) {
          this.$message.error(data.msg)
          return false
        }

        this.payCode = await this.util.qrCode(data.code_url)
        this.outTradeNo = data.out_trade_no
        this.codeLoading = false

        // 失效
        this.codeTimeOutFn = setTimeout(() => {
          this.codeTimeout = true
          this.codeTimeoutType = 1
        }, this.codeTimelate)

        this.fn_listenPay()
      } catch {
        this.codeTimeout = true
        this.codeTimeoutType = 2
      }
    },

    // 关闭弹框
    fn_closePayDialog () {
      this.dialogVisible = false
    },

    // 切换价格
    fn_changeCard (item, index) {
      if (this.cardIndex === index) {
        return false
      }

      clearTimeout(this.payInterval)
      this.cardIndex = index
      this.codeLoading = true
      this.bid = item.id

      this.fn_getPayCode()
    },

    async fn_listenPay (day, time) {
      const params = {
        out_trade_no: this.outTradeNo
      }
      const data = await this.axios.post('paymentOrder',
        qs.stringify(params),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )

      if (data.trade_state === 1) { // 购买成功
        this.fn_getAppTime()
      } else if (data.trade_state === 2 || data.trade_state === 3 || data.trade_state === 4) {
        this.fn_showFailDialog()
        this.errorText = data.trade_state_desc
      } else {
        this.payInterval = setTimeout(() => {
          this.fn_listenPay()
        }, 3000)
      }
    },

    // 获取购买记录, 更新缓存
    async fn_getAppTime () {
      const data = await this.axios.get('appPurchaseList')
      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      const boughtList = data.app_purchased_record

      this.$store.commit('_SET', {
        'buy.boughtList': boughtList
      })

      for (let i = 0; i < boughtList.length; i++) {
        if (this.appId === boughtList[i].app_id) {
          this.successOverTime = this.util.timeTofmt(boughtList[i].expired_time)
          this.$store.commit('_SET', {
            'buy.isBought': true,
            'buy.remainDay': boughtList[i].remain_days,
            'buy.expiredTime': boughtList[i].expired_time
          })
        }
      }

      this.fn_showSuccssDialog()
    },

    // 购买成功
    fn_showSuccssDialog () {
      this.stateDialogVisible = true
      this.stateType = 'success'
      this.$nextTick(() => {
        this.dialogVisible = false
      })
    },

    // 购买失败
    fn_showFailDialog () {
      this.stateDialogVisible = true
      this.stateType = 'fail'
    },

    // 关闭状态弹框
    fn_closeStateDialog () {
      if (this.stateType === 'fail') {
        this.codeLoading = true
        this.fn_getPayCode()
      }
    }
  },

  destroyed () {
    if (this.payInterval) {
      clearTimeout(this.payInterval)
    }

    if (this.codeTimeOutFn) {
      clearTimeout(this.codeTimeOutFn)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/var.scss";
.pay {
  &-dialog {
    z-index: 3000 !important;

    &__state {
      z-index: 3001 !important;
    }
  }
  &-header {
    margin-bottom: 27px;
  }

  &-card {
    border: 2px solid #EAEBF2;
    border-radius: 9px;
    padding: 24px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    transition: $--transition;
    user-select: none;
    cursor: pointer;

    &:hover,
    &.active {
      border: 2px solid rgba(250, 173, 20, 0.3);
      background-color: rgba(250, 173, 20, 0.06);
    }

    &--title {
      font-size: 16px;
      font-weight: 600;
      color: $--color-text-primary;
      line-height: 22px;
    }

    &--price {
      font-size: 30px;
      font-weight: 500;
      color: $--color-warning;
      line-height: 42px;

      &__rmb {
        font-size: 20px;
      }
    }

    &--original {
      color: $--color-text-placeholder;
      text-decoration: line-through;
      line-height: 20px;
    }
  }
  &-desc {
    margin-left: 16px;
    line-height: 25px;
    flex: 1;
    .temp{
      display: flex;
      align-items: center;
      margin-top: 28px;
    }
    .pay-tool--icon{
      margin-right: 5px;
    }
    .pay-tool--price {
      margin-top: 15px;
      color: #ECAB0E;
      font-size: 30px;
    }
  }

  &-code {
    position: relative;
    width: 50%;

    &--img {
      width: 110px;
      height: 110px;
      float: right;
      margin-top: 10px;
    }
    .q-loading{
      width: 110px;
      height: 110px;
      position: absolute;
      top: 10px;
      right: 0;
      left: unset;
    }

    &__overdue {
      width: 110px;
      height: 110px;
      position: absolute;
      top: 10px;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-weight: bold;
      background-color: rgba(255, 255, 255, 0.9);

      .el-button {
        margin-top: 10px;
      }
    }
  }

  &-state {
    height: 394px;
    padding-bottom: 80px;
    box-sizing: border-box;

    &__icon {
      font-size: 64px;

      &.success {
        color: $--color-success;
      }
      &.error {
        color: $--color-danger;
      }
    }

    &__text {
      font-weight: 600;
      font-size: 18px;
      margin: 12px 0 24px 0;
    }
  }
}
.pay-tool{
  border: 1px solid #EAEBF2;
  border-radius: 8px;
  display: flex;
  height: 130px;
}
.el-tag--medium{
  height: 48px;
  line-height: 48px;
  border-radius: 16px;
  font-size: 14px;
  border: unset;
  padding: 0 15px;
  display: inline-block;
  &.el-tag--warning{
    color: #C17F00;
    background: #FFF7C9;
  }
}
</style>
