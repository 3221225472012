<!--
 * @Author: zhanln
 * @Date: 2022-03-09 10:39:22
 * @LastEditTime: 2022-04-09 16:53:48
 * @LastEditors: zhanln
 * @Description: 选择企微号
-->

<template>
  <el-dialog
    title="选择云端账号"
    :visible.sync="visible"
    width="705px"
    :close-on-click-modal="false"
    append-to-body
  >
    <div class="robots" v-loading="loading">
      <div class="robots-left">
        <div class="robots-title">云端账号</div>
        <div class="robots-search">
          <el-input
            placeholder="请输入云端账号"
            prefix-icon="el-icon-search"
            clearable
            v-model="searchText"
          ></el-input>
        </div>
        <el-scrollbar class="robots-list">
          <div
            :class="['robots-item', item.disable ? 'is-disable' : '']"
            v-for="(item, index) of robotList.filter((item) => item.show)"
            :key="index"
            @click="fn_checkItem(item)"
          >
            <div class="robots-info">
              <span class="robots-info__avatar">
                <el-image
                  class="robots-info__avatar--img"
                  :src="item.avatar"
                  fit="cover"
                  lazy
                ></el-image
              ></span>
              <span class="robots-info__name"> {{ item.name }} </span>
              <span class="robots-info__type">
                <span class="q-success" v-if="item.status === 2">（在线）</span>
                <span class="q-info" v-else>（离线）</span>
              </span>
            </div>
            <div class="robots-check">
              <el-tooltip
                class="item"
                effect="dark"
                content="此账号已被其他客服账号接入"
                :disabled="!item.disable"
                placement="top-end"
              >
                <el-checkbox
                  v-model="item.selected"
                  :disabled="item.disable"
                  @click.native.stop
                ></el-checkbox>
              </el-tooltip>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <div class="robots-right">
        <div class="robots-title">
          已选账号<span class="q-primary">（{{ checkCount }}）</span>
        </div>
        <el-scrollbar class="robots-list right">
          <div
            class="robots-item right"
            v-for="(item, index) of robotList.filter((item) => item.selected)"
            :key="index"
          >
            <div class="robots-info">
              <span class="robots-info__avatar">
                <el-image
                  class="robots-info__avatar--img"
                  :src="item.avatar"
                  fit="cover"
                  lazy
                ></el-image
              ></span>
              <span class="robots-info__name right"> {{ item.name }} </span>
            </div>
            <div class="robots-close" @click="fn_checkItem(item, 'remove')">
              <i class="el-icon-close"></i>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>

    <div class="robots-footer">
      <div class="robots-footer__left">
        <el-button round @click="fn_clearSelect" :disabled="loading"
          >清空所选</el-button
        >
      </div>
      <div class="robots-footer__right">
        <el-button round @click="visible = false" :disabled="loading"
          >取消</el-button
        >
        <el-button round type="primary" @click="fn_confirm" :disabled="loading"
          >确定</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'selectRobot',

  props: {
    setDisable: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      loading: true,
      visible: false,
      searchText: '',
      robotList: []
    }
  },

  computed: {
    checkCount () {
      const arr = this.robotList.filter(item => item.selected)
      return arr.length
    }
  },

  watch: {
    searchText (val) {
      for (let i = 0; i < this.robotList.length; i++) {
        if (this.robotList[i].name.indexOf(val) > -1) {
          this.robotList[i].show = true
        } else {
          this.robotList[i].show = false
        }
      }
    }
  },

  methods: {
    async fn_open (ids, vids) {
      this.visible = true

      const list = await this.fn_getData()

      const idArr = ids ? ids.split(',').map(item => +item) : []
      const vidsArr = vids ? vids.split(',').map(item => +item) : []

      list.forEach(item => {
        item.show = true
        item.disable = false

        // 设置选中
        if (idArr.includes(item.id)) {
          item.selected = true
        } else {
          item.selected = false
        }

        // 设置禁选
        if (vidsArr.includes(item.id) || idArr.includes(item.id)) {
          item.disable = false
        } else {
          item.disable = this.setDisable && item.checked === 1
        }
      })

      this.robotList = list

      this.loading = false
    },

    async fn_getData () {
      this.loading = true
      const data = await this.axios.get('aggRobotList', {
        params: {
          qrcode_key: this.configKey
        }
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }

      const list = data.sort((a, b) => {
        if (a.status === b.status) {
          return 0
        } else if (a.status === 1) {
          return 1
        } else if (a.status === 2) {
          return -1
        }
      })

      return list
    },

    // 左边选择
    fn_checkItem (item, type) {
      if (item.disable) return
      item.selected = !item.selected
    },

    // 确认
    fn_confirm () {
      const list = this.robotList.filter(item => item.selected)
      this.$emit('callback', list)
      this.visible = false
    },

    // 清空所选
    fn_clearSelect () {
      this.robotList.forEach(item => {
        item.selected = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/utils.scss";
.robots {
  display: flex;
  border-radius: 8px;
  border: 1px solid #eaebf2;
  height: 434px;
  color: #212b36;

  &-left {
    width: 286px;
    border-right: 1px solid #eaebf2;
    box-sizing: border-box;
    background-color: #f7f8fc;
    height: 100%;
    overflow: hidden;
  }

  &-right {
    flex: 1;
    overflow: hidden;
  }

  &-title {
    margin: 16px 20px;
    line-height: 24px;
    font-weight: 500;
  }

  &-search {
    margin: 16px 20px;
  }

  &-list {
    height: calc(100% - 108px);

    &.right {
      height: calc(100% - 56px);
    }
    overflow: hidden;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 20px;
    user-select: none;

    &:not(.right) {
      cursor: pointer;
    }

    &.is-disable {
      cursor: not-allowed;
    }

    &:hover {
      background-color: #eaebf2;
    }
  }

  &-info {
    display: flex;
    align-items: center;
    flex: 1;
    overflow: hidden;
    &__avatar {
      line-height: 1;
      margin-right: 12px;
      &--img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }

    &__name {
      max-width: 100px;

      &.right {
        max-width: 100%;
      }
      @include ellipsis;
    }
  }

  &-close {
    font-size: 18px;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      color: $--color-danger;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }
}

.q-info {
  color: #b4bbcc;
}
</style>
