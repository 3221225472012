<template>
  <div class="log">
    <div class="log-title">更新日志（测试服)</div>
    <el-timeline class="log-timeline">
      <el-timeline-item
        :timestamp="item.version"
        placement="top"
        v-for="(item, index) in logList"
        :key="index"
      >
        <p class="log-desc" v-if="item.desc">{{ item.desc }}</p>
        <ul class="log-list">
          <li v-for="(item1, index1) in item.children" :key="index1">
            <div class="log-version flex">
              {{ item1.version
              }}<el-tag class="log-time" type="info" size="mini">{{
                item1.time
              }}</el-tag>
            </div>
            <ul class="log-list">
              <li v-for="(item2, index2) in item1.list" :key="index2">
                <template v-if="item2.type">
                  <span v-if="item2.type === 'add'" class="log-type q-success"
                    >【新增】</span
                  >
                  <span v-if="item2.type === 'ud'" class="log-type q-warning"
                    >【优化】</span
                  >
                  <span v-if="item2.type === 'fix'" class="log-type q-danger"
                    >【修复】</span
                  >
                  <span v-if="item2.type === 'ui'" class="log-type q-primary"
                    >【UI】</span
                  >
                  <span v-if="item2.type === 'code'" class="log-type q-info"
                    >【代码】</span
                  >
                </template>
                {{ item2.desc }}
              </li>
            </ul>
          </li>
        </ul>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
export default {
  data () {
    return {
      logList: [
        {
          version: '1.1.4x',
          children: [
            {
              version: 'v1.1.44',
              time: '2021-2-7',
              list: [
                {
                  type: 'fix',
                  desc: '切换企业后二三级菜单跳转错误'
                },
                {
                  type: 'ui',
                  desc: '外部应用图片超出屏幕宽度'
                },
                {
                  type: 'ud',
                  desc: '上传图片（七牛云）接口添加路径及后缀信息'
                }
              ]
            },
            {
              version: 'v1.1.43',
              time: '2021-2-5',
              list: [
                {
                  type: 'fix',
                  desc: '浏览器多标签打开时企业员工登录异常'
                }
              ]
            },
            {
              version: 'v1.1.42',
              time: '2021-2-5',
              list: [
                {
                  type: 'fix',
                  desc: '浏览器多标签打开时登录异常'
                }
              ]
            },
            {
              version: 'v1.1.41',
              time: '2021-2-5',
              list: [
                {
                  type: 'add',
                  desc: '外部应用按钮新标签页打开'
                }
              ]
            },
            {
              version: 'v1.1.40',
              time: '2021-1-29',
              list: [
                {
                  type: 'add',
                  desc: '手动配置应用加入侧边栏配置'
                }
              ]
            }
          ]
        },
        {
          version: '1.1.3x',
          children: [
            {
              version: 'v1.1.36',
              time: '2021-1-28',
              list: [
                {
                  type: 'ud',
                  desc: '员工-权限编辑优化'
                }
              ]
            },
            {
              version: 'v1.1.35',
              time: '2021-1-27',
              list: [
                {
                  type: 'ud',
                  desc: '接管后台功能'
                }
              ]
            },
            {
              version: 'v1.1.32',
              time: '2021-1-27',
              list: [
                {
                  type: 'ud',
                  desc: '任务宝海报上传失败重复提示'
                },
                {
                  type: 'ud',
                  desc: '标签搜索优化'
                }
              ]
            },
            {
              version: 'v1.1.31',
              time: '2021-1-27',
              list: [
                {
                  type: 'ud',
                  desc: '任务宝：邀请客户参与，活动规则，奖品通知，输入框调整'
                },
                {
                  type: 'fix',
                  desc: '任务宝：标签接口获取全部数据（9999）'
                },
                {
                  type: 'ud',
                  desc: '任务宝：邀请客户参与，海报背景，奖品内容-链接-封面，图片类型判断调整为通过文件头判断，非指定类型更改后缀将无法上传'
                }
              ]
            }
          ]
        },
        {
          version: '1.0.2x',
          desc: '关键更新：详情页，应用侧边栏',
          children: [
            {
              version: 'v1.0.24',
              time: '2021-1-22',
              list: [
                {
                  type: 'fix',
                  desc: '分析页面空数据报错判断'
                }
              ]
            },
            {
              version: 'v1.0.23',
              time: '2021-1-22',
              list: [
                {
                  type: 'fix',
                  desc: '修复海报二维码错位问题'
                }
              ]
            },
            {
              version: 'v1.0.21',
              time: '2021-1-22',
              list: [
                {
                  type: 'ui',
                  desc: 'ui 优化'
                },
                {
                  type: 'fix',
                  desc: '客户分析，客户总数错误'
                },
                {
                  type: 'ud',
                  desc: '客户列表、员工列表排序优化'
                },
                {
                  type: 'ud',
                  desc: '创建活动，选择标签弹框搜索优化'
                },
                {
                  type: 'fix',
                  desc: '群详情-成员列表，翻页组件不显示的问题'
                },
                {
                  type: 'fix',
                  desc: '活动列表，点击推广操作时页面显示二维码图片的问题'
                }
              ]
            },
            {
              version: 'v1.0.2',
              time: '2021-1-21',
              list: [
                {
                  type: 'add',
                  desc: '添加员工、客户、社群详情页'
                },
                {
                  type: 'add',
                  desc: '自建应用增加侧边栏配置（手动、自动）'
                },
                {
                  type: 'ud',
                  desc: '完善后台接管跳转'
                }
              ]
            }
          ]
        },
        {
          version: '1.0.1x',
          desc: '优化版本',
          children: [
            {
              version: 'v1.0.14.4',
              time: '2021-1-20',
              list: [
                {
                  type: 'fix',
                  desc: '任务宝-活动列表，修复推广二维码无法下载问题'
                },
                {
                  type: 'fix',
                  desc: '任务宝-参与客户，参与时间可添加时分秒'
                },
                {
                  type: 'ud',
                  desc: '任务宝-创建任务，任务为链接类型时，标题，摘要添加字数限制'
                },
                {
                  type: 'fix',
                  desc: '任务宝-创建任务，任务为链接类型时，输入链接图片为空的问题'
                }
              ]
            },
            {
              version: 'v1.0.14.3',
              time: '2021-1-18',
              list: [
                {
                  type: 'ud',
                  desc: '任务宝-创建任务，任务为链接类型时，标题，摘要添加字数限制'
                },
                {
                  type: 'ud',
                  desc: '任务宝-活动列表，邀请客户参与，添加“链接标题”、“链接摘要”、“封面”字段'
                }
              ]
            },
            {
              version: 'v1.0.14.2',
              time: '2021-1-14',
              list: [
                {
                  type: 'fix',
                  desc: '任务宝-修改活动，奖品内容为链接时，未显示链接内容'
                },
                {
                  type: 'ud',
                  desc: '任务宝-创建活动，奖品内容为链接时，设置默认封面'
                },
                {
                  type: 'fix',
                  desc: '任务宝-创建活动，奖品内容为链接时，修复校验规则，placeholder 提示文字'
                },
                {
                  type: 'code',
                  desc: '前端代码添加缓存hash，解决版本更新代码缓存问题'
                },
                {
                  type: 'ui',
                  desc: '【通用】所有table，优化小屏时字段过多导致换行的问题'
                },
                {
                  type: 'fix',
                  desc: '【通用】员工/群主选择器，搜索过滤后移除部门全选'
                },
                {
                  type: 'ud',
                  desc: '任务宝-创建活动，员工选择修改为仅显示5条，适应小屏'
                },
                {
                  type: 'fix',
                  desc: '任务宝-创建活动，因网络问题提交报错，增加提示'
                }
              ]
            },
            {
              version: 'v1.0.14.1',
              time: '2021-1-13',
              list: [
                {
                  type: 'fix',
                  desc: '修复创建活动，活动规则超出字数没有提示的问题'
                }
              ]
            },
            {
              version: 'v1.0.14',
              time: '2021-1-12',
              list: [
                {
                  type: 'ud',
                  desc: '【通用】员工/群主选择器，添加部门全选'
                },
                {
                  type: 'ud',
                  desc: '任务宝-创建活动，奖品通知，添加【任务阶段】，【奖品名称】变量'
                },
                {
                  type: 'ud',
                  desc: '任务宝-创建活动，活动规则预览内容调整'
                },
                {
                  type: 'ud',
                  desc: '任务宝-列表，邀请员工参与，活动规则调整为富文本编辑器，可添加【活动名称】变量'
                },
                {
                  type: 'ui',
                  desc: '任务宝-创建活动-任务阶段，选择奖品类型为链接时，页面ui调整'
                }
              ]
            },
            {
              version: 'v1.0.13',
              time: '2021-1-11',
              list: [
                {
                  type: 'ud',
                  desc: '任务宝-创建活动，活动规则、通知优化为富文本编辑器，文案调整'
                },
                {
                  type: 'ud',
                  desc: '任务宝-创建活动，阶段人数添加校验（每一阶人数需大于上一阶人数）'
                },
                {
                  type: 'ud',
                  desc: '任务宝-创建活动-更多，选择标签弹框增加搜索框'
                }
              ]
            },
            {
              version: 'v1.0.12',
              time: '2021-1-8',
              list: [
                {
                  type: 'ud',
                  desc: '任务宝-创建活动，选择图片后移除选择框'
                },
                {
                  type: 'ui',
                  desc: '任务宝-活动列表，“邀请客户参与”弹框高度调小，适应小屏'
                },
                {
                  type: 'fix',
                  desc: '群分析/客户分析/聊天分析，统一最大可选日期为“昨日”'
                },
                {
                  type: 'fix',
                  desc: '列表页，搜索时未重置页数的问题'
                },
                {
                  type: 'fix',
                  desc: '任务宝-创建任务，切换阶段时，奖品内容未验证的问题'
                },
                {
                  type: 'ud',
                  desc: '员工选择，仅展示该企业已激活的员工'
                },
                {
                  type: 'add',
                  desc: '员工-员工管理-员工列表，新增员工状态'
                },
                {
                  type: 'add',
                  desc: '客户-客户管理-客户列表，新增客户状态'
                }
              ]
            },
            {
              version: 'v1.0.11',
              time: '2021-1-7',
              list: [
                {
                  type: 'ud',
                  desc: '社群-群管理-群列表，群主选择器调整为多选'
                },
                {
                  type: 'ud',
                  desc: '【通用】员工/群主选择器，调整为弹窗显示'
                },
                {
                  type: 'ud',
                  desc: '群分析/客户分析/聊天分析，自定义日期选择器内日期根据所选日期标签变化'
                },
                {
                  type: 'ud',
                  desc: '除任务宝-活动列表，任务宝-创建活动外，其他页面日期选择器可选最大值为今天'
                },
                {
                  type: 'ud',
                  desc: '任务宝-活动列表，员工身份仅保留“推广”操作权限'
                },
                {
                  type: 'add',
                  desc: '任务宝-创建活动，海报背景图添加支持.jpg格式'
                }
              ]
            }
          ]
        },
        {
          version: '1.0.0',
          children: [
            {
              version: 'v1.0.0',
              time: '2020-12-31',
              list: [
                {
                  desc: '项目上线'
                }
              ]
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.log {
  padding: 24px;
  width: 960px;
  margin: 0 auto;
  font-size: 15px;
  background: #fff;

  &-title {
    font-size: 28px;
    color: #393d49;
    margin-bottom: 24px;
  }

  &-desc {
    background-color: #F7F8FC;
    border: 1px solid #eaeefb;
    padding: 8px 16px;
    font-size: 12px;
    margin: 0;
  }

  &-timeline {
    font-size: 14px;

    ::v-deep .el-timeline-item__timestamp {
      font-size: 24px;
      color: #333;
      padding-top: 0;
    }
  }

  &-list {
    list-style-type: disc;
    padding: 0 0 5px 15px;
    line-height: 32px;
  }

  &-version {
    font-size: 18px;
    color: #333;
    margin-top: 16px;
  }

  &-time {
    margin-left: 8px;
  }
}
</style>
