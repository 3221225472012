var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "30s快速配置",
        visible: _vm.visible,
        width: "640px",
        "custom-class": "setup",
        "before-close": _vm.fn_close
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "setup-body" }, [
        _vm.active === 1
          ? _c("div", [
              _c("div", { staticClass: "setup-code" }, [
                _c(
                  "div",
                  {
                    staticClass: "setup-top",
                    staticStyle: { "margin-top": "24px" }
                  },
                  [_vm._v("扫码开始自动配置")]
                ),
                _c(
                  "div",
                  { class: ["setup-code_img", !_vm.codeUrl ? "is-load" : ""] },
                  [
                    _vm.codeLoading
                      ? _c("div", { staticClass: "setup-code_load" }, [
                          _c("i", { staticClass: "el-icon-loading" }),
                          _c("span", {
                            domProps: { innerHTML: _vm._s(_vm.codeLoadingText) }
                          })
                        ])
                      : _vm._e(),
                    _vm.codeUrl
                      ? _c("img", { attrs: { src: _vm.codeUrl, alt: "" } })
                      : _vm._e()
                  ]
                ),
                _c("div", { staticClass: "setup-code_tips flex flex-center" }, [
                  _vm._v(" 请使用"),
                  _c("img", {
                    staticClass: "setup-code_logo",
                    attrs: { src: _vm.wecomLogo, alt: "" }
                  }),
                  _c("span", { staticClass: "setup-code_text" }, [
                    _vm._v("企业微信")
                  ]),
                  _vm._v("手机app扫码 ")
                ])
              ])
            ])
          : _vm._e(),
        _vm.active === 2
          ? _c(
              "div",
              [
                _c("div", { staticClass: "setup-top" }, [
                  _vm._v("还差最后一步完成配置")
                ]),
                _c(
                  "el-form",
                  {
                    ref: "appFormRef",
                    staticClass: "setup-form",
                    attrs: {
                      model: _vm.appform,
                      "label-width": "0",
                      rules: _vm.appFormRules
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "setup-form_item",
                        attrs: {
                          label: "客户联系Secret：",
                          prop: "internal_secret",
                          error: _vm.cusError
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "输入发送到企业微信号的客户联系Secret"
                          },
                          model: {
                            value: _vm.appform.internal_secret,
                            callback: function($$v) {
                              _vm.$set(_vm.appform, "internal_secret", $$v)
                            },
                            expression: "appform.internal_secret"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "setup-form_tips" },
                  [
                    _c(
                      "el-popover",
                      {
                        staticClass: "item",
                        attrs: {
                          placement: "top-start",
                          trigger: "hover",
                          offset: 120,
                          "popper-class": "q-tooltip is-light"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              margin: "24px 24px 16px",
                              width: "632px"
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src:
                                  "https://image.01lb.com.cn//uploads/wecom/21/0621/162426325415Y3voQM.png",
                                width: "632",
                                alt: ""
                              }
                            })
                          ]
                        ),
                        _c(
                          "span",
                          { attrs: { slot: "reference" }, slot: "reference" },
                          [_vm._v("如何在企业微信号查看 Secret？")]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "setup-form_btn",
                    attrs: { type: "primary", loading: _vm.btnLoading },
                    on: {
                      click: function($event) {
                        return _vm.fn_subAppform("appFormRef")
                      }
                    }
                  },
                  [_vm._v("完成")]
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "setup-contact_block" },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: { effect: "light", placement: "right" }
            },
            [
              _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _c("div", { staticClass: "setup-contact" }, [
                  _c("div", { staticClass: "setup-contact_title" }, [
                    _vm._v("添加专属客服")
                  ]),
                  _c("div", { staticClass: "setup-contact_text" }, [
                    _vm._v("一对一为您答疑解惑")
                  ]),
                  _c("div", { staticClass: "setup-contact_code" }, [
                    _c("img", { attrs: { src: _vm.contactCode, alt: "" } })
                  ])
                ])
              ]),
              _c("span", { staticClass: "setup-contact_link" }, [
                _c("i", { staticClass: "iconfont icon-a-customerservice" }),
                _vm._v("联系客服处理")
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }