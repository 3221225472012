/*
 * @Author: zhanln
 * @Date: 2022-03-21 17:53:31
 * @LastEditTime: 2022-03-23 15:12:31
 * @LastEditors: zhanln
 * @Description:
 */
// 通用素材路由
export default [
  {
    title: '通用素材',
    id: 1003,
    path: '/material',
    icon: ['material.png', 'material_active.png'],
    children: [
      {
        title: '表单',
        id: 100301,
        path: '/material/formTable',
        badge: '',
        children: [
          {
            title: '表单列表',
            id: 10030101,
            path: '/material/formTable/list',
            component: '/apps/formTable/list.vue'
          },
          {
            title: '数据',
            id: 10030102,
            path: '/material/formTable/detail',
            component: '/apps/formTable/detail.vue',
            backPath: '/material/formTable/list',
            is_menu: 0,
            pid: 10030101
          },
          {
            title: '表单编辑',
            id: 10030103,
            path: '/material/formTable/edit',
            component: '/apps/formTable/add.vue',
            backPath: '/material/formTable/list',
            is_menu: 0,
            pid: 10030101
          }
        ]
      },
      {
        title: '兑换码',
        id: 100302,
        path: '/material/code',
        badge: '',
        children: [
          {
            title: '兑换码列表',
            id: 10030201,
            path: '/material/code/list',
            component: '/apps/code/list.vue'
          },
          {
            title: '兑换码详情',
            id: 10030202,
            path: '/material/code/detail',
            component: '/apps/code/detail.vue',
            backPath: '/material/code/list',
            is_menu: 0,
            pid: 10030201
          }
        ]
      },
      {
        title: '素材库',
        id: 100303,
        path: '/material/library',
        badge: '',
        children: [
          {
            title: '企业素材',
            id: 10030301,
            path: '/material/library/enterprise',
            component: '/material/library/enterprise.vue'
          },
          {
            title: '个人素材',
            id: 10030302,
            path: '/material/library/personal',
            component: '/material/library/personal.vue'
          }
        ]
      }
    ]
  }
]
