/*
 * @Author: zhanln
 * @Date: 2021-06-18 15:13:11
 * @LastEditTime: 2021-11-27 19:05:18
 * @LastEditors: zhanln
 * @Description: 任务宝
 */

const base = '/wecom/task'

export default {
  // 创建活动
  initTasks: base + 's',
  // 活动名称
  taskName: base + 's/name',
  // 参与用户
  taskAccounts: base + '/accounts',
  // 参与用户统计
  statistic: base + '/statistic',
  // 参与用户导出
  taskAccountExport: base + '/account-export',
  // 活动列表
  taskList: base + 's/list/list',
  // 活动列表（参与客户）
  taskListJoins: base + 's/list/joins',
  // 活动列表（奖品记录）
  taskListRecords: base + 's/list/records',
  // 邀请客户参与
  inviteAccount: base + '/invite-account',
  // 奖品记录统计
  rewardStatistic: base + '/reward-statistic',
  // 奖品记录
  taskRewerdExport: base + '/reward-export',
  // 奖品记录导出
  rewardRecords: base + '/reward-records',
  // 活动推广
  taskUrls: base + '/urls',
  // 开始活动
  updateStatusStart: base + '/update-status/start',
  // 暂停活动
  updateStatusPause: base + '/update-status/pause',
  // 结束活动
  updateStatusEnd: base + '/update-status/end',
  // 清楚海报缓存
  taskClearCache: base + '/clear-cache',
  // 修改奖品发送成功状态
  updateRewardStatus: base + '/update-reward-status',
  // 修改奖品发送状态2.0
  updateRewardStatusV2: base + '/resend-reward',
  // 查询企微员工是否可以配置联系我
  wecomQrcodeUsersValid: base + '/wecomQrcodeUsersValid',
  // 实时计算活动发送客户数量
  customerSum: base + 's/sendmsg/num',
  // 兑换码列表
  taskCodeList: base + '/code-select',
  // 兑换码详情列表
  taskCodeDetail: base + '/code-detail',
  // 任务宝-重新发送兑换码
  taskResendCode: base + '/resend-code',
  // 是否有数据
  taskRecord: base + '/has-record',
  // 渠道列表
  taskChannel: base + '/channel-list',
  // 创建渠道
  taskCreateChannel: base + '/create-channel',
  // 修改渠道
  taskUpdateChannel: base + '/update-channel',
  // 删除渠道
  taskDelChannel: base + '/delete-channel'
}
