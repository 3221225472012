<!--
 * @Author: zhanln
 * @Date: 2021-06-10 11:25:49
 * @LastEditTime: 2023-01-31 11:27:51
 * @LastEditors: zhanln
 * @Description:
-->
<template>
  <div class="company">
    <!-- haeader -->
    <login-header v-if="showHeader" showUserInfo showUserCenter></login-header>

    <!-- company loading -->
    <div
      class="company-width company-loading"
      v-if="companyLoading"
      v-loading="companyLoading"
      element-loading-text="正在加载您的企业，请稍等"
    ></div>

    <template v-if="!companyLoading">
      <div class="company-width">
        <!-- 服务商列表 -->
        <div class="company-container" v-if="serviceList.length > 0">
          <div class="company-title">我的服务商</div>
          <div class="company-main">
            <el-row :gutter="24">
              <el-col
                :span="8"
                v-for="(item, index) in serviceList"
                :key="index"
              >
                <div
                  class="company-card service"
                  @click="fn_jumpToService(item.corp_id)"
                >
                  <div class="company-card_header">
                    <div class="left">
                      {{
                        item.corp_name.substr(0, 1) ||
                        item.corp_full_name.substr(0, 1)
                      }}
                    </div>
                    <div class="right">
                      <div class="company-card_title">
                        {{ item.corp_name || item.corp_full_name }}
                      </div>
                      <div class="company-card_user">
                        <div class="avatar">
                          <img
                            :src="item.avatar_url"
                            alt=""
                            v-if="item.avatar_url !== ''"
                          />
                          <img :src="avatarDefault" alt="" v-else />
                        </div>
                        <div class="name">
                          <div class="text" :title="item.nickname">
                            {{ item.nickname }}
                          </div>
                          <div class="gap"></div>
                          <div :class="['title', item.role_type_color]">
                            {{ item.role_type }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>

        <!-- 企业列表 -->
        <div class="company-container enterprise" v-if="companyTotal > 0">
          <div class="company-header" style="margin: 0">
            <div class="company-title">
              我的企业
              <el-button
                type="primary"
                size="small"
                round
                icon="iconfont icon-plus "
                class="ml-12"
                @click="fn_showBindCompanyDialog"
                v-if="showReg"
                >关联企业</el-button
              >
            </div>

            <el-button
              type="text"
              v-if="companyTotal > 0"
              @click="fn_showDel"
              style="margin-left: 16px"
              >{{ showDelIcon ? '取消' : '账号解绑' }}</el-button
            >
          </div>

          <div class="company-main">
            <el-row :gutter="24">
              <el-col
                :span="8"
                v-for="(item, index) in companyList"
                :key="index"
              >
                <div
                  :class="['company-card', showDelIcon ? 'silence' : '']"
                  @click="fn_companyClick(item, index)"
                >
                  <div
                    class="company-del"
                    v-if="showDelIcon"
                    @click.stop="fn_delCorp(item)"
                  >
                    <i class="el-icon-remove"></i>
                  </div>
                  <div class="company-card_header">
                    <div class="left">
                      {{
                        item.corp_name.substr(0, 1) ||
                        item.corp_full_name.substr(0, 1)
                      }}
                    </div>
                    <div class="right">
                      <div
                        class="company-card_title"
                        :title="item.corp_name || item.corp_full_name"
                      >
                        {{ item.corp_name || item.corp_full_name }}
                      </div>
                      <div class="company-card_user">
                        <div class="avatar">
                          <img
                            :src="item.avatar_url"
                            alt=""
                            v-if="item.avatar_url !== ''"
                          />
                          <img :src="avatarDefault" alt="" v-else />
                        </div>
                        <div class="name">
                          <div class="text" :title="item.nickname">
                            {{ item.nickname }}
                          </div>
                          <div class="gap"></div>
                          <div :class="['title', item.role_type_color]">
                            {{ item.role_type }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="company-card_content">
                    <div class="company-card_list">
                      <div class="item">
                        <span class="label">企业状态：</span>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          placement="top"
                          popper-class="q-tooltip"
                          :disabled="!corpTypeObj[item.corp_status].showTool"
                        >
                          <div
                            slot="content"
                            v-html="corpTypeObj[item.corp_status].tool"
                          ></div>
                          <span
                            :class="[
                              'text',
                              !corpTypeObj[item.corp_status].showTool
                                ? 'q-success'
                                : 'q-danger'
                            ]"
                            >{{ corpTypeObj[item.corp_status].type }}
                            <i
                              class="iconfont icon-warning-fill"
                              v-if="corpTypeObj[item.corp_status].showTool"
                            ></i
                          ></span>
                        </el-tooltip>
                      </div>
                      <div class="item">
                        <span class="label">员工状态：</span>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          placement="top"
                          popper-class="q-tooltip"
                          :disabled="!empTypeObj[item.type].showTool"
                        >
                          <div
                            slot="content"
                            v-html="empTypeObj[item.type].tool"
                          ></div>
                          <span
                            :class="[
                              'text',
                              !empTypeObj[item.type].showTool
                                ? 'q-success'
                                : 'q-danger'
                            ]"
                            >{{ empTypeObj[item.type].type }}
                            <i
                              class="iconfont icon-warning-fill"
                              v-if="empTypeObj[item.type].showTool"
                            ></i
                          ></span>
                        </el-tooltip>
                      </div>
                      <div class="item">
                        <span class="label">员工数量：</span>
                        <span class="text">{{ item.corp_count }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>

        <empty-card v-else @handleBind="fn_handleBind"></empty-card>
      </div>
    </template>

    <login-footer></login-footer>

    <!-- 关联企业卡片 -->
    <bind-card ref="bindCardRef" @handleBind="fn_handleBind"></bind-card>

    <!-- 代自建应用 -->
    <instead-app
      ref="insteadAppRef"
      @enter="fn_enter"
      @updateWecoms="fn_getCompnayList"
    ></instead-app>

    <!-- 进入企业卡片 -->
    <enter-card ref="enterCardRef"></enter-card>

    <!-- 关联/登录失败弹框 -->
    <error-card
      ref="errorCardRef"
      :title="errorCardTit"
      :content="errorCardContent"
      @handleBack="fn_initDate(true)"
    ></error-card>

    <!-- 点击企业卡片状态弹框 type === 1 为第三方应用未安装，type === 2 为第三方应用未授权-->
    <tip-card ref="tipCardRef"></tip-card>
  </div>
</template>

<script>
import loginHeader from './components/LoginHeader'
import loginFooter from './components/LoginFooter'
import emptyCard from './cards/emptyCard.vue'
import bindCard from './cards/bindCard.vue'
import enterCard from './cards/enterCard.vue'
import tipCard from './cards/tipCard.vue'
import errorCard from './cards/errorCard.vue'
import insteadApp from './cards/insteadAppCard.vue'
export default {
  name: 'Company',

  data () {
    return {
      userInfo: {},
      companyLoading: true,
      serviceList: [],
      companyTotal: null,
      companyList: [],
      adminName: '',
      adminAvatar: '',
      outInterval: null,
      avatarDefault: require('@/assets/images/default_avatar.png'),
      tipCardAvatar: '',
      tipCardName: '',
      tipCardEName: '', // 企业名称
      tipCardType: 2,
      errorCardTit: '',
      errorCardContent: '',
      showHeader: false,
      corpTypeObj: {
        0: {
          showTool: true,
          type: '自建应用未授权',
          tool: '当前企业未授权自建应用，<br />请联系贵公司创建者登录本系统完成授权。'
        },
        1: {
          showTool: false,
          type: '已授权'
        },
        2: {
          showTool: true,
          type: '客户联系参数未配置',
          tool: '当前企业客户联系参数未配置，<br />请联系贵公司创建者登录本系统完成授权。'
        },
        3: {
          showTool: true,
          type: '客户联系参数配置异常',
          tool: '当前企业客户联系参数配置异常，<br />请联系贵公司创建者登录本系统完成授权。'
        },
        4: {
          showTool: true,
          type: '自建应用配置异常',
          tool: '当前企业员工/客户权限配置异常，<br />请联系贵公司创建者登录本系统完成配置。'
        },
        5: {
          showTool: true,
          type: '自建应用未配置',
          tool: '当前企业自建应用未配置，<br />请联系贵公司创建者登录本系统完成配置。'
        }
      },
      empTypeObj: {
        0: {
          showTool: true,
          type: '未授权',
          tool: '当前企业未授权员工权限范围，<br />请联系贵公司创建者登录本系统完成配置。'
        },
        1: {
          showTool: false,
          type: '已授权'
        }
      },
      showDelIcon: false
    }
  },

  async created () {
    // 如果从企微返回
    const auth_type = this.$route.query.auth_type
    if (auth_type) {
      localStorage.removeItem('backCompanyId')
      if (auth_type === 'login') { // 员工登录返回
        await this.fn_wecomLogin(this.$route.query.auth_code)
      }
    } else { // 不是从企微返回
      // 如果当前 token 为企业 token，则获取账号 token
      await this.$store.dispatch('permission/GenerateAccountToken')

      this.showHeader = true

      this.fn_initDate()
    }
  },

  computed: {
    showReg () {
      // 根据用户id单独开发入口（夏）
      const localUserInfo = JSON.parse(localStorage.getItem('userInfo'))
      return localUserInfo && localUserInfo.id && (localUserInfo.id === 1351115 || localUserInfo.id === 35994)
    }
  },

  destroyed () {
    if (this.outInterval) {
      clearInterval(this.outInterval)
    }
  },

  methods: {

    // 初始化数据及开启监听
    fn_initDate (type) {
      // 获取服务商列表
      this.fn_getServiceList()

      // 获取企业列表
      this.fn_getCompnayList(type)

      // 监听登出
      this.outInterval = setInterval(() => {
        // 如果手动清除缓存或者其他页面退出登录
        const _token = localStorage.getItem('token')
        if (!_token) {
          this.$router.replace('login')
          this.$message.info('请重新登录')
        }
      }, 1000)
    },

    // 获取服务商列表
    async fn_getServiceList () {
      const Authorization = localStorage.getItem('token')
      const data = await this.axios.post('serviceList', Authorization)

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      if (data.length > 0) {
        this.serviceList = data.map(item => {
          if (item.role_type === '超级管理员') {
            item.role_types = 2
            item.role_type_color = 'warning'
          } else if (item.role_type === '管理员') {
            item.role_types = 1
            item.role_type_color = 'primary'
          } else {
            item.role_types = 0
          }
          return item
        })
      }
    },

    // 获取企业列表
    async fn_getCompnayList (type) {
      const data = await this.axios.get('wecomList')
      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      if (data.data && data.data.length > 0) {
        this.companyList = data.data.map(item => {
          if (item.role_type === '超级管理员') {
            item.role_types = 2
            item.role_type_color = 'warning'
          } else if (item.role_type === '管理员') {
            item.role_types = 1
            item.role_type_color = 'primary'
          } else {
            item.role_types = 0
          }
          return item
        })

        if (type) {
          setTimeout(() => {
            this.$refs.bindCardRef.visible = true
          }, 200)
        }
      } else {
        this.companyList = []
      }

      this.companyTotal = data.total
      this.companyLoading = false
    },

    // 跳转服务商
    fn_jumpToService (id) {
      const Authorization = localStorage.getItem('token')
      let http = 'https://partner.01lb.com.cn'
      if (process.env.NODE_ENV === 'test') {
        http = 'http://partner.test.01lb.com.cn'
      } else if (process.env.NODE_ENV === 'dev') {
        http = 'http://partner.dev.01lb.com.cn'
      } else if (process.env.NODE_ENV === 'prod') {
        http = 'https://partner.01lb.com.cn'
      }
      window.location.href = `${http}/transfer?token=${Authorization}&companyId=${id}`
    },

    // 点击企业卡片
    async fn_companyClick (corp) {
      if (this.showDelIcon) return
      const { corp_status: status, type, is_super_admin, corp_name, corp_full_name } = corp

      // corp_status：0 未授权 1 已授权 2客户联系未配置 3联系参数配置异常 4自建应用配置异常 5自建应用未配置

      // 都已经授权，进入首页
      if (status === 1 && type === 1) {
        this.fn_enter(corp)
        return
      }

      // 非超管提示
      if (is_super_admin !== 1 && (status === 0 || status === 4 || status === 5)) {
        this.$refs.tipCardRef.type = 1
        this.$refs.tipCardRef.visible = true
        return
      }

      // 超管操作
      if (status === 0) {
        this.$refs.insteadAppRef.fn_setStep(1)
        return
      }

      if (status === 4) {
        this.$refs.insteadAppRef.fn_setStep(3, false, corp)
        return
      }

      if (status === 5) {
        this.$refs.insteadAppRef.fn_setStep(2, false, corp)
        return
      }

      // 员工未授权
      if (type === 0) {
        this.$refs.tipCardRef.type = 2
        this.$refs.tipCardRef.corp_name = corp_name || corp_full_name
        this.$refs.tipCardRef.visible = true
        return
      }

      this.fn_enter(corp)
    },

    async fn_enter (corp) {
      this.$refs.insteadAppRef.visible = false
      this.$refs.bindCardRef.visible = false

      this.$refs.enterCardRef.cpnName = corp.corp_name || corp.corp_full_name
      this.$refs.enterCardRef.avatar = corp.avatar_url || corp.user_avatar
      this.$refs.enterCardRef.name = corp.nickname || corp.user_name
      this.$refs.enterCardRef.visible = true

      localStorage.setItem('companyId', corp.corp_id)
      const _type = localStorage.getItem('type')
      if (_type === 'account') {
        await this.fn_getCompanyToken()
      }
      const accessRoutesPromise = await this.$store.dispatch('permission/GenerateRoutes', corp.corp_id)
      this.$router.addRoutes(accessRoutesPromise)

      if (corp.corp_status === 2 && corp.is_super_admin === 1) {
        this.$store.commit('_SET', {
          'global.appConfigShow': true
        })
      }

      if (corp.corp_status === 3 && corp.is_super_admin === 1) {
        this.$router.push({
          path: '/enterprise/management/config'
        })
        return
      }

      this.$router.push({
        path: accessRoutesPromise[0].path
      })
    },

    // 关联企业弹框
    fn_showBindCompanyDialog () {
      this.$refs.bindCardRef.visible = true
      this.$refs.bindCardRef.hasClose = true
    },

    // 员工登录
    async fn_wecomLogin (code) {
      // 清空路径参数
      this.$router.push({
        query: {}
      })

      const data = await this.axios.post('loginByWecomQrcode', {
        auth_code: code
      })

      // 是否超管
      this.$store.commit('_SET', {
        'permission.isAdmin': data.is_super_admin === 1
      })
      // 异常处理
      if (data.code) {
        const _code = data.code
        this.$refs.errorCardRef.visible = true

        if (_code === 0) {
          this.$message.info(data.msg)
          return
        }

        if (_code === 5002) { // 企微未授权
          this.errorCardTit = '登录失败，企业未安装应用'
          this.errorCardContent = '您正在登录的企业没有安装<span style="color: #212b36">零一SCRM应用</span>，<br>请重新选择企业或者安装应用'
          return
        }

        if (_code === 5003) { // 企微用户未授权
          this.errorCardTit = data.msg
          this.errorCardContent = '您当前登录的企业未授权您访问，<br>请联系贵公司企业微信<span style="color: rgba(0, 0, 0, .85)">管理员</span>为你配置应用权限'
          return
        }

        if (_code === 5004) { // 企业身份重复
          const _corp = localStorage.getItem('companyName')
          const _name = this.userInfo.nickname
          this.errorCardTit = '企业身份重复'
          this.errorCardContent = `同一个企业内您只能拥有1个身份，当前企业身份：${_corp}(${_name})，请勿重复绑定该企业的其他身份。其他员工请使用其对应的微信账号自行注册绑定。`
        }
      } else {
        const enterObj = {
          corp_name: data.wecom.corp_name,
          corp_full_name: data.wecom.corp_full_name,
          nickname: data.nickname,
          corp_id: data.wecom.id
        }

        localStorage.setItem('type', 'wecom')
        localStorage.setItem('token', data.token)

        this.fn_enter(enterObj, true, 400)
      }
    },

    // 获取企业token
    async fn_getCompanyToken () {
      const data = await this.axios.post('getToken')
      localStorage.setItem('type', 'wecom')
      localStorage.setItem('token', data.token)
    },

    // 卡片显示解绑按钮
    fn_showDel () {
      this.showDelIcon = !this.showDelIcon
    },

    // 企业解绑确认
    fn_delCorp (item) {
      this.$lyConfirm({
        title: '是否解除当前账号与企业的绑定关系，解除后将无法进入企业',
        text: ''
      }).then(() => {
        this.fn_unbindWecom(item)
      }).catch(() => { })
    },

    // 企业解绑
    async fn_unbindWecom (item) {
      const data = await this.axios.post('unbindWecom', {
        wecom_id: +item.corp_id
      })
      if ((data && data.code) || (data && data.code === 0)) {
        this.fn_lyMsg('info', data.msg)
        return false
      }
      this.fn_lyMsg('success', '解除绑定成功！')
      this.showDelIcon = false
      this.fn_getCompnayList()
      this.$store.dispatch('permission/RemoveWecomUser', { wecomId: +item.corp_id })
    },

    // 代自建应用
    fn_handleBind () {
      this.$refs.insteadAppRef.fn_setStep(1)
    }

  },

  components: {
    loginHeader,
    loginFooter,
    emptyCard,
    bindCard,
    enterCard,
    tipCard,
    errorCard,
    insteadApp
  }
}
</script>

<style lang="scss" scoped>
@import './components/login.scss';
@import '@/assets/scss/utils.scss';

.icon-warning-fill {
  font-size: 14px;
}

.login-title {
  margin-top: 16px;
}
.enterprise {
  margin-bottom: 80px;
  .company-card {
    height: 246px;
  }
}

.company {
  position: relative;
  padding-top: 64px;
  display: flex;
  // justify-content: center;
  min-height: calc(100% - 64px);

  &-del {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: $--color-danger;
    border-radius: 50%;
    cursor: pointer;
    animation: showDelAnim 0.3s;
  }

  &-main {
    margin-bottom: 40px;
  }

  &-width {
    width: 1200px;
    margin: 0 auto;
  }

  ::v-deep &-loading {
    .el-loading-mask {
      background-color: transparent;
    }
  }

  &-default {
    margin-top: 26vh;

    ::v-deep &__btn {
      &-icon {
        position: relative;
        left: 0;
        transition: 0.25s;
      }

      &:hover {
        .company-default__btn-icon {
          left: 6px;
        }
      }
    }
  }

  &-title {
    position: relative;
    font-size: 18px;
    font-weight: 500;
    color: #212b36;
    margin: 24px 0;
    padding-left: 12px;
    line-height: 24px;

    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      width: 4px;
      height: 16px;
      background-color: #2b7cff;
      border-radius: 2px;
      transform: translateY(-50%);
    }
  }

  &-header {
    margin: 0 0 24px 0;
    display: flex;
    justify-content: space-between;
  }

  &-card {
    position: relative;
    margin-bottom: 24px;
    border-radius: 16px;
    box-shadow: 0px 16px 32px -4px rgba(145, 158, 171, 0.24),
      0px 0px 2px 0px rgba(145, 158, 171, 0.24);
    transition: 0.25s;
    background: url('../../assets/images/company_bg.png') right 101% no-repeat
      #fff;

    &:not(.silence):hover {
      cursor: pointer;
      transform: translateY(-10px);
      box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.05);
    }

    &_loading {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    &_header {
      padding: 24px;
      display: flex;
      .left {
        width: 54px;
        height: 54px;
        border-radius: 54px;
        background: #2b7cff;
        text-align: center;
        line-height: 54px;
        color: #fff;
        font-size: 16px;
        margin-right: 17px;
      }
      .right {
        flex: 1;
        overflow: hidden;
      }
    }

    &_title {
      font-weight: 500;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 10px;
      @include ellipsis;
    }

    &_user {
      display: flex;
      align-items: center;
      .avatar {
        margin-right: 10px;
        line-height: 1;
        img {
          width: 24px;
          height: 24px;
          display: block;
          border-radius: 24px;
        }
      }
      .name {
        display: flex;
        align-items: center;
        overflow: hidden;
        .text {
          flex: 1;
          @include ellipsis;
        }
      }
      .gap {
        width: 1px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0.15);
        margin: 0 10px;
      }
      .title {
        height: 24px;
        line-height: 24px;
        border-radius: 24px;
        padding: 0 10px;
        background: #f0f1f8;
        color: #454d5b;
        font-size: 12px;
        box-sizing: border-box;

        &.warning {
          background: #fffbe6;
          color: #faad14;
        }

        &.primary {
          background: #ebf3ff;
          color: #2263cc;
        }
      }
    }

    &_userContent {
      overflow: hidden;
    }

    &_name {
      display: flex;
      .text {
        @include ellipsis;
      }
    }

    &_content {
      padding: 16px 24px 24px;
    }

    &_list {
      font-size: 12px;
      & > .item {
        line-height: 22px;
        margin-bottom: 10px;
      }
    }

    &.service {
      min-height: auto;

      .company-card_header {
        border: none;
      }
    }
  }
}

.admin-avatar {
  position: relative;
  top: 3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin: 0 6px;
}

@keyframes showDelAnim {
  0% {
    transform: scale(0);
  }

  60% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}
</style>
