<template>
  <div class="ly-tag-select">
    <el-input suffix-icon="iconfont icon-down" @focus="handleFocus" readonly v-model="placeholderVal"></el-input>
    <ly-tag-dialog
      ref="tagDialog"
      :checked="value || ''"
      @getSelect="fn_getSelect">
    </ly-tag-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      checkList: [],
      placeholderVal: '全部'
    }
  },
  watch: {
    value (n) {
      if (!n) {
        this.placeholderVal = '全部'
      }
    }
  },
  methods: {
    handleFocus () {
      this.$refs.tagDialog.visible = true
    },

    // 弹框选中回调
    fn_getSelect (selected) {
      this.checkList = selected
      const tagIds = selected.map(e => e.tagId)
      const tagName = selected.map(e => e.tagName)
      this.placeholderVal = tagName.join(',')
      this.$emit('input', tagIds.join(','))
    }
  }
}
</script>

<style>

</style>
