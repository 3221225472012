/*
 * @Author: zhanln
 * @Date: 2021-06-18 15:12:06
 * @LastEditTime: 2022-03-08 21:17:54
 * @LastEditors: zhanln
 * @Description: 个人
 */

const base = '/account'

export default {
  // 获取登录二维码
  qrCode: base + '/auth/wechatQrcode',
  // 获取验证码
  checkCode: base + '/auth/sendCaptcha',
  // 通过 scene 登录
  login: base + '/auth/loginByScene',
  // 通过手机登录
  loginByMobile: base + '/auth/login-by-phone',
  // 授权企业
  wecom: base + '/auth/wecom',
  // 绑定手机号
  bindMobile: base + '/auth/bindMobile',
  // 获取账号详情
  getInfo: base + '/my/info',
  // 是否需要验证
  isVerify: base + '/my/isVerify',
  // 验证码是否正确
  verifyCaptcha: base + '/my/verifyCaptcha',
  // 获取更换微信二维码
  updateWechatQrcode: base + '/my/updateWechatQrcode',
  // 新二维码登录
  updateWechatRes: base + '/my/updateWechatRes',
  // 修改手机号
  updateMobile: base + '/my/updateMobile',
  // 获取企微登录二维码
  qrcodeUrl: base + '/auth/wecomQrcodeUrl',
  // 通过企微二维码登录
  loginByWecomQrcode: base + '/auth/loginByWecomQrcode',
  // 企业列表
  wecomList: base + '/my/wecoms',
  // 获取企业后台token
  getToken: base + '/auth/getWecomUserToken',
  // 解绑企业
  unbindWecom: base + '/auth/unbind-wecom',
  // 代自建应用手动完成配置
  customizedFinish: base + '/auth/customized-finish'
}
