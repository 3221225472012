<!--
 * @Author: zhanln
 * @Date: 2021-06-10 18:08:18
 * @LastEditTime: 2022-02-23 18:44:48
 * @LastEditors: zhanln
 * @Description: 安装自建应用
-->

<template>
  <el-dialog
    title="30s快速配置"
    :visible.sync="visible"
    width="640px"
    custom-class="setup"
    :before-close="fn_close"
  >
    <div class="setup-body">
      <!-- 扫码 -->
      <div v-if="active === 1">
        <div class="setup-code">
          <div class="setup-top" style="margin-top: 24px">扫码开始自动配置</div>
          <div :class="['setup-code_img', !codeUrl ? 'is-load' : '']">
            <div class="setup-code_load" v-if="codeLoading">
              <i class="el-icon-loading"></i>
              <span v-html="codeLoadingText"></span>
            </div>
            <img :src="codeUrl" alt="" v-if="codeUrl" />
          </div>
          <div class="setup-code_tips flex flex-center">
            请使用<img :src="wecomLogo" alt="" class="setup-code_logo" /><span
              class="setup-code_text"
              >企业微信</span
            >手机app扫码
          </div>
        </div>
        <!-- <div class="setup-code_manually" @click="fn_jumpToConfig">
          自有技术人员手动配置（不推荐）
        </div> -->
      </div>

      <!-- 配置客户 Secret -->
      <div v-if="active === 2">
        <div class="setup-top">还差最后一步完成配置</div>
        <el-form
          :model="appform"
          label-width="0"
          :rules="appFormRules"
          ref="appFormRef"
          class="setup-form"
        >
          <!-- <el-form-item
            label="自建应用Secret："
            prop="app_secret"
            class="setup-form_item"
            :error="appError"
          >
            <el-input
              v-model="appform.app_secret"
              placeholder="输入发送到企业微信号的自建应用Secret"
            ></el-input>
          </el-form-item> -->
          <el-form-item
            label="客户联系Secret："
            prop="internal_secret"
            class="setup-form_item"
            :error="cusError"
          >
            <el-input
              v-model="appform.internal_secret"
              placeholder="输入发送到企业微信号的客户联系Secret"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="setup-form_tips">
          <el-popover
            class="item"
            placement="top-start"
            trigger="hover"
            :offset="120"
            popper-class="q-tooltip is-light"
          >
            <div style="margin: 24px 24px 16px; width: 632px">
              <img
                src="https://image.01lb.com.cn//uploads/wecom/21/0621/162426325415Y3voQM.png"
                width="632"
                alt=""
              />
            </div>
            <span slot="reference">如何在企业微信号查看 Secret？</span>
          </el-popover>
        </div>
        <el-button
          type="primary"
          class="setup-form_btn"
          :loading="btnLoading"
          @click="fn_subAppform('appFormRef')"
          >完成</el-button
        >
      </div>
    </div>

    <!-- 客服 -->
    <div class="setup-contact_block">
      <el-tooltip class="item" effect="light" placement="right">
        <div slot="content">
          <div class="setup-contact">
            <div class="setup-contact_title">添加专属客服</div>
            <div class="setup-contact_text">一对一为您答疑解惑</div>
            <div class="setup-contact_code">
              <img :src="contactCode" alt="" />
            </div>
          </div>
        </div>
        <span class="setup-contact_link"
          ><i class="iconfont icon-a-customerservice"></i>联系客服处理</span
        >
      </el-tooltip>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'setup',
  data () {
    return {
      visible: false,
      codeUrl: '',
      wecomLogo: require('@assets/svg/wc_work_ic.svg'),
      active: 1,
      appform: {
        // app_secret: '',
        internal_secret: ''
      },
      appError: '',
      cusError: '',
      appFormRules: {
        // app_secret: [
        //   { required: true, message: '请输入自建应用Secret', trigger: 'change' }
        // ],
        internal_secret: [
          { required: true, message: '请输入客户联系Secret', trigger: 'change' }
        ]
      },
      btnLoading: false,
      contactCode: require('@assets/images/contact_me_qr.png'),
      configKey: '',
      codeLoading: true,
      configTime: 2000,
      codeLoadingText: '二维码加载中...',
      configInterval: null
    }
  },

  watch: {
    visible (val) {
      if (val) {
        this.fn_getConfigCode()
      }
    }
  },

  methods: {

    // 获取授权状态
    async fn_getAuthStatus (type = true) {
      const data = await this.axios.get('authStatus')

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }
      this.$store.commit('_SET', {
        'global.appConfig': data.err_status
      })
    },

    // 获取配置二维码
    async fn_getConfigCode () {
      const data = await this.axios.get('authAppQrcode')

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      this.codeUrl = data.url
      this.configKey = data.qrcode_key
      this.codeLoading = false

      this.configInterval = setInterval(() => {
        this.fn_appConfig()
      }, this.configTime)
    },

    // 扫码监听
    async fn_appConfig () {
      const data = await this.axios.get('authAppRes', {
        params: {
          qrcode_key: this.configKey
        }
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        return false
      }

      if (data.status === '2') {
        // 扫码成功
        this.codeLoading = true
        this.codeLoadingText = '扫码成功<br>请在手机点击登录'
      } else if (data.status === '3' || data.status === '4') {
        // 登录成功
        this.codeLoading = true
        this.codeLoadingText = '应用配置中<br>请勿刷新或跳转此页面'
      } else if (data.status === '5') {
        // 配置失败
        clearInterval(this.configInterval)
        this.$lyConfirm({
          title: '配置异常，请重新扫码配置',
          text: '',
          showCancelButton: false
        }).then(() => {
          this.fn_getConfigCode()
        }).catch(() => { })
      } else if (data.status === '8') {
        // 配置成功
        clearInterval(this.configInterval)
        this.codeLoadingText = '配置完成<br>即将进入下一步'
        this.active = 2
      }
    },

    // 配置自建应用
    fn_subAppform (formName) {
      this.appError = ''
      this.cusError = ''
      // 表单验证
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true
          this.fn_setting()
        } else {
          return false
        }
      })
    },

    // 提交表单
    async fn_setting () {
      const data = await this.axios.post('appSetting', this.appform)

      if ((data && data.code) || (data && data.code === 0)) {
        this.$message.error(data.msg)
        this.btnLoading = false
        return false
      }

      this.btnLoading = false

      if (data.secret_check === 1) {
        this.appError = '自建应用Secret 异常，请重新输入'
        return false
      }

      if (data.internal_check === 1) {
        this.cusError = '客户联系Secret 异常，请重新输入'
        return false
      }

      this.$message.success('配置完成！')
      this.visible = false
      this.fn_getAuthStatus()
    },

    // 关闭弹框
    fn_close () {
      this.$lyConfirm({
        title: '安装未完成，您确定要取消安装吗？',
        text: '',
        confirmButtonText: '确定'
      }).then(async () => {
        clearInterval(this.configInterval)
        this.visible = false
        this.$emit('close', false)
      }).catch(() => { })
    },

    // 跳转手动配置
    fn_jumpToConfig () {
      this.$router.push('/configuration')
    },

    // 跳转帮助文档
    fn_jumpToHelp () {
      window.open('https://work.weixin.qq.com/wework_admin/register_wx?from=myhome_openApi', '_blank')
    }
  },

  beforeDestroy () {
    clearInterval(this.configInterval)
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/var.scss";
::v-deep .setup {
  border-radius: 16px;
}

.setup {
  display: flex;
  align-items: center;

  &-close {
    position: absolute;
    right: 24px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    top: 16px;
    font-size: 16px;
    color: #000;
    cursor: pointer;
    border-radius: 2px;
    font-weight: bold;

    &:hover {
      color: $--color-primary;
    }
  }

  &-body {
    padding: 30px 80px;
    box-sizing: border-box;
  }

  &-title {
    font-size: 20px;
    font-weight: 500;
    color: $--color-text-primary;
    text-align: center;
  }

  &-top {
    text-align: center;
    color: $--color-text-primary;
    font-size: 16px;
    font-weight: 500;

    &_check {
      display: inline-block;
      width: 24px;
      height: 24px;
      background-color: $--color-success;
      color: #fff;
      border-radius: 50%;
      text-align: center;
      line-height: 24px;
      margin-right: 8px;
    }
  }

  &-step {
    margin-top: 30px;
    display: flex;

    &_item {
      &:not(:last-child) {
        flex: 1;
        margin-right: 12px;
      }
      display: flex;
      align-items: center;
      overflow: hidden;
    }

    &_index {
      width: 24px;
      height: 24px;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      border: 1px solid rgba(0, 0, 0, 0.25);
      color: rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      margin-right: 8px;

      &.active {
        color: #fff;
        border-color: $--color-primary;
        background-color: $--color-primary;
      }

      &.success {
        color: $--color-primary;
        border-color: $--color-primary;
        background-color: transparent;
      }
    }

    &_text {
      color: $--color-text-secondary;
      position: relative;

      &.active {
        color: $--color-text-primary;
      }

      &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        top: 50%;
        left: 100%;
        background-color: rgba(0, 0, 0, 0.15);
        margin-left: 12px;
      }
    }
  }

  &-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 332px;
    height: 308px;
    box-shadow: 0px 8px 16px 0px rgba(145, 158, 171, 0.24);
    border-radius: 8px;
    margin: 0 auto;
    &_load {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.95);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      i {
        font-size: 32px;
      }

      span {
        margin-top: 24px;
        text-align: center;
      }
    }

    &_img {
      position: relative;
      width: 180px;
      height: 180px;
      margin: 16px 0;
      padding: 3px;
      box-sizing: border-box;
      border: 1px dashed #a5a5a5;

      &.is-load {
        background-color: #f8f9fb;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    &_tips {
      text-align: right;
      font-size: 12px;
      color: #6e788a;
    }

    &_logo {
      margin: 0 6px 0 4px;
      height: 16px;
    }

    &_text {
      color: #2f7dcd;
      margin-right: 2px;
    }

    &_manually {
      width: 332px;
      background-color: #f8f8f8;
      color: $--color-text-regular;
      padding: 0 16px;
      border-radius: 8px;
      margin: 20px auto 0;
      cursor: pointer;
      height: 40px;
      line-height: 40px;
      box-sizing: border-box;
      text-align: center;
    }
  }

  &-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 14px;

    &_block {
      text-align: right;
      font-size: 12px;
      color: #454d5b;
      .icon-a-customerservice {
        margin-right: 5px;
        font-size: 14px;
      }
    }

    &_title {
      color: $--color-text-primary;
    }

    &_text {
      font-size: 12px;
      margin: 6px 0 10px 0;
    }

    &_code {
      img {
        height: 100px;
      }
    }

    &_item {
      margin: 40px 0;

      i {
        font-size: 16px;
        margin-left: 4px;
      }
    }
  }

  &-form {
    margin-top: 40px;
    ::v-deep {
      .el-form-item {
        margin-bottom: 14px;
      }
      .el-form-item__label {
        width: 100% !important;
        text-align: left;
      }
    }
    &_tips {
      text-align: right;
      font-size: 12px;
      color: $--color-primary;
      cursor: pointer;
    }

    &_btn {
      display: flex;
      width: 192px;
      height: 44px;
      border-radius: 44px;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 45px auto 0;
    }
  }
}
</style>
