var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c("login-header", { attrs: { showWork: "" } }),
      _vm.loginType === "code"
        ? _c("login-card", [
            _c("div", { staticClass: "login-wechat" }, [
              _c("div", { staticClass: "login-title" }, [
                _vm._v("微信扫码登录")
              ]),
              _c("div", { staticClass: "login-info" }, [
                _vm._v("请使用微信扫描二维码登录")
              ])
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.wechatCodeLoading,
                    expression: "wechatCodeLoading"
                  }
                ],
                staticClass: "login-wechat__code qy-mtop__3",
                attrs: {
                  "element-loading-spinner": "el-icon-loading",
                  "element-loading-text": _vm.loadingText,
                  "element-loading-custom-class": "login-loading"
                }
              },
              [
                _c("img", { attrs: { src: _vm.wechatCode, alt: "" } }),
                _vm.wechatCodeReload
                  ? _c(
                      "div",
                      {
                        staticClass: "login-wechat__reload",
                        on: { click: _vm.fn_getWechatCode }
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont icon-shuaxin1-right"
                        }),
                        _c("span", [_vm._v("二维码失效，点击刷新")])
                      ]
                    )
                  : _vm._e()
              ]
            ),
            _c(
              "div",
              { staticStyle: { "margin-top": "32px", "font-size": "12px" } },
              [
                _c("span", { staticClass: "login-info login-tips" }, [
                  _vm._v("扫码即同意")
                ]),
                _c(
                  "span",
                  {
                    staticClass: "login-regular is-link",
                    on: { click: _vm.fn_jumpToAgreement }
                  },
                  [_vm._v("《零一无限增长用户协议》")]
                )
              ]
            ),
            _c(
              "div",
              {
                staticClass: "login-badge",
                on: {
                  click: function($event) {
                    return _vm.fn_changeType("mobile")
                  }
                }
              },
              [_c("i", { staticClass: "iconfont icon-mobile" })]
            )
          ])
        : _vm._e(),
      _vm.loginType === "mobile"
        ? _c(
            "login-card",
            [
              _c("div", { staticClass: "login-title" }, [_vm._v("手机号登录")]),
              _c(
                "el-form",
                {
                  ref: "formMobileRef",
                  staticClass: "login-form__mobile qy-mtop__8",
                  attrs: { model: _vm.formMobile, rules: _vm.formMobileRules }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "mobile" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请输入手机号",
                                    maxlength: "11",
                                    disabled: _vm.mobileDisable
                                  },
                                  model: {
                                    value: _vm.formMobile.mobile,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formMobile, "mobile", $$v)
                                    },
                                    expression: "formMobile.mobile"
                                  }
                                },
                                [
                                  _c(
                                    "i",
                                    {
                                      attrs: { slot: "prefix" },
                                      slot: "prefix"
                                    },
                                    [_vm._v("手机号")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "code" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 12 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请输入验证码",
                                    maxlength: "6",
                                    disabled: _vm.codeDisable
                                  },
                                  model: {
                                    value: _vm.formMobile.code,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formMobile, "code", $$v)
                                    },
                                    expression: "formMobile.code"
                                  }
                                },
                                [
                                  _c(
                                    "i",
                                    {
                                      attrs: { slot: "prefix" },
                                      slot: "prefix"
                                    },
                                    [_vm._v("验证码")]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "code-btn",
                                  attrs: { disabled: _vm.checkCodeBtnDisable },
                                  on: { click: _vm.fn_getCheckCode }
                                },
                                [
                                  _vm.checkCodeBtnDisable && !_vm.mobileDisable
                                    ? [
                                        _vm._v(
                                          _vm._s(_vm.checkCodeTimeOut / 1000) +
                                            "s"
                                        )
                                      ]
                                    : [_vm._v("发送验证码")]
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticStyle: { "margin-top": "60px" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "login-btn__block mobile",
                          attrs: {
                            type: "primary",
                            loading: _vm.loginLoading,
                            round: ""
                          },
                          on: { click: _vm.fn_loginByMobile }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.loginLoading ? "登录中..." : "登录")
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { "margin-top": "32px", "font-size": "12px" }
                    },
                    [
                      _c("span", { staticClass: "login-info login-tips" }, [
                        _vm._v("登录即同意")
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "login-regular is-link",
                          on: { click: _vm.fn_jumpToAgreement }
                        },
                        [_vm._v("《零一无限增长用户协议》")]
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "login-badge",
                  on: {
                    click: function($event) {
                      return _vm.fn_changeType("code")
                    }
                  }
                },
                [_c("i", { staticClass: "iconfont icon-Code" })]
              )
            ],
            1
          )
        : _vm._e(),
      _c("login-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }