<!--
 * @Author: zhan
 * @Date: 2022-03-15 15:13:22
 * @LastEditTime: 2022-03-17 17:19:17
 * @Description: 按钮样式
-->

<template>
  <div>
    <!-- 选择按钮 -->
    <el-button
      class="emp-btn"
      round
      :icon="icon || ''"
      type="primary"
      @click="fn_open"
      >{{ btnText }}</el-button
    >
    <ly-robot ref="lyRobotRef" @callback="fn_getList"></ly-robot>
  </div>
</template>

<script>
export default {
  name: 'lyRobotBtn',

  props: {
    btnText: {
      type: String,
      default: '选择企微账号'
    },
    icon: {
      type: String,
      default: ''
    },
    robotIds: {
      type: String,
      defualt: ''
    },
    visibleIds: {
      type: String,
      defualt: ''
    }
  },

  methods: {
    fn_open () {
      this.$refs.lyRobotRef.fn_open(this.robotIds, this.visibleIds)
    },

    fn_getList (list) {
      this.$emit('callback', list)
    }
  }
}

</script>
