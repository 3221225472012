/*
 * @Author: zhanln
 * @Date: 2021-08-16 16:18:10
 * @LastEditTime: 2021-12-29 10:56:57
 * @LastEditors: zhanln
 * @Description:
 */
import Vue from 'vue'
Vue.directive('lydebounce', {
  inserted: function (el, binding) {
    const [type = 'input', fn, event = 'keydown', time = 300] = binding.value
    let targetEl
    let timer
    if (type === 'button') {
      targetEl = el
      targetEl.addEventListener('click', () => {
        timer && clearTimeout(timer)
        timer = setTimeout(() => {
          fn()
        }, time)
      })
    } else {
      targetEl = el.getElementsByTagName(type)[0]
      targetEl.addEventListener(event, () => {
        timer && clearTimeout(timer)
        timer = setTimeout(() => {
          fn()
        }, time)
      })
    }
  }
})
