// 引流获客路由
export default [
  {
    title: '引流获客',
    id: 1002,
    path: '/attract',
    icon: ['attract.png', 'attract_active.png'],
    children: [
      {
        title: '个人活码',
        id: 100201,
        path: '/attract/qr',
        badge: '',
        children: [
          {
            title: '活码列表',
            id: 10020101,
            path: '/attract/qr/list',
            component: '/client/qr/list.vue'
          },
          {
            title: '数据分析',
            id: 10020102,
            path: '/attract/qr/analysis',
            component: '/client/qr/analysis.vue'
          },
          {
            title: '新建个人活码',
            id: 10020103,
            path: '/attract/qr/edit',
            component: '/client/qr/edit.vue',
            backPath: '/attract/qr/list',
            is_menu: 0,
            pid: 10020101
          },
          {
            title: '数据分析',
            id: 10020104,
            path: '/attract/qr/simpleAnalysis',
            component: '/client/qr/simpleAnalysis.vue',
            backPath: '/attract/qr/list',
            is_menu: 0,
            pid: 10020101
          }
        ]
      }
    ]
  }
]
