var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "login-footer" },
    [
      _vm._v(" Copyright ©2021 Powered by 零一裂变 Version 1.0.0 "),
      _c(
        "el-link",
        { attrs: { href: "https://beian.miit.gov.cn/", target: "_black" } },
        [_vm._v("粤ICP备18034511号 ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }