var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        "custom-class": "card enter",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: _vm.width + "px",
        top: "24vh"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "card-body" }, [
        _vm.title
          ? _c("div", { staticClass: "card-title enter-title" }, [
              _c("i", { staticClass: "el-icon-success q-success" }),
              _vm._v(_vm._s(_vm.title) + " ")
            ])
          : _vm._e(),
        _c("div", { staticClass: "enter-info" }, [
          _c("div", { staticClass: "enter-info_avatar" }, [
            _c("img", {
              attrs: {
                src: _vm.avatar ? _vm.avatar : _vm.avatarDefault,
                alt: ""
              }
            })
          ]),
          _vm.name
            ? _c("div", { staticClass: "enter-info_name" }, [
                _vm._v(" " + _vm._s(_vm.name) + " ")
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "enter-cpn" }, [
          _c("div", { staticClass: "enter-cpn_name" }, [
            _vm._v(_vm._s(_vm.cpnName))
          ]),
          _c("div", { staticClass: "enter-cpn_load" }, [
            _c("i", { staticClass: "el-icon-loading" }),
            _vm._v("即将进入… ")
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }