<!--
 * @Author: zhanln
 * @Date: 2022-01-10 15:40:27
 * @LastEditTime: 2022-06-07 16:47:17
 * @LastEditors: zhan
 * @Description: 图片裁切
-->

<template>
  <el-dialog :title="title" :visible.sync="visible" width="500px" append-to-body :close-on-click-modal="false">
    <div class="ly-cropper">
      <vueCropper ref="cropper" :img="option.img" :outputSize="option.outputSize" :outputType="option.outputType"
        :info="option.info" :canScale="option.canScale" :autoCrop="option.autoCrop"
        :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight" :fixed="option.fixed"
        :fixedNumber="option.fixedNumber" :full="option.full" :fixedBox="option.fixedBox"
        :canMoveBox="option.canMoveBox" :original="option.original" :centerBox="option.centerBox" :high="option.high"
        :infoTrue="option.infoTrue" :maxImgSize="option.maxImgSize" :enlarge="option.enlarge" :mode="option.mode">
      </vueCropper>
    </div>
    <div class="ly-cropper__tool">
      <div class="left">
        <el-button size="small" icon="el-icon-plus" @click="$refs.cropper.changeScale(1)">放大</el-button>
        <el-button size="small" icon="el-icon-minus" @click="$refs.cropper.changeScale(-1)">缩小</el-button>
        <el-button size="small" icon="el-icon-refresh-right" @click="$refs.cropper.rotateRight()">旋转</el-button>
      </div>
      <el-button type="primary" round size="small" @click="fn_finish">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { VueCropper } from 'vue-cropper'
export default {
  name: 'LyCropper',
  components: {
    VueCropper
  },
  props: {
    title: {
      type: String,
      default: '上传图片调整'
    }
  },
  data () {
    return {
      visible: false,
      option: {
        img: '',
        outputSize: 1, // 剪切后的图片质量（0.1-1）
        outputType: 'png', // 裁剪生成图片的格式
        info: true, // 显示裁剪框的大小信息
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        fixedBox: false, // 固定截图框大小 不/允许改变
        canMove: true, // 图片是否可以移动
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        high: true, // 是否按照设备的dpr 输出等比例图片
        infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        maxImgSize: 2000, // 限制图片最大宽度和高度
        enlarge: 1, // 图片根据截图框输出比例倍数
        mode: 'contain' // 图片默认渲染方式
      }
    }
  },

  methods: {
    fn_init (files) {
      this.visible = true

      this.file = files.raw
      const isJpg = files.raw.type === 'image/jpg' || files.raw.type === 'image/jpeg'

      this.option.outputType = isJpg ? 'jpg' : 'png'

      const reader = new FileReader()
      reader.onload = e => {
        let data
        if (typeof e.target.result === 'object') {
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        this.option.img = data
      }
      reader.readAsArrayBuffer(files.raw)
    },

    // base64 转 file
    fn_dataURLtoFile (dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let len = bstr.length
      const u8arr = new Uint8Array(len)
      while (len--) {
        u8arr[len] = bstr.charCodeAt(len)
      }
      return new File([u8arr], filename, { type: mime })
    },

    // 裁切完成
    fn_finish () {
      this.$refs.cropper.getCropData(data => {
        const file = this.fn_dataURLtoFile(data, this.file.name)
        if (this.$listeners.finish) {
          this.$emit('finish', file)
        }
        this.visible = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ly-cropper {
  height: 452px;

  &__tool {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
