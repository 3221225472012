<template>
  <div class="main" ref="main">
    <el-container class="main-container">
      <el-container class="main-container-inner" v-if="!$route.params.id">
        <el-header height="48px" class="main-header">
          <el-row type="flex" class="row-bg" justify="space-between">
            <el-col :span="12">
              <el-tabs v-if="!$route.meta.backPath" v-model="activeName" @tab-click="$router.push(activeName)"
                :class="{ 'is-only': tabs.length === 1 }">
                <el-tab-pane :label="item.title" :name="item.path" v-for="item in tabs" :key="item.path"></el-tab-pane>
              </el-tabs>
              <div class="ly-tool__back" @click="
                $route.meta.backPath
                  ? $router.push($route.meta.backPath)
                  : $router.back()
              " style="height: 48px" v-else>
                <i class="iconfont icon-left1"></i>{{ firstTitle || $route.meta.title }}{{ cusTitle }}
              </div>
            </el-col>

            <!-- isSale：是否需要购买 -->
            <el-col :span="12" v-if="isSale">
              <div class="main-header--right">
                <!-- isBought：是否已经购买 -->
                <template v-if="!isBought">
                  <span style="margin-right: 12px">您还未开通应用，点击开通立即获得{{
                      appName
                  }}全部功能~</span>
                  <el-button type="text" @click="fn_showPayDialog('first')">开通应用</el-button>
                </template>

                <template v-else>
                  <template v-if="buyInfo">
                    <div v-if="remainDay > 0">
                      应用剩余天数：{{ remainDay }}
                      <el-tag type="danger" size="small" style="
                          margin-right: 12px;
                          margin-left: 12px;
                          border-radius: 12px;
                        ">即将到期</el-tag>
                    </div>

                    <div v-else>
                      应用已到期（{{ expiredTime }}到期）
                      <el-tag type="danger" size="small" style="margin-right: 12px; border-radius: 12px">已到期</el-tag>
                    </div>
                    <el-button type="text" @click="fn_showPayDialog">立即续费</el-button>
                  </template>
                </template>
              </div>
            </el-col>
          </el-row>
        </el-header>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"> </router-view>
        </keep-alive>

        <router-view v-if="!$route.meta.keepAlive"> </router-view>
      </el-container>

      <ly-apps :id="$route.params.id" v-else></ly-apps>
    </el-container>
    <ly-pay :payDialogVisible.sync="payDialogVisible" :title="payTitle" :appTitle="appName" :payType="payType"></ly-pay>
  </div>
</template>

<script>
import lottie from 'lottie-web'
import { Loading } from 'element-ui'
import lyApps from './apps.vue'
import lyPay from '@/components/LyPay'
import { mapState, mapGetters } from 'vuex'
export default {
  data () {
    return {
      payDialogVisible: false,
      activeName: '',
      payTitle: '',
      payType: '',
      loadingInstance: null
    }
  },

  components: {
    lyApps,
    lyPay
  },
  computed: {
    ...mapState({
      firstTitle: state => state.global.firstTitle,
      cusTitle: state => state.global.cusTitle,
      asideWidth: state => state.global.asideWidth,
      loadingData: state => state.global.loading > 0 // 大于0时loading
    }),
    ...mapGetters({
      appsTitle: 'permission/GET_TITLE',
      tabs: 'permission/GET_TAB'
    }),
    isSale () {
      return this.$store.state.buy.isSale
    },
    isBought () {
      return this.$store.state.buy.isBought
    },
    appName () {
      return this.$store.state.buy.appName
    },
    buyInfo () {
      const remainDay = this.$store.state.buy.remainDay
      if (remainDay === null) {
        return false
      } else {
        if (remainDay <= 7) {
          return true
        } else {
          return false
        }
      }
    },
    remainDay () {
      return this.$store.state.buy.remainDay
    },
    expiredTime () {
      return this.$day(this.$store.state.buy.expiredTime * 1000).format('YYYY-MM-DD HH:mm')
    }
  },
  watch: {
    $route: {
      handler ({ path }) {
        this.activeName = path
      },
      immediate: true
    },

    loadingData: {
      handler (n) {
        if (n) {
          this.loadingInstance = Loading.service({
            target: this.$refs.main,
            spinner: 'table-loading',
            fullscreen: false,
            text: '数据加载中...',
            background: 'rgba(255, 255, 255, 0.7)'
          })
          setTimeout(() => {
            this.lottie = lottie.loadAnimation({
              container: document.querySelector('.table-loading'),
              renderer: 'svg',
              loop: true,
              animationData: require('../../assets/loading.json')
            })
          })
        } else {
          this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
            this.loadingInstance && this.loadingInstance.close()
          })
        }
      }
    }
  },
  methods: {
    // 付费弹框
    fn_showPayDialog (type) {
      this.payTitle = type === 'first' ? `购买${this.appName}` : '立即续费'
      this.payType = type === 'first' ? 'first' : 'renewal'
      this.payDialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/layout.scss";

.el-tabs {
  display: inline-block;

  &.is-only::v-deep {
    .el-tabs__item {
      color: $--color-text-primary;
    }

    .el-tabs__active-bar {
      display: none;
    }
  }

  ::v-deep {
    .el-tabs__nav {
      display: flex;
    }

    .el-tabs__item {
      height: 47px;
      line-height: 47px;
      font-size: 16px;

      &.is-active {
        font-weight: 600;
      }
    }

    .el-tabs__header {
      margin-bottom: 0;
    }

    .el-tabs__nav-wrap::after {
      content: unset;
    }

    .el-tabs__active-bar {
      border-radius: 3px;
      height: 3px;
    }
  }
}

.ly-tool__back {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    color: $--color-primary;
  }

  i {
    font-size: 16px;
    margin-right: 8px;
  }
}
</style>
