<!--
 * @Author: zhanln
 * @Date: 2021-06-10 16:06:22
 * @LastEditTime: 2022-02-16 18:04:09
 * @LastEditors: zhanln
 * @Description:
-->

<template>
  <el-dialog
    :visible.sync="visible"
    custom-class="card enter"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :width="width + 'px'"
    top="24vh"
  >
    <div class="card-body">
      <div class="card-title enter-title" v-if="title">
        <i class="el-icon-success q-success"></i>{{ title }}
      </div>
      <div class="enter-info">
        <div class="enter-info_avatar">
          <img :src="avatar ? avatar : avatarDefault" alt="" />
        </div>
        <div class="enter-info_name" v-if="name">
          {{ name }}
        </div>
      </div>
      <div class="enter-cpn">
        <div class="enter-cpn_name">{{ cpnName }}</div>
        <div class="enter-cpn_load">
          <i class="el-icon-loading"></i>即将进入…
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'enterCard',
  data () {
    return {
      visible: false,
      title: '',
      avatarDefault: require('@/assets/images/default_avatar.png'),
      avatar: '',
      name: '',
      width: 340,
      cpnName: '深圳市零一裂变科技有限公司'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/var.scss";
@import "./card.scss";
.enter {
  &-title {
    display: flex;
    align-items: center;
    margin-top: 54px;

    i {
      margin-right: 8px;
      font-size: 20px;
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 62px 0 40px 0;
    width: 75%;

    &_avatar {
      border-radius: 50%;
      border: 1px solid #eeeeee;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      width: 100px;
      box-sizing: border-box;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid rgba($--color-primary, 0.1);
        animation: avatarAct 2s infinite;
        animation-timing-function: ease;
      }

      &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid rgba($--color-primary, 0.1);
        animation: avatarAct 2s infinite;
        animation-timing-function: ease;
        animation-delay: 0.5s;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 50%;
      }
    }

    &_name {
      font-size: 16px;
      font-weight: 400;
      color: $--color-text-primary;
      margin-top: 10px;
    }
  }

  &-cpn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 74px;
    width: 75%;

    &_load {
      color: rgba($color: #000000, $alpha: 0.45);

      i {
        margin-right: 4px;
      }
    }

    &_name {
      margin-bottom: 10px;
      color: $--color-text-primary;
    }
  }
}

@keyframes avatarAct {
  0% {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    left: -50%;
    top: -50%;
    opacity: 0;
  }
}
</style>
