var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mobimodel" }, [
    _c("div", { staticClass: "mobimodel-side left" }),
    _c("div", { staticClass: "mobimodel-side right" }),
    _c(
      "div",
      { staticClass: "mobimodel-view" },
      [
        _c("div", { staticClass: "mobimodel-toolbar" }, [
          _c("div", { staticClass: "item" }, [
            _vm._v(_vm._s(_vm.$day().format("HH:mm")))
          ]),
          _c("div", { staticClass: "bangs" }),
          _vm._m(0)
        ]),
        _c("div", { staticClass: "mobimodel-title" }, [
          _c("div", { staticClass: "mobimodel-title__left" }, [
            _c("i", { class: ["iconfont", _vm.backIcon] })
          ]),
          _c("div", { staticClass: "mobimodel-title__center" }, [
            _c("div", { staticClass: "mobimodel-title__title" }, [
              _vm._v(_vm._s(_vm.title))
            ]),
            _c("div", { staticClass: "mobimodel-title__sub" }, [
              _vm._v(_vm._s(_vm.sub))
            ])
          ]),
          _vm._m(1)
        ]),
        _c(
          "el-scrollbar",
          {
            staticClass: "mobimodel-content",
            style: {
              height: _vm.viewHeight
            }
          },
          [
            _vm.showWel
              ? _c("div", { staticClass: "mobimodel-welmsg" }, [
                  _vm._v(" 您已添加了张三，现在可以开始聊天了。"),
                  _c("br"),
                  _vm._v(" 对方为企业微信用户，"),
                  _c("b", [_vm._v("了解更多")]),
                  _vm._v("。 ")
                ])
              : _vm._e(),
            _vm._t("default", [
              _vm.msgList.length > 0
                ? _vm._l(_vm.msgList, function(item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "mobimodel-content__item" },
                      [
                        _c(
                          "div",
                          { staticClass: "mobimodel-content__avatar" },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@assets/images/avatar.jpg"),
                                alt: ""
                              }
                            })
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mobimodel-content__pop",
                            class: [item.type === "image" ? "lh-1" : ""]
                          },
                          [
                            item.type === "text"
                              ? _c("el-input", {
                                  ref: "refRule",
                                  refInFor: true,
                                  staticClass: "mobimodel-content__pop--text",
                                  attrs: {
                                    type: "textarea",
                                    autosize: "",
                                    resize: "none",
                                    readonly: ""
                                  },
                                  model: {
                                    value: item.content,
                                    callback: function($$v) {
                                      _vm.$set(item, "content", $$v)
                                    },
                                    expression: "item.content"
                                  }
                                })
                              : _vm._e(),
                            item.type === "image"
                              ? [
                                  item.isPoster
                                    ? [
                                        item.image
                                          ? _c("img", {
                                              staticClass:
                                                "mobimodel-content__pop--image is-poster",
                                              attrs: {
                                                src: item.image,
                                                alt: ""
                                              }
                                            })
                                          : _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mobimodel-content__pop--poster"
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: require("@assets/images/poster-default.png"),
                                                    alt: ""
                                                  }
                                                }),
                                                _c(
                                                  "div",
                                                  { staticClass: "text" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.defaultText)
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                      ]
                                    : _c("img", {
                                        staticClass:
                                          "mobimodel-content__pop--image",
                                        attrs: { src: item.image, alt: "" }
                                      })
                                ]
                              : _vm._e(),
                            item.type === "link"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "mobimodel-content__pop--link"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "title ellipsis2" },
                                      [_vm._v(_vm._s(item.title))]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex flex-between flex-center"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "desc ellipsis3" },
                                          [_vm._v(_vm._s(item.desc))]
                                        ),
                                        _c("div", { staticClass: "cover" }, [
                                          item.cover
                                            ? _c("img", {
                                                attrs: {
                                                  src: item.cover,
                                                  alt: ""
                                                }
                                              })
                                            : _c("img", {
                                                attrs: {
                                                  src:
                                                    "https://image.01lb.com.cn//uploads/wecom/21/1009/1633767898o4UJPl8q.png",
                                                  alt: ""
                                                }
                                              })
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            item.type === "applet"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mobimodel-content__pop--applet"
                                  },
                                  [
                                    _c("div", { staticClass: "name" }, [
                                      item.logo
                                        ? _c("div", { staticClass: "logo" }, [
                                            _c("img", {
                                              attrs: { src: item.logo, alt: "" }
                                            })
                                          ])
                                        : _vm._e(),
                                      _vm._v(" " + _vm._s(item.name) + " ")
                                    ]),
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v(_vm._s(item.title || item.name))
                                    ]),
                                    _c("div", { staticClass: "cover" }, [
                                      _c("img", {
                                        attrs: { src: item.cover, alt: "" }
                                      })
                                    ]),
                                    _c("div", { staticClass: "sys" }, [
                                      _c("span", [_vm._v("S")]),
                                      _vm._v("小程序")
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    )
                  })
                : _vm._e()
            ])
          ],
          2
        ),
        _vm.hasFooter
          ? _c("div", { staticClass: "mobimodel-footer" })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("i", { staticClass: "iconfont icon-a-CellularConnection" }),
      _c("i", { staticClass: "iconfont icon-Wifi" }),
      _c("i", { staticClass: "iconfont icon-Battery" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobimodel-title__right" }, [
      _c("i", { staticClass: "el-icon-more" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }