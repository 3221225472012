<template>
  <el-aside class="ly-side" width="260px">
    <div class="ly-side-bar">
      <el-menu :default-openeds="defaultOpeneds" :default-active="defaultActive">
        <el-scrollbar style="height: 100%;">
          <el-submenu :index="submenu.path" v-for="submenu in menus" :key="submenu.path"
            :class="{ 'class-active': defaultPid === submenu.meta.id }">
            <template slot="title">
              <img class="icon" :src="require(`@assets/svg/sidebar/${submenu.icon[getIconIndex(submenu)]}`)">
              <span>{{ submenu.meta.title }}</span>
            </template>
            <el-menu-item :index="item.path" v-for="item in submenu.children" :key="item.path">
              <router-link :to="item.path" :class="item.badge ? `brage brage-${item.badge}` : ''">{{ item.title }}
              </router-link>
            </el-menu-item>
          </el-submenu>
        </el-scrollbar>
      </el-menu>
    </div>
  </el-aside>
</template>

<script>
import allAsyncRoutes from '@/router/routes'
export default {
  data () {
    return {
      allAsyncRoutes
    }
  },
  computed: {
    menus () {
      const rtype = this.$store.state.permission.rtype === 'private' ? '1' : '2'
      const temp = this.$store.state.permission.asyncRoutes[this.$store.state.permission.wecomId]
      const hasCloudBean = this.$store.state.permission.hasCloudBean
      return temp.filter(item => {
        item.children = item.children && item.children.filter(e => !e.hidden)

        // 没有云豆时，隐藏云端服务菜单
        if (item.path === '/cloudService' && !hasCloudBean) {
          item.hidden = true
        }

        const id = item.meta && item.meta.id ? (item.meta.id + '').substring(0, 1) === rtype : true
        if (id && !item.hidden) {
          return item
        }
      })
    },
    // 默认展开所有项
    defaultOpeneds () {
      return this.menus.map(e => e.path)
    },
    defaultActive () {
      return this.$route.path
    },
    defaultPid () {
      return (this.$route.meta.pid + '').slice(0, 4) * 1
    }
  },
  methods: {
    getIconIndex (submenu) {
      const temp = submenu.children.map(e => e.path)
      const i = this.defaultActive.lastIndexOf('/')
      return temp.includes(this.defaultActive.substr(0, i)) ? 1 : 0
    }
  }
}
</script>

<style lang="scss" scoped>
.ly-side-bar {
  width: 260px;
  height: 100%;
  padding: 12px 10px;
  box-sizing: border-box;
  background: #fff;
  border-right: 1px solid #EAEBF2;
  z-index: 1;

  .el-menu {
    border-right: unset;
    height: 100%;

    ::v-deep {
      .el-scrollbar__view {
        max-height: 100%;
      }

      .el-submenu__title {
        padding: 0 0 0 4px !important;
        font-weight: 500;
        height: 42px;
        line-height: 42px;
        display: flex;
        align-items: center;

        &:hover {
          background: unset;
        }

        img {
          margin-right: 8px;
          height: 24px;
          width: 24px;
        }
      }

      .el-submenu {
        margin: 5px 0;

        .el-menu {
          margin-left: 25px;
          background: transparent;
          cursor: unset;
        }
      }

      .el-submenu.class-active {
        background: #F6FAFF;
        border-radius: 8px;
      }

      .el-submenu.is-opened>.el-submenu__title .el-submenu__icon-arrow {
        color: $--color-text-primary;
      }

      .el-icon-arrow-down:before {
        font-family: iconfont;
        font-size: 16px;
        content: "\e6b0";
        color: #c0c6d5;
      }
    }

    .el-menu-item {
      width: 50%;
      display: inline-block;
      min-width: unset;
      padding: 4px 0 4px 10px !important;
      color: $--color-text-regular;
      height: unset;
      line-height: 24px;
      cursor: unset;

      &:hover {
        background: unset;
      }

      &:focus {
        background: unset;
      }

      a:hover {
        color: $--color-primary;
      }

      .active {
        color: $--color-primary;
        font-weight: 500;
        position: relative;

        &::before {
          content: '';
          display: inline-block;
          width: 3px;
          height: 12px;
          border-radius: 2px;
          background: $--color-primary;
          position: absolute;
          left: -8px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .brage {
        display: inline-block;
        position: relative;
        line-height: 20px;

        &::after {
          content: '';
          position: absolute;
          display: inline-block;
          width: 40px;
          height: 32px;
          top: -20px;
          right: -24px;
          font-size: 12px;
        }

        &.brage-1::after {
          background-image: url('../../../assets/images/new.png');
          background-size: 100%;
        }

        &.brage-2::after {
          background-image: url('../../../assets/images/test.png');
          background-size: 100%;
        }

        &.brage-3::after {
          background-image: url('../../../assets/images/hot.png');
          background-size: 100%;
        }
      }
    }
  }
}
</style>
