<!--
 * @Author: zhanln
 * @Date: 2021-09-17 17:30:23
 * @LastEditTime: 2021-10-18 14:51:26
 * @LastEditors: Please set LastEditors
 * @Description: 编辑标签
-->

<template>
  <ly-dialog
    :visible.sync="visible"
    :setting="dialogSetting"
    class="edit-dialog"
    :title="title"
    @confirm="fn_confirm"
    @open="fn_open"
    @close="fn_close"
  >
    <div class="edit-tips" v-if="type === 'edit'">
      <i class="el-icon-message-solid"></i
      >注：修改/删除标签，已添加到用户身上的相关标签也随之变化
    </div>

    <el-form
      ref="formRef"
      :model="form"
      :rules="rules"
      size="medium"
      label-width="100px"
      class="edit-form"
    >
      <el-form-item label="标签组名称：" prop="tagGroupName">
        <el-input
          v-model="form.tagGroupName"
          maxlength="15"
          show-word-limit
          placeholder="请输入标签组名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-for="(tag, index) in form.tagList"
        :key="tag.key"
        :error="tag.error"
        :class="[index === form.tagList.length - 1 ? 'is-last' : '']"
        :ref="`item_${index}_ref`"
      >
        <template slot="label" v-if="index === 0"
          ><span class="q-danger">*</span> 标签名称：</template
        >
        <el-input
          v-model="tag.tagName"
          maxlength="15"
          show-word-limit
          placeholder="请输入标签名称"
          @change="fn_checkSameTag"
        ></el-input>
        <span
          class="edit-remove"
          @click="fn_removeTag(tag)"
          v-if="form.tagList.length > 1"
          ><i class="el-icon-remove-outline"></i
        ></span>
      </el-form-item>
      <el-form-item style="margin-left: -15px">
        <el-button
          round
          type="text"
          size="medium"
          icon="iconfont icon-plus"
          @click.prevent="fn_addTag()"
          >添加标签</el-button
        >
      </el-form-item>
    </el-form>

    <template #footer-left v-if="type === 'edit'">
      <el-button
        round
        type="text"
        size="medium"
        style="float: left"
        @click="fn_delGroup"
        :loading="delLoading"
        >删除标签组</el-button
      >
    </template>
  </ly-dialog>
</template>

<script>
import lyDialog from '@components/global/lyDialog'
import { tagApi } from '@components/wecomTags/http'
export default {
  name: 'edit-tags',

  components: {
    lyDialog
  },

  data () {
    return {
      COMM_HTTP: tagApi,
      visible: false,
      type: '',
      title: '编辑标签组',
      dialogSetting: {
        'close-on-click-modal': false,
        'close-on-press-escape': false,
        'append-to-body': true,
        confirmLoading: false
      },
      form: {},
      rules: {
        tagGroupName: [
          { required: true, message: '请输入标签组名称', trigger: 'blur' }
        ]
      },
      delLoading: false
    }
  },

  created () {
    this.fn_resetForm()
  },

  methods: {

    // 重置表单
    fn_resetForm () {
      this.form = {
        tagGroupName: '',
        tagList: [{
          tagName: '',
          error: '',
          tagOrder: 0
        }]
      }
    },

    // 删除标签组
    fn_delGroup (id) {
      this.delLoading = true
      this.$lyConfirm({
        title: '确定要删除该标签组（含组内的所有标签）吗？',
        text: '删除后，已添加到用户身上的相关标签也跟随删除，且不可恢复'
      }).then(async () => {
        this.dialogSetting.confirmLoading = true
        const data = await this.COMM_HTTP.delGroup({
          id: this.form.tagGroupId
        })

        if ((data && data.code) || (data && data.code === 0)) {
          this.delLoading = false
          this.dialogSetting.confirmLoading = false

          if (data.code === 1001005008) {
            this.visible = false
          }
          this.fn_lyMsg('info', data.msg)
          return false
        }

        this.dialogSetting.confirmLoading = false
        this.delLoading = false
        this.$emit('close')
        this.fn_lyMsg('success', '删除标签组成功！')
        this.visible = false
      }).catch(() => {
        this.delLoading = false
      })
    },

    // 删除标签
    fn_removeTag (item) {
      if (this.type === 'edit') {
        this.$lyConfirm({
          title: '确定要删除该标签吗？',
          text: '删除后，已添加到用户身上的该标签也跟随删除，且不可恢复'
        }).then(() => {
          var index = this.form.tagList.indexOf(item)
          this.form.tagList.splice(index, 1)
        }).catch(() => { })
      } else {
        var index = this.form.tagList.indexOf(item)
        this.form.tagList.splice(index, 1)
      }
    },

    // 添加标签
    fn_addTag () {
      this.form.tagList.push({
        tagName: '',
        error: '',
        tagOrder: this.form.tagList.length
      })
      this.$nextTick(() => {
        this.fn_scrollToView(this.$refs.formRef.$el)
      })
    },

    // 标签名校验
    fn_checkSameTag () {
      let verify = true
      const tags = this.form.tagList

      // 先校验是否为空
      let emptyLen = 0
      let errorFirst = -1
      tags.forEach((item, v) => {
        if (item.tagName === '') {
          errorFirst = errorFirst > -1 ? errorFirst : v
          item.error = '请输入标签名称'
          emptyLen++
        } else {
          item.error = ''
        }
      })

      if (emptyLen > 0) {
        this.fn_scrollToView(this.$refs[`item_${errorFirst}_ref`][0].$el, 'start')
        return false
      }

      // 再校验重复
      const tagMap = new Map()
      for (let i = 0; i < tags.length; i++) {
        if (tagMap.has(tags[i].tagName)) {
          const g = tagMap.get(tags[i].tagName)
          g.push(i)
          tagMap.set(tags[i].tagName, g)
        } else {
          tagMap.set(tags[i].tagName, [i])
        }
      }

      const sameList = []
      tagMap.forEach(function (value, key, map) {
        if (value.length > 1) {
          sameList.push(...value)
        }
      })

      if (sameList.length > 0) {
        this.form.tagList.forEach((item, index) => {
          if (sameList.indexOf(index) > -1) {
            errorFirst = errorFirst > -1 ? errorFirst : index
            item.error = '标签名称不可重复'
            emptyLen++
          } else {
            item.error = ''
          }
        })

        if (emptyLen > 0) {
          this.fn_scrollToView(this.$refs[`item_${errorFirst}_ref`][0].$el, 'start')
          return false
        }
        verify = false
      } else {
        this.form.tagList.forEach((item, index) => {
          item.error = ''
        })
      }

      return verify
    },

    // 滚动
    fn_scrollToView (elm, type = 'end') {
      elm.scrollIntoView({
        block: type,
        behavior: 'smooth'
      })
    },

    // 校验表单
    fn_submitForm () {
      let verify = true
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          verify = true
        } else {
          verify = false
        }
      })

      const verifyTag = this.fn_checkSameTag()
      return verify && verifyTag
    },

    // 提交
    fn_confirm () {
      const verify = this.fn_submitForm()
      if (!verify) return false

      this.fn_initSubForm(this.form)

      if (this.type === 'edit') {
        this.fn_editGroup()
      } else {
        this.fn_addGroup()
      }
    },

    // 修改标签组
    async fn_editGroup () {
      this.dialogSetting.confirmLoading = true
      const data = await this.COMM_HTTP.editGroup({
        ...this.form
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.dialogSetting.confirmLoading = false

        if (data.code === 1001005008) {
          this.visible = false
        }
        this.fn_lyMsg('info', data.msg)
        return false
      }

      this.fn_success('修改标签组成功！')
    },

    // 创建新标签组
    async fn_addGroup () {
      this.dialogSetting.confirmLoading = true
      const data = await this.COMM_HTTP.addGroup({
        ...this.form
      })

      if ((data && data.code) || (data && data.code === 0)) {
        this.dialogSetting.confirmLoading = false
        this.fn_lyMsg('info', data.msg)
        return false
      }

      this.fn_success('创建新标签组成功！')
    },

    // 提交处理，去除非必要的提交数据
    fn_initSubForm (data) {
      delete data.show
      let tagOrder = data.tagList.length + 1
      for (let i = 0; i < data.tagList.length; i++) {
        const tag = data.tagList[i]

        if (!tag.tagId) {
          tagOrder--
          tag.tagOrder = tagOrder
        }
        delete tag.error
        delete tag.show
      }
    },

    // 提交完成
    fn_success (msg) {
      this.$emit('confirm')
      this.visible = false
      this.fn_lyMsg('success', msg)
      this.dialogSetting.confirmLoading = false
    },

    // 打开
    fn_open () {
      this.title = this.type ? '编辑标签组' : '添加标签组'
    },

    // 关闭，重置表单
    fn_close () {
      this.fn_resetForm()
      this.$refs.formRef.resetFields()
      this.dialogSetting.confirmLoading = false
      this.type = ''
    }

  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/var.scss";
.edit {
  &-dialog {
    ::v-deep {
      .el-form-item:last-child {
        margin-bottom: 0;
      }

      .el-form-item__content {
        display: flex;
        align-items: center;
      }

      .el-form-item__label {
        padding-right: 0;
      }

      .el-input {
        width: 400px;
      }
    }
  }

  &-form {
    background-color: #fff;

    .el-form-item {
      margin-bottom: 24px;

      &.is-last {
        margin-bottom: 16px;
      }
    }
  }

  &-tips {
    display: flex;
    height: 40px;
    border-radius: 8px;
    align-items: center;
    border: 1px solid #deecff;
    background-color: #fafbfe;
    box-sizing: border-box;
    margin-bottom: 24px;
    padding: 0 12px;
    width: 500px;

    i {
      font-size: 16px;
      color: $--color-primary;
      margin-right: 4px;
    }
  }

  &-remove {
    display: inline-flex;
    line-height: 1;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-left: 6px;
    font-size: 18px;
    color: $--color-text-placeholder;
    transition: 0.25s color;

    &:hover {
      cursor: pointer;
      color: $--color-danger;
    }
  }
}
</style>
