/*
 * @Author: zhanln
 * @Date: 2021-06-18 14:59:10
 * @LastEditTime: 2022-12-15 14:16:34
 * @LastEditors: zhanln
 * @Description: api
 */

let host, frontHost
if (process.env.NODE_ENV === 'test') {
  host = 'http://api.test.01lb.com.cn'
  frontHost = 'http://www.test.01lb.com.cn'
} else if (process.env.NODE_ENV === 'dev') {
  host = 'http://api.dev.01lb.com.cn'
  // host = 'http://api.test.01lb.com.cn'
  frontHost = 'http://www.dev.01lb.com.cn'
  // frontHost = 'http://192.168.10.76:8877/'
} else if (process.env.NODE_ENV === 'prod') {
  host = 'https://api.01lb.com.cn'
  frontHost = 'https://www.01lb.com.cn'
}

const modulesFiles = require.context('./modules', false, /\.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const value = modulesFiles(modulePath)
  modules = Object.assign(modules, value.default)
  return modules
}, {})

const apis = {
  host,
  frontHost,
  ...modules
}

export default apis
