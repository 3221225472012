/*
 * @Author: zhanln
 * @Date: 2021-09-24 18:49:48
 * @LastEditTime: 2021-12-28 11:19:55
 * @LastEditors: zhanln
 * @Description:
 */
export default {
  state: {
    isCopy: false,
    isEdit: false,
    account_amount: 100,
    account_amount_fmt: '',
    status: 1,
    basic: {
      title: '',
      end_date: null,
      wecom_user_ids: null,
      code_fail_date: null,
      code_fail_mode: 1,
      welcome_msg: '#客户昵称#，终于等到你\n\n1. 参与本次活动，马上领取微信红包，直接到账的那种~\n2. 点击下方链接，立即领取。\n3. 生成你的专属海报，每邀请1位好友助力即可领取随机红包。\n4. 红包金额实时到账，可在微信钱包实时查看。\n\n点击链接立即领取红包（红包实时到账微信零钱）',
      link_type: 1,
      link_logo: [{
        url: 'https://image.01lb.com.cn//uploads/wecom/21/1008/1633687472W18cbF1A.png'
      }],
      link_title: '点击链接立即领取红包👇',
      link_desc: '红包实时到账微信零钱'
    },
    redenvelope: {
      account: {
        account_amount: null,
        account_amount_fmt: ''
      },
      amount: 0,
      activity_amount: 0,
      give_amount: 0,
      virtual_average: 4.3,
      first_give_min: 0.31,
      first_give_max: 1,
      is_give_old_user: 0,
      invite_num: 1,
      give_min: 0.31,
      give_max: 1,
      give_limit: 5,
      is_auto_tags: 0,
      auto_tags: [{
        id: 0,
        key: 0,
        invite_num: 0,
        tags: '',
        type: 1
      }, {
        id: 0,
        key: 1,
        invite_num: 2,
        tags: '',
        type: 2
      }]
    },
    poster: {
      img_url: 'https://image.01lb.com.cn//uploads/wecom/21/0902/16305856436tfAq38m.png',
      avatar_sharp: 2,
      avatar_status: 1,
      avatar_x: 30,
      avatar_y: 40,
      avatar_width: 50,
      avatar_height: 50,
      nickname_status: 1,
      nickname_size: 12,
      nickname_color: '#000000',
      nickname_x: 90,
      nickname_y: 55,
      nickname_offsetX: 0,
      nickname_align: 'left',
      qrcode_x: 230,
      qrcode_y: 512,
      qrcode_width: 80,
      qrcode_height: 80,
      share_msg: '我想要领取红包，帮忙扫码添加海报内的企业微信就能帮我助力啦！'
    },
    brush: {
      is_info: 1,
      is_over_info: 1,
      over_rate: 60,
      is_invite_limit: 0,
      invite_second: 1,
      invite_num_limit: 10
    },
    other: {
      show_title: '活动进行中',
      bg_title: '邀请好友领红包',
      rule_desc: '1、邀请【邀请好友数】位好友添加企业微信，立即获得微信实时到账的红包。\n2、任务完成后，系统实时发放红包至邀请者的微信零钱，用户可查看微信支付通知，或前往 “微信-我-支付-钱包” 中查看。\n3、若邀请助力的用户为老用户或该用户已经为他人助力，则此次助力无效。\n4、活动截止时间为【活动结束时间】，活动结束后，用户助力无法再获得红包。\n5、删除好友后助力无效。'
    }
  }
}
