/*
 * @Author: zhanln
 * @Date: 2021-12-27 16:18:14
 * @LastEditTime: 2021-12-27 16:19:17
 * @LastEditors: zhanln
 * @Description:
 */

const scaleAvatar = (url) => {
  const isQlogo = /^(http|https):\/\/wx.qlogo.cn\//
  const isQpic = /^(http|https):\/\/wework\.qpic\.cn\//
  const reg = /\/0$/
  if (isQpic.test(url)) {
    return url.replace(reg, '/100')
  }

  if (isQlogo.test(url)) {
    return url.replace(reg, '/96')
  }

  return url
}

export default scaleAvatar
