var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "main", staticClass: "main" },
    [
      _c(
        "el-container",
        { staticClass: "main-container" },
        [
          !_vm.$route.params.id
            ? _c(
                "el-container",
                { staticClass: "main-container-inner" },
                [
                  _c(
                    "el-header",
                    { staticClass: "main-header", attrs: { height: "48px" } },
                    [
                      _c(
                        "el-row",
                        {
                          staticClass: "row-bg",
                          attrs: { type: "flex", justify: "space-between" }
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              !_vm.$route.meta.backPath
                                ? _c(
                                    "el-tabs",
                                    {
                                      class: {
                                        "is-only": _vm.tabs.length === 1
                                      },
                                      on: {
                                        "tab-click": function($event) {
                                          return _vm.$router.push(
                                            _vm.activeName
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.activeName,
                                        callback: function($$v) {
                                          _vm.activeName = $$v
                                        },
                                        expression: "activeName"
                                      }
                                    },
                                    _vm._l(_vm.tabs, function(item) {
                                      return _c("el-tab-pane", {
                                        key: item.path,
                                        attrs: {
                                          label: item.title,
                                          name: item.path
                                        }
                                      })
                                    }),
                                    1
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass: "ly-tool__back",
                                      staticStyle: { height: "48px" },
                                      on: {
                                        click: function($event) {
                                          _vm.$route.meta.backPath
                                            ? _vm.$router.push(
                                                _vm.$route.meta.backPath
                                              )
                                            : _vm.$router.back()
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont icon-left1"
                                      }),
                                      _vm._v(
                                        _vm._s(
                                          _vm.firstTitle ||
                                            _vm.$route.meta.title
                                        ) +
                                          _vm._s(_vm.cusTitle) +
                                          " "
                                      )
                                    ]
                                  )
                            ],
                            1
                          ),
                          _vm.isSale
                            ? _c("el-col", { attrs: { span: 12 } }, [
                                _c(
                                  "div",
                                  { staticClass: "main-header--right" },
                                  [
                                    !_vm.isBought
                                      ? [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "12px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "您还未开通应用，点击开通立即获得" +
                                                  _vm._s(_vm.appName) +
                                                  "全部功能~"
                                              )
                                            ]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.fn_showPayDialog(
                                                    "first"
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("开通应用")]
                                          )
                                        ]
                                      : [
                                          _vm.buyInfo
                                            ? [
                                                _vm.remainDay > 0
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _vm._v(
                                                          " 应用剩余天数：" +
                                                            _vm._s(
                                                              _vm.remainDay
                                                            ) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "el-tag",
                                                          {
                                                            staticStyle: {
                                                              "margin-right":
                                                                "12px",
                                                              "margin-left":
                                                                "12px",
                                                              "border-radius":
                                                                "12px"
                                                            },
                                                            attrs: {
                                                              type: "danger",
                                                              size: "small"
                                                            }
                                                          },
                                                          [_vm._v("即将到期")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "div",
                                                      [
                                                        _vm._v(
                                                          " 应用已到期（" +
                                                            _vm._s(
                                                              _vm.expiredTime
                                                            ) +
                                                            "到期） "
                                                        ),
                                                        _c(
                                                          "el-tag",
                                                          {
                                                            staticStyle: {
                                                              "margin-right":
                                                                "12px",
                                                              "border-radius":
                                                                "12px"
                                                            },
                                                            attrs: {
                                                              type: "danger",
                                                              size: "small"
                                                            }
                                                          },
                                                          [_vm._v("已到期")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click:
                                                        _vm.fn_showPayDialog
                                                    }
                                                  },
                                                  [_vm._v("立即续费")]
                                                )
                                              ]
                                            : _vm._e()
                                        ]
                                  ],
                                  2
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "keep-alive",
                    [_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()],
                    1
                  ),
                  !_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()
                ],
                1
              )
            : _c("ly-apps", { attrs: { id: _vm.$route.params.id } })
        ],
        1
      ),
      _c("ly-pay", {
        attrs: {
          payDialogVisible: _vm.payDialogVisible,
          title: _vm.payTitle,
          appTitle: _vm.appName,
          payType: _vm.payType
        },
        on: {
          "update:payDialogVisible": function($event) {
            _vm.payDialogVisible = $event
          },
          "update:pay-dialog-visible": function($event) {
            _vm.payDialogVisible = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }