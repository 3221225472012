<template>
  <div class="login-header">
    <div class="login-width flex">
      <div class="flex">
        <div class="flex flex-center">
          <img src="~@assets/svg/01sk.svg" style="margin-right: 24px" />

          <el-link
            v-if="showWork"
            href="https://open.work.weixin.qq.com"
            :underline="false"
            target="_blank"
            style="line-height: 0"
            ><img
              src="~@assets/svg/wechat_work.svg"
              referrerpolicy="unsafe-url"
              alt="企业微信服务商"
          /></el-link>
        </div>

        <el-dropdown v-if="showCompany" @command="fn_CompanyCommand">
          <span class="el-dropdown-link">
            <div class="login-header__user">
              {{ companyName ? companyName : '' }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </div>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="company">选择企业</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <HeadSetting :userInfo="userInfo" v-if="showUserInfo"></HeadSetting>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import HeadSetting from '../../layout/components/HeaderSetting.vue'
export default {
  name: 'LoginHeader',
  components: {
    HeadSetting
  },
  props: {
    showWork: {
      type: Boolean,
      default: false
    },
    showUserInfo: {
      type: Boolean,
      default: false
    },
    showUserCenter: {
      type: Boolean,
      default: false
    },
    showCompany: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      companyName: ''
    }
  },

  async created () {
    this.companyName = localStorage.getItem('companyName')

    if (this.showUserInfo) {
      this.$store.dispatch('permission/GenerateUserInfo')
    }
  },

  computed: {
    ...mapState({
      userInfo: state => state.permission.userInfo
    })
  },

  methods: {
    // 企业下拉操作
    fn_CompanyCommand (command) {
      if (command === 'company') {
        this.$router.push('/company')
        localStorage.removeItem('config')
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.login {
  &-width {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 40px;
  }

  &-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    z-index: 1;

    &.is-high {
      z-index: 3000;
    }

    &__user {
      display: flex;
      align-items: center;

      &-avatar {
        width: 32px;
        height: 32px;
        margin-right: 12px;
        border-radius: 32px;
      }
    }
  }
}
</style>
