var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "lyEditor_" + _vm.id,
      class: ["ly-editor", _vm.error ? "is-error" : ""]
    },
    [
      _vm.insetMenu.length > 0 || _vm.emoji
        ? _c("div", { staticClass: "ly-editor__header" }, [
            _c(
              "div",
              { staticClass: "ly-editor__header-left" },
              [
                _vm.emoji
                  ? _c("ly-emoji", { on: { change: _vm.handleEmojiChange } }, [
                      _c("div", { staticClass: "emojibox" }, [
                        _c("i", { staticClass: "iconfont icon-emo" })
                      ])
                    ])
                  : _vm._e(),
                _vm._l(_vm.insetMenu, function(item, index) {
                  return _c(
                    "el-button",
                    {
                      key: index,
                      staticClass: "ly-editor__menus",
                      attrs: { icon: _vm.getIcon(item) },
                      on: {
                        click: function($event) {
                          return _vm.fn_insetText(item, "#")
                        }
                      }
                    },
                    [_vm._v(_vm._s(item))]
                  )
                })
              ],
              2
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "ly-editor__body" },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              resize: "none",
              placeholder: "请输入" + _vm.title,
              id: _vm.id,
              maxlength: _vm.maxLength
            },
            on: { focus: _vm.fn_focus, blur: _vm.fn_blur },
            model: {
              value: _vm.selfContent,
              callback: function($$v) {
                _vm.selfContent = $$v
              },
              expression: "selfContent"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "ly-editor__footer" }, [
        _vm.recoverContent
          ? _c(
              "span",
              {
                staticClass: "ly-editor__recover",
                on: { click: _vm.fn_recover }
              },
              [_vm._v("恢复默认文案")]
            )
          : _vm._e(),
        _c("span", { staticClass: "ly-editor__count" }, [
          _vm._v(_vm._s(_vm.selfContent.length) + " / " + _vm._s(_vm.maxLength))
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }