var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          staticClass: "emp-btn",
          attrs: { round: "", icon: _vm.icon || "", type: "primary" },
          on: { click: _vm.fn_open }
        },
        [_vm._v(_vm._s(_vm.btnText))]
      ),
      _c("ly-robot", { ref: "lyRobotRef", on: { callback: _vm.fn_getList } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }